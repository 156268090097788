import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, TemplateRef } from '@angular/core';
import { FormControl } from "@angular/forms";
import { Router } from '@angular/router';
import { NavController, PopoverController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { select, Store } from '@ngrx/store';
import { AutocompleteComponent } from "angular-ng-autocomplete";
import { Observable, Subscription } from 'rxjs';
import { actions, authUser } from 'src/app/authStore/auth.selector';
import { AppState } from 'src/app/reducers';
import { ConfigService } from "src/app/services/config/config.service";
import { HttpService } from 'src/app/services/http/http.service';
import { ProcessService } from 'src/app/services/process/process.service';
import { IssueAction } from "../actions/issue/state/issue.action.type";
import { environment } from "src/environments/environment";
import { AngularFireDatabase } from '@angular/fire/database';
import { ACTIONNAMES } from "src/app/services/endpoint.enum";
import { DynamicPopoverComponent, popoverProps } from "../dynamic-popover/dynamic-popover.component";
import { MixpanelService } from 'src/app/services/mixpanel/mixpanel.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild('auto') auto: AutocompleteComponent;
  @ViewChild('userProfile') userProfile: TemplateRef<any>;
  @Input() page: any;
  @Input() clrSearch: any;
  @Input() myTask: any;
  environment = environment;
  userName;
  userDesignation: string = '';
  selectedAction = new FormControl
  loggedinUser$: Observable<any>;
  userActions$: Observable<any>;
  listOfActions: [];
  public selectedActionName: string = '';
  public keyword = 'actionName';
  @Output() backBtnEvent = new EventEmitter<string>();
  @Output() onDidDismissPushOver: EventEmitter<any> = new EventEmitter();
  @Output() onTaskClickEvent: EventEmitter<any> = new EventEmitter();
  //@Output() actionSelected = new EventEmitter<string>();
  private pushOverOpen: boolean = false;
  private qcpCompoOpen: boolean = false;
  suscription: Subscription;
  deptName: any;

  // @HostListener('document:keydown', ['$event']) onKeydownHandler(evt: KeyboardEvent) {
  //   console.log(evt.key);
  //   let urlContents = location.href.split("/")

  //   let pageName = urlContents[urlContents.length - 1];
  //   console.log(urlContents);
  //   console.log(pageName);

  //   } 

  constructor(
    private store: Store<AppState>,
    public router: Router,
    public httpService: HttpService,
    public processService: ProcessService,
    private configService: ConfigService,
    public storage: Storage,
    public popoverController: PopoverController,
    private navCtrl: NavController,
    private mixpanelService: MixpanelService,
  ) {
    console.log('header constructor');
    this.store.pipe(select(authUser)).subscribe((d: any) => {
      if (d) {
        this.userName = d.fullName;
        this.userDesignation = d.isManager ? 'Stock Controller' : (d.roles?.includes('Manager Transfer') ? 'Manager' : 'Worker');
        this.deptName = d.departmentName.replace(/department$/i, '').trim();
        if (!!this.userName) {
          this.httpService.getUserData(this.userName).subscribe(d => {
            if (!!d && d.isTrue) {
              this.httpService.removeUserDocument(this.userName)
              this.router.navigate(["/home"]);
            }
          })
        }
      }
    });
  }

  ngOnInit() {
    console.log('header NGONiNIT ');
    this.loggedinUser$ = this.store.pipe(select(authUser));
    this.userActions$ = this.store.pipe(select(actions));
    this.userActions$.subscribe(actions => {
      if (actions) {
        this.listOfActions = actions;
      }
    });
    this.selectedActionName = "";
    console.log(this.auto);
    // this.auto.clear();
  }

  backToHome() {
    this.mpTrack('Home', '');
    this.router.navigate(['/home']);
    // this.backBtnEvent.emit();
  }

  mpTrack(actionName:any, actionId:any){
    this.processService.mpTrack(
      actionName,
      actionId,
    );
  }


  async selectEvent(item) {
    this.selectedActionName = "";

    console.log("selectEvent", item)
    // do something with selected item
    this.selectedActionName = item.actionName;

    if (this.selectedActionName == 'With Me') {
      this.store.dispatch(IssueAction.setStartPoint({ startFrom: 'Packet' }));
    }

    this.processService.setActionName(
      item.actionName,
      item.id,
      !!item.voucherType ? item.voucherType : '',
      item.listOfActionPermissions,
      ""
    )
    console.log(this.router.url)
    this.processService.actionSelected$.emit(false)


    if (item.actionName === ACTIONNAMES.responsibleUsers) {
      this.router.navigate(["allwithmedata"]);
    this.clearSearchValue();
      return
    }
    if (item.actionName === "Wages Master") {
      this.router.navigate(['/wages-add'])
    this.clearSearchValue();
      return
    }
    else if (item.actionName == ACTIONNAMES.advisorParameterMapping) {
      this.router.navigate(['advisorParamMap']);
    this.clearSearchValue();
      return;
    

      // const popover = await this.popoverController.create({
      //   component: PopoverUserComponent,
      //   cssClass: 'advisorParameterMapping',
      //   componentProps: {
      //     forPage: 'advisorParameterMapping',
      //   },
      // });
      // return popover.present();
      // popover.onDidDismiss().then((res) => {
      // });
    }

    console.log('%cthis.router.url ---', 'color:purple;font-size:14px')
    if (this.router.url != '/actionsWindow') {
      console.log('object111');

      this.processService.actionSelected$.emit(true)
      setTimeout(() => {
        this.router.navigate(['actionsWindow']);
      }, 500);

      //this.processService.actionSelected$.emit("true")
    } else {
      console.log('object222');
      this.processService.actionSelected$.emit(true)
    }
    this.clearSearchValue();
  }


  onChangeSearch(val: string) {
    console.log(val);
    console.log(this.listOfActions)
    let isExist = this.listOfActions.find((x: any) => x.actionName.toLocaleLowerCase() == val)
    console.log(isExist)
    if (isExist) {
      this.selectEvent(isExist)
    }
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }
  onFocused(e) {
    // do something when input is focused
  }
  // async logout() {
  //   this.store.dispatch({ type: 'Logout' });
  //   await this.storage.clear();
  //   this.httpService.isAuthenticated.next(false);
  //   this.router.navigateByUrl('/login')
  //   this.popoverController.dismiss();
  // }

  // getFirstLetterOfEachWord(str) {
  //   var newStr = str.split(' ').reduce((result, currentWord) =>
  //     result + currentWord.charAt(0).toUpperCase(), '');
  //   return newStr;
  // }

  backIt(): void {
    console.log('%cBack button trigger BEGIN', "font-size:1rem;color:#81B622;");
    console.log(this.navCtrl);
    this.backBtnEvent.emit();
    this.selectedActionName = "";
    //this.location.back();
    this.auto.clear();
    this.configService.backClicked(true);
    this.navCtrl.pop();
    console.log('%cBack button trigger FINISHED', "font-size:1rem;color:#81B622;");
  }

  async onUserClick(ev) {
    console.log(ev);
    if (!this.pushOverOpen) {
      this.pushOverOpen = true;
      const componentProps:popoverProps={
        alignment:"center",
        side:"left",
        template: this.userProfile,
        triggerId:"trigger-id"
      }
      const popover = await this.popoverController.create({
        component: DynamicPopoverComponent,
        cssClass: 'popover-user-profile',
        // showBackdrop: false,
        // data:,
        componentProps: { data:componentProps },
        event: ev,
        // translucent: true
      });
      await popover.present();
      await popover.onDidDismiss()
        .then((data) => {
          this.pushOverOpen = false;
          this.handleOnPushOverDismiss(data);
        });
    }
  }
  handleOnPushOverDismiss(d) {
    this.onDidDismissPushOver.emit(d);
    if (!!d && !!d.data && !!d.data.type && d.data.type == "Logout") {
      this.clearSearchValue();
    }
  }
  clearSearchValue() {
    this.selectedActionName = "";
    if (this.auto) {
      this.auto.clear();
    }
  }
  async openQCAnnotation() {
    console.log('openQCAnnotation');
    let modal = document.getElementsByTagName("ion-modal")[0];

    if (!!modal && modal.classList.contains("hide-modal")) {
      modal.classList.remove("hide-modal")
    }

    if (!this.qcpCompoOpen) {
      this.router.navigate(['/qcannotation']);
      // this.qcpCompoOpen = true;
      // const modal = await this.mdlCtrl.create({
      //   component: QcAnnotationComponent,
      //   cssClass: 'qcAnnotation',
      //   // showBackdrop: false,
      //   // data:,
      //   componentProps: { msg: 'Top Corner User' },
      //   // event: ev,
      //   // translucent: true
      // });
      // await modal.present();
      // await modal.onDidDismiss()
      //   .then((data) => {
      //     this.qcpCompoOpen = false;
      //     this.handleOnQCPModalDismiss(data);
      //   });
    }
  }
  handleOnQCPModalDismiss(d) {
    console.log('handleOnQCPModalDismiss');
    console.log(d);
  }
  goToMyTask() {
    this.onTaskClickEvent.emit();
  }
  ngOnDestroy(): void {
    console.log('header destroyed')
    this.selectedActionName = "";
    if (this.suscription) {
      this.suscription.unsubscribe();
    }
  }

  async onLogout() {
    this.store.dispatch({ type: 'Logout' });
    await this.storage.clear();
    this.httpService.isAuthenticated.next(false);
    this.popoverController.dismiss({ 'type': 'Logout', 'forPage': '' });
    setTimeout(()=>{
      sessionStorage.setItem('refreshData','false')
    },100)
    // console.log("Before clearing localStorage:", localStorage);
    // this.router.navigate(['/login']);
    // localStorage.removeItem("token");
    // localStorage.removeItem("userId");
    // localStorage.clear();
    // this.mixpanelService.logout();
  }

  // window(){
  //   window.open("http://192.168.7.150")

  // }
}
