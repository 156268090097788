export enum ENDPOINTS {
  MarkPacketAsUnproccessedForAutoReceive = "packet/markPacketAsUnproccessedForAutoReceive",
  opnionMasterSave = "opnion-master/save",
  opnionMasterfindAll = "opnion-master/findAll",
  generateTaskForReject = "virtual-task/generateTaskForReject/",
  isRejectedStatus = "packet-parameter-on-each-dept/isRejectedStatus",
  addMistakeLogs = "processActionHistoryLog/addMistakeLogs",
  addMistakeEntryOnLock = "processActionHistoryLog/addMistakeEntryOnLock",
  getLastMistakeEntry = "processActionHistoryLog/getLastMistakeEntry",
  inputReceiverByActionId = "processActionInput/inputReceiverByActionId",
  getPrintDataForDispatch = "packet/getPrintDataForDispatch?voucherId=",
  getIntermediatePlans = "packet/getIntermediatePlans",
  transferPacketFetch = "packet/transferPacketFetch",
  fetchPacketWhichAreLock = "packet/fetchPacketWhichAreLock",
  assignNextTaskForApprovalOnLock = "packet/assignNextTaskForApprovalOnLock",
  findAllByPacketIdOnAnyPacket = "packetPlanStone/findAllByPacketIdOnAnyPacket",
}

export enum ACTIONNAMES {
  opinionMaster = "Opinion Master",
  opinionGeneratorView = "Opinion Generator View",
  PrevuniversalOpinionView = "Prev universal Opinion View",
  universalAdminApprovalView = "Universal Admin Approval View",
  UNIVERSAL_OPINION_VIEW = "Universal Opinion View",
  lockMaster = "Lock Master",
  lockMaster2 = "Opinion approval",
  mistakeEntry = "Opinion Generator",
  mistakeEntry2 = "Generate Opinion",
  anyCut = "Any Cut Planning",
  reprint_dispatch_voucher = "Reprint Dispatch Voucher",
  recut = "Recut",
  autoReceive = "Auto Receive",
  return = "Return",
  userAdmin = "User Admin",
  transfer = "Transfer",
  FINAL_PLANNING = "Final Planning",
  RECONFIRM_FINAL_PLAN = "Reconfirm Final Plan",
  WITH_ME = "With Me",
  responsibleUsers = "Responsible User",
  advisorParameterMapping = "Advisor Parameter Mapping",
  managerTrasnfer = "Manager Transfer",
  thirdPartyTrf = "Third-Party Transfer",
  signerRequest = "Signer Requests",
  deptRuleConfig = "Department Rule Config",
  polishGrade = "Polish Grade Master",
  tallyMismatchedStock = "Tally Mismatched Stock",
  tallyStock = "Tally Stock",
  retunToSafe = "Return To Safe",
  opinionApprovalMaster = "Opinion Approval Master",
  trfPacketToDispatch = "Transfer To Dispatch",
  finalApprovalOnLock = "Final Approval On Lock",
  firstAllocation = "First Allocation",
  adminApproval = "Admin approval",
  intermediatePlanning = "Intermediate Planning",
  MAKEABLE_PLANNING = "Makeable Planning",
  packetReparing = "Packet Repairing",
  verifyRepairingAsking = "Verify Repairing Asking",
  generateReport = "Generate Reports",
  FETCH_BARCODE = "Fetch Barcode",
  PACKET_BIFURCATION_VIEW = "Packet Bifurcation View",
  Sales_Return_Voucher = "Sales Return Voucher",
  PROCESS_RULE_CONFIG = "Dept process Route Config",
  Self_Sales_Return_Voucher = "Self Sales Return Voucher",
  Opinion_Task_View = "Pending Packet Opinion View",
  Third_Party_config = "Third Party Config",
  Reconfirm_Bid_plan = "Reconfirm Bid Plans",
  BOMBAY_MAIL = "Manual approval",
  LOSS_LEDGER = "Loss Ledger",
  VIEW_WAGE_LEDGER = "View Wage ledger ",
  Planning = 'Planning',
  OPINION_REMARK = "Opinion Remark",
  Planning_Suggestion_Master = "Planning Suggestion Master", 
  ORDER_FORM = "Order Form",
  ORDER_DASHBOARD = "Order Dashboard"

 }

export enum REPORTS {
  // ALL_ACTION_PARAMETERS_REPORT = 'All Actions Parameter Report',
  PACKET_REPORT = "Pre-virtual Planning Status Report",
  // FINAL_PARAMETERS = 'Final parameters',
  // QC_REPORT = 'QC reports',
  STOCK_REPORT = "Current Stock Report",
  Yehuda_Report = "Yehuda Report",
  PACKET_PLAN_REPORT = "Packet Plan Report",
  VIRTUAL_TASK_Report = "Facets Pre-virtual Planning Status Report",
  // Facet_Dispatch = "Facet Dispatch",
  MANUAL_COLOR_FILL_REPORT = "Manual Color Fill Report",
  // lot_report = "Lot Report",
  signer_checker_Parameter_report = "Signer Checker Parameter Report",
  facet_signer_checker_Parameter_report = "Facet Signer Checker Parameter Report",
  stock_Report_without_parameter = "Stock Report Without Parameters",
  // curr_Dept_Param_report = 'Current Department Parameter Report'
  CSFCReport = "CSFC Report",
  // Facets_Outstanding_Report = "Facets Outstanding Report",
  Facets_Yehuda_Report = "Facets Yehuda Report",
  DNAReport = "DNA Report",
  Packet_Status_And_Details_Report = "Packet Status and Details Report",
  // Bid_Prediction_Report = "Bid Prediction Report",
  // Bid_Prediction_Status_Report = "Bid Prediction Status Report",
  Signer_Wages_Report = "Signer Wages Report",
  Checker_Bid_Report = "Checker Wages Report",
  Process_wise_Production_Report = "Process Wise Production Report",
  Department_wise_Production_Report = "Department Wise Production Report",
  PACKET_PLAN_REPORT_Lot_Wise='Packet Plan Report Lot wise'

}

export enum ROLES {
  adminApproval = "Admin approval",
  checker = "Checker",
}

export enum InternalCompany {
  tieaDiamond = "tieadiamond",
  facet = "Facet",
}

export enum ParameterTypes {
  Fluorescence = 'FLUORESCENCE',
  Color = 'COLOR',
  Purity = 'PURITY',
  Shape = 'SHAPE',
  TensionMapping = 'TENSION MAPPING',
  Stress = 'STRESS',
  StressType = 'STRESS TYPE',
  Die = 'DIE',
  StandNumber = 'STAND NUMBER',
  MachineNumber = 'MACHINE NUMBER',
  WorkerCode = 'WORKER CODE',
  MachineType = 'MACHINE TYPE',
  ProcessType = 'PROCESS TYPE',
  Remarks = 'REMARKS',
  Clarity = 'CLARITY',
  Milkyness = 'MILKYNESS',
  Tinch = 'TINCH',
  CUT = 'CUT',
  Symmetry = 'SYMMETRY',
  Polish = 'POLISH',
  Girdle = 'GIRDLE',
  Chapka = 'CHAPKA',
  NATTS = 'NATTS',
  LUSTER = 'LUSTER',
  POL = 'POL',
  TOP_OPEN = 'TOP OPEN',
  BOTTOM_OPEN = 'BOTTOM OPEN',
  DEPTH = 'DEPTH',
  Lab = 'LAB',
  StressValue = 'STRESS VALUE',
  OpinionRemark = 'OPINION REMARK',
  RATIO = 'RATIO'
}

