import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { MultipleBarcodeScannerComponent } from "../components/multiple-barcode-scanner/multiple-barcode-scanner.component";
import { HttpService } from "../services/http/http.service";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { AlertController, ModalController } from "@ionic/angular";
import { ConfigService } from "../services/config/config.service";
import { AppState } from "../reducers";
import { select, Store } from "@ngrx/store";
import { printData } from "../masterDataStore/master.data";
import { StaticInfo } from "src/environments/environment";
import { LoaderService } from "../services/loader/loader.service";
import { masterDataActions } from "../masterDataStore/masterData.action-type";
import { getAllTrybeDepartments } from "../masterDataStore/masterData.selector";
import { DynamicModalComponent } from "../transfer-packet-to-dispatch/dynamic-modal/dynamic-modal.component";
import { TrybemodalComponent } from "../components/trybemodal/trybemodal.component";
import * as moment from "moment";
import { ScannerinputComponent } from "../components/scannerinput/scannerinput.component";

@Component({
  selector: "app-packet-repairing",
  templateUrl: "./packet-repairing.component.html",
  styleUrls: ["./packet-repairing.component.scss"],
})
export class PacketRepairingComponent implements OnInit {
  barcodeInputList: any;
  readonly excelRegex = /\.xlsx$/i;
  readonly csvRegex = /\.csv$/i;
  selectRoles: string;
  deptDropdownDataOptions: any;
  selectedDipatchDepartment: null;
  infoToPrint: Object = {};
  dispatchData: any;
  voucherDto: any;
  isWageGiven: boolean = false;
  printData: any = printData;
  dispatchLossData: any;
  Columns: any;
  dispatchErrorMsg: string;
  @ViewChild("DispatchForm") dispatchModal: TemplateRef<any>;
  @ViewChild(ScannerinputComponent)
  private scannerComponet!: ScannerinputComponent;
  currid: any;
  wageOptions = [
    { label: "No Pay", value: 0 },
    // { label: "On Difference", value: 1 },
    { label: "On wage Rule", value: 2 },
  ];
  selectedValue: number = 0;
  packetId: any;
  detailColumns: any;
  allDeptValues: any;
  selectedWage: any;
  constructor(
    private store: Store<AppState>,
    public httpService: HttpService,
    public http: HttpClient,
    private router: Router,
    private alertCtrl: AlertController,
    private configService: ConfigService,
    private modalController: ModalController,
    private loaderService: LoaderService
  ) {
    this.detailColumns = [
      { name: "Process", prop: "actionName" },
      { name: "name", prop: "wageName" },
      { name: "Wage as per rule", prop: "amount" },
      { name: "Amount", prop: "isActive", columnTemplate: "isInput" },
    ];
  }
  wagesColumn: any = [
    { name: "Lot No", prop: "lotNo", columnTemplate: "lot-svg-template" },
    { name: "Packet ID", prop: "packetId" },
    { name: "Cts", prop: "cts" },
    { name: "InwardDate", prop: "inDate" },
    { name: "CreatedDate", prop: "createdDate" },
  ];
  dataSource: any = [];
  TransferdataSource: any = [];
  GridDataSource: any = [];
  ngOnInit() {
    this.infoToPrint = {};
    this.infoToPrint = {
      company: StaticInfo["company"], //Static Name
      address: StaticInfo["address"], //Static Address
      issueFor: null,
      issueDate: null, //Jangad Date
      issueNo: null, //Jnd No,
      stoneType: StaticInfo["stoneType"],
      senderNotes: StaticInfo["senderNotes"],
      processRemark: StaticInfo["processRemark"],
      subProcessRemark: StaticInfo["subProcessRemark"],
      data: {},
    };
    this.store.dispatch(masterDataActions.getRolesForUser());
    this.store.dispatch(masterDataActions.getTrybeDepartments({ packetIds: null }));

    this.store.pipe(select(getAllTrybeDepartments)).subscribe(data => {
      if (data) {
        this.allDeptValues = data;
        this.deptDropdownDataOptions = this.allDeptValues;
      }
    });
  }

  async openBarcodeScannerModal() {
    const modal = await this.modalController.create({
      component: MultipleBarcodeScannerComponent,
      cssClass: "multiplBarcodeScanner",
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === "done") {
      this.barcodeInputList = data.map(barcode => barcode.barcode);
      // if(this.dataSource.length>=1){
      //   this.configService.showToast('error', 'First Transfer Existing Stone ');
      //   return;
      // }
      // else {
      let payload = { packetId: this.barcodeInputList };
      this.currid = this.barcodeInputList[0];
      this.fetchGridData(payload);
      // }
    }
  }

  clearBarcode() {}

  async fetchGridData(payload) {
    try {
      const res: any = await this.httpService.postDataUsingPromise(true, "packet/transferPacketFetch", payload);
      if (res.statusCode === 200) {
        const newData = res?.data.packetData || [];
        if (newData.length === 0) {
          this.configService.showToast("error", "entered invalid packet / Packet not available");
          return;
        }
        // this.dataSource = (this.dataSource || []).concat(res?.data.packetData || []);
        const existingIds = new Set(this.dataSource ? this.dataSource.map(item => item.id) : []);
        // const newData = res?.data.packetData || [];
        const uniqueData = newData.filter(item => !existingIds.has(item.id));
        this.dataSource = (this.dataSource || []).concat(uniqueData);
        this.packetId = this.dataSource[0].packetId;
        await this.configService.showToast("Error", "Please Upload CSV File");
        if (res.data.packetData.length > 0) {
          this.currid = res.data.packetData[0].id;
          document.getElementById("fileInput").click();
        }
      }
    } catch (error) {
      console.error("An error occurred:", error);
      this.configService.showToast("error", error.message || "Something went wrong!");
    } finally {
    }
  }

  onSelectionChanged(event) {
    if (!!event.row) {
      this.currid = event.row.id;
      this.packetId = event.row.packetId;
      console.log(this.currid);
      document.getElementById("fileInput").click();
    }
  }

  onDepartmentChange(event) {

    if (!event) {
      this.selectRoles = "";
     this.TransferdataSource = [];
      return;
    }
    if (this.selectedValue == 2) {
      this.TransferdataSource = this.GridDataSource
        .filter((item: any) => item.deptID == this.selectedDipatchDepartment)
      // .map((item: any) => {
      //   return {
      //     ...item,  
      //   };
      // });
    }
    this.selectRoles = event.receiveRole;
  }

  async fileUpload(event) {
    // this.openTransferModal();
    try {
      let file;
      if (!!event?.target?.files && event?.target?.files?.length > 0) {
        file = event.target.files[0];
      }
      if (!this.validateFile(file?.name)) return;
      const formatData = new FormData();
      formatData.append("file", file);
      formatData.append("packetId", this.currid);
      const res: any = await this.httpService.saveDataToDB(true, `packetPlan/packetRepairingFileUpload`, formatData, "Multipart").toPromise();
      if (res.statusCode == 200) {
        let wageRuleData = res.data.wagesRule;
        this.GridDataSource = Object.keys(wageRuleData).reduce((acc, deptID) => {
          const items = wageRuleData[deptID].map(item => ({
            ...item,
            deptID,
          }));
          return [...acc, ...items];
        }, []);
        this.dataSource = this.dataSource.map(data => {
          if (data.id == this.currid) {
            return {
              ...data,
              fileUploaded: true,
            };
          }
          return data;
        });
        // this.openTransferModal();
        if (res.data) {
          // let temp = res.data;
          if (res.data.isError) {
            this.configService.showToast("error", "Error In Uploading File Packet Has Been Removed");
            this.dataSource = this.dataSource.filter(item => item.id !== this.currid);
            //download file if isError===true
            let filepath = res.data.path;
            console.log(this.configService.getCentralFileUrl() + filepath);
            // window.open(this.configService.getCentralFileUrl() + filepath + "/" + encodeURIComponent(joinedPath),'download');
            this.http.get(this.configService.getCentralFileUrl() + filepath, { responseType: "blob" }).subscribe(
              (response: Blob) => {
                const blob = new Blob([response]);
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.download = filepath;
                link.click();
              },
              error => {
                console.error("Failed to download file:", filepath, error);
              }
            );
            // console.log(this.dataSource);
            // console.log(this.currid);
            this.dataSource = this.dataSource.filter(item => item.packetId !== this.currid);
          } else {
            this.configService.showToast("success", res.message);
          }
        }
      } else {
        this.configService.showToast("error", res.message || "Something went wrong!");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      this.configService.showToast("error", error.message || "Something went wrong!");
    } finally {
      if (event && event.target) {
        event.target.value = "";
      }
    }
  }

  onUpload(event) {
    this.currid = event.row.data.id;
    this.packetId = event.row.data.packetId;
    const fileInput = document.getElementById("fileInput") as HTMLInputElement;
    fileInput.click();
    fileInput.value = "";
  }

  validateFile(fileName: string) {
    let isValid = true;
    let errorMsg = "";

    if (!this.csvRegex.test(fileName) && !this.excelRegex.test(fileName)) {
      isValid = false;
      errorMsg = "File must be in .csv or .xlsx";
    }
    if (errorMsg) {
      this.configService.showToast("error", errorMsg);
    }
    return isValid;
  }

  async openTransferModal() {
    this.selectRoles = "";
    this.dispatchErrorMsg = "";
    this.selectedDipatchDepartment = null;

    const modal = await this.modalController.create({
      component: DynamicModalComponent,
      componentProps: {
        template: this.dispatchModal,
        class: "dispatch-modal",
      },
      cssClass: "DynamicModalLarge",
    });
    await modal.present();
  }

  isAllFilesUploaded(): boolean {
    if (!this.dataSource || this.dataSource.length === 0) {
      return true;
    }
    return !this.dataSource.every(data => data?.fileUploaded);
  }

  async onTransfer() {
    console.log(this.TransferdataSource);
    this.modalController.dismiss();
    let payload;
    let endpoint;
    let selPacketID = this.dataSource.map(data => data.id);
    let selDepartmentID = !!this.selectedDipatchDepartment ? this.selectedDipatchDepartment : null;
    // let selRoleID = !!this.selectRoles[0] ? this.selectRoles[0].id : null;
    payload = {
      toDepartment: selDepartmentID,
      toRole: this.selectRoles,
      listOfPacketIds: selPacketID,
      isOpinionGenerated: 1,
      isWagesAffected: this.selectedValue,
    };
    if (this.selectedValue == 2) {
      payload["wagesRuleId"] = this.selectedWage?.id;
      payload["amount"] = this.selectedWage?.inputField != null && this.selectedWage.inputField !== "" ? this.selectedWage?.inputField : this.selectedWage?.amount;
      payload["wagesActionId"] = this.selectedWage?.actionId;
    }
    console.log(payload);
    endpoint = "packet/transfer";
    let response = await this.httpService.postDataUsingPromise(true, endpoint, payload);
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      (this.selectedDipatchDepartment = null), (this.infoToPrint["data"]["packets"] = this.dataSource);
      let totalCts = 0;
      let pktIds = this.dataSource.map(x => {
        totalCts += x.cts;
        return x.id;
      });
      // this.fetchGridData(null);
      await this.setDispatchVoucherData(response.data);
      let voucherData = {
        data: response.data,
      };
      const modal = await this.modalController.create({
        component: TrybemodalComponent,
        componentProps: {
          message: response.message,
          data: voucherData,
          dispatchData: this.dispatchData,
          voucherDto: this.voucherDto,
          printData: this.printData,
          dispatchLossData: this.dispatchLossData ? this.dispatchLossData : null,
        },
        cssClass: "modalprint",
      });
      modal.onDidDismiss().then(dataReturned => {
        if (dataReturned !== null) {
          console.log(dataReturned);
          this.dataSource = [];
          this.scannerComponet.onResetForm();
        }
      });
      return await modal.present();

      let data = response.message;
      this.configService.showToast("success", data);
    } else {
      console.error(response);
      this.configService.showToast("error", response.error.message);
    }
  }

  setDispatchVoucherData(data) {
    this.infoToPrint["voucherDate"] = new Date(data?.voucherDate).toLocaleDateString("en-GB");
    this.infoToPrint["issueDate"] = new Date(data?.voucherDate).toLocaleDateString("en-GB");
    this.infoToPrint["voucherNo"] = data.voucherDto.voucherLabel;
    this.infoToPrint["jangadNo"] = data.voucherDto.voucherLabel;
    this.infoToPrint["issueNo"] = data.voucherDto.voucherLabel;
    this.infoToPrint["fromCompany"] = data?.fromCompany;
    this.infoToPrint["toCompany"] = data?.toCompany;
    this.infoToPrint["issueFor"] = `Transfer`;
    this.printData = JSON.parse(JSON.stringify(this.infoToPrint));
    let voucherData = {
      data: data,
    };
    // let dataForTransfer = this.TransferdataSource.map(data => data.id).filter(data => data.parameters.length > 0);
    // let isLoss;
    if (!!data && data?.dispatchVoucher.length > 0) {
      this.dispatchData = data?.dispatchVoucher;
      this.voucherDto = data?.voucherDto;
      // this.infoToPrint['fromCompany'] = this.dispatchData[0].fromCompany;
      // this.infoToPrint['toCompany'] = this.dispatchData[0].toCompany;
      this.infoToPrint["voucherId"] = this.dispatchData[0].voucherId;
      this.infoToPrint["TocompanyName"] = this.dispatchData[0].partyName;
      this.infoToPrint["date"] = moment.utc(this.dispatchData[0].Date).local().format("DD-MMM-YYYY");
      this.infoToPrint["ttlPkt"] = this.dispatchData.length;
      let rctsttl = 0;
      let ctsttl = 0,
        newTotalRgCutWt = 0,
        totalLoss = 0,
        totalOutStandingWt = 0,
        totalValue = 0;
      this.dispatchData.map(d => {
        if (d.originalWt) ctsttl += d.originalWt;
        if (d.wt) rctsttl += d.wt;
        if (d.roughCutWt) newTotalRgCutWt += Number(d.roughCutWt);
        if (d.loss) totalLoss += Number(d.loss);
        if (d.outStandingWt) totalOutStandingWt += Number(d.outStandingWt);
        if (d.crtValue) totalValue += Math.round(d.crtValue * 1000) / 1000;
        // d['lotNo'] = d.currPacketId.split('#')[0];
      });
      this.infoToPrint["owtttl"] = ctsttl;
      this.infoToPrint["rctsttl"] = rctsttl;
      this.infoToPrint["newTotalRgCutWt"] = newTotalRgCutWt;
      this.infoToPrint["totalLoss"] = totalLoss;
      this.infoToPrint["totalOutStandingWt"] = totalOutStandingWt;
      this.infoToPrint["totalValue"] = totalValue;
      this.dispatchLossData = this.dispatchData?.filter(d => d.loss != null);
      // isLoss = (this.dispatchLossData !== undefined)
      this.printData = JSON.parse(JSON.stringify(this.infoToPrint));
    }
  }

  onWageOptionChange(event: any) {
    this.selectedValue = event.detail.value;
  }

  packetScanned(event) {
    let payload = {
      packetIds: [event],
    };
    this.fetchGridData(payload);
  }

  isInputChange(event) {
    event.data["inputField"] = event.value;
    if (event.data.inputField > event.data.amount) {
      this.configService.showToast(true, "Input cannot be greater than the amount");
      event.value = "";
    }
  }

  onRadioChange(event: any) {
     this.selectedDipatchDepartment = null;
     this.TransferdataSource = [];
    if (this.selectedValue == 2) {
      this.deptDropdownDataOptions = this.allDeptValues.filter(option => this.GridDataSource.some(wage => wage.deptID == option.id));
    } else {

      this.deptDropdownDataOptions = this.allDeptValues;
    }
  }

  onModalSelectionChanged(event) {
    this.selectedWage = event.row;
  }
}
