import { CommonModule, DecimalPipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { NgSelectModule } from "@ng-select/ng-select";
import { EffectsModule } from "@ngrx/effects";
import { AutocompleteLibModule } from "angular-ng-autocomplete";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { AccordionModule } from "ngx-accordion";
import { AccordionItemComponent } from "./accordion-item/accordion-item.component";
import { AccordionComponent } from "./accordion/accordion.component";
import { ActionOperationsComponent } from "./actions/action-operations/action-operations.component";
import { IncomingComponent } from "./actions/incoming/incoming.component";
import { IssueComponent } from "./actions/issue/issue.component";
import { IssueEffects } from "./actions/issue/state/issue.effects";
import { OutgoingComponent } from "./actions/outgoing/outgoing.component";
import { PacketHistoryComponent } from "./actions/packet-history/packet-history.component";
import { StoneTimeTrackingReportComponent } from "./actions/stone-time-tracking-report/stone-time-tracking-report.component";
import { WorkQualityMasterComponent } from "./actions/work-quality-master/work-quality-master.component";
//import { AppDataGridComponent } from './app-data-grid/app-data-grid.component';
import { DropdownModule } from "../dropdown/dropdown.module";
import { PacketchatModule } from "../packetchat/packetchat.module";
import { PrintskeltonModule } from "../printskelton/printskelton.module";
import { RouteselectionComponent } from "../routeselection/routeselection.component";
import { AssignVirtualTasksComponent } from "./actions/assign-virtual-tasks/assign-virtual-tasks.component";
import { BidPlansComponent } from "./bid-plans/bid-plans.component";
import { CopyplanmodalComponent } from "./copyplanmodal/copyplanmodal.component";
import { DataGridComponentModule } from "./data-grid/data-grid.module";
import { DeptwiseStockdetailsComponent } from "./deptwise-stockdetails/deptwise-stockdetails.component";
import { FinalPlanningReviewComponent } from "./final-planning-review/final-planning-review.component";
import { HeaderComponentModule } from "./header/header.module";
import { NgxFormModule } from "./ngx-form/ngx-form.module";
import { ParamhistoryModule } from "./paramhistory/paramhistory.module";
import { PlanInfoGridModule } from "./plan-info-grid/plan-info-grid.module";
import { PopoverUserComponent } from "./popover-user/popover-user.component";
import { PreplanningsummaryComponent } from "./preplanningsummary/preplanningsummary.component";
import { ScannerinputModule } from "./scannerinput/scannerinput.module";
import { SignerPlanningHistoryComponent } from "./signer-planning-history/signer-planning-history.component";
import { SignermodalComponent } from "./signermodal/signermodal.component";
import { TrybemodalComponent } from "./trybemodal/trybemodal.component";
import { UserwisestockdetailsComponent } from "./userwisestockdetails/userwisestockdetails.component";
import { VoucherwisepacketComponent } from "./voucherwisepacket/voucherwisepacket.component";
import { ResetOrderModule } from "../resetordermodal/resetordermodal.module";
import { DxSelectBoxModule } from "devextreme-angular";
import { PlanDrawerModule } from "../plan-drawer/plan-drawer.module";
import { OrderformComponent } from "../orderform/orderform.component";
import { FilterModule } from "../pipes/filter/filter.module";
import { TensionmappingComponent } from "../tensionmapping/tensionmapping.component";
import { FooterModule } from "../footer/footer.module";
import { RvverifyComponent } from "../rvverify/rvverify.component";
import { AssignsubmanagerComponent } from "../assignsubmanager/assignsubmanager.component";
import { DbcScanningComponent } from "../dbc-scanning/dbc-scanning.component";
import { QcCheckAndAnnotationComponent } from "../qc-check-and-annotation/qc-check-and-annotation.component";
import { PacketRouteDisplayDrawerComponent } from "./packet-route-display-drawer/packet-route-display-drawer.component";
import { WidthAlertComponent } from "../width-alert/width-alert.component";
import { WidthAlertModule } from "../width-alert/width-alert.module";
import { RouteCriteriaModule } from "../route-criteria/route-criteria.module";
import { SingleMultiDropdownModule } from "./single-multi-dropdown/single-multi-dropdown.module";
import { WorkQualityMasterModule } from "./actions/work-quality-master/work-quality-master.module";
import { FinalInspectionComponent } from "./final-inspection/final-inspection.component";
import { PacketInwardModule } from "../kps/packet-inward/packet-inward.module";
import { LabFinalCheckModule } from "../labfinalcheck/labfinalcheck.module";
import { ColorInspectionModule } from "../color-inspection/color-inspection.module";
import { lotAllocationModule } from "../kps/lot-allocation/lot-allocation.module";
import { TransferWithActionComponent } from "./transfer-with-action/transfer-with-action.component";
import { ChatAlertModule } from "./chat-alert-model/chat-alert.module";
import { PullModule } from "../pull/pull.module";
import { LockMasterModule } from "../lockmaster/lockmaster.module";
import { LockCriteriaMasterComponent } from "../lock-criteria-master/lock-criteria-master.component";
import { DbcmakeexcelComponent } from "../dbcmakeexcel/dbcmakeexcel.component";
import { FileDownloadModule } from "../file-download/file-download.module";
import { WagesLedgerModule } from "../wages-ledger/wages-ledger.module";
import { DeptWiseParametersComponent } from "../dept-wise-parameters/dept-wise-parameters.component";
import { ParamHistoryGridComponent } from "./param-history-grid/param-history-grid.component";
import { PlanningReviewComponent } from "../kps/planning-review/planning-review.component";
import { UserManagementModule } from "../user-management/user-management.module";
import { ReceiveForPartyModule } from "../receive-for-party/receive-for-party.module";
import { PremiumDiscountModule } from "../new-pd/new-pd.module";
import { PacketHistoryViewModule } from "../packet-histroy-view/packet-history-view,module";
import { TallyStockModule } from "../tally-stock/tally-stock.module";
import { ManualColorModule } from "../manual-color/manual-color.module";
import { TallyManagerViewodule } from "../tally-manager-view/tally-manager-view.module";
import { OpinionMasterComponent } from "../opinion-master/opinion-master.component";
import { MultipleBarcodeScannerComponent } from "./multiple-barcode-scanner/multiple-barcode-scanner.component";
import { UserSettingPopoverComponent } from "./user-setting-popover/user-setting-popover.component";
import { ActionOperationsPopupComponent } from "./actions/action-operations/action-operations-popup/action-operations-popup.component";
import { ReturnToSafeModule } from "../return-to-safe/return-to-safe.module";
import { PrintPageModule } from "./print-page/print-page.module";
import { CompletedPacketHistoryModule } from "../completed-packet-history/completed-packet-history.module";
import { LivePacketReportModule } from "../live-packet-report/live-packet-report.component.module";
import { ThirdPartyTransferComponent } from "./third-party-transfer/third-party-transfer.component";
import { EditPacketsComponent } from "./edit-packets/edit-packets.component";
import { WagesMasterModule } from "./wages-master/wages-master.module";
import { twoDecimalvaluesPipeModule } from "../pipes/two-decimal-values/two-decimal-values.module";
import { KgLotAllocationComponent } from "../kg-lot-allocation/kg-lot-allocation.component";
import { ManualFinalParametersModule } from "./manual-final-parameters/manual-final-parameters.module";
import { KgPacketCreationComponent } from "../kg-packet-creation/kg-packet-creation.component";
import { CriteriaMasterComponent } from "../opinion-criteria-master/criteria-master.component";
import { DispatchPacketModule } from "../dispatch-packet/dispatch-packet.module";
import { NewDropDownModule } from "./new-drop-down/new-drop-down.module";
import { getTotalUsingKeyPipeModule } from "../pipes/get-total-using-key/get-total-using-key.module";
import { ReprintDispatchModule } from "./reprint-dispatch-voucher/reprint-dispatch-voucher.module";
import { MyTaskForVirtualModule } from "../my-task-for-virtual/my-task-for-virtual.module";
import { ParameterMasterModule } from "./masters/parameter-master/parameter-master.module";
import { ProcessActionConfigModule } from "../processactionconfig/processactionconfig.module";
import { PolishGradeModule } from "../polish-grade/polish-grade.module";
import { OppinionApprovalMasterModule } from "../opinion-approval-master/opinion-approval-master.module";
import { ParameterFilterComponent } from "./planning-grid/parameter-filter/parameter-filter.component";
import { ViewvirtualtaskModule } from "./viewvirtualtask/viewvirtualtask.module";
import { TransferPacketToDispatchModule } from "../transfer-packet-to-dispatch/transfer-packet-to-dispatch.module";
import { OpinionCriteriaMasterPopupComponent } from "../opinion-criteria-master/opinion-criteria-master-popup/opinion-criteria-master-popup.component";
import { packetcriteriaModule } from "./packetcriteria/packetcriteria.module";
import { TrybeCardModule } from "./trybecard/trybecard.module";
import { BarcodeModule } from "./barcode/barcode.module";
import { OpinionGeneratorViewComponent } from "../opinion-generator-view/opinion-generator-view.component";
import { RoughpacketvalueModule } from "./roughpacketvalue/roughpacketvalue.module";
import { PacketRepairingodule } from "../packet-repairing/packet-repairing.module";
import { DynamicPopoverModule } from "./dynamic-popover/dynamic-popover.module";
import { DynamicPopoverComponent } from "./dynamic-popover/dynamic-popover.component";
import { D3PyramidChartComponent } from "./d3-pyramid-chart/d3-pyramid-chart.component";
import { PyramidviewcomponentComponent } from "./pyramidviewcomponent/pyramidviewcomponent.component";
import { SalesReturnVoucherComponent } from "./sales-return-voucher/sales-return-voucher.component";
import { packetWiseApprovalListModule } from "./packetwiseapprovallist/packetwiseapprovallist.module";
import { partyconfigModule } from "./partyconfig/partyconfig.module";
import { OldFileDownloadModule } from "../old-file-download/old-file-download.module";
import { OpinionScreenModule } from "../opinion-screen/opinion-screen.module";
import { OpinionViewHistoryModule } from "../opinion-screen/opinion-view-history/opinion-view-history.module";
@NgModule({
  imports: [
    CommonModule,
    DxSelectBoxModule,
    FormsModule,
    IonicModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgSelectModule,
    DataGridComponentModule,
    AutocompleteLibModule,
    NgxFormModule,
    HeaderComponentModule,
    ParamhistoryModule,
    ResetOrderModule,
    PlanDrawerModule,
    WidthAlertModule,
    ScannerinputModule,
    PlanInfoGridModule,
    EffectsModule.forFeature([IssueEffects]),
    AccordionModule,
    FilterModule,
    PlanDrawerModule,
    // RootselectionModule,
    PrintskeltonModule,
    PacketchatModule,
    DropdownModule,
    FooterModule,
    RouteCriteriaModule,
    SingleMultiDropdownModule,
    WorkQualityMasterModule,
    PacketInwardModule,
    ColorInspectionModule,
    LabFinalCheckModule,
    lotAllocationModule,
    ChatAlertModule,
    PullModule,
    LockMasterModule,
    FileDownloadModule,
    WagesLedgerModule,
    UserManagementModule,
    PremiumDiscountModule,
    ReceiveForPartyModule,
    PacketHistoryViewModule,
    TallyStockModule,
    ManualColorModule,
    TallyManagerViewodule,
    ReturnToSafeModule,
    PrintPageModule,
    CompletedPacketHistoryModule,
    LivePacketReportModule,
    WagesMasterModule,
    twoDecimalvaluesPipeModule,
    getTotalUsingKeyPipeModule,
    ManualFinalParametersModule,
    DispatchPacketModule,
    NewDropDownModule,
    MyTaskForVirtualModule,
    ReprintDispatchModule,
    ReprintDispatchModule,
    ParameterMasterModule,
    ProcessActionConfigModule,
    PolishGradeModule,
    OppinionApprovalMasterModule,
    ViewvirtualtaskModule,
    TransferPacketToDispatchModule,
    packetcriteriaModule,
    TrybeCardModule,
    BarcodeModule,
    RoughpacketvalueModule,
    packetWiseApprovalListModule,
    PacketRepairingodule,
    DynamicPopoverModule,
    partyconfigModule,
    OldFileDownloadModule,
    OpinionScreenModule,
    OpinionViewHistoryModule
  ],
  declarations: [
    IssueComponent,

    OpinionMasterComponent,
    // SingleMultiDropdownComponent,
    //FluorescenceComponent,
    ActionOperationsComponent,
    TransferWithActionComponent,
    ThirdPartyTransferComponent,
    SalesReturnVoucherComponent,
    EditPacketsComponent,
    LockCriteriaMasterComponent,
    TrybemodalComponent,
    IncomingComponent,
    OutgoingComponent,
    PacketHistoryComponent,
    // TrybecardComponent,
    SignermodalComponent,
    //AppDataGridComponent,
    // WorkQualityMasterComponent,
    CopyplanmodalComponent,
    PopoverUserComponent,
    UserSettingPopoverComponent,
    // TrybeCheckerComponent,
    SignerPlanningHistoryComponent,
    BidPlansComponent,
    StoneTimeTrackingReportComponent,
    UserwisestockdetailsComponent,
    DeptwiseStockdetailsComponent,
    AccordionComponent,
    AccordionItemComponent,
    PreplanningsummaryComponent,
    FinalPlanningReviewComponent,
    VoucherwisepacketComponent,
    AssignVirtualTasksComponent,
    // RouteCriteriaComponent,
    RouteselectionComponent,
    OrderformComponent,
    TensionmappingComponent,
    RvverifyComponent,
    AssignsubmanagerComponent,
    DbcScanningComponent,
    QcCheckAndAnnotationComponent,
    PacketRouteDisplayDrawerComponent,
    FinalInspectionComponent,
    DeptWiseParametersComponent,
    ParamHistoryGridComponent,
    DbcmakeexcelComponent,
    PlanningReviewComponent,
    MultipleBarcodeScannerComponent,
    OpinionCriteriaMasterPopupComponent,
    CriteriaMasterComponent,
    ActionOperationsPopupComponent,
    KgLotAllocationComponent,
    OpinionGeneratorViewComponent,
    KgPacketCreationComponent,
    ParameterFilterComponent,
    D3PyramidChartComponent,
    PyramidviewcomponentComponent,
    // ColorInspectionComponent,
    // LabfinalcheckComponent
    // PrintskeltonComponent
  ],
  entryComponents: [
    IssueComponent,
    //FluorescenceComponent,
    ActionOperationsComponent,
    TransferWithActionComponent,
    ThirdPartyTransferComponent,
    SalesReturnVoucherComponent,
    EditPacketsComponent,
    LockCriteriaMasterComponent,
    // TrybecardComponent,
    IncomingComponent,
    OutgoingComponent,
    PacketHistoryComponent,
    TrybemodalComponent,
    //AppDataGridComponent,
    WorkQualityMasterComponent,
    CopyplanmodalComponent,
    // TrybeCheckerComponent,
    SignerPlanningHistoryComponent,
    BidPlansComponent,
    StoneTimeTrackingReportComponent,
    UserwisestockdetailsComponent,
    DeptwiseStockdetailsComponent,
    AccordionComponent,
    AccordionItemComponent,
    PreplanningsummaryComponent,
    FinalPlanningReviewComponent,
    VoucherwisepacketComponent,
    AssignVirtualTasksComponent,
    RouteselectionComponent,
    OrderformComponent,
    RvverifyComponent,
    AssignsubmanagerComponent,
    DbcScanningComponent,
    DeptWiseParametersComponent,
    QcCheckAndAnnotationComponent,
    MultipleBarcodeScannerComponent,
    ActionOperationsPopupComponent,
  ],
  exports: [RouteselectionComponent, WidthAlertComponent, OpinionMasterComponent, DynamicPopoverComponent],
  providers: [DecimalPipe],
})
export class ComponentsModule {}
