import { Component, ElementRef, EventEmitter, Input, Output } from "@angular/core";
//import { DecimalPipe } from '@angular/common';
import DataGrid from "devextreme/ui/data_grid";
import { confirm } from "devextreme/ui/dialog";
import * as moment from "moment";
import { ConfigService } from "src/app/services/config/config.service";

export enum FILE_UPLOAD_STATUS {
  INITIATED = 0,
  INPROGRESS = 1,
  COMPLETED = 2,
  ERROR = 3,
  NoDataFound = 4  
}

export enum FILE_STATUS {
  INITIATED = "Initiated",
  INPROGRESS = "InProgress",
  COMPLETED = "Completed",
  ERROR = "Error",
  NoDataFound = 'NoDataFound'
}

export enum SIGNER_STATUS {
  PENDING = 1,
  LIMITREACHED = 2,
  COMPLETED = 3,
  NOPACKETFOUND = 4,
}

export enum SIGNER_DISPLAY_STATUS {
  PENDING = "Pending",
  LIMITREACHED = "Limit Reached",
  COMPLETED = "Completed",
  NOPACKETFOUND = "No Packet Found",
}

@Component({
  selector: "app-data-grid",
  templateUrl: "./data-grid.component.html",
  styleUrls: ["./data-grid.component.scss"],
})
export class DataGridComponent {
  loadingVisible = true;
  isEditing: boolean = false;
  dataGridInstance: DataGrid;
  @Input() inlineAlter: boolean = false;
  @Input() showsFirstSelected: boolean = false;
  @Input() columns: any;
  @Input() rowData: any;
  @Input() mode: string;
  @Input() pageNumber: any;
  @Input() noOfRecords: any;
  @Input() tab: any;
  @Input() count: any;
  @Input() page: any;
  @Input() showIsStoneOk: any = false;
  @Input() updateMode: any;
  @Input() searchPanelEnable: any;
  @Input() pageSize: any = 50;
  @Input() isFilter: any;
  @Input() showPager: boolean = true;
  @Input() totalSummary: any;
  @Input() groupTotalSummary: any;
  @Input() exportEnable: any = true;
  @Input() noRemoteOperations: any;
  @Input() showDelete: any;
  @Input() showEdit: any;
  @Input() showDownload: boolean;
  @Input() showInfo: boolean;
  @Input() showPrint: boolean;
  @Input() gridId: any;
  allMode: string;
  @Input() option: any;
  @Input() selectRowsByIndex: any;
  @Input() scanned: boolean;
  @Input() isPagingDisable = true;
  @Input() summaryData: any[];
  @Input() selectedDepartmentRole: any[];
  @Input() conditionsToDisable: any = {};
  @Input() processIsHold: boolean = false;
  currentFilter: any;
  newRow = true;
  @Input() disbleSaveEdit: boolean;
  @Output() callFunction: EventEmitter<any> = new EventEmitter();
  @Output() pageFunction: EventEmitter<any> = new EventEmitter();
  @Output() deleteFunction: EventEmitter<any> = new EventEmitter();
  @Output() editFunction: EventEmitter<any> = new EventEmitter();
  @Output() downloadFunction: EventEmitter<any> = new EventEmitter();
  @Output() infoFunction: EventEmitter<any> = new EventEmitter();
  @Output() printFunction: EventEmitter<any> = new EventEmitter();
  @Output() showData: EventEmitter<any> = new EventEmitter();
  @Output() historyFunction: EventEmitter<any> = new EventEmitter();
  @Output() showOrderDetailFunction: EventEmitter<any> = new EventEmitter();
  @Output() showWagesProcessConfigFunction: EventEmitter<any> = new EventEmitter();
  @Output() detailFunction: EventEmitter<any> = new EventEmitter();
  @Output() uploadFile: EventEmitter<any> = new EventEmitter();
  @Output() openRouteOnLink: EventEmitter<any> = new EventEmitter();
  @Output() pageSizeChange: EventEmitter<any> = new EventEmitter();
  @Output() doubleClickEvent: EventEmitter<any> = new EventEmitter();
  @Output() onRemarkSelection: EventEmitter<any> = new EventEmitter();
  @Output() showRouteEmitter: EventEmitter<any> = new EventEmitter();
  @Output() saveUpdatedData: EventEmitter<any> = new EventEmitter();
  @Output() editCanceled: EventEmitter<any> = new EventEmitter();
  @Output() isActiveEmitter: EventEmitter<any> = new EventEmitter();
  @Output() isInputEmitter: EventEmitter<any> = new EventEmitter();
  @Output() onCustomRowClick: EventEmitter<any> = new EventEmitter();
  @Output() onCheckBoxChecked: EventEmitter<any> = new EventEmitter();
  @Input() refreshGrid: boolean = false;
  @Input() deferred: boolean = false;
  @Input() tableId: string = "gridContainer";
  @Input() showHistory: any;
  @Input() addNew: boolean = false;
  @Input() addNewStockFlag: boolean = false;
  @Input() showSr: boolean;
  @Input() showRoute: boolean;
  @Input() isshowOrderDetail: any;
  @Input() showwageConfig: any;
  @Input() showDetail: any;
  @Input() showUploadFile: any;
  @Input() status: any;
  @Input() remarkStatusList: any;
  @Input() departments: any;
  @Input() roles: any;
  @Input() grades: any;
  @Input() processes: any;
  isSaveButtonDisabled: boolean = true;
  events: Array<string> = [];
  public selected: any = [];
  public myMesssages = {
    emptyMessage: "No data to display",
    totalMessage: "records",
  };
  public headerHeight = 50;
  public rowHeight = 30;
  public hotSettings;
  public id = "hotInstance";
  public hooks;
  public columnName: any;
  public selectedRows = 0;
  public selectedRowsLen = 0;
  public errorTextArray = [];
  public isValidRow: boolean = true;
  readonly FILE_UPLOAD_STATUS = FILE_UPLOAD_STATUS;
  readonly FILE_STATUS = FILE_STATUS;

  readonly SIGNER_STATUS = SIGNER_STATUS;
  readonly SIGNER_DISPLAY_STATUS = SIGNER_DISPLAY_STATUS;

  constructor(private el: ElementRef, private _ConfigService: ConfigService) {
    this.deleteItem = this.deleteItem.bind(this);
    this.editItem = this.editItem.bind(this);
    this.downloadItem = this.downloadItem.bind(this);
    this.infoItem = this.infoItem.bind(this);
    this.printItem = this.printItem.bind(this);
    this.showProcessHistory = this.showProcessHistory.bind(this);
    this.showOrderDetail = this.showOrderDetail.bind(this);
    this.showWagesProcessConfig = this.showWagesProcessConfig.bind(this);
    this.showProcessDetail = this.showProcessDetail.bind(this);
    this.onUploadFile = this.onUploadFile.bind(this);

    this.allMode = "allPages";
    this.onEditorPreparing = this.onEditorPreparing.bind(this);
  }

  ngOnChanges(changes) {
    console.log(changes);
    console.log(this.selectRowsByIndex);
    if (!!changes.scanned || !!changes.selectRowsByIndex) {
      if (!!this.selectRowsByIndex && this.selectRowsByIndex.length > 0) {
        console.log(this.selectRowsByIndex);
        this.dataGridInstance?.selectRowsByIndexes(this.selectRowsByIndex);
      } else {
        this.dataGridInstance?.selectRowsByIndexes([]);
      }
    }

    if (!!changes.addNew && !!changes.addNew.currentValue) {
      if (this.selectedDepartmentRole) {
        const defaultValues = {
          fullName: "",
          password: "",
          roleId: this.selectedDepartmentRole,
        };
        console.log(defaultValues);
        if (!!this.dataGridInstance?.option("dataSource")) {
          const dataSource: any = this.dataGridInstance.option("dataSource");
          dataSource.push(defaultValues);
          this.dataGridInstance.focus();
          this.dataGridInstance.refresh();

          setTimeout(() => {
            this.dataGridInstance.editRow(0);
            // this.dataGridInstance.saveEditData();
            // this.dataGridInstance.refresh();
            const saveLinkElement = document.querySelector(".dx-link-save");
            if (saveLinkElement) {
              if (saveLinkElement instanceof HTMLElement) {
                saveLinkElement.style.color = "gray";
                saveLinkElement.style.cursor = "not-allowed";
              }
            }
          }, 100);
        }
      } else {
        this.dataGridInstance.addRow();
      }
    }

    // if(!!changes['selectedRowList']){
    //   this.selectedRows = this.selectedRowList.map((rowData)=>rowData.id);
    // }

    console.log("dataGridInstance");
    console.log(this.dataGridInstance);

    if (!!changes.refreshGrid && !!changes.refreshGrid.currentValue) {
      this.refresh();
    }
  }

  onRowPrepared(e: any) {
    if (e.rowType === "data") {
      if (this.showsFirstSelected && e.data.selected) {
        // e.rowElement.style.backgroundColor = '#49A4F5';
      }
      if (e.data.isHold === 1) {
        e.rowElement.style.backgroundColor = "#3A2024";
      }
      if (e.data.isStoneOk === 1 && this.showIsStoneOk) {
        e.rowElement.style.backgroundColor = "#24311C";
      }
    }
  }

  // onRowPrepared(event: any): void {
  //   const rowIsValid = this.validateRowData(event.data);

  //   if (!rowIsValid) {
  //     // Apply a custom style or show an error message
  //     event.rowElement.style.backgroundColor = 'lightcoral';
  //     // Alternatively, you can show a tooltip or display an error message in a custom way
  //     event.rowElement.title = 'This row has validation errors';
  //   }
  // }

  validateRowData(rowData: any): boolean {
    // Your custom validation logic here
    console.log("Validating row data:", rowData);
    // let errorTextArray = [];
    //let valid = true;
    this.columns.forEach(col => {
      const columnName = col.name;
      const prop = col.prop;
      //!event.oldData[prop]
      if (!this.rowData[prop]) {
        this.isValidRow = false;
        this.errorTextArray.push(columnName);
      }

      // if (col.validationRules) {
      //   col.validationRules.forEach((rule) => {
      //     console.log(rule);
      //     // Perform dynamic validation checks based on rule.type and columnValue
      //     // For example, check for 'required', 'numeric', etc.
      //     // Set event.isValid and event.errorText accordingly
      //   });
      // }
    });
    return this.isValidRow;

    // Example validation condition (replace with your own logic)
    //return rowData && rowData.roleId !== undefined;
  }

  onEditingEnd(event: any) {
    console.log("Editing has ended", event);
    const rowIndex = event.row.rowIndex;
    const rowKey = this.dataGridInstance.getKeyByRowIndex(rowIndex);
    // Manually trigger row validation
    // this.dataGridInstance.validateRow(rowIndex)
    //   .done((result) => {
    //     console.log('Validation Result:', result);

    //     // You can handle the validation result here
    //     if (result.isValid) {
    //       // Row is valid, you can proceed with other actions
    //     } else {
    //       // Row is not valid, handle accordingly
    //     }
    //   });
  }

  onCellClick(e) {
    if (e.row.isSelected) {
      e.component.clearSelection();
    }
  }

  refresh() {
    // if (this.dataGridInstance != undefined) {
    this.dataGridInstance?.clearSelection();
    console.log("refreshed Grid and cleaned data");

    // }
  }

  deleteItem(e) {
    let index = e.component.getRowIndexByKey(e.key);
    let rowEl = e.component.getRowElement(index);

    // rowEl[0].classList.add("rowToDelete");

    confirm("Do you really want to delete this record?", "Warning").then(r => {
      rowEl[0].classList.remove("rowToDelete");
      if (r) {
        this.deleteFunction.emit(e);
      }
    });
  }

  editItem(event) {
    this.editFunction.emit(event);
  }

  downloadItem(event) {
    this.downloadFunction.emit(event);
  }

  infoItem(event) {
    this.infoFunction.emit(event);
  }

  printItem(event) {
    this.printFunction.emit(event);
  }

  saveGridInstance(e) {
    this.dataGridInstance = e.component;
  }

  onSelectionChanged(event) {
    // if(event?.selectedRowsData?.[0]?.isHold && !this.processIsHold){
    if (event.selectedRowsData.some(packet => packet.isHold && !this.processIsHold)) {
      this.refresh();
      this._ConfigService.showToast("error", "Packet Is Locked!");
      return;
    }
    for (const key in this.conditionsToDisable) {
      switch (key) {
        case "notEqual":
          for (const conditionKey in this.conditionsToDisable[key]) {
            const hasDisabledRow = event.selectedRowsData.some(packet => packet[conditionKey] !== this.conditionsToDisable[key][conditionKey]);
            if (hasDisabledRow) {
              this.refresh();
              this._ConfigService.showToast("error", "Packet Is Disabled!");
              return;
            }
          }
          break;
      }
    }
    event.row = event.selectedRowsData[0];
    event.columnName = this.columnName;
    this.selectedRows = event.selectedRowsData.length;
    this.selectedRowsLen = event.selectedRowsData.length;
    this.call(event);
    // return event
  }

  call(event) {
    //this.refresh();
    this.selected = event.row;
    if (this.selected) {
      //this.event.publish("selectedValue", this.selected);
      // console.log("selected", this.selected);
    }
    this.callFunction.emit(event);
  }

  ngOnInit(): void {
    console.log("columns", this.columns);
  }

  customInDateTime(data) {
    if (!!data.inDate) {
      return moment.utc(data.inDate).local().format("DD-MMM-YYYY hh:mm a");
    }
    if (!!data.inwardDate) {
      return moment.utc(data.inwardDate).local().format("DD-MMM-YYYY hh:mm a");
    }
  }

  customDateTime(data) {
    if (!!data.createdDate) {
      return moment.utc(data.createdDate).local().format("DD-MMM-YYYY hh:mm a");
    }
  }

  customLastActionDate(data) {
    if (!!data.actionModifiedDate) {
      return moment.utc(data.actionModifiedDate).local().format("DD-MMM-YYYY hh:mm a");
    }
  }

  voucherCreatedDate(data) {
    if (!!data.createdDate) {
      return moment(data.createdDate).format("DD-MMM-YYYY hh:mm a");
    }
  }

  singerRequestDate(data) {
    if (!!data.createdDate) {
      return moment(data.createdDate).format("DD-MMM-YYYY hh:mm:ss a");
    }
  }

  singerRequestAssignedDate(data) {
    if (!!data.modifiedDate) {
      return moment(data.modifiedDate).format("DD-MMM-YYYY hh:mm:ss a");
    }
  }

  activityDate(data) {
    if (!!data.ActivityDate) {
      return moment.utc(data.ActivityDate).local().format("DD-MMM-YYYY");
    }
  }

  issueDate(data) {
    if (!!data.test || data.issueDate) {
      return moment.utc(data.test).local().format("DD-MMM-YYYY");
    }
  }

  recievedOn(data) {
    if (!!data.receivedOn) {
      // return data.receivedOn
      return moment(data.receivedOn).format("DD-MMM-YYYY hh:mm a");
    }
  }

  orderDateTime(data) {
    if (!!data.orderDate) {
      return moment.utc(data.orderDate).local().format("DD-MMM-YYYY hh:mm a");
    }
  }

  orderTargetDateTime(data) {
    if (!!data.targetDate) {
      return moment.utc(data.targetDate).local().format("DD-MMM-YYYY hh:mm a");
    }
  }

  customOutDateTime(data) {
    if (!!data.outDate) {
      return moment.utc(data.outDate).local().format("DD-MMM-YYYY hh:mm a");
    }
  }

  customFromDateTime(data) {
    if (!!data.fromDate) {
      return moment.utc(data.fromDate).local().format("DD-MMM-YYYY");
    }
  }

  customModifiedDateTime(data) {
    if (!!data.modifiedDate) {
      return moment.utc(data.modifiedDate).local().format("DD-MMM-YYYY");
    }
  }
  customToDateTime(data) {
    if (!!data.toDate) {
      return moment.utc(data.toDate).local().format("DD-MMM-YYYY");
    }
  }
  customfromDateTime(data) {
    if (!!data.fromDate) {
      return moment.utc(data.fromDate).local().format("DD-MMM-YYYY hh:mm a");
    }
  }
  onContentReady(evt) {
    console.log("onContentReady");
    console.log(evt);
  }
  showProcessHistory(event) {
    // event.preventDefault();
    this.historyFunction.emit(event);
  }
  showOrderDetail(event) {
    // event.preventDefault();
    this.showOrderDetailFunction.emit(event);
  }

  showWagesProcessConfig(event) {
    // event.preventDefault();
    this.showWagesProcessConfigFunction.emit(event);
  }

  triggerShowRoute = (event: any) => {
    this.showRouteEmitter.emit(event);
  };

  showProcessDetail(event) {
    console.log("showProcessDetail");
    this.detailFunction.emit(event);
  }

  onUploadFile(event) {
    this.uploadFile.emit(event);
  }
  openRouteSelection(event) {
    console.log("this is route selection");
    this.openRouteOnLink.emit(event);
  }
  onPageSizeChange(e) {
    this.pageFunction.emit(e);
  }
  onRowDoubleClick(e) {
    console.log("am coming here");
    this.doubleClickEvent.emit(e);
  }
  onRemarkValueChanged(data, rowData, cell?) {
    console.log(data, rowData);
    if (data?.value?.name) {
      rowData.cvdStatus = data.value.name;
      rowData.orderRemark = data.value;
      rowData.isActive = false;
      // this.rowData = this.rowData.map((dt:any,i:number)=>{
      //   if(dt.id == rowData.id){
      //     dt.remark = data.value;
      //     dt.isActive = false;
      //   }
      //   return dt;
      // });
      // rowData['isActive'] = false;
      // this.rowData[this.rowData.findIndex((dt:any)=>dt.id==rowData.id)]['isActive']=false;
    } else if (data.value) {
      rowData.cvdStatus = data.value;
    }

    this.onRemarkSelection.emit({ data, rowData });
    // return rowData
  }

  chipSuccess = ["completed", "true", "yes", 2];
  chipInitiated = ["initiated", 0];
  chipError = ["error", "false", "no", 3];
  chipPending = ["pending", "in progress", "in process", 1];
  getChipClass(value: any): string {
    if (this.chipSuccess.includes(value)) return "chip-success";
    if (this.chipInitiated.includes(value)) return "chip-initiated";
    if (this.chipError.includes(value)) return "chip-error";
    if (this.chipPending.includes(value)) return "chip-pending";
    return "";
  }

  getChipData(value: any): string {
    if (this.chipSuccess.includes(value)) return "completed";
    if (this.chipInitiated.includes(value)) return "initiated";
    if (this.chipError.includes(value)) return "error";
    if (this.chipPending.includes(value)) return "in progress";
    return "";
  }

  checkBoxValueChanged($event, data, rowIndex) {
    // $event.value = !$event.value;
    // this.rowData[rowIndex]['isActive'] = !$event.value;
    if ($event.value) {
      let highestValue = 0,
        highestValueId;
      this.rowData.map((dt: any) => {
        if (!dt?.orderRemark && highestValue < dt.finalValue) {
          highestValue = dt.finalValue;
          highestValueId = dt.id;
        }
      });
      if (highestValueId == this.rowData[rowIndex].id) {
        this.rowData = this.rowData.map((dt: any, i: number) => {
          if (rowIndex != i) {
            dt.isActive = false;
          } else {
            dt.orderRemark = "";
            dt.isActive = true;
          }
          return dt;
        });
      } else {
        if (!highestValueId || highestValue <= this.rowData[rowIndex].finalValue) {
          this.rowData = this.rowData.map((dt: any, i: number) => {
            if (rowIndex == i && dt.orderRemark) {
              dt.isActive = true;
              dt.orderRemark = "";
            } else {
              dt.isActive = false;
            }
            return dt;
          });
        } else {
          this._ConfigService.showToast("success", "Please select plan with highest value and no remark selected!");
          this.rowData = this.rowData.map((dt: any, i: number) => {
            if (rowIndex == i) dt.isActive = false;
            return dt;
          });
        }
      }
    }
  }

  showPrintButton(cellData: any) {
    return ["completed"].includes(cellData?.status?.toLowerCase());
  }

  alterEvent(event, eventName) {
    this.events.unshift(eventName);
    console.log(this.events);
    let eventData = {
      Name: eventName,
      rowData: event,
    };
    if (eventName == "NewRowInit" && this.selectedDepartmentRole) {
      console.log(this.rowData);
    } else if (eventName == "Saved") {
    } else if (eventName == "RowInserted") {
      this.newRow = true;
    } else if (eventName == "RowRemoved") {
      this.editCanceled.emit(eventData);
    } else if (eventName == "EditCanceled") {
      this.editCanceled.emit(eventData);
    } else if (eventName == "RowUpdated") {
      console.log("RowUpdated");
      //this.rowEdited(this.events);
      //this.dataGridInstance.validateRow(0)
    }
  }

  // validateGridData(): boolean {
  //   const dataSource: any = this.dataGridInstance.option('dataSource');

  //   // Your custom validation logic for the entire grid
  //   // Return true if the entire grid is valid, otherwise, return false
  //   const isGridValid = dataSource.every((rowData) =>
  //     this.validateRowData(rowData)
  //   );

  //   return isGridValid;
  // }

  onSaving(event: any) {
    if (this.disbleSaveEdit) {
      if (event.changes.length == 0) {
        event.cancel = true;
      }
    }
  }

  async savedInlineData(event) {
    const { changes } = event;
    if (!changes.length) return;
    this.errorTextArray = [];
    this.isValidRow = true;
    let filtered = await this.rowData.map(x => {
      if (changes[0]?.data?.id === x.id) {
        x["isUpdate"] = true;
      } else {
        x["isUpdate"] = false;
      }
      delete x["__KEY__"];
      return x;
    });
    this.saveUpdatedData.emit(filtered);
  }

  setRole(event) {
    console.log(event);
  }

  onFocusedCellChanged(data) {
    console.log(data);
  }

  showPassword(cellInfo: any): string {
    return "***";
  }

  cellTemplate(container, options) {
    console.log(options.column?.lookup?.dataSource);
    const noBreakSpace = "\u00A0";
    console.log(options.value);
    const fields: string[] = (options.value || []).map((id: number) => {
      let t = options.column?.lookup?.dataSource.find(x => x?.id == id);
      console.log(t);
      if (t?.role) return t.role;
      else if (t?.grade) return t.grade;
      else null;
    });
    const text = fields.join(", ");
    container.textContent = text || noBreakSpace;
    container.title = text;
  }

  usernameValidationRules: any[] = [{ type: "required", message: "Username is required" }];

  onRowValidating(event: any) {
    const isEdited = false;
    const newData = event.newData;
    const oldData = event.oldData;
    const errorTextArray = [];

    this.columns.forEach(col => {
      const columnName = col.name;
      const prop = col.prop;
      if (col.isStockColumn) {
        if (!event.newData[prop]) {
          if (!event.oldData[prop]) {
            event.isValid = false;
            errorTextArray.push(columnName);
          }
          if (event.oldData[prop] && (event.newData[prop] === "" || event.newData[prop]?.length == 0)) {
            event.isValid = false;
            errorTextArray.push(columnName);
          }
          // event.isValid = false;
          // errorTextArray.push(columnName);
        }
      } else if (col.required) {
        if (newData && oldData) {
          // Both newData and oldData are available (editing an existing row)
          if (event.oldData[prop] && (event.newData[prop] === "" || event.newData[prop]?.length == 0)) {
            event.isValid = false;
            errorTextArray.push(columnName);
          }
        } else if (newData) {
          // newData is available (adding a new row)
          if (!event.newData[prop]) {
            event.isValid = false;
            errorTextArray.push(columnName);
          }
        }
      }
    });
    if (errorTextArray.length > 0) {
      event.errorText = `${errorTextArray.join(", ")} cannot be empty.`;
    }
  }

  onEditorPreparing(e: any) {
    if (this.disbleSaveEdit) {
      e.editorOptions.onValueChanged = function (args: { value: any }) {
        const saveLinkElement = document.querySelector(".dx-link-save");
        if (saveLinkElement instanceof HTMLElement) {
          saveLinkElement.style.color = "#1ca8dd";
          saveLinkElement.style.cursor = "pointer";
        }
        e.setValue(args.value);
      };
    }
  }

  // e.editorOptions.onValueChanged = function (args) {
  //   console.log(args)
  //   console.log(e.row)
  //
  //   if(e.row.data['firstName'] && e.row.data['password']){
  //
  //     const saveLinkElement = document.querySelector('.dx-link-save');
  //       if (saveLinkElement) {
  //         // Disable the link
  //         saveLinkElement.removeAttribute('href');

  //         // Optionally, apply custom styling to indicate the link is disabled
  //         if (saveLinkElement instanceof HTMLElement) {
  //           saveLinkElement.style.color = 'red';
  //           saveLinkElement.style.cursor = 'pointer';
  //           // Add any additional styling as needed
  //         }
  //       }
  //   }

  // //   // Implement your logic here
  // //   e.element
  // //     .find("[aria-label='Save']")
  // //     .dxButton('instance')
  // //     .option('disabled', false);
  // //   //e.setValue(args.value); // Updates the cell value
  // };
  // if(this.disbleSaveEdit){
  //   if (event.parentType === 'dataRow' && event.dataField === 'yourSaveButtonField') {
  //     event.editorOptions.readOnly = true;
  //   }
  // }
  // console.log(event.editorName, event.parentType)
  // // Handle the editor preparing
  // if (event.parentType === 'dataRow' && event.editorName === 'dxButton') {
  //   // Customize the Save button appearance
  //   event.editorOptions = {
  //     text: 'ss',
  //     onClick: () => this.savedInlineData(event),
  //     disabled: !this.isEditing, // Disable the button if not in editing mode
  //   };
  // }

  isActiveChanged(data, event) {
    event.stopPropagation();
    this.isActiveEmitter.emit(data);
  }
  onInputChange(data: any, event: any): void {
    data.value = event.target.value;
    this.isInputEmitter.emit(data);
  }

  onRowClick(event) {
    this.onCustomRowClick.emit(event);
  }

  onCheckBoxChange(data, event) {
    event.stopPropagation();
    this.onCheckBoxChecked.emit(data);
  }
}
