import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxFormModule } from '../components/ngx-form/ngx-form.module';
import { HeaderComponentModule } from '../components/header/header.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { PlanningGridModule } from '../components/planning-grid/planning-grid.module';
import { ResetordermodalComponent } from './resetordermodal.component';
import dxDataGrid from 'devextreme/ui/data_grid';
import { DxDataGridModule, DxSelectBoxModule } from 'devextreme-angular';
import { DataGridComponentModule } from '../components/data-grid/data-grid.module';
import { EffectsModule } from '@ngrx/effects';
import { SignerEffects } from '../components/trybesigner/state/signer.effects';
import { NewDropDownModule } from '../components/new-drop-down/new-drop-down.module';



@NgModule({
  declarations: [ResetordermodalComponent],
  imports: [
    DataGridComponentModule,
    CommonModule,
    DxSelectBoxModule,
    IonicModule,
    FormsModule,
    NgSelectModule,
    HeaderComponentModule,
    NgxFormModule,
    NgMultiSelectDropDownModule,
    NewDropDownModule,
    EffectsModule.forFeature([SignerEffects]),
  ]
})
export class ResetOrderModule { }
