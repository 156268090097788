import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { IonContent, ModalController } from '@ionic/angular';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, Subscription } from 'rxjs';
import { SignerAction } from '../components/trybesigner/state/signer.action.type';
import { getAllPacketPlans, getReviewStoneDept, getSelectedPlanData, selectFileDetails, selectSpecificFileDetail } from '../components/trybesigner/state/signer.selector';
import { masterDataActions } from '../masterDataStore/masterData.action-type';
import { getAllTrybeDepartments, getParamsValuesListByName } from '../masterDataStore/masterData.selector';
import { routeMasterAPIs } from '../packet-route-master/state/packet-route-master.data';
import { AppState } from '../reducers';
import { HttpService } from '../services/http/http.service';
import { getCurrentRowData } from '../tempDataStore/tempData.selector';
import { ConfigService } from '../services/config/config.service';

import { Event, Router } from '@angular/router';
import { getCurrentAction } from '../tempDataStore/tempData.selector';
import { filter, first, takeUntil } from 'rxjs/operators';
import { getPlansAccToUserChecker } from '../components/trybe-checker/state/checker.selector';
import { authUser } from '../authStore/auth.selector';
import { chekerAction } from '../components/trybe-checker/state/checker.action.type';
import { LoaderService } from '../services/loader/loader.service';
import { HomeActions } from '../home/state/home.action-type';
import { myVirtualTaskActions } from '../components/my-virtual-task/state/my-virtual-task.actions.type';
import { virtualAPIs } from '../components/my-virtual-task/state/my-virtual-task.data';
import { ACTIONNAMES, ParameterTypes } from '../services/endpoint.enum';
import { TrybemodalComponent } from '../components/trybemodal/trybemodal.component';
import { environment } from 'src/environments/environment';
import { debug } from 'console';

@Component({
  selector: 'app-plan-drawer',
  templateUrl: './plan-drawer.component.html',
  styleUrls: ['./plan-drawer.component.scss'],
})
export class PlanDrawerComponent implements OnInit, OnChanges, OnDestroy {
  columns: any;
  dataSource: any;
  fileName: any;
  bidPlanData: any = [
    {
      "seqno": 1,
      "Bid": 10,
      "kg": 1.1,
    },
    {
      "seqno": 2,
      "Bid": 20,
      "kg": 2.2,
    },
    {
      "seqno": 4,
      "Bid": 40,
      "kg": 4.4,
    }];
  isShown: boolean = false;
  drawerPlanStoneForm: any = {};
  drawerPlanForm: any = {};
  ACTIONNAMES = ACTIONNAMES;
  currentUserRole;
  currentUser;
  public segment: string = "cap";
  public selectedTab: any = 1;
  public grades;
  checkerpacketPlans = [];
  public gradeArr = [];
  notActiveCount:number = 0;
  @Input() show: boolean = false;
  @Input() hide: boolean = false;
  xpsFileName: any;
  private subscriptions: Subscription[] = [];
  @Input() enable: boolean = false;
  @Input() page: any;
  @Input() currentPlanRowData: any;
  @Input() onDeSelectParentCheckbox: any;
  @Input() drawerData: any;
  @Input() jpgChange: Subject<any>;
  @Input() selectedCheckerTabs: any = null;
  iscutSaved: boolean = true;
  selectxmlfile: any;
  AdminApprovalRemarks:any=null;
  @Input() onNextBtn: any;
  @Input() saveDraftUpdateDataSubject: Subject<any>;
  saveDraftUpdateData:any = [];
  @Output() closeEvent: EventEmitter<any> = new EventEmitter();
  @Output() isDraweropen = new EventEmitter<boolean>();
  @Output() saveEvent: EventEmitter<any> = new EventEmitter();
  // @Output() closeEvent: EventEmitter<any> = new EventEmitter();
  @ViewChild(IonContent) private ionContent: any;
  @ViewChild('planDrawer') public planDrawer: any;

  public unsuscribes$: Subject<any> = new Subject<any>();

  remarkList: any = [{
    id: 1, name: 'Remark1'
  },
  {
    id: 2, name: 'Remark2'
  }];
  plansAlphabates: any = [];//or actions
  processes: any = [];//or actions
  departments: any = [];
  isInactivechecked: boolean;
  checkIsActive: boolean;
  cuts: any = [];
  userData = null;
  public dropDownCutData: object = {
    name: 'Cut',
    placeText: 'Select Cuts',
    options: this.remarkList,
    selectedData: [],//by default Department
    propertName: 'name',
    modeFlag: 'single', //single/multiple
  };
  public dropDownDepartmentData: object = {
    name: 'Department',
    placeText: 'Select',
    options: this.remarkList,
    selectedData: [],//by default Department
    propertName: 'department',
    modeFlag: 'single', //multiple
  };
  public dropDownProcessData: object = {
    name: 'Process',
    placeText: 'Select',
    options: this.remarkList,
    selectedData: [],//by default Department
    propertName: 'process',
    modeFlag: 'single', //multiple
  };
  public dropDownRemarkData: any = {
    name: 'Remark',
    placeText: 'Select Remarks',
    options: this.remarkList,
    selectedData: [],//by default Department
    propertName: 'remark',
    modeFlag: 'single', //single/multiple
  };
  otherRemark: string = "";
  selectedPlans: any;
  selPlanSuscription$: Subscription;
  rowDataSuscription$: Subscription;
  packetData: any;
  finalTabData: any;
  finalDropdownRemark: any;
  packetPlanData: any;
  departmentProcess: any = [];
  jpgFileName: any = null;
  planidofpiece;
  actionInfo: any;
  actionId: any;
  actionName: any;
  actionStatus: any;
  hasUserSavedPlan: any;
  tabId: number;
  reviewStonePayload: any;
  storeData: any;
  fileDetailsObject: any = {
    'xmlURL': '',
    'xmlFileName': '',
    'jpgURL': '',
    'jpgFileName': '',
    'remarkByUser': '',
    'remarkSelected': {}
  };


  planDrawerFileDetails: any = [];
  userRemarkValue: any;
  project: string = environment.project;

  defaultTabID: number;
  isAddDisabled: boolean = false;
  isMakeable: any;
  // viewTableData$ : Observable<any> = this.store.select(getReviewStoneDept);
  constructor(
    private store: Store<AppState>,
    private httpService: HttpService,
    private _ConfigService: ConfigService,
     private modalController:ModalController,
    private loaderService: LoaderService,
    public router: Router

  ) { }
  ngOnChanges(changes: SimpleChanges): void {
    // console.log(this.onDeSelectParentCheckbox);
    // console.log(this.bidPlanData);
    // console.log(this.onNextBtn);
    // console.log(this.currentPlanRowData);
    // let testObj = this.currentPlanRowData;
    // console.log(testObj);
    // // if (changes.currentPlanRowData && changes.currentPlanRowData?.currentValue?.packetPlanStones) {
    // //   // Access the packetPlanStones property here
    // //   console.log("before")
    // //   console.log("here", this.currentPlanRowData.packetPlanStones)
    // // }

    // // this.defaultTabID = this.currentPlanRowData["packetPlanStones"][0]["id"];
    // // debugger
    // console.log(this.defaultTabID);
    // console.log('ngOnChanges------DRAWER')
    // console.log(changes);

    this.store.pipe(select(authUser),takeUntil(this.unsuscribes$)).subscribe((data: any) => {
      if (data) {
        this.currentUser = data.id? data.id : data.userId;
        this.currentUserRole = data.roles[0];
      }
    })
    this.isAddDisabled = false;
    this.currentPlanRowData?.packetPlanStones?.map((dt: any) => {
      if (dt.id == this.tabId && Number(dt.expPol) > 1) {
        this.isAddDisabled = true;
      }
    })

    this.selectedTab = 1;

    if (this.onDeSelectParentCheckbox == false && !this.onNextBtn) {
      this.closeDrawer();
    }
    if (this.selPlanSuscription$) {
      this.selPlanSuscription$.unsubscribe();
    }
    if (this.rowDataSuscription$) {
      this.rowDataSuscription$.unsubscribe();
    }
    
    if (changes.currentPlanRowData && changes.currentPlanRowData.currentValue) {
      this.plansAlphabates = [];
      let selectedPlan = changes.currentPlanRowData.currentValue;
      this.grades = changes.currentPlanRowData.currentValue;
      console.log(this.selectedPlans);
      if (selectedPlan.packetPlanStones) {
        selectedPlan.packetPlanStones.forEach((d, k) => {
          let obj: any = {};
          obj['planId'] = selectedPlan.id;
          obj['id'] = d.id;
          obj['isMakeable'] = null;
          obj['noOfCut'] = k + 1;
          obj['piece'] = d.piece || this.getHexValue(k);
          obj['inActive'] = d.inActive;
          obj['xpsFileName'] = null;
          obj['xps'] = null;
          this.plansAlphabates.push(obj);
        });
        this.dropDownCutData['options'] = [];
        // this.dropDownCutData['options'] = this.plansAlphabates;
        this.dropDownCutData['options'] = [{
          name: 'YES',
          id: 1
        }, {
          name: 'NO',
          id: 0
        }]

      }
      if (this.grades.packetPlanStones) {
        this.gradeArr = [];
        let notActiveCount = 0;
        this.grades.packetPlanStones.forEach((d, k) => {
          let obj: any = {};
          obj['planId'] = this.grades.id;
          obj['id'] = d.id;
          obj['piece'] = d.piece || this.getHexValue(k);
          this.gradeArr.push(obj);
          if(!d.inActive)
          notActiveCount+=1;
        });
        this.notActiveCount = notActiveCount;
      }
      console.log('----------gradeArr-----');
      console.log(this.gradeArr);
      // this.gradeArr=[];

      console.log(changes.currentPlanRowData.currentValue)
      //----------------Drawer Data Prepartion START--------------
      this.store.pipe(select(getParamsValuesListByName(ParameterTypes.Remarks))).subscribe(data => {
        if (!!data) {
          this.finalDropdownRemark = data;
          this.remarkList = data;
          let remarks: Array<[]> = data.map(d => {
            return { id: d.id, remark: d.name };
          });
          // this.drawerData['remarkList'] = this.remarkList;
          // this.drawerData['remarks'] = remarks;
          this.dropDownRemarkData.options = remarks;
          console.log('..........Remarks');
          console.log(data);
        }
      });
      this.store.dispatch(masterDataActions.getTrybeDepartments({ packetIds: null }));
      this.store.pipe(select(getAllTrybeDepartments)).subscribe((data) => {
        if (data) {
          console.log('AllTrybeDepartments');
          console.log(data);
          this.departments = [];
          data.forEach((curr: any, k: number) => {
            let obj1: object = {};
            obj1 = { id: curr.id, department: curr.name };
            this.departments.push(obj1);
          });
          // this.drawerData['departments'] = this.departments;
          this.dropDownDepartmentData['options'] = this.departments;
        }
      })

      this.fetchAllAtions();
      console.log(this.drawerData);
      this.dropDownRemarkData['selectedData'] = [];
      this.dropDownCutData['selectedData'] = [];
      this.otherRemark = null;

      this.drawerPlanStoneForm = {
        reviewStoneData: null,
        remark: null,
        noOfCuts: null,
        otherRemark: null,
      }
      //----------------Drawer Data Prepartion END--------------

    }
    if (changes.drawerData && changes.drawerData.currentValue) {
      console.log('-------drawerData------');
      console.log(changes.drawerData);

    }
    // this.store.pipe(select(getParamsValuesListByName('Fluorescence'))).subscribe(data => {
    //   if (!!data) {
    //     this.dropDownFLData.options = data;
    //   }
    // })

    // this.store.pipe(select(getParamsValuesListByName('Color'))).subscribe(data => {
    //   if (!!data) {
    //     this.dropDownCLData.options = data;
    //   }
    // })

    // this.store.pipe(select(getParamsValuesListByName('Clarity'))).subscribe(data => {
    //   if (!!data) {
    //     this.dropDownCLAData.options = data;
    //   }
    // })



    if (!!changes.show) {
      if (changes.show.currentValue) {
        this.openDrawer()
      }
    }
    if (!!changes.hide) {
      if (changes.hide.currentValue) {
        this.closeDrawer()
      }
    }
    this.selPlanSuscription$ = this.store.pipe(select(getSelectedPlanData)).subscribe((plan: any) => {
      if (plan) {
        console.log('Selected plan');
        console.log(plan);
        this.selectedPlans = plan;
        console.log(this.selectedPlans);
        this.selectedPlans = this.selectedPlans.filter((obj, index, self) =>
          index === self.findIndex((o) => o.id === obj.id)
        );
        this.selectedPlans = this.selectedPlans.map((e:any) => {
          const copyE = {...e};
          if (!!copyE.bidAmt) {
            copyE.bidAmt = Number(Math.round(e.bidAmt * 100) / 100)
          }
          if (!!copyE.totalKGRate) {
            copyE.totalKGRate = Number(Math.round(e.totalKGRate * 100) / 100)
          }
          return copyE;
        })

        // for(let i= 0; i<this.selectedPlans.length; i++){
        //   console.log(this.selectedPlans[i].hasSavedPlan)
        //   this.hasUserSavedPlan = this.selectedPlans[i].hasSavedPlan;
        // }
        this.hasUserSavedPlan = this.selectedPlans[0]?.hasSavedPlan ?? false;
        console.log(this.hasUserSavedPlan);

      }
    });
    this.rowDataSuscription$ = this.store.pipe(select(getCurrentRowData)).subscribe((rd: any) => {
      if (rd) {
        console.log(rd);
        this.packetData = !!rd[0] ? rd[0] : null;
        console.log(this.packetData);
      }
    });
    this.isDraweropen.emit(this.isShown);
    // this.store.pipe(select(getReviewStoneDept)).subscribe((data: any) => {
    //   if (data) {
    //     console.log('Selected plan');
    //     console.log(data);
    // // debugger
    //   }
    // });
  }
  ngOnInit() {
    console.log(this.currentPlanRowData);    
    this.subscriptions.push(
      this.store.pipe(select(getAllPacketPlans)).subscribe((data) => {
        if (data) {
          console.log(data);
          this.packetPlanData = data;
        }
      })
    );
    console.log(this.onDeSelectParentCheckbox);
    console.log('------------Drawer-----ngOnInit---------');
    this.columns = [
      // { name: "voucherId", prop: "voucherId" },
      { name: "Department", prop: "department" },
      { name: "Processplanning", prop: "process" },
      // { name: "Current Order Rate", prop: "CurrentOrderRate" },
      // { name: "Default Remark Value", prop: "remark" },
    ];
    this.dataSource = [
      { id: 1, "department": "Select Department", "order": "#232323", "process": "Select Process", "CurrentOrderRate": "$24324", "remark": "" }
      // , { id: 1, "department": "QC and Scan Preparation", "order": "#232323", "process": "CSSP", "CurrentOrderRate": "$24324", "remark": "" }
      // , { id: 1, "department": "3", "order": "#232323", "process": "shape", "CurrentOrderRate": "$24324", "remark": "" }
    ];

    this.store.pipe(select(getCurrentAction)).pipe(filter(data => !!data), first()).subscribe((data: any) => {
      if (data) {
        console.log(data);
        this.actionInfo = data;
        this.actionStatus = data.status;
        this.actionName = data.name;
        this.actionId = data.id;
      }
    });

    this.jpgChange?.subscribe((event: any) => {
      this.getJpgFile(event);
    })

    
    this.store.pipe(select(authUser), takeUntil(this.unsuscribes$)).subscribe((data: any) => {
      if (data) {
        this.userData = data;
      }
    })

    this.saveDraftUpdateDataSubject?.subscribe((data:any)=>this.saveDraftUpdateData=(data?data:[]));
  }

  ngAfterViewChecked() {
    this.planidofpiece = this.plansAlphabates[this.selectedTab - 1];
  }

  alpha: any = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  getHexValue(a, doneIndexs = []) {
    a += 1;
    var c = 0;
    var x = 1;
    while (a >= x) {
      c++;
      a -= x;
      x *= 26;
    }
    var s = "";
    for (var i = 0; i < c; i++) {
      s = this.alpha.charAt(a % 26) + s;
      a = Math.floor(a / 26);
    }
    return s;
  }

  async fetchAllAtions() {
    console.log('fetchAllActions');
    let response: any = await this.httpService.fetchDataUsingPromise(true, routeMasterAPIs.getActionsEndPoint);
    console.log(response);
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      console.log('%cActions----------', 'color:green;font-weight:bold;font-size:1rem;');
      let data: Array<[]> = response.data
      this.processes = data.map((d: any) => {
        let { id, actionName } = d;
        return { id: id, process: actionName };
      });
      this.processes = this.processes.filter(na => na.process != null);
      this.dropDownProcessData['options'] = [];
      this.dropDownProcessData['options'] = this.processes;
      // this.drawerData['processes'] = this.processes;
      console.log(this.processes);
    } else {
      console.error(response);
    }
  }
  closeDrawer() {
    const style = this.planDrawer.nativeElement.style;
    // const style = this.ionContent.el.style;
    style.width = 0;
    console.log(this.onDeSelectParentCheckbox);
    if (this.onDeSelectParentCheckbox == false) {
      this.gradeArr = [];
    }
    console.log('Bye..');
    // if (this.isFormShown) {
    //   this.isFormShown = false;
    //   this.closeDrawerEvt.emit();
    // }
    this.isShown = false;
    this.planDrawerFileDetails = [];
    this.departmentProcess = [];
  }

  openDrawer() {
    this.departmentProcess = [];
    this.xpsFileName = null;
    this.jpgFileName = null;
    this.fileDetailsObject = {
      'xmlURL': '',
      'xmlFileName': '',
      'jpgURL': '',
      'jpgFileName': '',
      'remarkByUser': '',
      'remarkSelected': {}
    };
    console.log(this.ionContent);
    let screenWith = innerWidth;
    console.log(screenWith);
    let formWidth = 350;
    if (screenWith >= 1281) {
      formWidth = 700;
    } else if (screenWith >= 1025) {
      formWidth = 590;
    } else if (screenWith >= 768) {
      formWidth = 392;
    } else {
      formWidth = 350;
    }
    if (this.ionContent) {
      console.log(this.ionContent);
      // console.log(this.ionContent.nativeElement);
      const style = this.planDrawer.nativeElement.style;
      // const style = this.ionContent.el.style;
      style.width = formWidth + 'px';
      // this.drawerDiv.nativeElement.classList.add("actionForm");//now adding width by screen media quries
      // this.isFormShown = true;
      // this.openDrawerEvent.emit(formWidth);
      this.isShown = true;
    }
    // console.log(this.planidofpiece);debugger
    this.tabId = this.plansAlphabates?.[0]?.id;
    console.warn(this.tabId);
    console.log(`%c${this.tabId}`, 'color:aqua;font-size:20px;');

    // console.warn(this.selectedPlans.hasSavedPlan);

    // debugger

    if (!!this.storeData) {
      let arr: any = Object.keys(this.storeData);
      console.log(arr);
      for (let i = 0; i < arr.length; i++) {
        if (this.tabId == arr[i]) {
          console.log(this.tabId, arr[i]);
          this.viewSpecificTabData()
          this.selectDrawerFileDetails()
          this.viewSpecificDrawerFileDetails();

        }
      }
    }
  }
  onSelectionChanged(evt) {
    console.log('onSelectionChanged');
    console.log(evt);

  }
  async getJpgFile(event: any) {
    console.log(event, "753753753756378563753753");
    let files = event.target.files;
    this.fileName = files[0];
    console.log(this.fileName);
    console.log(this.fileName["name"]);

    this.jpgFileName = !!files[0] && files[0].name ? files[0].name : null;
    console.log(this.plansAlphabates);
    let file = event.target.files[0];
    this.uploadJpgFile(file)
  }

  async uploadJpgFile(isFiles) {
    const formData = new FormData();
    formData.append('file', isFiles);
    formData.append('packetId', this.packetData.id);
    let res: any;

    res = await this.httpService.uploadJpgFileForplanning(formData)
    this.httpService.getErrorAndDisplayIt(res);
    // await this.httpService.dismissLoader()
    if (res.statusCode === 200) {
      let data = res.message;
      this._ConfigService.showToast("success", data);

      // ****************************************************************************************************

      this.fileDetailsObject["jpgURL"] = res.data;
      this.fileDetailsObject["jpgFileName"] = this.fileName["name"];


    } else {
      console.error(res);
      this._ConfigService.showToast("error", res.error.message);
    }
  }
  // ***********************************************************************************************************
  async deleteJpgFile(isFiles) {
    console.log(this.planidofpiece);
    const formData = new FormData();
    formData.append('file', isFiles);
    console.log(formData)
    console.log(isFiles);
    formData.append('packetPlanStoneId', this.planidofpiece.id);
    let res: any;

    res = await this.httpService.uploadJpgFileForplanning(formData)
    this.httpService.getErrorAndDisplayIt(res);
    if (res.statusCode === 200) {
      console.log(res.data); //file download link
      let data = res.message;
      console.log(data);
      this._ConfigService.showToast("success", data);
    } else {
      console.error(res);
      this._ConfigService.showToast("error", res.error.message);
    }
  }





  async getXmlFile(event: any) {
    console.log(event, "753753753756378563753753");
    let files = event.target.files;
    this.selectxmlfile = <File>event.target.files[0];
    this.xpsFileName = !!files[0] && files[0].name ? files[0].name : null;
    console.log(files);
    console.log(this.plansAlphabates);//xpsFile
    console.log(this.xpsFileName);
    let planid = this.plansAlphabates?.[0]?.id;
    let packedid = this.packetData.id
    let file = event.target.files[0];
    console.log(file);

    this.uploadXpsFile(file)
  }

  async uploadXpsFile(isFiles) {
    console.log(this.planidofpiece);
    const formData = new FormData();
    formData.append('file', isFiles);
    console.log(formData)
    formData.append('packetPlanStoneId', this.planidofpiece.id);
    let res: any;
    res = await this.httpService.uploadXmlFileForplanning(formData)
    this.httpService.getErrorAndDisplayIt(res);
    // await this.httpService.dismissLoader()
    if (res.statusCode === 200) {
      console.log(res.data);  //file download link
      let data = res.message;
      console.log(data);
      this._ConfigService.showToast("success", data);
      console.log(this.fileDetailsObject);

      this.fileDetailsObject["xmlURL"] = res.data;
      this.fileDetailsObject["xmlFileName"] = this.xpsFileName;
      console.log(this.fileDetailsObject);




    } else {
      console.error(res);
      this._ConfigService.showToast("error", res.error.message);
    }
  }

  fetchDataInDrawer(id) {
    console.log(id)
    if (id) {
      this.httpService.fetchData(true, `review-packet-plan-stone/getPacketPlanStone/${id}`).subscribe(response => {
        console.log('getRoutemasterData');
        console.log(response);
        this.httpService.getErrorAndDisplayIt(response);
      });
    }
  }

  dropDownSelection(evt, val, index) {
    console.log('dropDownSelection');
    console.log(evt);

    if (evt.property == "name") {
      if (evt.arrayList.length > 0) {
        this.drawerPlanStoneForm['noOfCuts'] = evt.arrayList[0];
        this.iscutSaved = false;
      } else {
        this.drawerPlanStoneForm['noOfCuts'] = null;
        this.iscutSaved = true;
      }

    } else if (evt.property == "remark") {
      if (evt.arrayList.length > 0) {
        this.drawerPlanStoneForm['remark'] = evt.arrayList[0] ? evt.arrayList[0] : '';
        this.fileDetailsObject["remarkSelected"] = evt.arrayList[0];
        console.log(this.fileDetailsObject);
        // this.onRemarkDropdownSelection(evt.arrayList[0]);
      } else {

        this.drawerPlanStoneForm['remark'] = null;
        this.fileDetailsObject["remarkSelected"] = null;
        console.log(this.fileDetailsObject);
      }

    } else if (evt.property == "department") {
      let obj = this.departmentProcess[index];
      // obj['selectedData'] = evt.arrayList;
      if (obj && obj['department'] && obj['department']['selectedData']) {
        obj['department']['selectedData'] = evt.arrayList;

      }
      //   if (evt.arrayList.length > 0) {
      //   this.drawerPlanStoneForm['remark'] = evt.arrayList[0];
      // } else {
      //   this.drawerPlanStoneForm['remark'] = null;
      // }
    } else if (evt.property == "process") {
      let obj = this.departmentProcess[index];
      if (obj && obj['process'] && obj['process']['selectedData']) {
        obj['process']['selectedData'] = evt.arrayList;
      }
      // if (evt.arrayList.length > 0) {
      //   this.drawerPlanStoneForm['remark'] = evt.arrayList[0];
      // } else {
      //   this.drawerPlanStoneForm['remark'] = null;
      // }
    }

  }
  removeImage(img: any) {
    console.log("we are in remove image function", img);

  }

  onRemarkDropdownSelection(data) {
    if (data) {
      console.log("select the data");
      console.log(data.remark);
      this.store.dispatch(SignerAction.addRemarkForPacketPlandrawer({
        remark: data.remark + ',' + this.otherRemark,
        packetplanStoneID: this.planidofpiece.id,
      }))
    } else if (!data) {

      this.store.dispatch(SignerAction.addRemarkForPacketPlandrawer({
        remark: '' + ',' + this.otherRemark,
        packetplanStoneID: this.planidofpiece.id

      }))

    }
  }




  segmentChanged(ev: any) {
    console.log(this.plansAlphabates);
    let x = this.selectedTab;
    // this.isInactivechecked = false;
    this.planidofpiece = this.plansAlphabates[x - 1];
    if (this.selectedTab != 'end') {
      this.tabId = this.planidofpiece.id;
      if(this.planidofpiece["isMakeable"]==true){
        this.isInactivechecked =true
      }
    }
   
    this.isAddDisabled = false;
    this.currentPlanRowData?.packetPlanStones?.map((dt: any) => {
      if (dt.id == this.tabId && Number(dt.expPol) > 1) {
        this.isAddDisabled = true;
      }
    })

    this.jpgFileName = null;
    this.xpsFileName = null;
    this.otherRemark = null;
    this.dropDownRemarkData['selectedData'] = null;
    this.departmentProcess = [];
    this.segment = ev.detail.value;
    console.log(this.segment);

    console.log(this.drawerPlanStoneForm);
    if (this.selectedTab != 'end') {
      console.warn(this.tabId);
      console.log(`%c${this.tabId}`, 'color:aqua;font-size:20px;');

      this.fetchDataInDrawer(this.tabId);
      this.viewSpecificTabData();
      this.selectDrawerFileDetails();
      this.viewSpecificDrawerFileDetails();

    }
    if (this.selectedTab == "end") {
      this.fetchFinalDataInDrawer()
    }
    this.viewSpecificTabData();
    // this.selectDrawerFileDetails();
    // this.viewSpecificDrawerFileDetails();
    console.log(this.drawerPlanStoneForm)

  }
  onGridRemarkChange(evt) {
    console.log('onGridRemarkChange');
    console.log(evt);
  }
  deSelectDoc() {
    console.log('deSelectDoc');
    this.fileName = "";
  }
  deSelectXps(evt) {
    console.log('deSelectXps');
    console.log(evt);
    this.xpsFileName = null;


  }

  deSelectjpg() {
    this.jpgFileName = null;
    this.deleteJpgFile(null);
    console.log("jpg file deleted from the backend");
    this.fileDetailsObject.jpgURL = '';
    this.fileDetailsObject.jpgFileName = '';
    console.log(this.fileDetailsObject);

  }
  closeModal() {
    this.selectedTab = 1;
    this.departmentProcess = [];
    this.xpsFileName = null;
    this.jpgFileName = null;
    console.log("closing modal");
    if (!this.onNextBtn) {
      // this.store.dispatch(SignerAction.removeSelectedPlans());

    }
    this.closeEvent.emit(this.drawerPlanStoneForm);
    this.closeDrawer();
  }

  async savePlans() {
    if(
      this.actionName == 'Smart Recut Planning' || this.packetData.vitualActionName == "Smart Recut Planning" || this.packetData.currActionName=="Smart Recut Planning"
      ||
      this.actionName == this.ACTIONNAMES.anyCut || this.packetData.vitualActionName == this.ACTIONNAMES.anyCut || this.packetData.currActionName==this.ACTIONNAMES.anyCut
    ) {
      await this.orderRemarkandupdatepacketPlan()
      let payload ={
        "packetId" : this.packetData?.id,
        "packetPlanStoneId" : this.selectedPlans?.[0]?.packetPlanStones?.[0]?.id
      };
      
      if(!this.saveDraftUpdateData?.length){
        if (this.saveDraftUpdateData.findIndex((dt: any) => dt.id == this.selectedPlans?.[0]?.id) > -1) {
          this.saveDraftUpdateData = this.saveDraftUpdateData.filter((dt: any) => dt.id != this.selectedPlans?.[0]?.id);
          this.saveDraftUpdateData.push(this.selectedPlans?.[0]);
        } else{
          this.saveDraftUpdateData.push(this.selectedPlans?.[0]);
        }
        // this.saveDraftUpdateData.push(this.selectedPlans?.[0]);
      }
      this.httpService.saveData(true,'packetPlan/updatePacketPlansAndStones',this.saveDraftUpdateData).subscribe((res:any)=>{
        this.httpService
        .assignPropertyAfterRecut(payload)
        .subscribe((res: any) => {
          console.log(res);
          if(res.data == true){
            this.showHoldModal()
          }
          if(res.statusCode==200){
            if(!!this.packetData?.vitualActionName){
              this.httpService.updateData(true, `virtual-task/update/${this.packetData.virtualId}`, {}).subscribe((res: any) => {
                this.httpService.getErrorAndDisplayIt(res);
            this.store.dispatch(myVirtualTaskActions.fetchMyVirtualTasks({ api: virtualAPIs.virtualTaskEndPoint, Payload: null }));
                // this.saveinputParameter("true");
              });
            }
            else{
              // this.saveinputParameter()
            }
            this._ConfigService.showToast("success",' Plan Saved Successfully');
            this.router.navigate(['./home']);
            HomeActions.getReceivedPackets({ limit: 50 });
          } else {    
            this._ConfigService.showToast(
              'error',
              res?.message || 'Something went wrong!'
            );
          }
                    
        },
        (err:any)=>{
                  
          this._ConfigService.showToast(
            'error',
            err?.message || 'Something went wrong!'
          );
        });
      });
            return;
    } else {
      console.log("onnext save btn-------savePlans", this.selectedPlans);
      // this.store.dispatch(getSignerBestPlans({ packetId: this.rowData.id }));
      // return;
  
      const plansPayload = this.selectedPlans.map(item => ({
        packetPlanId: item.id,
        planPriority: item?.selectionOrder || item?.planPriority
      }));

      console.log(plansPayload);
      this.store.dispatch(SignerAction.saveSignerBestPlans({ payload: plansPayload, pktData: this.packetData }));
      let saveData = {};
      let listOfParameter = [];
      this.departmentProcess = [];
      this.planDrawerFileDetails = [];
      // this..forEach((d: any, k: number) => {
      //   let snNo = k + 1;
      //   let paranmName = "Plan " + snNo;
      //   let obj = {
      //     "parameterName": paranmName,
      //     "parameterValue": d.id
      //   }
      //   listOfParameter.push(obj);
      // })
  
      let payload = {
        "parameterName": "Plan " + 1,
        "parameterValue": this.currentPlanRowData.id
      }
      listOfParameter.push(payload);
  
      saveData['packetId'] = this.packetData.id;
      saveData['listOfParameter'] = listOfParameter;
  
      let barcode = this.packetData.packetId;
  
      let obj = {
        "listOfAllPackets": []
      }
      let barcodeObj = {
        packetId: barcode
      }
      obj['listOfAllPackets'].push(saveData);
      // this.rowData = {}
      console.log(obj);
      console.log(this.actionId);
      console.log(barcodeObj);
        
      // here to move to red to green flag
      // this.store.dispatch(masterDataActions.saveStoneParamater({ parameterDetails: obj, actionId: this.actionId, formType: "JSON", barcodeObj: barcodeObj, actionInfo: {name :"Planning"} }));
      // this.store.dispatch(masterDataActions.saveStoneParamater({ parameterDetails: this.payload, actionId: this.rowData.currAction, formType: "JSON", barcodeObj: { packetId: this..packetId }, actionInfo: "Planning" }));
      this.store.dispatch(SignerAction.removeSelectedPlans());
    }
  }
  remarkValue(event: any) {
    console.log(event);
    this.userRemarkValue = event.target.value;
    let obj = { ...this.fileDetailsObject };
    obj["remarkByUser"] = this.userRemarkValue;
    this.fileDetailsObject = obj;

    console.log(this.fileDetailsObject);
  }

async  showHoldModal(){
    const Modal = await this.modalController.create({
      component: TrybemodalComponent,
      cssClass: 'holdPacket',
      componentProps: {
        data: { status: 'lockPacket', data: "" },
      },
    });
    Modal.onDidDismiss().then((d: any) => this.handleModalDismisss(d));
    return await Modal.present();
  }

  async handleModalDismisss(d) {
    console.log(d);
  }


  reviewStone() {
    console.log(this.drawerPlanStoneForm);
    // debugger
    let dataArray = [];
    if (this.drawerPlanStoneForm) {
      for (let i = 0; i < this.drawerPlanStoneForm.reviewStoneData.length; i++) {
        let a: any = {}
        let deptName = this.drawerPlanStoneForm.reviewStoneData[i].department.selectedData[0].department;
        let deptId = this.drawerPlanStoneForm.reviewStoneData[i].department.selectedData[0].id;
        let processName = this.drawerPlanStoneForm.reviewStoneData[i].process.selectedData[0].process;
        let processId = this.drawerPlanStoneForm.reviewStoneData[i].process.selectedData[0].id;

        let deptObj = { department: deptName, id: deptId };
        let processObj = { process: processName, id: processId };
        let jointObj = { department: deptObj, process: processObj }


        a[this.tabId] = [deptObj, processObj];
        dataArray.push(jointObj)
        // console.log(dataArray)
        // debugger

      }
    }

    if (this.drawerPlanStoneForm) {
      let list = [];
      for (let i = 0; i < this.drawerPlanStoneForm.reviewStoneData.length; i++) {
        let packetPlanStoneId = this.tabId;
        let deptId = this.drawerPlanStoneForm.reviewStoneData[i].department.selectedData[0].id;
        let actionId = this.drawerPlanStoneForm.reviewStoneData[i].process.selectedData[0].id;
        let dataObj = {
          packetPlanStoneId: packetPlanStoneId,
          deptId: deptId,
          actionId: actionId
        }
        list.push(dataObj);
      }
      let payload = { list }
      console.log(payload)
      this.reviewStonePayload = payload;
      console.log(this.reviewStonePayload);

      // this.store.dispatch(addDeptToReviewStone({payload: this.reviewStonePayload, tabId : this.tabId}))
      this.drawerPlanStoneForm['otherRemark'] = this.otherRemark;

      this.store.dispatch(SignerAction.addReviewStoneData({
        payload: this.reviewStonePayload, storePayload: dataArray, tabId: this.tabId
      }))
    }

    // ******************
    this.store.pipe(select(getReviewStoneDept)).subscribe((data: any) => {
      if (data) {
        console.log('Selected plan');
        console.log("data accessed from store", data);
        console.log(this.tabId);
        this.storeData = data;
        console.log(this.storeData);
        console.log(typeof this.storeData)
        console.log(this.storeData[this.tabId])
        // this.keyId = Object.keys(data);
        // for(let i=0; i<this.keyId.length; i++){
        //   console.log(this.keyId[i]);
        // }

        // console.log(this.tabId);
        // console.log(this.planidofpiece.id)
        // console.log(data.id)
        // debugger

      }
    });
  }

  // selectDrawerFileDetails(){
  //   this.store.pipe(select(selectFileDetails)).subscribe((data: any) => {
  //     if (data) {
  //         this.planDrawerFileDetails = data;
  //         console.log(this.planDrawerFileDetails);
  //     }
  //   });
  // }
  // selectSpecificFileDetail
  selectDrawerFileDetails() {
    this.store.pipe(select(selectSpecificFileDetail(this.tabId))).subscribe((data: any) => {
      if (data) {
        this.planDrawerFileDetails = data;

        this.fileDetailsObject = { ...data[0] };

        console.log(this.planDrawerFileDetails);
      }
    });
  }



  viewSpecificDrawerFileDetails() {
    console.log(this.planDrawerFileDetails);
    console.log(this.dropDownRemarkData);

    // debugger
    if (this.planDrawerFileDetails && this.planDrawerFileDetails.length) {
      let index = 0;
      // this.planDrawerFileDetails = [];

      this.xpsFileName = this.planDrawerFileDetails[0].xmlFileName;
      this.jpgFileName = this.planDrawerFileDetails[0].jpgFileName;
      this.otherRemark = this.planDrawerFileDetails[0].remarkByUser;
      if (this.dropDownRemarkData['selectedData'] == null) {
        this.dropDownRemarkData['selectedData'] = [];
      }
      if (!!this.planDrawerFileDetails[0].remarkSelected && !this.isEmpty(this.planDrawerFileDetails[0].remarkSelected)) {
        this.dropDownRemarkData['selectedData'].push(this.planDrawerFileDetails[0].remarkSelected);
      }

      console.log(this.dropDownRemarkData);
      console.log(this.planDrawerFileDetails);
      console.log(this.xpsFileName, this.jpgFileName, this.userRemarkValue, this.dropDownRemarkData.selectedData);
      // debugger
      // this.planDrawerFileDetails
    }
  }

  isEmpty(obj) {
    return JSON.stringify(obj) === '{}';
  }

  viewSpecificTabData() {
    console.log(this.storeData, this.tabId);

    if (!!this.storeData && Object.keys(this.storeData).length > 0) {
      if (!!this.storeData[this.tabId]) {
        let index = 0;
        this.departmentProcess = [];
        this.storeData[this.tabId].map(data => {
          this.addDepartment();
          this.departmentProcess[index]['department']['selectedData'].push(data['department']);
          this.departmentProcess[index]['process']['selectedData'].push(data['process']);
          index++;
        });
        console.log(this.departmentProcess);
      }
    }
  }


  addPlan() {
    // this.onProcessDeptDropdown(this.drawerPlanStoneForm)

    console.log(this.planidofpiece)
    this.tabId = this.planidofpiece.id;
    this.onRemarkDropdownSelection(this.drawerPlanStoneForm.remark)

    this.selectedTab = parseInt(this.selectedTab)
    console.log(typeof (this.selectedTab));
    // console.log(this.plan)

    this.selectedTab = eval(this.selectedTab + 1)
    console.log(this.selectedTab);
    console.log(this.gradeArr.length);
    if (this.selectedTab === this.gradeArr.length + 1) {
      this.selectedTab = "end"
    }
    this.drawerPlanStoneForm['reviewStoneData'] = this.departmentProcess;
    console.log('addPlan');
    console.log(this.departmentProcess);
    console.log(this.drawerPlanStoneForm);

    this.drawerPlanStoneForm['otherRemark'] = this.otherRemark;
    this.reviewStone();

    let tempFileDetailsObj: any = {};
    tempFileDetailsObj[this.tabId] = [this.fileDetailsObject];
    console.log(tempFileDetailsObj);

    this.store.dispatch(SignerAction.saveFileDetails({ payload: tempFileDetailsObj, tabId: this.tabId }));

    // if(this.isInactivechecked){
    // this.markAsMakeable(this.tabId)
    // }

    // this.fileDetailsObject = {
    //   'xmlURL' : '',
    //   'xmlFileName' : '',
    //   'jpgURL' : '',
    //   'jpgFileName' : '',
    //   'remarkByUser' : '',
    //   'remarkSelected' : {}
    //  };
    this.departmentProcess = [];
    // debugger
  }

  twoDecimalvalues(number) {
    let temp = String(number)
    let s = temp.split(".")
    return `${s[0]}${s.length > 1 ? "." + s[1].substring(0, 2) : ""}`
  }

  disaplyMaxValue(orderValue, kgRate) {
    if (!orderValue || orderValue == "-" ) {
      orderValue = 0
    }
    if (!kgRate || kgRate == "-") {
      kgRate = 0
    }
    return Math.max(orderValue, kgRate)
  }

 async markAsMakeable(planID){
  if(!!planID){
    let endpoint: string = 'packet/storePacketDetailFromChecker';
    let payload:any = {
      "packetId" :this.packetData?.id ,
      "packetPlanStoneId" : planID
   }
    let response = await this.httpService.postDataUsingPromise(
      true,
      endpoint,
      payload
    );
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      this.dataSource = response.data;
      this.selectedTab = 'end'
    } 
    else{
      this._ConfigService.showToast(false,'Error Occur')
    }
  }
  }


 async submitPlan() {
   console.log(this.actionName)
    // let obj = {...this.fileDetailsObject};
    // obj["remarkByUser"] =this.userRemarkValue ;
    // this.fileDetailsObject = obj;

    // let temp = [...this.selectedPlans];
    // console.log(temp);
    // temp[0].hasSavedPlan = true;
    // this.selectedPlans = temp;
    // this.hasUserSavedPlan = true;
    // console.log(temp)
    await this.orderRemarkandupdatepacketPlan()
    this.selectedPlans = [...this.selectedPlans].map((d) => {
      let obj = { ...d };
      obj.hasSavedPlan = true;
      this.selectedPlans = obj
      return this.selectedPlans;
    })
    // if(this.actionName == 'Smart Recut Planning'){
    //   let payload ={
    //     "packetId" : this.selectedPlans[0]?.id,
    //     "packetPlanStoneId" : this.selectedPlans[0]?.packetPlanStones[0]?.id
    //   };
    //   this.selectedPlans
    //   
    //   this.httpService
    //   .assignPropertyAfterRecut(payload)
    //   .subscribe((res: any) => {
    //     console.log(res);
    //     if(res.statusCode==200){
    //    this._ConfigService.showToast("success",' Plan Saved Successfully');
    //    this.router.navigate(['./home']);
      
    //     }
    //               
    //   },
    //   (err:any)=>{
    //             
    //     this._ConfigService.showToast(
    //       'error',
    //       err.message || 'Something went wrong!'
    //     );
    //   });
    // }
    // console.log(this.selectedPlans);
    this.drawerPlanStoneForm['otherRemark'] = this.otherRemark;
    if (!!this.drawerPlanStoneForm && !!this.drawerPlanStoneForm.remark) {
      console.log(this.drawerPlanStoneForm.remark.remark)
      console.log("when remakr");

      this.store.dispatch(SignerAction.addRemarkForPacketPlandrawerFinalTab({
        remark: this.drawerPlanStoneForm.remark.remark + ',' + this.drawerPlanStoneForm.otherRemark,
        packetplanStoneID: this.currentPlanRowData.id,
        noOfCut: 0,
      }))
    }
    else if (!!this.drawerPlanStoneForm) {
      this.store.dispatch(SignerAction.addRemarkForPacketPlandrawerFinalTab({
        remark: "",
        packetplanStoneID: this.currentPlanRowData.id,
        noOfCut:0,
      }))
      console.log("when no remakr");
    }

    this.saveEvent.emit(this.drawerPlanStoneForm);

    this.fileDetailsObject = {
      'xmlURL': '',
      'xmlFileName': '',
      'jpgURL': '',
      'jpgFileName': '',
      'remarkByUser': '',
      'remarkSelected': {}
    };
    this.departmentProcess = [];
    // debugger

  }

  async submitPlanForChecker() {
    console.log(this.selectedPlans);
    await this.orderRemarkandupdatepacketPlan();
    if(this.page==='checker' || this.page === 'bombay mail'){
    // let obj = {...this.fileDetailsObject};
    // obj["remarkByUser"] =this.userRemarkValue ;
    // this.fileDetailsObject = obj;

    // let temp = [...this.selectedPlans];
    // console.log(temp);
    // temp[0].hasSavedPlan = true;
    // this.selectedPlans = temp;
    // this.hasUserSavedPlan = true;
    // console.log(temp)
    if(this.isInactivechecked || this.checkIsActive){
     await this.markAsMakeable(this.tabId)
    }
    this.selectedPlans = [...this.selectedPlans].map((d) => {
      let obj = { ...d };
      obj.hasSavedPlan = true;
      this.selectedPlans = obj
      return this.selectedPlans;
    })
    console.log(this.selectedPlans);
    console.log(this.hasUserSavedPlan);

    // console.log(this.selectedPlans);
    this.drawerPlanStoneForm['otherRemark'] = this.otherRemark;
    this.saveEvent.emit(this.drawerPlanStoneForm);
  
    if (!!this.drawerPlanStoneForm && !!this.drawerPlanStoneForm.remark) {
      console.log(this.drawerPlanStoneForm.remark.remark)
      console.log("when remakr");

      this.store.dispatch(SignerAction.addRemarkForPacketPlandrawerFinalTab({
        remark: this.drawerPlanStoneForm.remark.remark + ',' + this.drawerPlanStoneForm.otherRemark,
        packetplanStoneID: this.currentPlanRowData.id,
        noOfCut: !!this.drawerPlanStoneForm.noOfCuts?.id ? !!this.drawerPlanStoneForm.noOfCuts?.id : '',
      }))
    }
    else if (!!this.drawerPlanStoneForm) {
      this.store.dispatch(SignerAction.addRemarkForPacketPlandrawerFinalTab({
        remark: "",
        packetplanStoneID: this.currentPlanRowData?.id,
        noOfCut: !!this.drawerPlanStoneForm.noOfCuts?.id ? !!this.drawerPlanStoneForm.noOfCuts?.id : '',
      }))
      console.log("when no remakr");
    }

    // return
    // this.saveEvent.emit(this.drawerPlanStoneForm);

    this.fileDetailsObject = {
      'xmlURL': '',
      'xmlFileName': '',
      'jpgURL': '',
      'jpgFileName': '',
      'remarkByUser': '',
      'remarkSelected': {}
    };
    this.departmentProcess = [];
    this.store.pipe(select(getPlansAccToUserChecker)).subscribe((data) => {
      console.log(data);

      if (data) {
        this.checkerpacketPlans = [...data].filter((dt:any)=>{
          return this.currentPlanRowData.id !== dt.id;
        });
      }
    })
    const indexes = this.checkerpacketPlans.filter(object => object.selectionOrder?object.selectionOrder:object.planPriority).map(x => x.selectionOrder?x.selectionOrder:x.planPriority)
    let order = 1;
    if (indexes.length > 0) {
      console.log(order)
      order = Math.max(...indexes) + 1;
    }
    // this.checkerpacketPlans = this.checkerpacketPlans.map((x: any) => {
    //   if (x.id == this.selectedPlans[0].id) {
    //     let item = { ...x };
    //     item['selectionOrder'] = order;
    //     item['selected'] = true;
    //     x = { ...item };
    //   }
    //   return x
    // })
    
    if(this.packetData.vitualActionName != ACTIONNAMES.BOMBAY_MAIL){
      this.store.dispatch(SignerAction.selectPacketPlansForCheckerView({ planPriority: order, id: this.currentPlanRowData.id, userId: this.userData?.id || this.userData?.userId, packetId: this.packetData.id }));
    }

    if(this.packetData.vitualActionName ==  ACTIONNAMES.BOMBAY_MAIL && this.selectedCheckerTabs == this.currentUser){
      this.store.dispatch(SignerAction.selectPacketPlansForCheckerView({ planPriority: order, id: this.currentPlanRowData.id, userId: this.userData?.id || this.userData?.userId, packetId: this.packetData.id }))
      this.router.navigate(['./home']);
    }
    if (this.selectedCheckerTabs == 'All') {
      this.store.dispatch(chekerAction.setCheckerPlanFinal({ packetPlanId: this.currentPlanRowData.id }));
      // isMakeable: this.isMakeable ? 1 : 0 ;
      // this.submitPlanForChecker()
    }
  }
  else if (this.page==ACTIONNAMES.MAKEABLE_PLANNING || this.page=='intermediatePlanning'){
    console.log(this.checkIsActive)
    const res = {
      'isMakeAble' : this.isInactivechecked,
      'packetPlanId': this.selectedPlans[0].id,
      'checkIsActive': this.checkIsActive,
    }
//     console.log(res);
//     console.log("sssssssspayload.......",this.drawerPlanStoneForm)
//      return
    this.saveEvent.emit(res);
  }
  }
  
 async orderRemarkandupdatepacketPlan() {
  try {
    if(this.selectedPlans.length > 0){
    const payload1 = this.selectedPlans[this.selectedPlans.length - 1].packetPlanStones.flatMap(stone =>
      stone.orders.map(order => ({
        packetPlanStoneId: stone.id,
        orderId: order.id,
        orderRemark: order.orderRemark?.name || order.orderRemark || ''
      }))
    );
    let payload = payload1.filter(e => e.orderRemark != null && e.orderRemark != "")
    let response1 = await this.httpService.postDataUsingPromise(
      true,
      'plan-stone-order/createNew',
      payload
    );
    await this.httpService.getErrorAndDisplayIt(response1);
    if (response1.statusCode === 200) {
      let message = response1.message;
       const payload2 = {
      plans: this.selectedPlans[this.selectedPlans.length - 1].packetPlanStones.flatMap(stone =>
      stone.orders
      .filter(order => order.isActive)
      .map(order => ({
        id: stone.id, 
        orderId: Number(order.id),
        maxOrderValue: Number(stone.maxOrderValue)
      }))
      )
     };
       let response2 = await this.httpService.postDataUsingPromise(
        true,
        'packetPlanStone/mapOrdersWithPlan',
        payload2
      );
      this._ConfigService.showToast('success', message);

    } else {
      console.error(response1);
      this._ConfigService.showToast('error', response1.error.message);
    }
  }
  } catch (error) {
    console.error(error);
    this._ConfigService.showToast('error', 'An unexpected error occurred.');
  }
}

  
  onAddClick() {
    console.log("addded a new process");
    let N: number = this.dataSource.length;
    let k = 0;
    k += N;
    let obj =
      { id: k, "department": "Select Department", "order": "#232323", "process": "Select Process", "CurrentOrderRate": "$24324", "remark": "" };
    this.dataSource.push(obj);

  }

  /*===========Department-Process Table Code START==========*/
  addDepartment() {
    console.log("addded a new addDepartment");
    let mId = this.departmentProcess.length + 1;
    let newRow: object = {
      id: mId, department: JSON.parse(JSON.stringify(this.dropDownDepartmentData)), process: JSON.parse(JSON.stringify(this.dropDownProcessData)),
    }
    if (newRow && newRow.constructor == Object && Object.keys(newRow).length !== 0) {
      this.departmentProcess.push(newRow);
    }
    newRow = {};
    console.log(newRow);
    console.log(this.departmentProcess);
  }
  editDepartment(d, index) {
    console.log("editDepartment");
    console.log(d, index);

  }
  removeDepartment(d, index) {
    console.log("removeDepartment");
    console.log(d, index);
    if (this.departmentProcess) {
      this.departmentProcess.splice(index, 1);
    }
  }
  /*===========Department-Process Table Code END==========*/

  onInactivebtn(event) {
    // this.isInactivechecked = event.detail.checked;
    if(this.isInactivechecked){
     this.planidofpiece['isMakeable'] = true;
     this.checkIsActive = true
    }
    else{
      this.planidofpiece['isMakeable'] = null
    }
  }

  async uploadRowFile(planId, rowId) {
    const selectedFile = this.xpsFileName;
    this.packetPlanData = [...this.packetPlanData].map(n => {
      const formatData = new FormData();
      formatData.append("file", selectedFile);
      formatData.append("packetPlanStoneId", planId)
      let otherParam = { packetPlanStoneId: planId, for: 'uploadXpsOrHtmlFile', "packetId": rowId }
      this.store.dispatch(SignerAction.uploadFileToPacketPlans({ postData: formatData, param: otherParam }));
      //const copyOfObj = [...n.packetPlanStones];
      const packetPlanStones = n.packetPlanStones.map(x => {
        if (x.id == planId) {
          return {
            ...x,
            isFileSelected: true,
            xpsFile: selectedFile
          }
        } else {
          return x
        }
      })
      return { ...n, packetPlanStones }

    })



  }

  fetchFinalDataInDrawer() {
    let id = this.currentPlanRowData.id
    console.log(id);
    this.httpService.fetchData(true, `packetPlanStone/getRemarkAndNoOfCuts/${id}`).subscribe(response => {
      console.log(response);
      this.httpService.getErrorAndDisplayIt(response);
      this.finalTabData = response.data;
      if (!!this.finalTabData) {

        if (!!this.finalTabData.remarks) {
          const { remarks } = this.finalTabData;
          const values = remarks.split(',');
          this.otherRemark = values[1];
          console.log("coming here");
          const nameToFilter = values[0];
          const filteredData = this.finalDropdownRemark.filter(obj => obj.name === nameToFilter);
          console.log(filteredData);
          let modifyfilteredData = {
            "id": filteredData[0].id,
            "remark": filteredData[0].name
          }
          console.log(modifyfilteredData);

          this.dropDownRemarkData['selectedData'] = [modifyfilteredData];
        }

        if (!!this.finalTabData.noOfCuts) {
          console.log("coming here");
          if (this.finalTabData.noOfCuts == 0) {
            console.log("coming here");
            this.dropDownCutData['selectedData'] = [{ id: 0, name: "NO" }];
            this.drawerPlanStoneForm['noOfCuts'] = this.dropDownCutData['selectedData'][0];
            this.iscutSaved = false;

          }
          else if (this.finalTabData.noOfCuts == 1) {
            console.log("coming here");
            this.dropDownCutData['selectedData'] = [{ id: 1, name: "YES" }]
            this.drawerPlanStoneForm['noOfCuts'] = this.dropDownCutData['selectedData'][0];
            this.iscutSaved = false;

          }
        }
      }
    }
    );
  }

  getUploadedxpsFile(id) {
    console.log(id);
    return new Promise((resolve, reject) => {
      if (id) {
        let endpoint: string = `getUploadedXpsOrHtmlFile/:${id}`;
        this.httpService.fetchData(true, endpoint).subscribe((res: any) => {
          resolve(null);
          if (res) {
            let resultCode: number = res.statusCode;
            console.log(resultCode);
            let d: any = res.data;
            console.log(d);
            if (resultCode == 200) {
              console.log(d);
            }
          }
        }, (err) => { reject(); });
      } else {
        reject();
      }
    });
  }

  async savePlanForChecker() {
    console.log('submitPlan', this.selectedPlans);
    let paramData = {};
    let postData = {};
    let currActionName = this.actionName;
    paramData = {
      paramName: this.actionName,
      paramValue: this.selectedPlans,
    };
    let rowDta = { ...this.selectedPlans };
    rowDta['paramData'] = paramData;
    rowDta['actionInfo'] = this.actionInfo;
    console.log(rowDta);
    postData['listOfPacketPlaneIdList'] = [];
    postData['listOfPacketPlaneIdList'] = [this.selectedPlans];
    let payload: any = {
      "packetId"  : this.packetData.id,
      "packetPlanId" : this.selectedPlans[0]?.id,
      "remark" : this.AdminApprovalRemarks
   };
    await this.store.dispatch(SignerAction.setPacketPlanToIsFinal({ payload: payload }));
    // old payload ----  await this.store.dispatch(SignerAction.setPacketPlanToIsFinal({ packetplan: postData, rowData: rowDta }));
  }


  planReviewSave() {
    const packetPlanStoneIds = this.gradeArr.filter((dt: any) => dt.isSelected).map((dt: any) => dt.id) || [];
    const payload = {
      "packetPlanStoneIds": packetPlanStoneIds,
      "isRough": 1
    }
    this.httpService.saveData(true, 'packetPlanStone/updateRoughReturn', payload).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this._ConfigService.showToast("success", "Saved Successfull!");
        this.closeModal();
      }
      else
        this._ConfigService.showToast("error", res.error.message || 'Something went wrong!');
    });
  }

  rretClick() {
    this.httpService.fetchData(true, `stone-specific-dept/sendPacketToRoughDept/${this.packetData.id}`).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this._ConfigService.showToast("success", "Rought Return Sucessfully!");
        this.closeModal();
      }
      else
        this._ConfigService.showToast("error", res.error.message || 'Something went wrong!');
    });
  }

  saveinputParameter(isVirtual?) {
    let listOfParameter = [];
    console.log(this.currentPlanRowData);
    let barcodeObj = {
      packetId: this.packetData?.id,
    };
    let paramObj;
      paramObj = {
        parameterName: 'Smart Recut Planning',
        parameterValue: 'yes',
      };
    
    listOfParameter.push(paramObj);
    let obj = {
      listOfAllPackets: [
        {
          packetId: this.packetData?.id,
          listOfParameter,
        },
      ],
    };
    if (isVirtual) {
      obj["isTensionVirtual"] = "1";
    }

    this.store.dispatch(
      masterDataActions.saveStoneParamater({
        parameterDetails: obj,
        actionId: this.actionId || this.packetData.currAction,
        formType: 'JSON',
        barcodeObj: barcodeObj,
        actionInfo: this.actionName,
      })
    );
  }

  ngOnDestroy() {
    this.unsuscribes$.next();
    this.unsuscribes$.complete();
  }
}






