import { Component, Input, OnInit } from "@angular/core";
import { ModalController, NavParams, PopoverController } from "@ionic/angular";
import { AppState } from "src/app/reducers";
import { select, Store } from "@ngrx/store";
import { getParamsValuesListByName } from "src/app/masterDataStore/masterData.selector";
import { HttpService } from "src/app/services/http/http.service";
import * as moment from "moment";
import { authUser } from "src/app/authStore/auth.selector";
import { getCurrentAction, getCurrentRowData } from "src/app/tempDataStore/tempData.selector";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { HomeActions } from "src/app/home/state/home.action-type";
import { ConfigService } from "src/app/services/config/config.service";
import { PopoverUserComponent } from "../popover-user/popover-user.component";
import { environment } from "src/environments/environment";
import { TrybemodalComponent } from "../trybemodal/trybemodal.component";
import { ResetordermodalComponent } from "src/app/resetordermodal/resetordermodal.component";
import { ParameterTypes } from "src/app/services/endpoint.enum";

@Component({
  selector: "app-final-inspection",
  templateUrl: "./final-inspection.component.html",
  styleUrls: ["./final-inspection.component.scss"],
})
export class FinalInspectionComponent implements OnInit {
  planData = {
    id: 62080,
    packetId: 14623,
    signerId: 135,
    seqNo: "Saw1-1",
    remarks: null,
    bidAmt: 0,
    packetPlanStones: [
      {
        id: 68114,
        packetPlanId: 62080,
        tag: 2,
        expPol: "0.486",
        shapeIds: 655,
        clarityId: 63,
        cutId: 376,
        colorIds: 227,
        pavAngle: "41.250",
        pavHeight: "43.800",
        crAngle: "35.700",
        crHeight: "14.200",
        tableVal: "60.400",
        girdle: "4.500",
        diameter: "4.990",
        height: "62.600",
        lOrW: "1.000",
        rapoRate: "598.000",
        sawMic: "70mic",
        lengthMM: "4.990",
        heightMM: "3.120",
        widthMM: "4.990",
        createdDate: "2023-09-19T06:04:00.000Z",
        createdBy: "2-surat-dev-signer1",
        modifiedDate: null,
        modifiedBy: null,
        isDeleted: 0,
        planShape: "ROUND",
        planColor: "H",
        planCut: "EXCL",
        planClarity: "SI1",
        planSymmetry: null,
        symmetryId: 0,
        xpsFile: null,
        isDone: null,
        remark: null,
        newCut: "",
        pd: null,
        pdSaved: false,
        kgRate: 0,
        imageFile: null,
        labId: null,
        labName: null,
        isMakeAble: null,
        LD: "0.13",
        orderId: null,
        planFluorescence: "NONE",
        fluorescenceId: 497,
        sawRghWgt: "0.972",
        shape: "ROUND",
        clarity: "SI1",
        color: "H",
        cut: "EXCL",
        symmetry: null,
        progName: null,
        selectedProgramId: null,
        fluorescence: "FAINT",
        fluorescenceIds: 498,
        piece: "A",
        selected: false,
        isFileSelected: false,
        doubtfulValue: 0,
        isShownDoubtfulValue: false,
      },
    ],
    selected: false,
    isExpand: true,
    totExpYield: 0.49,
    totRghYield: 0.97,
    Yield: 0,
    expPolPer1: 16.83,
    expPolPer2: 16.83,
    plannedRemark: "",
    hasSavedPlan: false,
    totalKGRate: 0,
    bestRate: 0,
    totalRate: 0,
    tag: 2,
    selectedRemark: [],
  };
  alpha: any = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  shapeOptions = [];
  shapeIds = [];
  clarityOptions = [];
  fluorescenceOptions = [];
  clarityIds = [];
  private unsubscribe$: Subject<any> = new Subject<any>();
  currentTime = moment().format("hh:mm A");
  public today = moment(new Date()).format("DD MMM, YYYY");
  public timestamp = moment().format("YYYY-MM-DD HH:mm:ss");
  colorOptions = [];
  colorIds = [];
  public gradeToNum = { A: 1, B: 2, C: 3, D: 4, E: 5, F: 6, G: 7, H: 8, I: 9, J: 10, K: 11, L: 12, M: 13, N: 14, O: 15, P: 16, Q: 17, R: 18, S: 19, T: 20, U: 21, V: 22, W: 23, X: 24, Y: 25, Z: 26 };

  shouldDisableButton: boolean = false;
  cutOptions = [];
  @Input() data;
  @Input() hideTitleToggle: boolean = false;
  @Input() hideSubmit: boolean = false;
  cutIds = [];
  toggleNotOkayValue: boolean = false;
  Comments: any = null;
  fluIds = [];
  girdleOptions: any = [];
  nattsOptions: any = [];
  labOptions: any = [];
  tinchOptions: any = [];
  lusterOptions: any = [];
  symmentryOptions = [];
  transformedObject: any;
  project: string = environment.project;
  arrayOfObjects: any;
  packetData;
  payload: {
    packetId: any;
    info: {
      message: any;
      username: any;
      time: any;
      media: any;
      date: any;
      timestamp: any;
      important: boolean;
    };
  };
  userName: any;
  rowData: any;
  actionID: any;
  actionStatus: any;
  polishOptions: any;
  finalPayload: any;

  constructor(
    private modalCtl: ModalController,
    private store: Store<AppState>,
    private httpService: HttpService,
    public modalCtrl: ModalController,
    private configService: ConfigService,
    public popoverController: PopoverController,
    private navParams: NavParams
  ) {
    this.packetData = this.navParams.get("data");
    if (!!this.packetData && this.packetData?.fromPage == "First Allocation") {
      this.toggleNotOkayValue = false;
      if (this.packetData?.packetPlans) {
        this.arrayOfObjects = this.packetData.packetPlans;
      }
    }
  }

  ngOnInit() {
    const transformedObject: any = {};
    if (this.packetData.fromPage === "With Me") {
      this.hideSubmit = true;
    }
    if (this.packetData?.fromPage != "First Allocation") {
      this.data?.data?.forEach(item => {
        if (item?.paramName == "pricingResponse") {
          transformedObject[item?.paramName] = JSON.parse(item?.paramValue);
        } else transformedObject[item?.paramName] = item?.paramValue;
      });
      if (this.project === "kg" && transformedObject["pricingResponse"]) {
        let finalPrice = 0;
        if (!transformedObject["pricingResponse"]["MPM BASE_PD"]) {
          transformedObject["pricingResponse"]["MPM BASE_PD"] = 0;
        }
        if (!transformedObject["pricingResponse"]["MIX PRICE"]) {
          transformedObject["pricingResponse"]["MIX PRICE"] = 0;
        }

        finalPrice = Math.max(transformedObject["pricingResponse"]["MPM BASE_PD"], transformedObject["pricingResponse"]["MIX PRICE"]);
        if (transformedObject["pricingResponse"] && finalPrice) {
          transformedObject["pricingResponse"]["stone_value"] = Math.round(finalPrice * transformedObject.expPol * 100) / 100;
          transformedObject["pricingResponse"]["net_stone_value"] = Math.round(finalPrice * transformedObject.expPol * 100) / 100;
          transformedObject["pricingResponse"]["rapoRate"] = transformedObject["pricingResponse"].RAPAPORT;
          transformedObject["pricingResponse"]["RAPAPORT"] = transformedObject["pricingResponse"].RAPAPORT;
        }
      }
      this.arrayOfObjects = [transformedObject];
      console.log(this.arrayOfObjects);
    }

    this.store.pipe(select(authUser)).subscribe((d: any) => {
      if (d) {
        console.log(d);

        this.userName = d;
        console.log(this.userName);
      }
    });
    this.store
      .pipe(select(getCurrentRowData))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((rd: any) => {
        if (rd) {
          console.log("getCurrentRowData");
          console.log(rd);
          this.rowData = rd[0];
        }
      });

    this.store.pipe(select(getParamsValuesListByName(ParameterTypes.Girdle))).subscribe(girdleData => {
      if (!!girdleData) {
        this.girdleOptions = girdleData;
      }
    });

    this.store.pipe(select(getParamsValuesListByName(ParameterTypes.NATTS))).subscribe(Data => {
      if (!!Data) {
        this.nattsOptions = Data;
      }
    });

    this.store.pipe(select(getParamsValuesListByName(ParameterTypes.Lab))).subscribe(Data => {
      if (!!Data) {
        this.labOptions = Data;
      }
    });

    this.store.pipe(select(getParamsValuesListByName(ParameterTypes.LUSTER))).subscribe(Data => {
      if (!!Data) {
        this.lusterOptions = Data || [];
      }
    });

    this.store.pipe(select(getParamsValuesListByName(ParameterTypes.Tinch))).subscribe(Data => {
      if (!!Data) {
        this.tinchOptions = Data || [];
      }
    });
    
    this.store.pipe(select(getParamsValuesListByName(ParameterTypes.Clarity))).subscribe(Data => {
      if (!!Data) {
        this.clarityOptions = Data || [];
      }
    });

    this.store.pipe(select(getParamsValuesListByName(ParameterTypes.Fluorescence))).subscribe(Data => {
      if (!!Data) {
        this.fluorescenceOptions = Data || [];
      }
    });

    this.store.pipe(select(getParamsValuesListByName(ParameterTypes.Symmetry))).subscribe(Data => {
      if (!!Data) {
        this.symmentryOptions = Data || [];
      }
    });

    this.store
      .pipe(select(getCurrentAction))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data: any) => {
        if (data) {
          console.log(data);
          this.actionID = data.id;
          this.actionStatus = data.status;
        }
      });

    this.store.pipe(select(getParamsValuesListByName(ParameterTypes.Color))).subscribe(colorData => {
      if (!!colorData) {
        this.colorOptions = colorData;

        this.store.pipe(select(getParamsValuesListByName(ParameterTypes.Shape))).subscribe(shapeData => {
          if (!!shapeData) {
            this.shapeOptions = shapeData;
            this.store.pipe(select(getParamsValuesListByName(ParameterTypes.CUT))).subscribe(cutData => {
              if (!!cutData) {
                this.cutOptions = cutData;

                this.store.pipe(select(getParamsValuesListByName(ParameterTypes.Polish))).subscribe(polishData => {
                  if (!!polishData) {
                    this.polishOptions = polishData;

                    this.store.pipe(select(getParamsValuesListByName(ParameterTypes.Purity))).subscribe(purityData => {
                      if (!!purityData) {
                        // this.clarityOptions = purityData;

                        this.store.pipe(select(getParamsValuesListByName(ParameterTypes.Symmetry))).subscribe(symmetryData => {
                          if (!!symmetryData) {
                            // this.symmentryOptions = symmetryData;

                            this.store.pipe(select(getParamsValuesListByName(ParameterTypes.Fluorescence))).subscribe(fluorescenceData => {
                              if (!!fluorescenceData) {
                                // this.fluorescenceOptions = fluorescenceData;
                                let newObj = [...this.planData.packetPlanStones].map((d: any) => {
                                  let clarityIds = [];
                                  let colorIds = [];
                                  let shapeIds = [];
                                  let fluorescenceIds = [];

                                  if (d.clarity) {
                                    clarityIds = this.clarityOptions.filter(x => x.name == d.clarity);
                                  }

                                  if (d.color) {
                                    colorIds = this.colorOptions.filter(x => x.name == d.color);
                                  }

                                  if (d.shape) {
                                    shapeIds = this.shapeOptions.filter(x => x.name == d.shape);
                                  }

                                  if (d.fluorescence) {
                                    fluorescenceIds = this.fluorescenceOptions.filter(x => x.name == d.fluorescence);
                                  }

                                  return {
                                    ...d,
                                    colorIds: colorIds,
                                    clarityIds: clarityIds,
                                    shapeIds: shapeIds,
                                    fluorescenceIds: fluorescenceIds,
                                  };
                                });

                                this.planData = {
                                  ...this.planData,
                                  packetPlanStones: newObj,
                                };
                                console.log(this.planData);
                              }
                            });
                          }
                        });
                      }
                    });
                  }
                });
              }
            });
          }
        });
      }
    });

    this.packetCalculationsInit();
  }

  switchTabs(evt) {
    console.log(this.toggleNotOkayValue);
    if (this.toggleNotOkayValue == true) {
      this.shouldDisableButton = true;
    } else {
      this.shouldDisableButton = false;
    }
  }

  addComments(evt) {
    console.log(this.Comments);
  }

  getHexValue(a) {
    a += 1;
    var c = 0;
    var x = 1;
    while (a >= x) {
      c++;
      a -= x;
      x *= 26;
    }
    var s = "";
    for (var i = 0; i < c; i++) {
      s = this.alpha.charAt(a % 26) + s;
      a = Math.floor(a / 26);
    }
    return s;
  }

  closeModal(data: any) {
    if (!!data) {
      this.modalCtl.dismiss(data);
      console.log(data);
    } else {
      console.log(this.Comments);

      this.modalCtl.dismiss();
    }
  }

  async submitData(data) {
    if (this.shouldDisableButton) return;

    if (environment.project == "kps") {
      const mandatoryKeys = {
        shapeId: "Shape",
        expPol: "Est Pol Weight",
        colorId: "Color",
        clarityId: "Clarity",
        fluorescenceId: "Fluorescence",
        cutId: "Cut",
        polishId: "Pol",
        symmetryId: "Symmetry",
        // "depthPercentage": "Depth",
        // "mRatio": "1.600",
        natts: "Natts",
        tinch: "Tinch",
        luster: "Luster",
        pricingResponse: "Pricing",
        // "topOpenName": "Top Open",
        // "bottomOpenName": "Bottom Open"
      };
      for (const key in mandatoryKeys) {
        if (!data?.[0]?.[key]) {
          this.configService.showToast("Error", `${mandatoryKeys[key]} is required`);
          return;
        }
        // if(!res?.gridRow?.height && !res?.gridRow?.depthPercentage){
        //   this.configService.showToast('Error', `Depth or Height is required`);
        //   return;
        // }
        // if(!res?.gridRow?.diameter && !res?.gridRow?.lOrW){
        //   this.configService.showToast('Error', `Diameter or Ratio is required`);
        //   return;
        // }
      }
    }

    this.finalPayload = data;

    if (!!data && this.toggleNotOkayValue == false) {
      this.shouldDisableButton = true;

      const response = await this.saveInputParameter("verified");

      if (response.statusCode === 200) {
        this.configService.showToast("success", response.message || "Parameter has been saved successfully.");
        this.modalCtl.dismiss(data);
        this.closeModal(null);
      }

      this.shouldDisableButton = false;
    } else if (this.toggleNotOkayValue) {
      console.log(this.Comments);
      this.sendCommentToPacketChat();
      this.modalCtl.dismiss(data);
      this.closeModal(null);
    }
  }

  getRate(rateType: string, kgRate: number, RAPAPORT: number, value: number, totalCarat: number, order: any) {
    let finalOrderValue = 1;
    switch (rateType) {
      case "Back":
        finalOrderValue = RAPAPORT - RAPAPORT * (value / 100);
        finalOrderValue = finalOrderValue * totalCarat;
        break;
      case "Piece":
        finalOrderValue = value;
        break;
      case "Percentage":
        finalOrderValue = kgRate + kgRate * ((value * -1) / 100);
        break;
      case "Rate Below":
        finalOrderValue = value;
        break;
      case "per cts rate":
        finalOrderValue = value * totalCarat;
        break;
    }
    return Number.isNaN(finalOrderValue) ? 0 : finalOrderValue;
  }

  async packetCalculationsInit() {
    // this.arrayOfObjects =
    // this.arrayOfObjects.map(packetPlan => {
    // let packetPlanClone = { ...packetPlan };
    // packetPlanClone['totalMaxOrderValue'] = 0;
    // return {
    // ...packetPlan,
    // packetPlan = packetPlan.map(plans => {
    //   let plansClone = { ...plans };
    //   plansClone['maxOrderValue'] = null;
    //   return plansClone;
    // })
    // }

    // })
    for (let plans of this.arrayOfObjects) {
      // if (!packetplan?.['totalMaxOrderValue'])
      // packetplan['totalMaxOrderValue'] = 0;
      // for (let plans of packetplan) {
      plans["maxOrderValue"] = null;
      let maxFinalValue: any = 0;
      let isFinal = false;
      if (!!plans.orders) {
        let isFinalOrderExist = plans.orders.find(el => !!el.isMarked);
        if (!!isFinalOrderExist) {
          maxFinalValue = isFinalOrderExist.finalValue;
        } else {
          for (let order of plans.orders) {
            if (!order?.orderRemark) {
              let rap = plans.RAPAPORT > 0 ? plans.RAPAPORT : plans.rapoRate;
              let calculatedValue = this.getRate(order.rateType, plans.kgRate, rap, order.value, plans.expPol, order);
              order.finalValue = calculatedValue;
              maxFinalValue = Math.max(maxFinalValue, order.finalValue);
            }
          }
        }
      }
      plans["maxOrderValue"] = Number(maxFinalValue.toFixed(2));
      // packetplan['totalMaxOrderValue'] += (this.disaplyMaxValue(plans['maxOrderValue'], plans['kgRate']) || 0);
      // packetplan['totalKGRate'] = packetplan['totalMaxOrderValue']
      // };
    }
    // this.arrayOfObjects = this.arrayOfObjects.map((dt: any) => {
    //   return {
    //     ...dt,
    //     totalMaxOrderValue: dt.packetPlanStones.map((ppsdt: any) => (this.disaplyMaxValue(ppsdt['maxOrderValue'], ppsdt['kgRate']) || 0)).reduce((accumulator, currentValue) => {
    //       return accumulator + currentValue
    //     }, 0),
    //   }
    // });
    // console.log(this.packetPlans)
  }

  appliedOrderAdded: boolean = false;
  async onResetOrder(d: any, indexofChild: number) {
    let isActiveFound = false;
    if (this.arrayOfObjects[indexofChild]["appliedOrders"]?.length) {
      if (!this.appliedOrderAdded) {
        this.arrayOfObjects[indexofChild]["orders"] = [
          ...this.arrayOfObjects[indexofChild]["appliedOrders"].map((dt: any) => ({ ...dt, isActive: dt.remark ? false : true, orderRemark: dt.remark })),
          ...(this.arrayOfObjects[indexofChild]["orders"] || []),
        ];
        this.appliedOrderAdded = true;
      }
      isActiveFound = true;
    } else {
      (this.arrayOfObjects[indexofChild]["orders"] || []).map((dt: any) => {
        if (dt.isActive && !dt.orderRemark && !isActiveFound) {
          isActiveFound = true;
        }
      });
    }
    if (!isActiveFound) {
      let highestValue = 0,
        highestValueId;
      (this.arrayOfObjects[indexofChild]?.orders || []).map((dt: any) => {
        if (!dt?.orderRemark && highestValue < dt.finalValue) {
          highestValue = dt.finalValue;
          highestValueId = dt.id;
        }
      });
      (this.arrayOfObjects[indexofChild]?.orders || []).map((dt: any, i: number) => {
        if (!dt?.orderRemark && highestValueId == dt.id) {
          this.arrayOfObjects[indexofChild].orders[i]["isActive"] = true;
        } else {
          this.arrayOfObjects[indexofChild].orders[i]["isActive"] = false;
        }
      });
    }

    const resetOrder = await this.modalCtrl.create({
      component: ResetordermodalComponent,
      cssClass: "restorePage",
      componentProps: {
        data: { orderDetails: this.arrayOfObjects[indexofChild], packetId: this.rowData?.id, showIsActive: true },
      },
    });

    resetOrder.onDidDismiss().then((d: any) => {
      if (d.data) {
        if (d.data?.dataSource) {
          this.arrayOfObjects[indexofChild].orders = d.data.dataSource.map((dt: any) => {
            let odt = (this.arrayOfObjects[indexofChild]?.orders || []).find((oodt: any) => oodt.id == dt.id);
            return {
              ...(odt || {}),
              ...dt,
              orderRemark: dt.orderRemark?.name || "",
            };
          });
          //add updated orders
        }
        let highestValue = 0,
          isActiveFound = false;
        this.arrayOfObjects[indexofChild]?.orders?.map((dt: any) => {
          if (dt.isActive && !dt.orderRemark && !isActiveFound) {
            highestValue = dt.finalValue;
            isActiveFound = true;
          }
        });
        if (!isActiveFound)
          (this.arrayOfObjects[indexofChild]?.orders || []).map((dt: any) => {
            if (!dt?.orderRemark && highestValue < dt.finalValue) {
              highestValue = dt.finalValue;
            }
          });
        this.arrayOfObjects[indexofChild]["maxOrderValue"] = highestValue;

        // this.packetCalculationsInit();
        // this.PDValueSubmission(id)
      }
    });
    return await resetOrder.present();
  }

  async saveDataForFinalInspection() {
    delete this.finalPayload[0].null;
    const parameter = [];
    for (const key in this.finalPayload?.[0] || {}) {
      if (this.finalPayload[0][key] == null || this.finalPayload[0][key] == undefined) continue;

      const nameToKey = ["shapeId", "clarityId", "colorId", "cutId", "fluorescenceId", "natts", "labName", "polishId", "symmetryId", "tinch", "luster"];
      const optionsOfkey = {
        shapeId: "shapeOptions",
        clarityId: "clarityOptions",
        colorId: "colorOptions",
        cutId: "cutOptions",
        fluorescenceId: "fluorescenceOptions",
        natts: "nattsOptions",
        labName: "labOptions",
        polishId: "polishOptions",
        symmetryId: "symmentryOptions",
        tinch: "tinchOptions",
        luster: "lusterOptions",
      };
      if (key == "pricingResponse" && this.finalPayload?.[0]?.[key]) {
        parameter.push({
          paramName: key,
          paramValue: JSON.stringify(this.finalPayload[0][key]),
        });
      } else if (nameToKey.includes(key)) {
        parameter.push({
          paramName: key,
          paramValue: this.getIdByName(this.finalPayload[0][key], this[optionsOfkey[key]], "name"),
        });
      } else {
        parameter.push({
          paramName: key,
          paramValue: this.finalPayload[0][key],
        });
      }
    }
    let payload = {
      packetId: this.rowData.id,
      actionId: this.actionID,
      parameter,
    };

    console.log(payload);
    let response = await this.httpService.postDataUsingPromise(true, "packet-parameter-on-each-dept/saveFinalIncpetion", payload);
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      if (response.data == true) {
        this.showHoldModal();
      }
      let data = response.message;
      this.configService.showToast("success", data);
    } else {
      console.error(response);
      this.configService.showToast("error", response.error.message);
    }
    return response;
  }

  async showHoldModal() {
    this.closeModal(null);
    const Modal = await this.modalCtrl.create({
      component: TrybemodalComponent,
      cssClass: "holdPacket",
      componentProps: {
        data: { status: "lockPacket", data: "" },
      },
    });
    Modal.onDidDismiss().then((d: any) => this.handleModalDismisss(d));
    return await Modal.present();
    this.closeModal(null);
  }

  async handleModalDismisss(d) {
    console.log(d);
  }

  async dropDownSelection(evt: any, planId) {
    // why we manipulating packet plan stone after every packet change ?
    let property = evt.property;
    let idList = evt.idList;
    let selected = evt.arrayList;
    if (property == "Shape") {
      console.log(selected);
      this.arrayOfObjects[0].shapeId = selected[0]?.name;
      let newObj = await [...this.planData.packetPlanStones].map((d: any) => {
        if (d.id == planId && !!selected[0]) {
          return {
            ...d,
            shape: selected[0].name,
            planShape: selected[0].name,
            shapeId: selected[0].id,
            shapeIds: selected,
          };
        } else {
          return d;
        }
      });
      this.planData = { ...this.planData, packetPlanStones: newObj };
    } else if (property == "Clarity") {
      this.arrayOfObjects[0].clarityId = selected[0]?.name;
      let newObj1 = await [...this.planData.packetPlanStones].map((d: any) => {
        if (d.id == planId && !!selected[0]) {
          return {
            ...d,
            clarity: selected[0].name,
            planClarity: selected[0].name,
            clarityId: selected[0].id,
            clarityIds: selected,
          };
        } else {
          return d;
        }
      });
      this.planData = { ...this.planData, packetPlanStones: newObj1 };
    } else if (property == "Color" && !!selected[0]) {
      this.arrayOfObjects[0].colorId = selected[0]?.name;
      let newObj2 = await [...this.planData.packetPlanStones].map((d: any) => {
        if (d.id == planId) {
          return {
            ...d,
            color: selected[0].name,
            planColor: selected[0].name,
            colorId: selected[0].id,
            colorIds: selected,
          };
        } else {
          return d;
        }
      });
      this.planData = { ...this.planData, packetPlanStones: newObj2 };
    } else if (property == "Fluorescence") {
      this.arrayOfObjects[0].fluorescenceId = selected[0]?.name;

      let newObj1 = await [...this.planData.packetPlanStones].map((d: any) => {
        if (d.id == planId && !!selected[0]) {
          return {
            ...d,
            flu: selected[0].name,
            planFlu: selected[0].name,
            fluId: selected[0].id,
            fluIds: selected,
          };
        } else {
          return d;
        }
      });
      this.planData = { ...this.planData, packetPlanStones: newObj1 };
    } else if (property == "Cut") {
      this.arrayOfObjects[0].cutId = selected[0]?.name;
      let newObj1 = await [...this.planData.packetPlanStones].map((d: any) => {
        if (d.id == planId && !!selected[0]) {
          return {
            ...d,
            cut: selected[0].name,
            planCut: selected[0].name,
            pluId: selected[0].id,
            pluIds: selected,
          };
        } else {
          return d;
        }
      });
      this.planData = { ...this.planData, packetPlanStones: newObj1 };
    } else if (property == "Symmetry") {
      this.arrayOfObjects[0].symmetryId = selected[0]?.name;
      let newObj1 = await [...this.planData.packetPlanStones].map((d: any) => {
        if (d.id == planId && !!selected[0]) {
          return {
            ...d,
            symm: selected[0].name,
            plansymm: selected[0].name,
            symmId: selected[0].id,
            symmIds: selected,
          };
        } else {
          return d;
        }
      });
      this.planData = { ...this.planData, packetPlanStones: newObj1 };
    } else if (property == "Polish") {
      this.arrayOfObjects[0].polishId = selected[0]?.name;
      let newObj1 = await [...this.planData.packetPlanStones].map((d: any) => {
        if (d.id == planId && !!selected[0]) {
          return {
            ...d,
            pol: selected[0].name,
            planPlan: selected[0].name,
            planId: selected[0].id,
            planIds: selected,
          };
        } else {
          return d;
        }
      });
      this.planData = { ...this.planData, packetPlanStones: newObj1 };
    } else if (property == "Girdle") {
      this.arrayOfObjects[0].girdleId = selected[0]?.name;
      let newObj1 = await [...this.planData.packetPlanStones].map((d: any) => {
        if (d.id == planId && !!selected[0]) {
          return {
            ...d,
            pol: selected[0].name,
            planPlan: selected[0].name,
            planId: selected[0].id,
            planIds: selected,
          };
        } else {
          return d;
        }
      });
      this.planData = { ...this.planData, packetPlanStones: newObj1 };
    } else {
    }
  }
  async sendCommentToPacketChat() {
    this.payload = {
      packetId: this.rowData.id,
      info: {
        message: this.Comments,
        username: this.userName.fullName,
        time: this.currentTime,
        media: "text",
        date: this.today,
        timestamp: this.timestamp,
        important: false,
      },
    };
    console.log(this.payload);
    let response = await this.httpService.postDataUsingPromise(true, "chat-app/saveChat", this.payload);
    console.log(response);

    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      let data = response.data;
      this.saveInputParameter(this.Comments);
      console.log(data);
    } else {
      this.shouldDisableButton = false;
      console.error(response);
    }
  }

  async updatePlanData(data) {
    // set it to null currently
    if ("girdleId" in data) {
      data.girdleId = null;
    }
    const payload = [];
    for (const key in data[0]) {
      if (data[0].hasOwnProperty(key)) {
        payload.push({
          paramName: key,
          paramValue: isNaN(Number(data[0][key])) ? data[0][key] : Number(data[0][key]),
        });
      }
    }
    console.log(payload);
    let response = await this.httpService.postDataUsingPromise(true, `packet/updatePacketFieldParameters?packetId=${this.rowData.id}`, payload);
    console.log(response);
    await this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      this.configService.showToast("success", "Updated successfully");
      console.log(response.data);
      let data = response.message;
    } else {
      console.error(response);
    }
  }

  async saveInputParameter(paramValue) {
    console.log(this.arrayOfObjects);

    // const dynamicVal = this.arrayOfObjects.map((item) => {
    //   return Object.entries(item).map(([key, value]) => ({
    //     'parameterName': key,
    //     'parameterValue': value
    //   }));
    // }).flat();
    // console.log(dynamicVal);
    let payload = {
      listOfAllPackets: [
        {
          packetId: this.rowData?.id.toString(),
          listOfParameter: {
            parameterName: "Final Inspection",
            parameterValue: "yes",
          },
        },
      ],
      actionId: this.actionID,
    };

    const res = await this.saveDataForFinalInspection();
    if (res.statusCode == 200) {
      let response = await this.httpService.postDataUsingPromise(true, `processActionInput/inputReceiverByActionId/${this.actionID}`, payload);
      console.log(response);
      await this.httpService.getErrorAndDisplayIt(response);
      if (response.statusCode === 200) {
        // this.updatePlanData(this.finalPayload)
        this.store.dispatch(HomeActions.getActionWisePendingAndCompletedPacketsCountList());
        this.store.dispatch(
          HomeActions.getActionNStatusWisePacketList({
            actionId: this.actionID,
            status: this.actionStatus,
          })
        );
        console.log(response.data);
        let data = response.message;
        console.log(data);
      } else {
        this.shouldDisableButton = false;
        console.error(response);
      }
      return response;
    }
  }

  ngDoCheck() {
    if (this.toggleNotOkayValue == true && this.Comments != "" && this.Comments != null) {
      this.shouldDisableButton = false;
    }
  }

  getIdByName(name: any, array: any, key: any) {
    if (name) return array?.find((dt: any) => dt[key] == name)?.id;
    return null;
  }

  getAlphabeticalChar(index: number): string {
    return String.fromCharCode("A".charCodeAt(0) + index - 1);
  }

  async rowClick(values: any, index: number) {
    values = {
      ...values,
      nattsName: values.natts,
      shape: values.shapeId,
      symmetry: values.symmetryId,
      clarity: values.clarityId,
      color: values.colorId,
      fluorescence: values.fluorescenceId,
      cut: values.cutId,
      pol: values.polishId,
      tinchName: values.tinch,
      lusterName: values.luster,
      diameter: values.diameter ? values.diameter : values.lOrW,
      labName: values?.pricingResponse?.mLab,
    };
    const popover = await this.popoverController.create({
      component: PopoverUserComponent,
      cssClass: "planningRowParameterSelection",
      componentProps: { forPage: "planningRowParameterSelection", gridRow: values },
    });
    await popover.present();
    popover.onDidDismiss().then(async (res: any) => {
      if (res?.data?.gridRow) {
        let obj = {
          id: [123],
          packetPlanStones: [res.data.gridRow],
        };
        let data = await this.updateOrderFormAfterUpdatingParam([obj])
        res.data.gridRow = data[0].packetPlanStones[0];
        
        this.arrayOfObjects[index] = {
          ...res.data.gridRow,
          natts: res.data.gridRow.nattsName,
          shapeId: res.data.gridRow.shape,
          symmetryId: res.data.gridRow.symmetry,
          clarityId: res.data.gridRow.clarity,
          colorId: res.data.gridRow.color,
          fluorescenceId: res.data.gridRow.fluorescence,
          cutId: res.data.gridRow.cut,
          polishId: res.data.gridRow.pol,
          tinch: res.data.gridRow.tinchName,
          luster: res.data.gridRow.lusterName,
        };

        
        if (!this.arrayOfObjects[index].pricingResponse) {
          this.arrayOfObjects[index].pricingResponse = {};
        }
        if (res?.data?.gridRow?.labName && this.arrayOfObjects[index].pricingResponse) this.arrayOfObjects[index].pricingResponse.mLab = res?.data?.gridRow?.labName;

        this.getPricing(index);
      }
    });
  }

    async updateOrderFormAfterUpdatingParam(payload, i?: number, j?: number) {
    try {
      let response = await this.httpService.postDataUsingPromise(true, "packetPlanStone/getOrderList", payload);
      this.httpService.getErrorAndDisplayIt(response);

      if (response.statusCode === 200) {
        let data = response.data;
        if (data?.length) {
          if (i > -1 && j > -1) {
            this.packetCalculationsInit();
          } else if (i > -1) {
          } else {
          }
        }
        return data;
      } else {
        console.error(response);
        return null;
      }
    } catch (error) {
      console.error("An error occurred:", error);
      return null;
    }
  }

  getPricing(i: number) {
    let ratio = null;
    if (this.arrayOfObjects[i].shape == "ROUND" || this.arrayOfObjects[i].expPol < 0.18) {
      ratio = this.arrayOfObjects[i].diameter;
    } else {
      ratio = this.arrayOfObjects[i].lOrW;
    }
    
    if(this.project == 'kg')
      ratio = Math.round(this.arrayOfObjects[i].lengthMM / this.arrayOfObjects[i].widthMM * 100) / 100

    if (this.arrayOfObjects[i]?.depthPercentage) {
      this.arrayOfObjects[i].height = this.arrayOfObjects[i].depthPercentage;
    }
    let lab = "";
    lab = this.arrayOfObjects[i]?.pricingResponse?.mLab ? this.arrayOfObjects[i]?.pricingResponse?.mLab : this.arrayOfObjects[i].labName;

    const kpPricingPayload = {
      id: i + 1, //
      mShape: this.arrayOfObjects[i]?.shape,
      mCrts: this.arrayOfObjects[i]?.expPol,
      mLab: lab || "",
      mColor: this.arrayOfObjects[i]?.color,
      mPurity: this.arrayOfObjects[i]?.clarity,
      mFlrc: this.arrayOfObjects[i]?.fluorescence || "NONE",
      mCut: this.arrayOfObjects[i]?.cut,
      mPol: this.arrayOfObjects[i]?.pol,
      mSym: this.arrayOfObjects[i]?.symmetry,
      mDepth: this.arrayOfObjects[i]?.height || this.arrayOfObjects[i]?.depthPercentage,
      mRatio: ratio,
      mNatts: this.arrayOfObjects[i]?.nattsName || "NO BLK",
      mTinch: this.arrayOfObjects[i]?.tinchName || "N/A",
      mMilky: this.arrayOfObjects[i]?.lusterName || "NORMAL",
      mTO: "N/A", //this.arrayOfObjects[i]?.topOpenName,
      mBO: "N/A", //this.arrayOfObjects[i]?.bottomOpenName
    };

    const kgPricingPayload = {
      cts: this.arrayOfObjects[i]?.expPol,
      Shape: this.arrayOfObjects[i]?.shape,
      Clarity: this.arrayOfObjects[i]?.clarity,
      Color: this.arrayOfObjects[i]?.color,
      Cut: this.arrayOfObjects[i]?.cut,
      Polish: this.arrayOfObjects[i]?.pol,
      Symmetry: this.arrayOfObjects[i]?.symmetry,
      Fluorescence: this.arrayOfObjects[i]?.fluorescence || "NONE",
      ID: i + 1,
      "Pd Cat": this.arrayOfObjects[i]?.pdCat,
      Length: this.arrayOfObjects[i].lengthMM,
      Width: this.arrayOfObjects[i].widthMM,
      "Table Per": Math.round(this.arrayOfObjects[i].tableVal * 100) / 100,
      "Tot Depth": this.arrayOfObjects[i]?.height || this.arrayOfObjects[i]?.depthPercentage,
      "New Cut": "",
      L_W: ratio,
    };

    const kgPricingNewCutPayload = {
      expPol: this.arrayOfObjects[i]?.expPol,
      shape: this.arrayOfObjects[i]?.shapeId,
      clarity: this.arrayOfObjects[i]?.clarityId,
      color: this.arrayOfObjects[i]?.colorId,
      cut: this.arrayOfObjects[i]?.cutId,
      polish: this.arrayOfObjects[i]?.polishId,
      symmetry: this.arrayOfObjects[i]?.symmetryId,
      fluorescence: this.arrayOfObjects[i]?.fluorescenceId || 497,
      ID: this.arrayOfObjects[i]?.id,
      pdCatId: this.arrayOfObjects[i]?.pdCatId,
      tableVal: Math.round(this.arrayOfObjects[i].tableVal * 100) / 100,
      crAngle: this.arrayOfObjects[i]?.crAngle,
      height: this.arrayOfObjects[i]?.height || this.arrayOfObjects[i]?.depthPercentage,
      "New Cut": "",
      lOrW: this.arrayOfObjects[i].lOrW,
      girdle: this.arrayOfObjects[i]?.girdle,
      tinch: this.arrayOfObjects[i]?.tinch,
      lengthMM:this.arrayOfObjects[i]?.lengthMM,
      widthMM:this.arrayOfObjects[i]?.widthMM
    };
    if (this.project == "kg") {
      this.httpService.getNewCutValueOnFinalInspection(kgPricingNewCutPayload).subscribe((resCut: any) => {
        if (!!resCut.data) {
          kgPricingPayload["New Cut"] = resCut.data?.name || "";
        } else {
          kgPricingPayload["New Cut"] = "";
        }

        this.httpService.getPrincing(environment.PricingUrl, [kgPricingPayload]).subscribe((res: any) => {
          if (res?.data?.[0]?.Errors) {
            this.configService.showToast("error", res?.data?.[0]?.Errors);
            return;
          }
          let data = res.data[0];
          let finalPrice = 0;
          if (!data["MPM BASE_PD"]) {
            data["MPM BASE_PD"] = 0;
          }
          if (!data["MIX PRICE"]) {
            data["MIX PRICE"] = 0;
          }

          finalPrice = Math.max(data["MPM BASE_PD"], data["MIX PRICE"]);
          if (data && finalPrice) {
            this.arrayOfObjects[i]["pricingResponse"]["stone_value"] = Math.round(finalPrice * this.arrayOfObjects[i].expPol * 100) / 100;
            this.arrayOfObjects[i]["pricingResponse"]["net_stone_value"] = Math.round(finalPrice * this.arrayOfObjects[i].expPol * 100) / 100;
            this.arrayOfObjects[i]["pricingResponse"]["rapoRate"] = data.RAPAPORT;
            this.arrayOfObjects[i]["pricingResponse"]["RAPAPORT"] = data.RAPAPORT;
          }
        });
      });
    } else {
      this.httpService.getPrincing(environment.PricingUrl, [kpPricingPayload]).subscribe((res: any) => {
        if (res.length > 0) {
          let data = res[0];
          if (!this.arrayOfObjects[i]["pricingResponse"]) this.arrayOfObjects[i]["pricingResponse"] = {};
          this.arrayOfObjects[i]["pricingResponse"]["Lab"] = data["mLab"];
          this.arrayOfObjects[i]["pricingResponse"]["mLab"] = data["mLab"];
          this.arrayOfObjects[i]["pricingResponse"]["stone_value"] = data["stone_value"];
          this.arrayOfObjects[i]["pricingResponse"]["labour"] = data["labour"];
          this.arrayOfObjects[i]["pricingResponse"]["pointer_range"] = data["pointer_range"];
          this.arrayOfObjects[i]["pricingResponse"]["net_stone_value"] = data["net_stone_value"];
        }
      });
    }
  }
}
