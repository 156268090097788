import { AfterViewInit, Component, Input, OnChanges, OnInit, SecurityContext, SimpleChange } from "@angular/core";
import { HttpService } from "../services/http/http.service";
import { ConfigService } from "../services/config/config.service";
import { LoaderService } from "../services/loader/loader.service";
import { ModalController, NavParams } from "@ionic/angular";
import { Subject, Subscription } from "rxjs";
import { Store, select } from "@ngrx/store";
import { getCompletedPacketsData, getPendingPacketsData } from "../home/state/home.selector";
import { HomeActions } from "../home/state/home.action-type";
import { getCurrentAction } from "../tempDataStore/tempData.selector";
import { takeUntil } from "rxjs/operators";
import { masterDataActions } from "../masterDataStore/masterData.action-type";
import { DomSanitizer } from "@angular/platform-browser";
import { getParamsValuesListByName } from "../masterDataStore/masterData.selector";
import { virtualAPIs } from "../components/my-virtual-task/state/my-virtual-task.data";
import { ParameterTypes } from "../services/endpoint.enum";

@Component({
  selector: "app-tensionmapping",
  templateUrl: "./tensionmapping.component.html",
  styleUrls: ["./tensionmapping.component.scss"],
})
export class TensionmappingComponent implements OnChanges {
  stressValue: string = "";
  stressName: string = "";
  rowData: any;
  isFilter: boolean = false;
  tensionActionData: any = "";
  private unsubscribe$: Subject<any> = new Subject<any>();
  currentIndex = 0;
  packetData;
  actionStatus = "";
  isCountShown: boolean = false;
  packetCount: number;
  dropDownCopyPacketData: any;
  finalSelection: any;
  shownSelection: any = "";
  dropDownLotData: any = {
    name: "lotNo",
    placeText: "lot no.",
    options: [],
    selectedData: [],
    key: "name",
    modeFlag: "single", //single/multiple
  };
  dropDownPacketData: any = {
    name: "PacketNo",
    placeText: "Pacekt No.",
    options: [],
    selectedData: [],
    key: "packet",
    modeFlag: "single", //single/multiple
  };
  isGetDataDisabled: boolean = true;
  arrayOfPacketData: any = [];
  packetHeading;
  packetDataArrList: any = false;
  isStressNameNone: boolean = false;
  isNoneSelected: null | boolean = null;

  hideHeader: boolean = false;
  dropdown: any = {};
  videoOfTension: any = "";
  isVirtual: number = 0;
  packetIDUrl: string = "";
  @Input() data: any;
  @Input() forPage: any = "";

  private subscriptions: Subscription[] = [];
  isUpdateDisable: boolean = true;

  stressData: any = [];
  stressNameData: any = [];
  stressTypeData: any = [];
  stressTypeNameData: any = [];
  constructor(
    private httpService: HttpService,
    private _ConfigService: ConfigService,
    public loaderService: LoaderService,
    public store: Store,
    private modalCtrl: ModalController,
    private domSanitizer: DomSanitizer
  ) {}

  ngOnChanges(changes): void {}
  unique: any = [];
  packetName: any = [];

  ngOnInit() {
    console.log(this.forPage);

    this.getMasterData();

    this.store
      .pipe(select(getCurrentAction))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((action: any) => {
        if (action) {
          console.log("Selected action");
          console.log(action);
          this.tensionActionData = action;
          this.actionStatus = action.status;
        }
      });
    console.log(this.actionStatus);

    if (this.forPage == "" || this.forPage == "undefined") {
      this.store.dispatch(
        HomeActions.getActionNStatusWisePacketList({
          actionId: 132,
          status: this.tensionActionData.status,
        })
      );
      if (this.tensionActionData.status == "Pending") {
        this.subscriptions.push(
          this.store
            .pipe(select(getPendingPacketsData(this.tensionActionData.id)))
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(data => {
              if (data) {
                this.packetData = Object.values(data);
                console.log(this.packetData);
                this.dropDownCopyPacketData = Object.values(data);
                if (this.actionStatus == "Pending" && this.packetData) {
                  this.fetchVideoOfTension();
                  this.unsubscribe();
                }
              }
            })
        );
        let lotNo = this.packetData.map(item => item.lotNo);
        this.unique = Array.from(new Set(lotNo));
        console.log(this.packetData);
        this.dropDownLotData.options = this.unique;
      } else if (this.tensionActionData.status == "Completed") {
        let videoFetched = false;
        this.subscriptions.push(
          this.store
            .pipe(select(getCompletedPacketsData(this.tensionActionData.id)))
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(data => {
              if (data) {
                console.log(this.packetData);
                this.packetData = Object.values(data);
                this.dropDownCopyPacketData = Object.values(data);
                console.log(this.packetData);
                for (let i = 0; i < this.packetData.length; i++) {
                  console.log(i);
                  this.stressName = this.packetData[i]?.inputDetails[0]?.paramSValue;
                  this.stressValue = this.packetData[i]?.inputDetails[1]?.paramSValue;
                }
                if (this.actionStatus == "Completed") {
                  this.fetchVideoOfTension();
                  this.unsubscribe();
                }

                let lotNo = this.packetData.map(item => item.lotNo);
                this.unique = Array.from(new Set(lotNo));
                this.dropDownLotData.options = this.unique;
              }
            })
        );
      }
    }

    if (this.forPage == "virtualTensionPlanning") {
      this.packetData = this.data;
      this.dropDownCopyPacketData = Object.values(this.packetData);
      console.log(this.data, this.packetData);
      let lotNo = this.data.map(item => item.lotNo);
      this.unique = Array.from(new Set(lotNo));
      console.log(this.data);
      this.dropDownLotData.options = this.unique;
      this.tensionActionData = { id: 132, name: "Tension Mapping" };
      this.isVirtual = 1;

      this.fetchVideoOfTension();
    }

    // this.dropDownLotNo.options = [{
    //   id: 1,
    //   name: "yes"
    // },
    // {
    //   id: 2,
    //   name: "No"
    // }]
    console.log(this.currentIndex);
  }

  ionViewWillEnter() {
    this.isNoneSelected = null;
  }

  getMasterData() {
    this.store.pipe(select(getParamsValuesListByName(ParameterTypes.Stress))).subscribe(data => {
      if (!!data) {
        this.stressData = data;
        this.stressNameData = data.map((item: any) => item.name);
      }
    });
    this.store.pipe(select(getParamsValuesListByName(ParameterTypes.StressValue))).subscribe(data => {
      if (!!data) {
        this.stressTypeData = data;
        this.stressTypeNameData = data.map((item: any) => item.name);
      }
    });
  }

  onStressSelection(event, param) {
    if (param === "stressName") {
      this.stressName = event.detail.value;
      console.log(this.stressName);
      if (this.stressName == "NONE") {
        this.isStressNameNone = true;
        // this.isNoneSelected = true;
        this.stressValue = "0";
      } else {
        this.isStressNameNone = false;
        this.isNoneSelected = false;
      }
    } else if (param === "stressValue") {
      this.stressValue = event.detail.value;
    }
  }

  setValuesOfDropdown(evt) {
    if (this.dropdown[evt.property] == undefined) {
      if (evt.arrayList.length > 0) {
        this.dropdown[evt.property] = {};
        this.dropdown[evt.property] = evt.arrayList[0];
        console.log(evt.arrayList);
      } else {
        delete this.dropdown[evt.property];
      }
    } else {
      if (evt.arrayList.length > 0) {
        this.dropdown[evt.property] = {};
        this.dropdown[evt.property] = evt.arrayList[0];
      } else {
        delete this.dropdown[evt.property];
      }
    }
    console.log(this.dropdown);
    this.isUpdateDisable = Object.keys(this.dropdown).length === 0 ? true : false;
  }

  dropDownSelection(evt: any, plan: any) {
    // this.arrayOfPacketData = [];
    console.log("dropDownSelection");
    console.log(evt);
    this.finalSelection = evt.arrayList[0];
    if (evt.property == "lotNo") {
      if (evt.arrayList.length === 0) {
        this.dropDownPacketData.options = [];
        this.isGetDataDisabled = true;
        this.isCountShown = false;
      } else {
        this.isCountShown = true;
      }
      this.dropDownPacketData.selectedData = [];
      //  this.dropDownPacketData.options =[];
      this.setValuesOfDropdown(evt);
      console.log(this.setValuesOfDropdown);

      let map = new Map();

      for (let item of this.dropDownCopyPacketData) {
        let lotNo = item.lotNo;
        console.log(lotNo);
        if (map.has(lotNo)) {
          let data = map.get(lotNo);
          data.push(item.packetId);
          map.set(lotNo, data);
        } else {
          map.set(lotNo, [item.packetId]);
        }
      }

      console.log(map);
      // arrayOfPacketData: any = [];
      Array.from(map)
        .map(([name, value]) => ({ name, value }))
        .filter((data: any) => {
          console.log(data.name);
          if (evt.arrayList[0] == data.name) {
            this.dropDownPacketData.options = data.value;
            console.log(data.value);
            this.packetCount = this.dropDownPacketData.options.length;
            console.log(this.packetCount);
            console.log(this.dropDownPacketData.option);
            console.log(evt.arrayList[0]);
            console.log(evt);
            return true;
          } else {
            console.log("wrong value here");
            // this.dropDownPacketData.options = [];
            return false;
          }
        });

      console.log(this.arrayOfPacketData);
      // debugger
      this.packetName = this.arrayOfPacketData;
      console.log(this.dropDownLotData.selectedData);
      // debugger
    } else if (evt.property == "PacketNo") {
      if (evt.arrayList.length == 0) {
        this.packetDataArrList = true;
        this.isGetDataDisabled = true;
      } else {
        this.isGetDataDisabled = false;
      }
      // this.dropDownPacketData.options = [];
      this.setValuesOfDropdown(evt);
    }
  }

  showNextArray(): void {
    if (this.isFilter != true) {
      if (this.currentIndex === this.packetData.length - 1) {
        return; // Stop if last index is reached
      }
      this.stressName = null;
      this.stressValue = null;
      this.currentIndex++;
    }
    this.fetchVideoOfTension();
  }

  unsubscribe() {
    // Unsubscribe from all subscriptions in the array
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
  getId(name: any, array: string) {
    let res = 0;
    this[array]?.map((dt: any) => {
      if (dt.name == name) res = dt.id;
    });
    return res;
  }
  onSaveClick() {
    console.log(this.packetData);
    let listOfParameter = [];
    let stressName = {
      parameterName: "Stress",
      parameterValue: this.getId(this.stressName, "stressData"),
    };
    listOfParameter.push(stressName);
    let stressVal = {
      parameterName: "Stress Value",
      parameterValue: this.getId(this.stressValue, "stressTypeData"),
    };
    listOfParameter.push(stressVal);
    console.log(this.stressName);
    console.log(this.stressValue);

    console.log(listOfParameter);

    let formType = "Multipart";
    let barcodeObj = {
      packetId: this.packetData[this.currentIndex].packetId,
    };
    let obj = {
      listOfAllPackets: [
        {
          packetId: this.packetData[this.currentIndex].id,
          listOfParameter,
        },
      ],
      isTensionVirtual: this.isVirtual,
    };
    console.log(this.tensionActionData.id);
    console.log(barcodeObj);
    console.log(obj);
    console.log(listOfParameter);
    if (this.isFilter == true) {
      if (this.forPage == "") {
        this.data = this.packetData;
      }

      this.currentIndex = 0;
      this.packetData = this.data;
      console.log(this.data);
      this.dropDownLotData.selectedData = [];
      this.dropDownPacketData.selectedData = [];
    }

    // this.store.dispatch(
    //   masterDataActions.saveStoneParamater({
    //     parameterDetails: obj,
    //     actionId: this.tensionActionData.id,
    //     formType: 'JSON',
    //     barcodeObj: barcodeObj,
    //     actionInfo: this.tensionActionData.name,
    //   })
    // );

    this.httpService.saveStoneParamater(obj, this.tensionActionData.id, "JSON").subscribe(res => {
      if (res.statusCode === 200 || res.statusCode === 201) {
        this._ConfigService.showToast("success", res.message || "Packet Created Succesfully");

        if (!this.isFilter) {
          this.showNextArray();
        }
      } else {
        this._ConfigService.showToast("error", res.message || "Something went wrong!");
      }
    });

    // if (!this.isFilter) {
    //   this.showNextArray();
    // }
  }

  async changesStatus(id: number) {
    // 
    let endpoint: string = `${virtualAPIs.statusVirtualTaskEndPoint}${id}`;
    this.httpService.fetchData(true, endpoint).subscribe((res: any) => {
      if (res) {
        console.log("change Status");
        console.log(res);
        let resultCode: number = res.statusCode;
        let d: any = res.data;
        let message: string = res.message;
        console.log(d);
        if (resultCode === 200) {
          // 
          console.log("%c" + message, "color:green;font-size:1rem;");
        } else {
          // 
          console.log("%c" + message, "color:red;font-size:1rem;");
          // this.showModal(false, message, {});
        }
      }
    });
  }

  async fetchVideoOfTension() {
    console.log(this.packetData);
    if (!!this.packetData && this.packetData.length > 0) {
      console.log(this.packetData);
      console.log(this.currentIndex);
      console.log("fetchAllActions");
      const packetID = this.packetData[this.currentIndex]?.id;
      // let response: any = await this.httpService.fetchDataOfTensionFile(true, `getfileForVision?folder=uploads/${this.packetData?.[this.currentIndex]?.barcodeNo}/V360&extension=html`);
      if (packetID) {
        await this.changesStatus(this.packetData[this.currentIndex]?.virtualId);
        let response: any = await this.httpService.fetchDataOfTensionFile(true, `processActionInput/getFileThroughCentralSystem/${packetID}`);
        console.log(response);
        const barcode = this.httpService.getErrorAndDisplayIt(response);

        const self = this;
        if (response.statusCode === 200) {
          if (response.data.filePath.length != 0) {
            const filePath = response.data;
            let newFileName = filePath.filePath[0];
            newFileName = newFileName.split("/");
            let joinedPath = newFileName.slice(1).join("/");
            setTimeout(function () {
              self.videoOfTension = self.domSanitizer.bypassSecurityTrustResourceUrl(`${self._ConfigService.getCentralFileUrl() + newFileName[0] + "/"}${encodeURIComponent(joinedPath)}`);
            }, 1000);
          } else {
            this._ConfigService.showToast("error", "File Not Available");
            this.videoOfTension = null;
          }
        } else {
          console.error(response);
        }
      }
    }
  }

  dropDownPacketSelection() {
    this.packetData = this.data;

    // let map = new Map()
    // for(let item of this.data){
    //     let lotNo = item.lotNo
    //     if(map.has(lotNo)){
    //         let data = map.get(lotNo)
    //         data.push(item.packetId)
    //         map.set(lotNo,data)

    //     }else{
    //         map.set(lotNo,[item.packetId])
    //     }
    // }

    // console.log(map);
    // this.packetName = map;
  }
  async getData(e) {
    console.log(e);
    this.isFilter = true;
    console.log(this.dropDownPacketData.options);
    console.log(this.data);
    console.log(this.packetData);
    this.shownSelection = this.finalSelection;
    console.log(this.dropDownPacketData.selectedData);
    console.log("temp", this.shownSelection);
    console.log(this.finalSelection);

    const desiredId = this.finalSelection;

    const desiredObject = [this.dropDownCopyPacketData.find(obj => obj.packetId === desiredId)];

    console.log(desiredObject);
    this.packetData = desiredObject;

    console.log(this.packetData);
    this.fetchVideoOfTension();
  }
}
