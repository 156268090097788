import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { GET_CHILD_PARAMETERS, GET_MASTER_PARAMETERS } from 'src/app/components/masters/state-management/masters.action';
import { selectChildParameters, selectMasterParameters } from 'src/app/components/masters/state-management/masters.selector';
import { getAllParameters } from 'src/app/masterDataStore/masterData.selector';

@Component({
  selector: 'app-opinion-criteria-master-popup',
  templateUrl: './opinion-criteria-master-popup.component.html',
  styleUrls: ['./opinion-criteria-master-popup.component.scss'],
})
export class OpinionCriteriaMasterPopupComponent implements OnInit {
  copyData: any;

  constructor(
    private navParams: NavParams,
    public popoverController: PopoverController,
    private store: Store, 
  ) { 
    this.getMasterParameters();
  }

  subScriptions = new Subscription();
  forPage:string = '';
  dropDownChangeData: any = {
    name: 'change',
    placeText: 'Select',
    options: [
      // {
      //   name: 'Upgrade',
      //   id: 'Upgrade',
      // },
      // {
      //   name: 'Downgrade',
      //   id: 'Downgrade',
      // },
      // {
      //   name: 'Change',
      //   id: 'Change',
      // },
      // {
      //   name: 'Equal to',
      //   id: 'Equal to',
      // },
      // {
      //   name: 'Range',
      //   id: 'Range',
      // }
    ],
    selectedData: [],
    key: 'name',
    modeFlag: 'single', //single/multiple
  };
  showRange: boolean = false;
  paramsToShowRange: string[] = [
    'POLISHED WEIGHT',
    'POINTER',
    'DEPTH',
    'RATIO',
    'NET VALUE DIFFERENCE'
  ];

  showUpDownDrop: boolean = false;
  paramOperatorToShowUpDown: string[] = [
    "Tolerance"
  ]
 
  paramsToShowUpDownRange: string[] = []
  approvalParameter:any;
  // dropDownCheckerPolishGrade: any = {
  //   name: 'Department',
  //   placeText: 'Select Department',
  //   options: [],
  //   selectedData: [],
  //   key: 'grade',
  //   modeFlag: 'single', //single/multiple
  // };
  dropDownApprovalParam: any = {
    name: 'Approval Parameter',
    placeText: 'Select Parameter',
    options: [
      // {
      //   name: 'LAB',
      //   id: 'LAB',
      //   masterName: 'Lab',
      // },
      // {
      //   name: 'POINTER',
      //   id: 'POINTER',
      // },
      // {
      //   name: 'POLSHED WEIGHT',
      //   id: 'POLSHED WEIGHT',
      // },
      // {
      //   name: 'Opinion Remark',
      //   id: 'OpinionRemark',
      //   masterName : 'OpinionRemark'
      // },
      // {
      //   name: 'CLARITY',
      //   id: 'CLARITY',
      //   masterName: 'Clarity',
      // },
      // {
      //   name: 'COL',
      //   id: 'COL',
      //   masterName: 'Color',
      // },
      // {
      //   name: 'FLR',
      //   id: 'FLR',
      //   masterName: 'Fluorescence',
      // },
      // {
      //   name: 'CUT',
      //   id: 'CUT',
      //   masterName: 'CUT',
      // },
      // {
      //   name: 'SYM',
      //   id: 'SYM',
      //   masterName: 'Symmetry',
      // },
      // {
      //   name: 'POLISHING',
      //   id: 'POLISHING',
      //   masterName: 'Polish',
      // },
      // {
      //   name: 'LUSTER',
      //   id: 'LUSTER',
      //   masterName: 'Luster',
      // },
      // {
      //   name: 'TINCH',
      //   id: 'TINCH',
      //   masterName: 'Tinch',
      // },
      // {
      //   name: 'DEPTH',
      //   id: 'DEPTH',
      //   masterName: 'DEPTH',
      // },
      // {
      //   name: 'RATIO',
      //   id: 'RATIO',
      // },
      // {
      //   name: 'TOP OPEN',
      //   id: 'TOP OPEN',
      //   masterName: 'TOP OPEN',
      // },
      // {
      //   name: 'BOTTOM OPEN',
      //   id: 'BOTTOM OPEN',
      //   masterName: 'BOTTOM OPEN',
      // },
    ],
    selectedData: [],
    key: 'name',
    modeFlag: 'single', //single/multiple
  };
  allMasterData:any = [];
  dropDownUserData: any = {
    name: 'user',
    placeText: 'Approval By',
    options: [
      // {
      //   id: 'Checker',
      //   name: 'Checker',
      // },
      // {
      //   id: 'Admin',
      //   name: 'Admin',
      // },
      // {
      //   id: 'Hc1',
      //   name: 'Hc1',
      // },
      // {
      //   id: 'Hc2',
      //   name: 'Hc2',
      // },
    ],
    selectedData: [],
    key: 'name',
    modeFlag: 'single', //single/multiple
  };

  dropDownMistakeOf: any = {
    name: 'mistakeOf',
    placeText: 'Mistake Of',
    options: [
      // {
      //   id: 'Stock Controller',
      //   name: 'Stock Controller',
      // },
      // {
      //   id: 'Manager Transfer',
      //   name: 'Manager Transfer',
      // },
      {
        id: 'Worker',
        name: 'Worker',
      },
      {
        id: 'Checker',
        name: 'Checker',
      },
      {
        id: 'Smart Recut',
        name: 'Smart Recut',
      },
      {
        id: 'No worker Mistake',
        name: 'No worker Mistake',
      },
      {
        id: 'Department',
        name: 'Department',
      },
      {
        id: 'Process',
        name: 'Process',
      },
    ],
    selectedData: [],
    key: 'name',
    modeFlag: 'single', //single/multiple
    payloadKey: 'mistakeOf',
  };
  parameters: any[] = [];
  upDropDown = {
    name: 'up',
    placeText: 'Select',
    selectedData: [],
    key: 'priority',
    options: [],
    modeFlag: 'single', //single/multiple
    payloadKey: 'up',
  }

  downDropDown = {
    name: 'down',
    placeText: 'Select',
    selectedData: [],
    key: 'priority',
    options: [],
    modeFlag: 'single', //single/multiple
    payloadKey: 'down',
  }

  formDataObject:any = [{}];

  ngOnInit() {
    let d = this.navParams.data;
    this.forPage = d.forPage;
    if(d.selectedApprovalParam){
      if(d?.dropDownApprovalParam?.options){
        this.dropDownApprovalParam.options = d.dropDownApprovalParam.options || [];
      }
      this.dropDownApprovalParam.selectedData = [d.selectedApprovalParam];
      this.approvalParameter = d.selectedApprovalParam;
      if(this.paramsToShowRange?.includes(this.approvalParameter?.name)){
        this.showRange = true;
        this.formDataObject = [{
          [this.approvalParameter?.name]: {range: {}}
        }];
      }
    }
    // if(d.dropDownCheckerPolishGrade){
    //   this.dropDownCheckerPolishGrade = d.dropDownCheckerPolishGrade;
    // }
    if(d.allMasterData){
      this.allMasterData = d.allMasterData;
    }
    if(d.dropDownUserData){
      this.dropDownUserData = d.dropDownUserData;
    }
    if(d.approvalParameter){
      this.formDataObject = [{
        [d.approvalParameter.key]: d.approvalParameter
      }];
    }
    if(d.operatorByParam){
      this.dropDownChangeData.options = d.operatorByParam;
    }

    if(this.paramOperatorToShowUpDown.includes(d.approvalParameter?.change?.id)){
      this.showUpDownDrop = true;
    } 
  this.copyData = JSON.parse(JSON.stringify(this.formDataObject));
  }
  dropdownChanged(event: any, approvalParameter: any, key:string, formData:any){    
    if(!formData[approvalParameter.name])
    formData[approvalParameter.name] = {};

    formData[approvalParameter.name][key]=event?.arrayList?.[0];
    if(this.paramOperatorToShowUpDown.includes(event?.arrayList?.[0].id)){
        formData[approvalParameter?.name]['range'] = {}

      this.showUpDownDrop = true;
    }
  }

  onUpDownChange(event: any, approvalParameter: any, key:string, formData:any){
      if(key == 'up'){
        formData[approvalParameter?.name]['range'].up = event?.arrayList?.[0].priority;
      }else if(key == 'down'){
        formData[approvalParameter?.name]['range'].down = event?.arrayList?.[0].priority * -1;
      }
  }

  addRow(){
    this.formDataObject.push({
      [this.approvalParameter?.name]: {range: {}}
    });
  }

  deleteRow(formData:any){
    this.formDataObject = this.formDataObject.filter((row:any) => row!=formData);
  }

  cancel() {
    this.formDataObject = this.copyData
    this.popoverController.dismiss(this.formDataObject);
  }

  submit(){
    this.popoverController.dismiss(this.formDataObject);
  }

  getMasterParameters() {
    const subs = this.store.select(getAllParameters).subscribe(res => {
      const masterList = JSON.parse(JSON.stringify(res));
      if (masterList) {
        this.parameters = JSON.parse(JSON.stringify(masterList));
        this.downDropDown.options = this.upDropDown.options = this.parameters['CLARITY'].sort((a, b) => a.priority - b.priority).map(el => {
         el = {...el, priority : el.priority / 10 } 
          return el} 
        );  
      }
    });
    this.subScriptions.add(subs);
  }
}
