import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { select, Store } from '@ngrx/store';
import CustomStore from 'devextreme/data/custom_store';
import Query from 'devextreme/data/query';
import { Observable, Subject, Subscription } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { actions } from 'src/app/authStore/auth.selector';
import { getPacketParameterHistoryData, getParamsValuesListByName } from 'src/app/masterDataStore/masterData.selector';
import { AppState } from 'src/app/reducers';
import { HttpService } from 'src/app/services/http/http.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ScannerinputComponent } from '../scannerinput/scannerinput.component';
import { TrybemodalComponent } from '../trybemodal/trybemodal.component';
import { myVirtualTaskActions } from './state/my-virtual-task.actions.type';
import { MyVirtualTask, virtualAPIs } from './state/my-virtual-task.data';
import { getMyTasks } from './state/my-virtual.task.selectors';
import { ResetordermodalComponent } from 'src/app/resetordermodal/resetordermodal.component';
import { TensionmappingComponent } from 'src/app/tensionmapping/tensionmapping.component';
import { ConfigService } from 'src/app/services/config/config.service';
import { SignerAction } from '../trybesigner/state/signer.action.type';
import { masterDataActions } from 'src/app/masterDataStore/masterData.action-type';
import { saveCurrentRowData } from 'src/app/tempDataStore/tempData.action';
import { ProcessService } from 'src/app/services/process/process.service';
import { FileDownloadComponent } from 'src/app/file-download/file-download.component';
import { PopoverUserComponent } from '../popover-user/popover-user.component';
import { PlanningGridComponent } from '../planning-grid/planning-grid.component';
import { HomeActions } from 'src/app/home/state/home.action-type';
import { getFinalPlanData } from '../trybe-checker/state/checker.selector';
import { OpinionMasterComponent } from 'src/app/opinion-master/opinion-master.component';
import { ACTIONNAMES } from 'src/app/services/endpoint.enum';
import { OldFileDownloadComponent } from 'src/app/old-file-download/old-file-download.component';
import { environment } from 'src/environments/environment';
import { OpinionScreenComponent } from 'src/app/opinion-screen/opinion-screen.component';

@Component({
  selector: 'app-my-virtual-task',
  templateUrl: './my-virtual-task.component.html',
  styleUrls: ['./my-virtual-task.component.scss'],
})
export class MyVirtualTaskComponent implements OnInit {
  @ViewChild(ScannerinputComponent)
  private scannerComponet!: ScannerinputComponent;
  public assignedColumns: any[] = [];
  public pendingColumns: any[] = [];
  public completedColumns: any[] = [];
  public listOfActionsWithForm: any = [];

  public virtualActionId: number;
  public virtualId: number;
  public pageSize: number = 50;
  public page: string = 'virtualTask';
  public segment: string = "pending";
  public pendingCount: number = 0;
  public completedCount: number = 0;
  public assignedCount: number = 0;
  public formJson: any;
  actionNameOnBtnClick: string;
  public selectedRow: any = {}
  public selectedRows: any = []
  public actionName: string = "";
  showCheckerFinal:boolean = true;
  public actionStatus: string = null;
  public refreshField: boolean = false;
  public showDrawer: boolean = false;
  public resetForm: boolean = false;
  public reloadForm: boolean = false;
  public freezForm: boolean = false;
  public refreshGrid: boolean = false;
  private unsubscribe$: Subject<any> = new Subject<any>();
  private project : string;
  // public refreshPenGrid: boolean = false;
  // public refreshComGrid: boolean = false;

  public pendingData: any = [];
  public completedData: any = [];
  public assignedGridData: any = [];
  public currActionSavedDetails: any = [];
  public action: any = null;
  ACTIONNAMES=ACTIONNAMES;
  public fromJsonInputs: any = null;
  public status: any;

  public paramSelectionList: any = {};
  public actionInfo: any = {};
  myTasks$: Observable<MyVirtualTask>;
  private unsuscribe$ = new Subject<void>()
  // public myTasks$ = Observable<any>
  public activeTool: string = "myTask";
  public currentSegment: string = "Forms"
  public modifiedGridObject: any;
  private pendingGridPageInfo: any = {
    limit: this.pageSize,
    page: 0,
  }
  private pageInfo: any = {
    limit: this.pageSize,
    page: 0,
  }
  gridData: any = [];
  gridDataCount: number = 0;
  routeTabs: any;
  pageNumber: number = 0;
  myTodoTasks: any;
  isPreviouslyPlanUploaded: boolean = false;
  isHistory:boolean = false;
  isFormRequired:boolean = true;
  packetHistories: any[];
  private subscriptions: Subscription[] = [];
  capFileUrl: any;
  selectedEcsvOption: string;
  winnerPlan: any;
  finalPacketPlans: any;

  constructor(
    private httpService: HttpService,
    private route: ActivatedRoute,
    private modalController: ModalController,
    private store: Store<AppState>,
    private router: Router,
    public processService: ProcessService,
    private configureService: ConfigService,
    public popoverController: PopoverController,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.route.queryParams.pipe(takeUntil(this.unsuscribe$)).subscribe(activeToolTabs => {
      console.log(activeToolTabs);

      if (activeToolTabs === undefined || Object.keys(activeToolTabs).length == 0) {
        let activeToolTabs = {
          "activetool": "myTask"
        }
        this.routeTabs = activeToolTabs;
      }
      else {

        console.log(activeToolTabs);
        this.routeTabs = activeToolTabs;
        console.log(this.routeTabs.activetool);
        console.log(this.routeTabs);

      }
    }
    );
    this.actionNameOnBtnClick = this.route.snapshot.queryParamMap.get('actionName');
    this.selectTool(this.routeTabs.activetool);
    this.project = environment.project
    // this.getFreshMyTaskDataAndSetPaginationToGrid();
    // this.store.dispatch(myVirtualTaskActions.fetchMyVirtualTasks({ api: virtualAPIs.virtualTaskEndPoint, Payload: null }));
    this.assignedColumns = [
      { name: 'Packet ID', prop: 'packetId' },
      { name: 'Cts', prop: 'cts' },
      { name: 'IssueDate', prop: 'issueDate' },
      // { name: 'Pkt Grade', prop: 'pktGrade' },
      { name: 'Lot No', prop: 'lotNo',columnTemplate:'lot-svg-template' },
      // { name: 'Status', prop: 'pktStatus' },
      // { name: 'PredLimit', prop: 'predLimit' },
      { name: 'Process', prop: 'virtualActionName' },
      { name: 'Process Status', prop: 'status' },
      { name: 'To User', prop: 'toUserName' },
      { name: 'Role', prop: 'toRole' },
      { name: 'InwardDate', prop: 'inDate' },
      { name: 'OutwardDate', prop: 'outDate' },
    ];
    this.pendingColumns = [
      { name: 'Packet ID', prop: 'packetId' },
      { name: 'Cts', prop: 'cts' },
      { name: 'IssueDate', prop: 'issueDate' },
      // { name: 'Pkt Grade', prop: 'pktGrade' },
      { name: 'Lot No', prop: 'lotNo',columnTemplate:'lot-svg-template' },
      // { name: 'Status', prop: 'pktStatus' },
      // { name: 'PredLimit', prop: 'predLimit' },
      { name: 'FromUser', prop: 'fromUser' },
      { name: 'Process', prop: 'vitualActionName' },
      { name: 'InwardDate', prop: 'inDate' },
      { name: 'OutwardDate', prop: 'outDate' },
    ];
    this.completedColumns = [
      { name: 'Packet ID', prop: 'packetId' },
      { name: 'Cts', prop: 'cts' },
      { name: 'IssueDate', prop: 'issueDate' },
      // { name: 'Pkt Grade', prop: 'pktGrade' },
      { name: 'Lot No', prop: 'lotNo',columnTemplate:'lot-svg-template' },
      // { name: 'Status', prop: 'pktStatus' },
      { name: 'PredLimit', prop: 'predLimit' },
      { name: 'FromUser', prop: 'fromUser' },
      { name: 'Process', prop: 'vitualActionName' },
      { name: 'InwardDate', prop: 'inDate' },
      { name: 'OutwardDate', prop: 'outDate' },
    ];
    // this.formJson = [
    //   {
    //     "key": "Vision 360",
    //     "type": "file",
    //     "defaultValue": null,
    //     "templateOptions": {
    //       "label": "Vision 360",
    //       "placeholder": "Attach File",
    //       "focus": false,
    //       "disabled": false
    //     },
    //     "id": "formly_2_input_Vision 360_1",
    //     "hooks": {},
    //     "modelOptions": {},
    //     "validation": {
    //       "messages": {}
    //     },
    //     "wrappers": [
    //       "form-field"
    //     ],
    //     "_keyPath": {
    //       "key": "Vision 360",
    //       "path": [
    //         "Vision 360"
    //       ]
    //     }
    //   }
    // ];
    this.store.pipe(select(actions)).pipe(takeUntil(this.unsuscribe$)).subscribe(actions => {
      if (actions) {
        this.listOfActionsWithForm = actions;
        console.log(actions);
      }
    })
    // this.getPendingCompletedTaskList();
    // this.store.dispatch(myVirtualTaskActions.fetchMyVirtualTasks());
    this.myTasks$ = this.store.pipe(select(getMyTasks)).pipe((map(res => {
      console.log(res);
      this.myTodoTasks = res?.pending;
      // this.openTension()
      return res;
    })));
    // this.myTasks$.pipe(takeUntil(this.unsuscribe$)).subscribe((task: any) => {
    //   if (task) {
    //     this.segment = task.assignedCount !== 0 ? 'assigned' : 'pending';
    //     console.log(this.segment);
    //   }
    // });
    this.subscriptions.push(
      this.store
        .pipe(select(getPacketParameterHistoryData))
        .subscribe((history) => {
          if (history) {
            this.packetHistories = [];
            for (let index = 0; index < history.length; index++) {
              const d = history[index];
              if (d.historyStatus == 'Completed') {
                this.packetHistories.push(d);
              }
            }
          }
        })
    );

    this.store
    .pipe(select(getFinalPlanData))
    .subscribe((data: any) => {
      if (data) {
        this.winnerPlan = data;
        console.log(this.winnerPlan);
      }
    });

  }
  segmentChanged(ev: any) {
    this.segment = ev.detail.value;

  }
  barcodeDetection(evt) {
    console.log('barcodeDetection/search');
    console.log(evt);
    let gridName = this.activeTool == 'myTask' ? this.segment : 'assignedByMe';
    console.log(this.activeTool);
    console.log(gridName);
    if (evt.length == 0) {
      this.showModal(false, 'Invalid barcode/Packet Id, no records found!', {});
      return;
    }
    let payload = {
      "packetId": evt
    }
    console.log(payload);
    let endpoint = virtualAPIs.virtualTaskEndPoint;
    endpoint = `${endpoint}?task=${gridName}`;
    this.store.dispatch(myVirtualTaskActions.fetchFilterMyVirtualTasks({ filterapi: endpoint, filterPayload: payload }));

  }
  clearBarcode(state) {
    this.scannerComponet.onResetForm();
    let gridName = this.activeTool == 'myTask' ? this.segment : this.activeTool;
    console.log("clearBarcode/clear Search box");
    console.log(state);
    console.log(gridName);
    this.store.dispatch(myVirtualTaskActions.fetchMyVirtualTasks({ api: virtualAPIs.virtualTaskEndPoint, Payload: null }));

  }
  onAssignedGridSelectionChanged(e: any) {
    this.selectedRows = e.selectedRowsData;
    this.selectedRow = e.row;
    if (this.selectedRows.length !== 0) {
      console.log(this.selectedRow);
      console.log(this.selectedRows);
      // this.actionStatus = 'Pending';
      // this.virtualActionId = this.selectedRow.virtualAction;
      // this.virtualId = this.selectedRow.virtualId;
      // this.action = this.listOfActionsWithForm.filter((act: any) => act.id === this.virtualActionId)[0];
      // this.actionName = this.action.actionName;
      // this.formjsonForDynamicForm();
      // this.showDrawer = true;
      // this.resetForm = true;
      // setTimeout(() => {
      //   this.showDrawer = false;
      //   this.resetForm = false;
      // }, 500);
      // this.changesStatus(this.virtualId);
    }
  }
  async onPendingGridSelectionChanged(e: any) {
    this.store.dispatch(
      saveCurrentRowData({ rowData: e.selectedRowsData })
    );
    console.log(this.action);
    this.selectedRows = e.selectedRowsData;
    this.selectedRow = e.row;
    if (this.selectedRows.length !== 0) {
      this.actionStatus = 'Pending';
      this.virtualActionId = this.selectedRow.virtualAction;
      this.virtualId = this.selectedRow.virtualId;
      this.action = this.listOfActionsWithForm.filter((act: any) => act.id === this.virtualActionId)[0];
      console.log(this.action);
      this.actionName = this.action?.actionName;
      if(this.actionName == 'Manual approval' && this.selectedRow){
        this.router.navigate(['/newchecker']); 

        // {
        //   queryParams: { isPage: 'bombay mail' },
        // }
      }
      if (this.actionName == 'Qc Check And Verify') {
        // this.donwloadCapFile()
      }
      if (this.actionName == 'Lock Master') {
         this.lockModal(this.selectedRow)
      }
      if (this.actionName == ACTIONNAMES.finalApprovalOnLock) {
        this.lockModal(this.selectedRow);
        return;
      }
      if (this.actionName === "Review By Checker" && this.selectedRow) {
        this.router.navigate(['/planning'], { queryParams: { isPage: "reviewByChecker" } });
      }
      if (this.actionName === "Admin approval" && this.selectedRow) {
        this.router.navigate(['/newchecker'], { queryParams: { isPage: "adminApproval", finalPlan: true } });
        // this.router.navigate(['/planning'], { queryParams: { isPage: "reviewByChecker" } });
      }
      if (this.actionName === "Review For Smart Recut" && this.selectedRow) {
        this.router.navigate(['/planning'], { queryParams: { isPage: "reviewForSmartRecut" } });
        // this.router.navigate(['/planning'], { queryParams: { isPage: "reviewByChecker" } });
      }
      if (this.actionName === 'Tension Mapping') {
        this.openTension();
      }
      else
        this.formjsonForDynamicForm();
      this.changesStatus(this.virtualId);
    }
  }
  async onCompletedGridSelectionChanged(e: any) {
    this.selectedRows = e.selectedRowsData;
    this.selectedRow = e.row;
    if (this.selectedRows.length !== 0) {
      this.store.dispatch(
        saveCurrentRowData({ rowData: e.selectedRowsData })
      );
      this.actionStatus = 'Completed';
      this.virtualActionId = this.selectedRow.virtualAction;
      this.virtualId = this.selectedRow.virtualId;
      this.action = this.listOfActionsWithForm.filter((act: any) => act.id === this.virtualActionId)[0];
      this.actionName = this.action?.actionName;
      console.log(this.actionName);
      if (this.actionName == 'Planning') {
        this.router.navigate(['/newchecker'], { queryParams: { packetId: this.selectedRow.id, status: 'Done', msg: 'ViewOnlyChecker' } });
      }
      let row = { ...this.selectedRow };
      this.currActionSavedDetails = await this.getData();
      row['inputDetails_virtual'] = this.currActionSavedDetails;
      this.selectedRow = row;
      console.log(this.currActionSavedDetails);
      // this.formjsonForDynamicForm();
      // this.changesStatus(this.virtualId);
    }
  }
  async getData() {
    return new Promise((resolve, reject) => {
      let endpoint = virtualAPIs.getProcessDetailEndpoint;
      endpoint = `${endpoint}?packetId=${this.selectedRow.id}&actionId=${this.virtualActionId}`;
      this.httpService
        .fetchData(true, endpoint)
        .subscribe((res) => {
          console.log(res)
          if (res.statusCode === 200) {
            resolve(res.data)
          } else {
            // this.showModal(false, message, {});
            reject([]);
          }
        });
    })
  }

  async lockModal(data) {
    console.log("hiiiiiiii");
    const Modal = await this.modalController.create({
      component:OpinionMasterComponent,
      cssClass: 'opinion-screen-modal',
     // component: TrybemodalComponent,
     // cssClass: 'lockModal',
      componentProps: {
        data: { status: 'lockModal', data: data, actionName: this.actionName}
      },
    });
    Modal.onDidDismiss().then((d: any) => {
      this.refreshGrid = true;
      setTimeout(() => {
        this.refreshGrid = false;
      }, 0);
    });
    return await Modal.present();
  }

   handleModalDismisss(d){
    console.log(d);

   }

  formSubmitted(formData: any) {
    let formType: string = 'JSON'
    let fromJsonInputsModified = { ...this.fromJsonInputs };
    fromJsonInputsModified.listOfParameter =
      fromJsonInputsModified.listOfParameter.map((x) => {
        const xData = { ...x };
        let paramName = xData.parameterName;
        if (formData[paramName]) {
          xData.parameterValue = formData[paramName];
        }
        return { ...xData };
      });
    fromJsonInputsModified.packetId = this.selectedRow.id;
    if (this.actionName === 'Qc Check And Verify' && typeof formData === "string") {
      this.saveQcCheckAndVerify(formData);
      return;
    }
    let parameters = {
      listOfAllPackets: [],
    };
    //   {
    //     "listOfAllPackets": [
    //         {
    //             "packetId": 2168,
    //             "listOfParameter": [
    //                 {
    //                     "parameterName": "Tension Mapping",
    //                     "parameterValue": 5583
    //                 }
    //             ]
    //         }
    //     ],
    //     "id" : 1
    // }
  
    if (formData['file']) {
      const formatData = new FormData();
      formType = 'Multipart';
      let file = formData['file']['0'];
      console.log(file);
      formatData.append('file', file);
      formatData.append('id', this.virtualId.toString());
      let newListOfParameters = fromJsonInputsModified.listOfParameter.filter((d: any) => d.parameterValue != '');
      fromJsonInputsModified.listOfParameter = newListOfParameters;
      parameters['listOfAllPackets'].push(fromJsonInputsModified);
      formatData.append('listOfAllPackets', JSON.stringify(parameters['listOfAllPackets']));
      this.saveMyVirtualTask(formatData, formType);
    } else {
      parameters['listOfAllPackets'].push(fromJsonInputsModified);
      parameters['id'] = this.virtualId;
      this.saveMyVirtualTask(parameters, formType);
    }
  }

 async saveQcCheckAndVerify(formData){
    let payload = {
      listOfAllPackets: [
        {
          packetId: this.selectedRow.id.toString(),
          listOfParameter:   {
            parameterName: 'Qc Check And Verify',
            parameterValue: formData,
          },
        },
      ],
      actionId: this.virtualActionId,
      id: this.selectedRow.virtualId
    };
    console.log(payload);
    let endpoint: string = `${virtualAPIs.saveVirtualTaskEndPoint}${this.virtualActionId}`;
      let response = await this.httpService.postDataUsingPromise(
        true,
        endpoint,
        payload
      );
      console.log(response);
      await this.httpService.getErrorAndDisplayIt(response);
      if (response.statusCode === 200) { 
         this.configureService.showToast('Success', 'Saved Successfully')
        this.store.dispatch(myVirtualTaskActions.fetchMyVirtualTasks({ api: virtualAPIs.virtualTaskEndPoint, Payload: null }));
        console.log(response.data);
        let data = response.message;
        console.log(data);
      } else {
        console.error(response);
      }
      return response;
    
  }
  removeProcessDetails(e: any) {
    console.log('removeProcessDetails');
    console.log(e);
  }

  saveMyVirtualTask(payload: any, formType: string) {
    console.log('saving my virtual task');
    
    let endpoint: string = `${virtualAPIs.saveVirtualTaskEndPoint}${this.virtualActionId}`;
    console.log(payload, formType, endpoint);
    this.httpService.saveDataToDB(true, endpoint, payload, formType).subscribe((res: any) => {
      if (res) {
        console.log(res);
        let resultCode: number = res.statusCode;
        let d: any = res.data;
        let message: string = res.message;
        if (resultCode === 200) {
          this.store.dispatch(myVirtualTaskActions.fetchMyVirtualTasks({ api: virtualAPIs.virtualTaskEndPoint, Payload: null }));
          
          console.log('My virtual task saved successfully');
          this.showModal(true, message, {});
        } else {
          
          console.log('%cFail to save my virtual task', "color:red;font-size:14px");
          this.showModal(false, message, {});
        }
      }
    })
  }

  async showCheckerFinalPlan(currHistory) {    
    console.log('showCheckerFinalPlan');
      if (!!this.selectedRow && !!this.selectedRow.id) {
        this.store.dispatch(
          HomeActions.getPacketPlanStoneIfIsFinal({
            packetId: this.selectedRow.id,
          })
        );
  
        this.store.pipe(select(getFinalPlanData)).pipe(takeUntil(this.unsubscribe$)).subscribe(async (data: any) => {
          console.log(data)
          if (data?.length && this.showCheckerFinal) {
            this.finalPacketPlans = data;
            
            this.showCheckerFinal = false;
    
            const popover = await this.popoverController.create({
              component: PlanningGridComponent,
              cssClass: 'planningRowOrderspopoup p-none',
              componentProps: {
                packetPlans: this.finalPacketPlans,
                allowAction: false,
                showClosePopup: true,
                getifDraweropen: "getifDraweropen",
                selectedPacket: this.selectedRow,
              },
            });
    
            await popover.present();
    
            popover.onDidDismiss()
              .then((res) => {
                this.showCheckerFinal = true;
                if (res.data) {
                  //after model close....
                }
              });
          }
        });
  
        // this.router.navigate(['/trybechecker'], {
        //   queryParams: {
        //     packetId: this.selectedRow.id,
        //     status: 'Done',
        //     msg: 'ViewOnlyChecker',
        //   },
        // });
      }
    }

  changesStatus(id: number) {
    // 
    let endpoint: string = `${virtualAPIs.statusVirtualTaskEndPoint}${id}`;
    this.httpService.fetchData(true, endpoint).subscribe((res: any) => {
      if (res) {
        console.log('change Status');
        console.log(res);
        let resultCode: number = res.statusCode;
        let d: any = res.data;
        let message: string = res.message;
        console.log(d);
        if (resultCode === 200) {
          // 
          console.log("%c" + message, "color:green;font-size:1rem;");
        } else {
          // 
          console.log("%c" + message, "color:red;font-size:1rem;");
          // this.showModal(false, message, {});
        }
      }
    });
  }

  async formjsonForDynamicForm() {
    console.warn('formjsonForDynamicForm----');
    if (this.action != null) {
      let formData = this.action.hasOwnProperty('listOfForms') && this.action.listOfForms.length !== 0 ? this.action.listOfForms[0].formData : null;
      this.actionInfo = {};
      this.actionInfo = {
        id: this.action.id,
        name: this.action?.actionName,
        status: this.actionStatus,
        actionForm: formData,
      }
      console.log(this.actionInfo);
      // if (this.action.actionName == 'Tension Mapping') {
      //   this.openTension();
      // }
      if (this.action?.actionName === 'Planning') {
        let isLaserMarking = await this.isLaserMarkingDone(this.selectedRow?.id);

        // this.httpService.getData(true, `packet/getOrderOnBasisOfPacketId/${this.selectedRow.id}`, {}).subscribe(async (res: any) => {
        //   if (!!res.data && res.data.length > 0) {
        //     const roughCriteriaData = res.data.map((dt: any) => {
        //       if (!dt?.uiJson)
        //         return {
        //           orderNo: dt?.orderNo,
        //           orderDate: dt?.orderDate,
        //         };
        //       const uiJson = JSON.parse(dt?.uiJson);
        //       return {
        //         ...uiJson,
        //         Shape: uiJson?.dropdownJson?.Shape?.datas?.map((sdt: any) => sdt.name).join(', '),
        //         Color: uiJson?.dropdownJson?.Color?.datas?.map((sdt: any) => sdt.name).join(', '),
        //         Clarity: uiJson?.dropdownJson?.Clarity?.datas?.map((sdt: any) => sdt.name).join(', '),
        //         Propagation: uiJson?.dropdownJson?.Propagation?.datas?.map((sdt: any) => sdt.name).join(', '),
        //         CUT: uiJson?.dropdownJson?.CUT?.datas?.map((sdt: any) => sdt.name).join(', '),
        //         Polish: uiJson?.dropdownJson?.Polish?.datas?.map((sdt: any) => sdt.name).join(', '),
        //         Symmetry: uiJson?.dropdownJson?.Symmetry?.datas?.map((sdt: any) => sdt.name).join(', '),
        //         Fluorescence: uiJson?.dropdownJson?.Fluorescence?.datas?.map((sdt: any) => sdt.name).join(', '),
        //         Tinch: uiJson?.dropdownJson?.Tinch?.datas?.map((sdt: any) => sdt.name).join(', '),
        //         Natts: uiJson?.dropdownJson?.Natts?.datas?.map((sdt: any) => sdt.name).join(', '),
        //         Milkyness: uiJson?.dropdownJson?.Milkyness?.datas?.map((sdt: any) => sdt.name).join(', '),
        //         Topsopens: uiJson?.dropdownJson?.Topsopens?.datas?.map((sdt: any) => sdt.name).join(', '),
        //         Botsopen: uiJson?.dropdownJson?.Botsopen?.datas?.map((sdt: any) => sdt.name).join(', '),
        //         perpieceRate: uiJson?.perpieceRate?.from,
        //         sizeRange: (uiJson?.sizeRange?.from || '') + '-' + (uiJson?.sizeRange?.to || ''),
        //         diameterRatio: (uiJson?.diameter?.from || uiJson?.diameter?.to) ? ((uiJson?.diameter?.from || '') + '-' + (uiJson?.diameter?.to || '')) : ((uiJson?.ratio?.from || '') + '-' + (uiJson?.ratio?.to || '')),
        //         table: (uiJson?.table?.from || '') + '-' + (uiJson?.table?.to || ''),
        //         cheight: (uiJson?.cheight?.from || '') + '-' + (uiJson?.cheight?.to || ''),
        //         girdle: (uiJson?.girdle?.from || '') + '-' + (uiJson?.girdle?.to || ''),
        //         cangle: (uiJson?.cangle?.from || '') + '-' + (uiJson?.cangle?.to || ''),
        //         culet: (uiJson?.culet?.from || '') + '-' + (uiJson?.culet?.to || ''),
        //         depth: (uiJson?.depth?.from || '') + '-' + (uiJson?.depth?.to || ''),
        //         pdepth: (uiJson?.pdepth?.from || '') + '-' + (uiJson?.pdepth?.to || ''),
        //         pangle: (uiJson?.pangle?.from || '') + '-' + (uiJson?.pangle?.to || ''),
        //         remark: uiJson?.remark,
        //         orderNo: dt?.orderNo,
        //         orderDate: dt?.orderDate,
        //       }
        //     });

        //     if (roughCriteriaData?.length) {
        //       const popover = await this.popoverController.create({
        //         component: PopoverUserComponent,
        //         cssClass: 'planningRowOrderspopoup',
        //         componentProps: {
        //           forPage: 'roughCriteria',
        //           roughCriteriaData
        //         },
        //       });
        //       await popover.present();
        //       popover.onDidDismiss().then((res) => {
        //         if (isLaserMarking === true && this.isPreviouslyPlanUploaded == false) {
        //           let info = {
        //             rowData: this.selectedRow,
        //             status: 'Initial',
        //             parameterList: this.paramSelectionList,
        //             className: 'ecsvModal',
        //             history: this.packetHistories,
        //           };

        //           this.showDynamicFormAsPerJson(this.actionInfo);
        //           this.showModal(true, 'e-csv Upload', info);
        //         }
        //         else if (isLaserMarking === true && this.isPreviouslyPlanUploaded == true) {
        //           this.isPreviousPlantrue();
        //         }
        //         else {
        //           console.log(isLaserMarking?.message);
        //           this.configureService.showToast("error", "This Packet Is Not Yet Issued To Laser Marking");
        //         }
        //       });
        //     } else {
        //       if (isLaserMarking === true && this.isPreviouslyPlanUploaded == false) {
        //         let info = {
        //           rowData: this.selectedRow,
        //           status: 'Initial',
        //           parameterList: this.paramSelectionList,
        //           className: 'ecsvModal',
        //           history: this.packetHistories,
        //         };

        //         this.showDynamicFormAsPerJson(this.actionInfo);
        //         this.showModal(true, 'e-csv Upload', info);
        //       }
        //       else if (isLaserMarking === true && this.isPreviouslyPlanUploaded == true) {
        //         this.isPreviousPlantrue();
        //       }
        //       else {
        //         this.configureService.showToast("error", "This Packet Is Not Yet Issued To Laser Marking");
        //       }
        //     }
        //   } else {
        //     let info = {
        //       rowData: this.selectedRow,
        //       status: 'Initial',
        //       parameterList: this.paramSelectionList,
        //       className: 'ecsvModal',
        //       history: this.packetHistories,
        //     };

        //     // this.showDynamicFormAsPerJson(this.actionInfo);
        //     this.showModal(true, 'e-csv Upload', info);
        //   }

        if (isLaserMarking === true && this.isPreviouslyPlanUploaded == false) {
          let info = {
            rowData: this.selectedRow,
            status: 'Initial',
            parameterList: this.paramSelectionList,
            className: 'ecsvModal',
            history: this.packetHistories,
          };

          this.showDynamicFormAsPerJson(this.actionInfo);
          this.showModal(true, 'e-csv Upload', info);
        }
        else if (isLaserMarking === true && this.isPreviouslyPlanUploaded == true) {
          this.isPreviousPlantrue();
        }
        else {
          this.configureService.showToast("error", "This Packet Is Not Yet Issued To Laser Marking");
        }
        // })
      }
      else if (this.action?.actionName === 'Final Planning' || this.action?.actionName == 'Smart Recut Planning' || this.action?.actionName == this.ACTIONNAMES.anyCut) {
        if (this.isPreviouslyPlanUploaded == false) {
          let info = {
            rowData: this.selectedRow,
            status: 'Initial',
            parameterList: this.paramSelectionList,
            className: 'ecsvModal',
            history: this.packetHistories,
          };
          if (this.action?.actionName === 'Final Planning') {
            this.showDynamicFormAsPerJson(this.actionInfo);
          }
          this.showModal(true, 'e-csv Upload', info);
        }
        else if (this.isPreviouslyPlanUploaded == true) {
          this.isPreviousPlantrue();
        }
        // this.store.dispatch(
        //   saveCurrentRowData({ rowData: packet[0] })
        // );
        // this.router.navigate(['/newchecker']);
      }

      else if (this.action?.actionName === 'Planning Review by Head Checker') {
        await this.processService.setActionName(
          this.action?.actionName,
          this.action.id,
          !!this.action.voucherType ? this.action.voucherType : '',
          this.action.listOfActionPermission,
          ''
        );
        //this.processService.actionSelected$.emit(true)
        this.router.navigate(['actionsWindow']);
        //this.router.navigate(['/']);

      } else if (this.action?.actionName === ACTIONNAMES.verifyRepairingAsking) {
        this.newLockModal(this.selectedRow);
      }
      else {
        this.showDynamicFormAsPerJson(this.actionInfo);
      }
    }
  }


  async newLockModal(data: any, viewOnly?: boolean) {
    const Modal = await this.modalController.create({
      component: OpinionScreenComponent,
      cssClass: "newopinion-screen-modal",
      componentProps: {
        data: { status: "lockModal", data: data, actionName: this.actionName, viewOnly, isheader: false },
      },
    });
    Modal.onDidDismiss().then((d: any) => {
      this.refreshGrid = true;
      this.store.dispatch(myVirtualTaskActions.fetchMyVirtualTasks({ api: virtualAPIs.virtualTaskEndPoint, Payload: null }));
      setTimeout(() => {
        this.refreshGrid = false;
      }, 0);
    });
    return await Modal.present();
  }

  showDynamicFormAsPerJson(data: any) {
    if (data) {
      console.log('showDynamicFormAsPerJson');
      console.log(data);
      if (data.actionForm) {
        let formDataObject = JSON.parse(data.actionForm);
        let parsedFormData: any = formDataObject.form;
        //this.formJson = parsedFormData
        this.fromJsonInputs = formDataObject.inputJson;
        let fdata = parsedFormData.map((field: any) => {
          if (field.type == 'select' || field.type == "searchSelect") {
            let paramName = field.key;
            if(this.project != "kps") {
              if (paramName == 'Tension Mapping')
                paramName = 'Stress Value';
              if (paramName == 'Stress Type')
                paramName = 'Stress';
            }
            console.log(paramName);
            this.store
              .pipe(select(getParamsValuesListByName(paramName.toUpperCase())))
              .subscribe((data) => {
                console.log(data);
                if (data) {
                  console.log('getParamsValuesListByName');
                  console.log(data);
                  if(this.project != "kps") {
                    if (paramName == 'Stress Value')
                      paramName = 'Tension Mapping';
                    if (paramName == 'Stress')
                      paramName = 'Stress Type';
                  }

                  this.paramSelectionList[paramName] = data;
                  let newData = data.map((x) => {
                    let newobj = {
                      label: x.name,
                      value: x.id,
                    };
                    return newobj;
                  });
                  field.templateOptions.options = newData;
                }
              })
          }
          return field;
        });
        console.log(fdata);
        this.formJson = fdata;
      }
    }
    if (data && this.actionStatus == 'Completed') {
      console.log(this.selectedRow.virtualInputDetails);
      console.log(this.formJson);
      console.log('virtualInputDetails');
      console.log(this.currActionSavedDetails);
      console.log(this.currActionSavedDetails[0]);
      // let modifiedFormJson = this.formJson.filter(d => d.key != "file");// return form array without attachment file
      if (!!this.formJson) {
        this.formJson.forEach((d: any) => {
          if (d.hideExpression == false) {
            d.hideExpression = true;
          }
        }); // return form array without attachment file
      }
      this.formJson.map((f) => {
        let paramName = f.key;
        console.log(f);
        // if (paramName == 'Tension') {//remove static Code Raju
        //   paramName = 'Tension Mapping';
        // }
        console.log(this.selectedRow.inputDetails_virtual[0].details);
        let inputVal = this.selectedRow.inputDetails_virtual[0].details.find((i) => {
          console.log(i.paramName);
          if (i.paramName == paramName) {
            return i;
          }
        });
        console.log(inputVal);
        if (inputVal) {
          inputVal =
            inputVal.paramIValue ||
            inputVal.paramBValue ||
            inputVal.paramDValue ||
            inputVal.paramSValue;
          //for selection input
          if (f.type == 'select' || f.type == "searchSelect") {
            let selectedData = this.paramSelectionList[paramName].filter(
              (x) => x.id == inputVal
            )[0];
            f.defaultValue = selectedData.id;
          } else {
            f.defaultValue = inputVal;
          }
        }
        return f;
      });

      this.refreshField = true;
      setTimeout(() => {
        this.refreshField = false;
      }, 500);
      console.log(this.formJson);
    } else {
      this.resetForm = true;
      // this.reloadForm = true;
      setTimeout(() => {
        this.resetForm = false;
        // this.reloadForm = false;
      }, 500);
    }
    // this.resetForm = true;
    // this.reloadForm = true;
    // setTimeout(() => {
    //   this.reloadForm = false;
    // this.resetForm = false;
    // }, 500);

    if (data.name === 'Planning' || data.name === 'Final Planning'|| data.name === 'Lock Master') {
      this.showDrawer = false
    }
    else {
      this.showDrawer = true;
      setTimeout(() => {
        this.showDrawer = false;
      }, 500);
    }
  }

  closeDynamicForm() {
    this.refreshGrid = true;
    setTimeout(() => {
      this.refreshGrid = false;
    }, 1000);
  }

  async remove(event) {
    console.log("deelteting the data");
    let data = event.row.data
    let virtualId = data.virtualId;
    console.log(virtualId);
    if (!!virtualId) {
      console.log("calling apissssssss");

      this.store.dispatch(myVirtualTaskActions.deleteAssignedTask({ virtualId: virtualId }));

    }
  }

  async openTension() {
    console.log(this.selectedRow);
    console.log(this.selectedRows);
    const tensionForm = await this.modalController.create({
      component: TensionmappingComponent,
      cssClass: 'tensionPage',
      componentProps: {
        data: this.myTodoTasks,
        forPage: 'virtualTensionPlanning'
      },
    });

    tensionForm.onDidDismiss().then((d: any) => {
      this.store.dispatch(myVirtualTaskActions.fetchMyVirtualTasks({ api: virtualAPIs.virtualTaskEndPoint, Payload: null }));


    });
    return await tensionForm.present();
  }


  // showActForm Func END
  async showModal(state: boolean, message: string, data: any, isAppend?: boolean) {
    // this.refreshGrid = false;
    let append = isAppend;
    if (state) {
      if (Object.keys(data).length !== 0 && !!data.className) {
        const modal = await this.modalController.create({
          component: TrybemodalComponent,
          componentProps: {
            message: message,
            data: data,
            isFormDisabled: true,
          },
          cssClass: data.className,
        });
        modal.onDidDismiss().then((d) => this.handleModalDismiss(d, append));
        return await modal.present();
      } else {
        if (Object.keys(data).length !== 0 && !!data.actionId) {
          const modal = await this.modalController.create({
            component: TrybemodalComponent,
            componentProps: {
              message: message,
              data: data,
              isFormDisabled: true,
            },
            cssClass: 'trybe_modal_1',
          });
          modal.onDidDismiss().then((d: any) => this.onModalDismiss(d));
        } else {
          const modal = await this.modalController.create({
            component: TrybemodalComponent,
            componentProps: {
              message: message,
              isFormDisabled: true,
            },
            cssClass: 'trybe_modal_1',
          });
          modal.onDidDismiss().then((d: any) => this.onModalDismiss(d));
          return await modal.present();
        }
      }
    } else {
      const modal = await this.modalController.create({
        component: TrybemodalComponent,
        componentProps: {
          message: message,
        },
        cssClass: 'trybe_modal_0',
      });
      return await modal.present();
    }
  }
  onModalDismiss(d: any) {
    console.log('object');
    console.log(d);
  }

  handleModalDismiss(data: any, isAppend?: boolean) {
    console.log('handleModalDismiss');
    console.log(data);
    let append = isAppend;

    if (!!data && !!data.data && data.data.status == 'ecsv file submitted') {
      console.log('Form Submitted');
      console.log(data.data);
      // this.formSubmitted(data.data);
      this.ecsvUploadWithOtherDetails(data.data, append);
    } else {
      this.refreshGrid = true;
      setTimeout(() => {
        this.refreshGrid = false;
      }, 1000);
    }
  }

  async ecsvUploadWithOtherDetails(formData, isAppend?: boolean) {
    let append = isAppend;

    let formType = 'JSON';
    console.log(formData);
    console.log(this.fromJsonInputs);
    let fromJsonInputsModified = { ...this.fromJsonInputs };
    fromJsonInputsModified.listOfParameter =
      (fromJsonInputsModified?.listOfParameter || [])?.map((x) => {
        const xData = { ...x };
        let paramName = xData.parameterName;
        if (formData[paramName]) {
          xData.parameterValue = formData[paramName];
        }
        let packet1 = xData.packet1;
        let packet2 = xData.packet2;
        if (!!packet1 && formData['packet1']) {
          let f1 = formData['packet1'];
          packet1.forEach((p1: any) => {
            let paramName = p1.parameterName;
            if (f1[paramName]) {
              p1.parameterValue = f1[paramName];
            }
          });
        }
        if (!!packet2 && formData['packet2']) {
          let f2 = formData['packet2'];
          packet2.forEach((p2: any) => {
            let paramName = p2.parameterName;
            if (f2[paramName]) {
              p2.parameterValue = f2[paramName];
            }
          });
        }
        return { ...xData };
      });
    fromJsonInputsModified.packetId = this.selectedRow.id;
    let barcode = this.selectedRow.packetId;

    let obj = {
      listOfAllPackets: [],
    };
    let barcodeObj = {
      packetId: barcode,
    };
    const formatData = new FormData();
    if (!!this.scannerComponet) {
      this.scannerComponet.onResetForm();
    }
    // return;
    if (formData['file']) {
      console.log(formData['file']);
      formType = 'Multipart';
      let file = formData['file']['0'];
      formatData.append('file', file);
      let newListOfParameters = fromJsonInputsModified.listOfParameter.filter(
        (d) => d.parameterValue != ''
      );
      fromJsonInputsModified.listOfParameter = newListOfParameters;
      obj['listOfAllPackets'].push(fromJsonInputsModified);
      // formatData.append("listOfAllPackets", JSON.stringify(listOfAllPackets));
      formatData.append(
        'listOfAllPackets',
        JSON.stringify(obj['listOfAllPackets'])
      );
      await this.store.dispatch(
        masterDataActions.ecsvUpload({
          parameterDetails: formatData,
          packetID: this.selectedRow.id,
          actionId: this.action.id,
          formType: formType,
          barcodeObj: barcodeObj,
          actionInfo: this.actionInfo,
          isAppend: append || false
        })
      );
    } else {
      if (this.action.actionName === 'Final Planning') {
        this.store.dispatch(
          masterDataActions.ecsvUpload({
            parameterDetails: formatData,
            packetID: this.selectedRow.id,
            actionId: this.action.id,
            formType: formType,
            barcodeObj: barcodeObj,
            actionInfo: this.actionInfo,
            isAppend: append || false,
          })
        );

      }
      obj['listOfAllPackets'].push(fromJsonInputsModified);
      console.log(obj);
      // return;
      // this.store.dispatch(masterDataActions.saveStoneParamater({ parameterDetails: obj, actionId: this.actionId, formType: formType, barcodeObj: barcodeObj }));
    }

    setTimeout(() => {
      this.resetForm = false;

      console.log('%cECSV', 'color:orange;font-size:2rem;');
    }, 500);
  }
  selectTool(toolName) {
    this.activeTool = toolName;
  }
  async getFreshMyTaskDataAndSetPaginationToGrid() {
    console.log('setPaginationToDxGrid- Grid Data');
    //tranfer Grid, ReturnGrid
    let payload = null;
    // if (!!this.barcodeInputs) {
    //   payload = { "packetId": this.barcodeInputs.trim() };  //toFiltter grid Data by barcodeInputs
    // }
    this.modifiedGridObject = new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        console.log("loadOptions ", loadOptions);
        if (!!loadOptions.take) {
          if ([10, 20, 50, 100, 500].indexOf(loadOptions.take) == -1) {
            loadOptions.take = this.pageInfo['limit'];
          } else {
            this.pageInfo['limit'] = loadOptions.take;
          }
          this.pageNumber = loadOptions.skip / loadOptions.take;
          this.pageInfo['page'] = this.pageNumber;
          console.log(this.pageInfo);
          // let response: any = await this.httpService.fetchWithMeGridData(this.pageInfo, payload);
          // console.error('------------ASIGN');
          // console.log(response, this.gridData, this.gridDataCount);
          // if (response.statusCode == 200) {
          //   this.httpService.updateTranferReturnAssignGridData(response.data);
          // } else {
          //   console.error(response);
          // }
          return {
            data: Query(this.gridData)
              .toArray(),
            totalCount: this.gridDataCount,
            groupCount: this.gridDataCount
          };
        } else {
          return {
            data: this.gridData,
            totalCount: this.gridDataCount,
            groupCount: this.gridDataCount
          }
        }
      }
    });
  }
  onPaginationChange(evt) {
    let nagName = evt.fullName;
    switch (nagName) {
      case 'paging.pageSize':
        let size = evt.value;
        this.pageInfo['limit'] = size;
        this.getFreshMyTaskDataAndSetPaginationToGrid();
        break;
      case 'paging.pageIndex':
        let pageIndex = evt.value;
        this.pageInfo['page'] = pageIndex;
        this.getFreshMyTaskDataAndSetPaginationToGrid();
        break;
    }
  }

  async isPreviousPlantrue() {
    const Modal = await this.modalController.create({
      component: TrybemodalComponent,
      cssClass: 'lockModal',
      componentProps: {
        data: { status: 'isPreviousPlantrue', }
      },
    });
    Modal.onDidDismiss().then((d: any) => this.handleModalDismissForPreviousPlan(d));
    return await Modal.present();
  }

  async handleModalDismissForPreviousPlan(data) {
    if (!!data) {
      this.showDynamicFormAsPerJson(this.actionInfo);
      this.selectedEcsvOption = data.data;
      console.log(this.selectedEcsvOption);
      if (this.selectedEcsvOption === 'newFile') {
        let info = {
          rowData: this.selectedRow,
          status: 'Initial',
          parameterList: this.paramSelectionList,
          className: 'ecsvModal',
          history: this.packetHistories,
        };
        this.store.dispatch(SignerAction.setSelectedEcsvOption({ value: this.selectedEcsvOption }));
        this.showModal(true, 'e-csv Upload', info);
      }
      else if (this.selectedEcsvOption === 'oldData') {
        if (this.selectedRow?.packetId)
          this.store.dispatch(
            SignerAction.getPacketPlans({
              packetIdOb: {
                "packetId": this.selectedRow?.packetId
              }
            })
          );
        this.store.dispatch(SignerAction.setSelectedEcsvOption({ value: this.selectedEcsvOption }));
        await this.router.navigate(['/planning']);

      }
      else if (this.selectedEcsvOption === 'appendData') {
        let info = {
          rowData: this.selectedRow,
          status: 'Initial',
          parameterList: this.paramSelectionList,
          className: 'ecsvModal',
          history: this.packetHistories,
        };
        this.store.dispatch(SignerAction.setSelectedEcsvOption({ value: this.selectedEcsvOption }));
        this.showModal(true, 'e-csv Upload', info, true);
      }
    }
  }

  async isLaserMarkingDone(id) {

    
    let res: any;
    res = await this.httpService.findIsLaserMarkingDone(id);
    
    if (!!res && res.statusCode == 200) {
      this.isPreviouslyPlanUploaded = res?.data?.isPlanUploaded
      console.log(res);
      return res?.data?.isLaserMarkingDone;
    }
    let data = res;
    if (res.status == 401) {
    } else if (res.status == 500) {
      await this.configureService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configureService.showToast("error", data.error);
    }

  }

  async donwloadCapFile() {
    let payload = {
      packetIds: [this.selectedRow?.id]
    };
    console.log(payload);
    let response = await this.httpService.postDataUsingPromise(
      true,
      'processActionInput/getFileThroughCentralSystemForDbcCap',
      payload
    );
    console.log(response);
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      this.capFileUrl = response.data[0];
      console.log(this.capFileUrl);
      let file = this.configureService.getCentralFileUrl() + `${this.capFileUrl}`
      //  let file = "https://kps-pd.s3.ap-south-1.amazonaws.com//javascript_tutorial.pdf"
      if (this.capFileUrl) {
        console.log(file);
        window.open(file, "Download")
      }
    } else {
      console.error(response);
      this.configureService.showToast("error", "Cap File Downloaded failed")

    }
  }

  downloadFile(event) {
    this.openDownloadModal(event.row.data)
  }

  async infoFunction() {
    this.httpService.getData(true, `packet/getOrderOnBasisOfPacketId/${this.selectedRow.id}`, {}).subscribe(async (res: any) => {
      const roughCriteriaData = res.data.map((dt: any) => {
        if (!dt?.uiJson)
          return {
            orderNo: dt?.orderNo,
            orderDate: dt?.orderDate,
          };
        const uiJson = JSON.parse(dt?.uiJson);
        return {
          ...uiJson,
          Shape: uiJson?.dropdownJson?.Shape?.datas?.map((sdt: any) => sdt.name).join(', '),
          Color: uiJson?.dropdownJson?.Color?.datas?.map((sdt: any) => sdt.name).join(', '),
          Clarity: uiJson?.dropdownJson?.Clarity?.datas?.map((sdt: any) => sdt.name).join(', '),
          Propagation: uiJson?.dropdownJson?.Propagation?.datas?.map((sdt: any) => sdt.name).join(', '),
          CUT: uiJson?.dropdownJson?.CUT?.datas?.map((sdt: any) => sdt.name).join(', '),
          Polish: uiJson?.dropdownJson?.Polish?.datas?.map((sdt: any) => sdt.name).join(', '),
          Symmetry: uiJson?.dropdownJson?.Symmetry?.datas?.map((sdt: any) => sdt.name).join(', '),
          Fluorescence: uiJson?.dropdownJson?.Fluorescence?.datas?.map((sdt: any) => sdt.name).join(', '),
          Tinch: uiJson?.dropdownJson?.Tinch?.datas?.map((sdt: any) => sdt.name).join(', '),
          Natts: uiJson?.dropdownJson?.Natts?.datas?.map((sdt: any) => sdt.name).join(', '),
          Milkyness: uiJson?.dropdownJson?.Milkyness?.datas?.map((sdt: any) => sdt.name).join(', '),
          Topsopens: uiJson?.dropdownJson?.Topsopens?.datas?.map((sdt: any) => sdt.name).join(', '),
          Botsopen: uiJson?.dropdownJson?.Botsopen?.datas?.map((sdt: any) => sdt.name).join(', '),
          perpieceRate: uiJson?.perpieceRate?.from,
          sizeRange: (uiJson?.sizeRange?.from || '') + '-' + (uiJson?.sizeRange?.to || ''),
          diameterRatio: (uiJson?.diameter?.from || uiJson?.diameter?.to) ? ((uiJson?.diameter?.from || '') + '-' + (uiJson?.diameter?.to || '')) : ((uiJson?.ratio?.from || '') + '-' + (uiJson?.ratio?.to || '')),
          table: (uiJson?.table?.from || '') + '-' + (uiJson?.table?.to || ''),
          cheight: (uiJson?.cheight?.from || '') + '-' + (uiJson?.cheight?.to || ''),
          girdle: (uiJson?.girdle?.from || '') + '-' + (uiJson?.girdle?.to || ''),
          cangle: (uiJson?.cangle?.from || '') + '-' + (uiJson?.cangle?.to || ''),
          culet: (uiJson?.culet?.from || '') + '-' + (uiJson?.culet?.to || ''),
          depth: (uiJson?.depth?.from || '') + '-' + (uiJson?.depth?.to || ''),
          pdepth: (uiJson?.pdepth?.from || '') + '-' + (uiJson?.pdepth?.to || ''),
          pangle: (uiJson?.pangle?.from || '') + '-' + (uiJson?.pangle?.to || ''),
          remark: uiJson?.remark,
          orderNo: dt?.orderNo,
          orderDate: dt?.orderDate,
        }
      });
      if (roughCriteriaData?.length) {
        const popover = await this.popoverController.create({
          component: PopoverUserComponent,
          cssClass: 'planningRowOrderspopoup',
          componentProps: {
            forPage: 'roughCriteria',
            roughCriteriaData
          },
        });
        await popover.present();
      }
    });
  }

  async openDownloadModal(data) {
    const Modal = await this.modalController.create({
      component: OldFileDownloadComponent,
      cssClass: 'DownloadFileModal',
      componentProps: {
        data: { data: 'fileDownload', packetData: data, virtualAction: data?.virtualAction || '' }
      },
    });
    Modal.onDidDismiss().then((d: any) => this.handleModalDismissOfDownload(d));
    return await Modal.present();
  }

  async handleModalDismissOfDownload(d) {
    if (d.data) {
      console.log(d);
    }
    console.log("closing modal");
  }
  displayHistory(e) {
    this.isFormRequired = false;
    this.currentSegment = 'paramsHistory';
    this.isHistory = true;
    console.log('-------onHistortyClick-------');
    console.log(e);
    let currentPacketId = e.row.data.id;
    this.selectedRow = e.row.data;
    console.log(currentPacketId);
    console.log(e.row.data.packetId);
    this.store.dispatch(saveCurrentRowData({ rowData: [this.selectedRow] }));
    if (!!currentPacketId) {
      console.log('History found');
      this.showDrawer = true;
      setTimeout(() => {
        this.showDrawer = false;
      }, 500);
    }
  }

  ngOnDestroy() {
    console.log('MyVirtualTaskComponent is destroyed');
    this.unsuscribe$.next();
    this.unsuscribe$.complete();
  }
}
