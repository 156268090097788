import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AppState } from 'src/app/reducers';
import { select, Store } from '@ngrx/store';
import { getParamsValuesListByName } from 'src/app/masterDataStore/masterData.selector';
import { HttpService } from 'src/app/services/http/http.service';
import * as moment from 'moment';
import { authUser } from 'src/app/authStore/auth.selector';
import {
  getCurrentAction,
  getCurrentRowData,
} from 'src/app/tempDataStore/tempData.selector';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { HomeActions } from 'src/app/home/state/home.action-type';
import { ConfigService } from 'src/app/services/config/config.service';
import { ParameterTypes } from 'src/app/services/endpoint.enum';

@Component({
  selector: 'app-param-history-grid',
  templateUrl: './param-history-grid.component.html',
  styleUrls: ['./param-history-grid.component.scss'],
})
export class ParamHistoryGridComponent implements OnInit {
  planData = {
    id: 62080,
    packetId: 14623,
    signerId: 135,
    seqNo: 'Saw1-1',
    remarks: null,
    bidAmt: 0,
    packetPlanStones: [
      {
        id: 68114,
        packetPlanId: 62080,
        tag: 2,
        expPol: '0.486',
        shapeIds: 655,
        clarityId: 63,
        cutId: 376,
        colorIds: 227,
        pavAngle: '41.250',
        pavHeight: '43.800',
        crAngle: '35.700',
        crHeight: '14.200',
        tableVal: '60.400',
        girdle: '4.500',
        diameter: '4.990',
        height: '62.600',
        lOrW: '1.000',
        rapoRate: '598.000',
        sawMic: '70mic',
        lengthMM: '4.990',
        heightMM: '3.120',
        widthMM: '4.990',
        createdDate: '2023-09-19T06:04:00.000Z',
        createdBy: '2-surat-dev-signer1',
        modifiedDate: null,
        modifiedBy: null,
        isDeleted: 0,
        planShape: 'ROUND',
        planColor: 'H',
        planCut: 'EXCL',
        planClarity: 'SI1',
        planSymmetry: null,
        symmetryId: 0,
        xpsFile: null,
        isDone: null,
        remark: null,
        newCut: '',
        pd: null,
        pdSaved: false,
        kgRate: 0,
        imageFile: null,
        labId: null,
        labName: null,
        isMakeAble: null,
        LD: '0.13',
        orderId: null,
        planFluorescence: 'NONE',
        fluorescenceId: 497,
        sawRghWgt: '0.972',
        shape: 'ROUND',
        clarity: 'SI1',
        color: 'H',
        cut: 'EXCL',
        symmetry: null,
        progName: null,
        selectedProgramId: null,
        fluorescence: 'FAINT',
        fluorescenceIds: 498,
        piece: 'A',
        selected: false,
        isFileSelected: false,
        doubtfulValue: 0,
        isShownDoubtfulValue: false,
      },
    ],
    selected: false,
    isExpand: true,
    totExpYield: 0.49,
    totRghYield: 0.97,
    Yield: 0,
    expPolPer1: 16.83,
    expPolPer2: 16.83,
    plannedRemark: '',
    hasSavedPlan: false,
    totalKGRate: 0,
    bestRate: 0,
    totalRate: 0,
    tag: 2,
    selectedRemark: [],
  };
  alpha: any = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  shapeOptions = [];
  shapeIds = [];
  clarityOptions = [];
  fluorescenceOptions = [];
  clarityIds = [];
  private unsubscribe$: Subject<any> = new Subject<any>();
  currentTime = moment().format('hh:mm A');
  public today = moment(new Date()).format('DD MMM, YYYY');
  public timestamp = moment().format('YYYY-MM-DD HH:mm:ss');
  colorOptions = [];
  colorIds = [];
  shouldDisableButton: boolean = false;
  cutOptions = [];
  @Input() data;
  @Input() hideTitleToggle: boolean = false;
  @Input() hideSubmit: boolean = false;
  cutIds = [];
  toggleNotOkayValue: boolean = false;
  Comments: any = null;
  fluIds = [];
  girdleOptions: any;
  symmentryOptions = [];
  transformedObject: any;
  arrayOfObjects: any;
  paramHistoryGridData: object;
  payload: {
    packetId: any;
    info: {
      message: any;
      username: any;
      time: any;
      media: any;
      date: any;
      timestamp: any;
      important: boolean;
    };
  };
  userName: any;
  rowData: any;
  actionID: any;
  actionStatus: any;
  polishOptions: any;
  finalPayload: any;
  currUserDept: any;

  constructor(
    private modalCtl: ModalController,
    private store: Store<AppState>,
    private httpService: HttpService,
    private configService: ConfigService,

  ) { }

  ngOnInit() {
    this.store.pipe(select(authUser)).subscribe((user) => {
      if (!!user) {
        this.currUserDept = user['name'];
      }
    });

    let transformedObject = {};
    this.paramHistoryGridData = {};
    for (const key in this.data.data) {
      transformedObject = {};
      this.data.data[key]?.map((dt: any) => {
        transformedObject[dt.paramName] = dt.paramValue;
      });
      if (!this.paramHistoryGridData?.[key])
        this.paramHistoryGridData[key] = [];
      this.paramHistoryGridData[key].push(transformedObject);
    }
    this.arrayOfObjects = [transformedObject];
    this.store.pipe(select(authUser)).subscribe((d: any) => {
      if (d) {

        this.userName = d;
      }
    });
    this.store
      .pipe(select(getParamsValuesListByName(ParameterTypes.Girdle)))
      .subscribe((girdleData) => {
        if (!!girdleData) {
          this.girdleOptions = girdleData;
        }
      })

    this.store
      .pipe(select(getCurrentAction))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data: any) => {
        if (data) {
          this.actionID = data.id;
          this.actionStatus = data.status;
        }
      });

    this.store
      .pipe(select(getParamsValuesListByName(ParameterTypes.Color)))
      .subscribe((colorData) => {
        if (!!colorData) {
          this.colorOptions = colorData;

          this.store
            .pipe(select(getParamsValuesListByName(ParameterTypes.Shape)))
            .subscribe((shapeData) => {
              if (!!shapeData) {
                this.shapeOptions = shapeData;
                this.store
                  .pipe(select(getParamsValuesListByName(ParameterTypes.CUT)))
                  .subscribe((cutData) => {
                    if (!!cutData) {
                      this.cutOptions = cutData;

                      this.store
                        .pipe(select(getParamsValuesListByName(ParameterTypes.Polish)))
                        .subscribe((polishData) => {
                          if (!!polishData) {
                            this.polishOptions = polishData;


                            this.store
                              .pipe(select(getParamsValuesListByName(ParameterTypes.Clarity)))
                              .subscribe((purityData) => {
                                if (!!purityData) {
                                  this.clarityOptions = purityData;


                                  this.store
                                    .pipe(select(getParamsValuesListByName(ParameterTypes.Symmetry)))
                                    .subscribe((symmetryData) => {
                                      if (!!symmetryData) {
                                        this.symmentryOptions = symmetryData;

                                        this.store
                                          .pipe(select(getParamsValuesListByName(ParameterTypes.Fluorescence)))
                                          .subscribe((fluorescenceData) => {
                                            if (!!fluorescenceData) {
                                              this.fluorescenceOptions = fluorescenceData;
                                              let newObj = [
                                                ...this.planData.packetPlanStones,
                                              ].map((d: any) => {
                                                let clarityIds = [];
                                                let colorIds = [];
                                                let shapeIds = [];
                                                let fluorescenceIds = [];

                                                if (d.clarity) {
                                                  clarityIds = this.clarityOptions.filter(
                                                    (x) => x.name == d.clarity
                                                  );
                                                }

                                                if (d.color) {
                                                  colorIds = this.colorOptions.filter(
                                                    (x) => x.name == d.color
                                                  );
                                                }

                                                if (d.shape) {
                                                  shapeIds = this.shapeOptions.filter(
                                                    (x) => x.name == d.shape
                                                  );
                                                }

                                                if (d.fluorescence) {
                                                  fluorescenceIds =
                                                    this.fluorescenceOptions.filter(
                                                      (x) => x.name == d.fluorescence
                                                    );
                                                }

                                                return {
                                                  ...d,
                                                  colorIds: colorIds,
                                                  clarityIds: clarityIds,
                                                  shapeIds: shapeIds,
                                                  fluorescenceIds: fluorescenceIds,
                                                };
                                              });

                                              this.planData = {
                                                ...this.planData,
                                                packetPlanStones: newObj,
                                              };
                                            }
                                          });
                                      }
                                    })
                                }
                              });
                          }
                        })
                    }
                  })
              }
            });

        }
      });
  }

  switchTabs(evt) {
    if (this.toggleNotOkayValue == true) {
      this.shouldDisableButton = true;
    } else {
      this.shouldDisableButton = false;
    }
  }

  addComments(evt) {
  }

  getHexValue(a) {
    a += 1;
    var c = 0;
    var x = 1;
    while (a >= x) {
      c++;
      a -= x;
      x *= 26;
    }
    var s = '';
    for (var i = 0; i < c; i++) {
      s = this.alpha.charAt(a % 26) + s;
      a = Math.floor(a / 26);
    }
    return s;
  }

  closeModal(data: any) {
    if (!!data) {
      this.modalCtl.dismiss(data);
    } else {

      this.modalCtl.dismiss();
    }
  }

  submitData(data) {
    this.finalPayload = data;
    if (data) {
      this.updatePlanData(this.finalPayload)
      this.modalCtl.dismiss(data);
    }
  }

  async dropDownSelection(evt: any, planId) {
    // why we manipulating packet plan stone after every packet change ?
    let property = evt.property;
    let idList = evt.idList;
    let selected = evt.arrayList;
    if (property == 'Shape') {
      this.arrayOfObjects[0].shapeId = selected[0]?.name
      let newObj = await [...this.planData.packetPlanStones].map((d: any) => {
        if (d.id == planId && !!selected[0]) {
          return {
            ...d,
            shape: selected[0].name,
            planShape: selected[0].name,
            shapeId: selected[0].id,
            shapeIds: selected,
          };
        } else {
          return d;
        }
      });
      this.planData = { ...this.planData, packetPlanStones: newObj };
    } else if (property == 'Clarity') {
      this.arrayOfObjects[0].clarityId = selected[0]?.name;
      let newObj1 = await [...this.planData.packetPlanStones].map((d: any) => {
        if (d.id == planId && !!selected[0]) {
          return {
            ...d,
            clarity: selected[0].name,
            planClarity: selected[0].name,
            clarityId: selected[0].id,
            clarityIds: selected,
          };
        } else {
          return d;
        }
      });
      this.planData = { ...this.planData, packetPlanStones: newObj1 };
    } else if (property == 'Color' && !!selected[0]) {
      this.arrayOfObjects[0].colorId = selected[0]?.name;
      let newObj2 = await [...this.planData.packetPlanStones].map((d: any) => {
        if (d.id == planId) {
          return {
            ...d,
            color: selected[0].name,
            planColor: selected[0].name,
            colorId: selected[0].id,
            colorIds: selected,
          };
        } else {
          return d;
        }
      });
      this.planData = { ...this.planData, packetPlanStones: newObj2 };
    } else if (property == 'Fluorescence') {
      this.arrayOfObjects[0].fluorescenceId = selected[0]?.name;

      let newObj1 = await [...this.planData.packetPlanStones].map((d: any) => {
        if (d.id == planId && !!selected[0]) {
          return {
            ...d,
            flu: selected[0].name,
            planFlu: selected[0].name,
            fluId: selected[0].id,
            fluIds: selected,
          };
        } else {
          return d;
        }
      });
      this.planData = { ...this.planData, packetPlanStones: newObj1 };
    }
    else if (property == 'Cut') {
      this.arrayOfObjects[0].cutId = selected[0]?.name;
      let newObj1 = await [...this.planData.packetPlanStones].map((d: any) => {
        if (d.id == planId && !!selected[0]) {
          return {
            ...d,
            cut: selected[0].name,
            planCut: selected[0].name,
            pluId: selected[0].id,
            pluIds: selected,
          };
        } else {
          return d;
        }
      });
      this.planData = { ...this.planData, packetPlanStones: newObj1 };
    }
    else if (property == 'Symmetry') {
      this.arrayOfObjects[0].symmetryId = selected[0]?.name;
      let newObj1 = await [...this.planData.packetPlanStones].map((d: any) => {
        if (d.id == planId && !!selected[0]) {
          return {
            ...d,
            symm: selected[0].name,
            plansymm: selected[0].name,
            symmId: selected[0].id,
            symmIds: selected,
          };
        } else {
          return d;
        }
      });
      this.planData = { ...this.planData, packetPlanStones: newObj1 };
    }
    else if (property == 'Polish') {
      this.arrayOfObjects[0].polishId = selected[0]?.name;
      let newObj1 = await [...this.planData.packetPlanStones].map((d: any) => {
        if (d.id == planId && !!selected[0]) {
          return {
            ...d,
            pol: selected[0].name,
            planPlan: selected[0].name,
            planId: selected[0].id,
            planIds: selected,
          };
        } else {
          return d;
        }
      });
      this.planData = { ...this.planData, packetPlanStones: newObj1 };
    }
    else if (property == 'Girdle') {
      this.arrayOfObjects[0].girdleId = selected[0]?.name;
      let newObj1 = await [...this.planData.packetPlanStones].map((d: any) => {
        if (d.id == planId && !!selected[0]) {
          return {
            ...d,
            pol: selected[0].name,
            planPlan: selected[0].name,
            planId: selected[0].id,
            planIds: selected,
          };
        } else {
          return d;
        }
      });
      this.planData = { ...this.planData, packetPlanStones: newObj1 };
    }

    else {
    }
  }
  async sendCommentToPacketChat() {
    this.payload = {
      packetId: this.rowData.id,
      info: {
        message: this.Comments,
        username: this.userName.fullName,
        time: this.currentTime,
        media: 'text',
        date: this.today,
        timestamp: this.timestamp,
        important: false,
      },
    };
    let response = await this.httpService.postDataUsingPromise(
      true,
      'chat-app/saveChat',
      this.payload
    );

    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      let data = response.data;
      // this.saveInputParameter(this.Comments);
    } else {
      console.error(response);
    }
  }

  async updatePlanData(data) {
    // set it to null currently
    if ('girdleId' in data) {
      data.girdleId = null;
    }
    const payload = [];
    for (const key in data[0]) {
      if (data[0].hasOwnProperty(key)) {
        payload.push({
          paramName: key,
          paramValue: isNaN(Number(data[0][key])) ? data[0][key] : Number(data[0][key]),
        });
      }
    }
    let response = await this.httpService.postDataUsingPromise(
      true,
      `packet/updatePacketFieldParameters?packetId=${this.data.id}`,
      payload
    );
    await this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      this.configService.showToast("success", (response.message || "Updated successfully!"));
    } else {
      this.configService.showToast("error", (response.message || "Something went wrong!"));
      // console.error(response);
    }
  }

  ngDoCheck() {
    if (
      this.toggleNotOkayValue == true &&
      this.Comments != '' &&
      this.Comments != null
    ) {
      this.shouldDisableButton = false;
    }
  }

  getIdByName(name: any, array: any, key: any) {
    if (name)
      return (array || []).find((dt: any) => dt[key] == name)?.id;
    return null;
  }


}
