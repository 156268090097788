import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import { FormsModule } from "@angular/forms";
import { DxDataGridModule, DxPopoverModule } from "devextreme-angular"
import { MatTooltipModule } from "@angular/material/tooltip";
import { OpinionViewHistoryComponent } from "./opinion-view-history.component";

@NgModule({
  declarations: [OpinionViewHistoryComponent],
  entryComponents: [],
  exports: [OpinionViewHistoryComponent],
  providers: [],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    DxDataGridModule,
    IonicModule,
    MatTooltipModule,
  ],
})
export class OpinionViewHistoryModule {}
