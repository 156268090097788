import { Component, Input, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { HttpService } from '../services/http/http.service';
import { AppState } from '../reducers';
import { ConfigService } from '../services/config/config.service';
import { LoaderService } from '../services/loader/loader.service';
import {
  FetchAllColorData,
  FetchAllColorShadeData,
  FetchAllFancyData,
  FetchAllLabParameterData,
  getParamsValuesListByName,
  getPreplanningSummaryDetails,
  selectColorShade,
  selectColorValues,
  selectFancyValues,
  selectLabParametersData,
} from '../masterDataStore/masterData.selector';
import { Observable, Subject, Subscription } from 'rxjs';
import { masterDataActions } from '../masterDataStore/masterData.action-type';
import { getAllLabParametersData } from '../masterDataStore/masterData.action';
import { takeUntil } from 'rxjs/operators';
import {
  getCurrentAction,
  getCurrentRowData,
} from '../tempDataStore/tempData.selector';
import { ModalController } from '@ionic/angular';
import { NewPdComponent } from '../new-pd/new-pd.component';
import { PDComponent } from '../components/pd/pd.component';
import { ParameterTypes } from '../services/endpoint.enum';

@Component({
  selector: 'app-color-inspection',
  templateUrl: './color-inspection.component.html',
  styleUrls: ['./color-inspection.component.scss'],
})
export class ColorInspectionComponent implements OnInit {
  @Input() page = 'colorInspection';
  private subscriptions: Subscription[] = [];
  valuesArray: any[];
  labParameterData: any;
  private unsubscribe$: Subject<any> = new Subject<any>();
  headerSelectedValues: any = {
    normalColor: {},
    colorShade: {},
    fancyColor: {},
    clarity: {},
    cut: {},
    Symmetry: {},
    Polish: {},
    fluorescence: {},
    heartarrow: {},
    TBLFM: {}


  };
  // { [key: string]: string[] } = {};
  ColorData: any;
  dropdown: any = {};
  fancyData: any;
  payload: any;
  allLabParameter: any;
  isUpdateDisable: boolean = true;
  isDataLoaded = false;
  activeValues: { [key: string]: boolean } = {};
  ColorShadeData: any;
  currentTab: string = 'normalColor';
  currentLabTab: string = 'clarity';
  dropDownShapeData: any = {
    name: 'shape',
    placeText: 'shape Selection',
    options: [],
    selectedData: [],
    key: 'name',
    modeFlag: 'single', //single/multiple
  };
  actionName: any;
  actionStatus: any;
  currRowData: any;
  finalpayload: { packetDetails: any[]; packetId: any; isLab: number };
  actionId: any;
  saveData: any;
  greenclass: any;
  redclass: any;
  modifySaveData: any;
  labParameterDataForLab: any;
  valuesArrayofLab: any = [];
  clarityData: any;
  cutData: any;
  symmetryData: any;
  polishData: any;
  flourData: any;
  resigningLabData: any;
  constructor(
    private store: Store<AppState>,
    public httpService: HttpService,
    private modalCtl: ModalController,
    private configService: ConfigService,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {

    this.store.pipe(select(getParamsValuesListByName(ParameterTypes.Clarity))).pipe(takeUntil(this.unsubscribe$)).subscribe(data => {
      if (!!data) {
        this.clarityData = data;
        this.valuesArrayofLab = data;
      }
    });

    this.store.pipe(select(getParamsValuesListByName(ParameterTypes.CUT))).pipe(takeUntil(this.unsubscribe$)).subscribe(data => {
      if (!!data) {
        this.cutData = data;
      }
    });
    this.store.pipe(select(getParamsValuesListByName(ParameterTypes.Symmetry))).pipe(takeUntil(this.unsubscribe$)).subscribe(data => {
      if (!!data) {
        this.symmetryData = data;
      }
    });
    this.store.pipe(select(getParamsValuesListByName(ParameterTypes.Polish))).pipe(takeUntil(this.unsubscribe$)).subscribe(data => {
      if (!!data) {
        this.polishData = data;
      }
    });
    this.store.pipe(select(getParamsValuesListByName(ParameterTypes.Fluorescence))).pipe(takeUntil(this.unsubscribe$)).subscribe(data => {
      if (!!data) {
        this.flourData = data;
      }
    });
    this.store
      .pipe(select(getCurrentAction))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((action: any) => {
        if (action) {
          this.actionName = action?.name;
          this.actionId = action?.id;
          this.actionStatus = action?.status;
        }
      });

    this.store
      .pipe(select(getCurrentRowData))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((rd: any) => {
        if (rd) {
          this.currRowData = rd[0];
        }
      });
    // if (this.actionStatus == 'Pending') {
    this.store
      .select(selectLabParametersData)
      .subscribe((labParametersData) => {
        if (!!labParametersData && labParametersData.length == 0) {
          this.store.dispatch(masterDataActions.getAllLabParametersData());
        }
      });

    this.store.select(selectColorValues).subscribe((colorValues) => {
      if (!!colorValues && colorValues.length == 0) {
        this.store.dispatch(masterDataActions.getAllColorValues());
      }
    });

    this.store.select(selectFancyValues).subscribe((fancyValues) => {
      if (!!fancyValues && fancyValues.length == 0) {
        this.store.dispatch(masterDataActions.getAllFancyValues());
      }
    });

    this.store.select(selectColorShade).subscribe((colorShade) => {
      if (!!colorShade && colorShade.length == 0) {
        this.store.dispatch(masterDataActions.getAllColorShade());
      }
    });
    // }
    this.store.pipe(select(FetchAllLabParameterData)).subscribe((data) => {
      if (data) {
        this.allLabParameter = data;
        this.labParameterDataForLab = data;
        this.resigningLabData = data;
        this.labParameterData = data;
      }
    });

    this.store
      .pipe(
        select(getParamsValuesListByName('Shape')),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((data) => {
        if (!!data) {
          this.dropDownShapeData.options = data;
        }
      });

    this.subscriptions.push(
      this.store.pipe(select(FetchAllColorData)).subscribe((data) => {
        if (data) {
          this.ColorData = data;
          if (this.currentTab == 'normalColor') {
            this.valuesArray = this.ColorData;
          }
        }
      })
    );
    this.subscriptions.push(
      this.store.pipe(select(FetchAllFancyData)).subscribe((data) => {
        if (data) {
          this.fancyData = data;
        }
      })
    );

    this.subscriptions.push(
      this.store.pipe(select(FetchAllColorShadeData)).subscribe((data) => {
        if (data) {
          this.ColorShadeData = data;
        }
      })
    );
    this.finalpayload = {
      packetDetails: [],
      packetId: this.currRowData?.id,
      isLab: 1,
    };
    if (this.actionStatus == 'Completed') {
      this.getColorInspectionData();
    }
    if (this.page == 'labFinalCheck') {
      this.getColorInspectionData();
    }
    // this.subscriptions.push(subscription);
  }

  ngAfterViewInit() {
    this.currentTab = 'normalColor';
  }

  segmentChanged(event) {
    this.currentTab = event.detail.value;
    if (this.currentTab == 'normalColor') {
      this.labParameterData = this.allLabParameter;
      this.valuesArray = this.ColorData;
    } else if (this.currentTab == 'colorShade') {
      this.labParameterData = this.allLabParameter;
      this.valuesArray = this.ColorShadeData;
    } else if (this.currentTab == 'fancyColor') {
      const keysArray = Object.keys(this.fancyData);
      // const modifiedKeysArray: string[] = keysArray.map((key: string) => key.replace("Color", ""));
      const arrayOfObjects = keysArray.map((key) => ({ name: key }));
      this.labParameterData = arrayOfObjects;
      this.valuesArray = this.fancyData;
    }
  }

  segmentChangedOfLab(event) {
    this.currentLabTab = event.detail.value;
    if (this.currentLabTab == 'clarity') {
      this.labParameterDataForLab = this.resigningLabData
      this.valuesArrayofLab = this.clarityData;
    } else if (this.currentLabTab == 'cut') {
      this.labParameterDataForLab = this.resigningLabData
      this.valuesArrayofLab = this.cutData;
    } else if (this.currentLabTab == 'Symmetry') {
      this.labParameterDataForLab = this.resigningLabData
      this.valuesArrayofLab = this.symmetryData;
    }
    else if (this.currentLabTab == 'Polish') {
      this.labParameterDataForLab = this.resigningLabData
      this.valuesArrayofLab = this.polishData;
    }
    else if (this.currentLabTab == 'fluorescence') {
      this.labParameterDataForLab = this.resigningLabData
      this.valuesArrayofLab = this.flourData;
      console.log(this.valuesArrayofLab);

    }
    else if (this.currentLabTab == 'heartarrow') {
      this.labParameterDataForLab = [
        {
          "id": 1,
          "code": "Value",
          "name": "Value",
        },

      ]

      this.valuesArrayofLab = [
        {
          "id": 1,
          "name": "Yes"
        },
        {
          "id": 2,
          "name": "No"
        },
      ]
    }
    else if (this.currentLabTab == 'TBLFM') {
      this.valuesArrayofLab = this.valuesArrayofLab = [
        {
          "id": 1,
          "name": "Yes"
        },
        {
          "id": 2,
          "name": "No"
        },
      ]
    }
  }

  onClickOfValues(headerName: string, value: any, tab: string) {

    this.payload = {
      paramName: headerName + '-' + tab,
      paramValue: value.name,
    };
    // console.log(this.headerSelectedValues,tab, headerName, value, this.headerSelectedValues?.[tab]?.[headerName]?.[0]?.['name'], value?.name, this.headerSelectedValues?.[tab]?.[headerName]?.[0]?.['name']?.includes(value?.name),(this.headerSelectedValues?.[tab]?.[headerName]?.[0]?.['name'] && value?.name && this.headerSelectedValues?.[tab]?.[headerName]?.[0]?.['name'].includes(value?.name))  );
    if (!this.headerSelectedValues?.[tab]?.[headerName]) {
      this.headerSelectedValues[tab][headerName] = [value];
    } else if (this.headerSelectedValues?.[tab]?.[headerName]?.[0]?.['name'] && value?.name && this.headerSelectedValues?.[tab]?.[headerName]?.[0]?.['name'].includes(value?.name)) {
      const index = this.headerSelectedValues[tab][headerName].findIndex((dt: any) => dt?.name?.includes(value.name));
      if (this.actionStatus != 'Completed') {
        this.headerSelectedValues[tab][headerName].splice(index, 1);
        this.finalpayload.packetDetails = this.finalpayload.packetDetails.filter((dt: any) => (!((dt.paramValue?.split('+')?.length > 1 ? dt.paramValue?.split('+')[0] : (dt.paramValue?.split('@')?.length > 1 ? dt.paramValue?.split('@')?.[0] : dt.paramValue)) == this.payload.paramValue && dt.paramName == this.payload.paramName)));
      }
    } else {
      // this.headerSelectedValues?.[tab]?.[headerName]?.[0]?.['name'].includes(value?.name)
      this.headerSelectedValues[tab][headerName] = [value];
    }

    if (!this.headerSelectedValues?.[tab]?.[headerName])
      return false;
    this.headerSelectedValues[tab][headerName] = this.headerSelectedValues[tab][headerName]?.map(
      (dt: any) => {
        let temp = [dt.name]
        if ((this.page == 'colorInspection' && this.actionStatus == 'Completed') || this.page == 'labFinalCheck') {
          temp = (dt?.name || '').split("@")
          if (temp.length > 1) {
            return { ...dt, isArrow: false };
          }
          else if (temp.length < 2) {
            temp = (dt?.name || '').split("+");
            if (temp.length > 1) {
              return { ...dt, isArrow: true };
            }
          } else {
            return { ...dt, isArrow: null };
          }
        }
        return dt;
      }
    )
    // if user selected btns and again if it deselect remove it from final payload

    if (this.isValueSelected(value, headerName, tab)) {
      const paramNameToRemove = this.payload.paramName;
      const paramValueToRemove = this.payload.paramValue;
      this.finalpayload.packetDetails = this.finalpayload.packetDetails.filter(
        (item) => {
          return !(
            item.paramName === paramNameToRemove &&
            item.paramValue != paramValueToRemove
          );
        }
      );
      // if not code will go in else
      this.updatepayload(this.payload);
    }


  }

  getValueForHeader(item: any, headerName: string): string {
    if (item.hasOwnProperty('name')) {
      return item['name'];
    } else {
      return;
    }
  }

  isArrow(value: any, headerName: any, tab: any, bool: boolean) {
    return this.headerSelectedValues[tab][headerName]?.filter(
      (dt: any) => {
        if (dt?.name?.includes(value.name))
          return ((dt?.isArrow === bool));
        else
          return false;
      }
    )?.length > 0
  }

  isValueSelected(value: any, headerName: any, tab: string): boolean {
    return (
      this.headerSelectedValues?.[tab]?.[headerName] &&
      this.headerSelectedValues[tab][headerName]?.filter(
        (dt: any) => {
          let temp = [dt.name]
          if ((this.page == 'colorInspection' && this.actionStatus == 'Completed') || this.page == 'labFinalCheck') {
            temp = dt.name.split("@")
            if (temp.length < 2) {
              temp = dt.name.split("+")
            }
          }
          return ((temp[0] == value.name) || (dt.name == value.name));
        }
      )?.length > 0
    );
  }

  get isButtonDisabled(): boolean {
    return (
      Object.keys(this.headerSelectedValues.normalColor).length === 0 &&
      Object.keys(this.headerSelectedValues.colorShade).length === 0 &&
      Object.keys(this.headerSelectedValues.fancyColor).length === 0
    );
  }
  setValuesOfDropdown(evt) {
    if (this.dropdown[evt.property] == undefined) {
      if (evt.arrayList.length > 0) {
        this.dropdown[evt.property] = {};
        this.dropdown[evt.property] = evt.arrayList[0];
      } else {
        delete this.dropdown[evt.property];
      }
    } else {
      if (evt.arrayList.length > 0) {
        this.dropdown[evt.property] = {};
        this.dropdown[evt.property] = evt.arrayList[0];
      } else {
        delete this.dropdown[evt.property];
      }
    }
    this.isUpdateDisable =
      Object.keys(this.dropdown).length === 0 ? true : false;
  }

  dropDownSelection(evt: any, plan: any) {
    if (evt.property == 'shape') {
      this.setValuesOfDropdown(evt);
    }
  }

  async updatepayload(obj) {
    this.finalpayload.packetDetails.push(obj);
  }

  onRemove() {
    let data = {
      packetId: this.currRowData?.id,
      listOfProcessActionInputId: [],
    };

    if (this.currRowData?.inputDetails) {
      for (const inputDetail of this.currRowData.inputDetails) {
        data.listOfProcessActionInputId.push(inputDetail?.piId);
      }
    }
    if (!!data && data?.listOfProcessActionInputId.length != 0) {
      this.store.dispatch(
        masterDataActions.removeStoneParamater({
          parameterDetails: data,
          actionId: this.actionId,
        })
      );
    }
    this.deleteDataOnRemoval(this.currRowData.id);
    this.modalCtl.dismiss();
  }

  async onSavebtnClick() {
    if (this.page == 'labFinalCheck') {
      await this.deleteDataOnRemoval(this.currRowData.id);
    }
    let response = await this.httpService.postDataUsingPromise(
      true,
      'packetDetails/add',
      this.finalpayload
    );
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      this.modalCtl.dismiss();
      this.saveinputParameter();
      let data = response.message;
      this.configService.showToast('success', data);
    } else {
      console.error(response);
      this.configService.showToast('error', response.error.message);
    }
  }

  saveinputParameter() {
    let listOfParameter = [];
    let barcodeObj = {
      packetId: this.currRowData.id,
    };
    let paramObj;
    if (this.page == 'colorInspection') {
      paramObj = {
        parameterName: 'isColorcheck',
        parameterValue: 'yes',
      };
    }
    else if (this.page == 'labFinalCheck') {
      paramObj = {
        parameterName: 'labfinalCheck',
        parameterValue: 'yes',
      };
    }
    listOfParameter.push(paramObj);
    let obj = {
      listOfAllPackets: [
        {
          packetId: this.currRowData.id,
          listOfParameter,
        },
      ],
    };

    this.store.dispatch(
      masterDataActions.saveStoneParamater({
        parameterDetails: obj,
        actionId: this.actionId,
        formType: 'JSON',
        barcodeObj: barcodeObj,
        actionInfo: this.actionName,
      })
    );
  }

  onNextbtnClick() {
    this.currentTab =
      this.currentTab == 'normalColor' ? 'colorShade' : 'fancyColor';
  }

  async getColorInspectionData() {
    let endpoint: string = `packetDetails/getPacketDetailsForIsLab/${this.currRowData?.id}`;
    let response: any = await this.httpService.fetchDataUsingPromise(
      true,
      endpoint
    );
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      this.saveData = response.data;
      this.modifySaveData = this.saveData.map((item) => {
        let temp = item.paramName.split('-');
        let paramSValueObj = {
          name: item.paramSValue,
        };
        return {
          paramName: temp[0],
          paramSValue: paramSValueObj,
          currentTab: temp[1],
        };
      });
      this.onCompletedPage(this.modifySaveData);
    } else {
      console.error(response);
    }
  }
  onUpArrow(val: object, header: string) {
    if (this.actionStatus == 'Pending') {
      if (this.headerSelectedValues[this.currentTab][header].length === 1) {
        if (
          this.headerSelectedValues[this.currentTab][header]['isArrow'] === true
        ) {
          this.headerSelectedValues[this.currentTab][header]['isArrow'] = null;
          if (this.headerSelectedValues?.[this.currentTab]?.[header]?.[0]?.['isArrow'] || this.headerSelectedValues?.[this.currentTab]?.[header]?.[0]?.['isArrow'] == false)
            this.headerSelectedValues[this.currentTab][header][0]['isArrow'] = null;
        } else {
          this.headerSelectedValues[this.currentTab][header]['isArrow'] = true;
          if (this.headerSelectedValues?.[this.currentTab]?.[header]?.[0]?.['isArrow'] || this.headerSelectedValues?.[this.currentTab]?.[header]?.[0]?.['isArrow'] == false)
            this.headerSelectedValues[this.currentTab][header][0]['isArrow'] = true;
        }
      }

      this.greenclass =
        this.headerSelectedValues[this.currentTab][header]['isArrow'];
      if (this.redclass == false) {
        this.redclass = null;
      }
      let flag = this.headerSelectedValues[this.currentTab][header]['isArrow']
      if (flag == true || flag == null) {
        for (let element of this.finalpayload.packetDetails) {
          let paramName = `${header}-${this.currentTab}`
          //  let temp = `${header}-+${this.currentTab}`
          if (element.paramName == paramName && flag == true) {
            const value = (element.paramValue.split('@')?.length > 1) ? element.paramValue.split('@')[0] : ((element.paramValue.split('+')?.length > 1) ? element.paramValue.split('+')[0] : element.paramValue);
            element.paramValue = `${value}+`
          } else if (element.paramName == paramName && flag == null) {
            const value = (element.paramValue.split('@')?.length > 1) ? element.paramValue.split('@')[0] : ((element.paramValue.split('+')?.length > 1) ? element.paramValue.split('+')[0] : element.paramValue);
            // element.paramValue = `${element.paramValue.split('+')[0]}`
            element.paramValue = `${value}`

          }
        }
      }

    }
  }

  onUpArrowForLab(val: object, header: string) {
    if (this.actionStatus == 'Pending') {
      if (this.headerSelectedValues[this.currentLabTab][header].length === 1) {
        if (
          this.headerSelectedValues[this.currentLabTab][header]['isArrow'] === true
        ) {
          this.headerSelectedValues[this.currentLabTab][header]['isArrow'] = null;
          if (this.headerSelectedValues?.[this.currentTab]?.[header]?.[0]?.['isArrow'] || this.headerSelectedValues?.[this.currentTab]?.[header]?.[0]?.['isArrow'] == false)
            this.headerSelectedValues[this.currentLabTab][header][0]['isArrow'] = null;
        } else {
          this.headerSelectedValues[this.currentLabTab][header]['isArrow'] = true;
          if (this.headerSelectedValues?.[this.currentTab]?.[header]?.[0]?.['isArrow'] || this.headerSelectedValues?.[this.currentTab]?.[header]?.[0]?.['isArrow'] == false)
            this.headerSelectedValues[this.currentLabTab][header][0]['isArrow'] = true;
        }
      }

      this.greenclass =
        this.headerSelectedValues[this.currentLabTab][header]['isArrow'];
      if (this.redclass == false) {
        this.redclass = null;
      }
      let flag = this.headerSelectedValues[this.currentLabTab][header]['isArrow']
      if (flag == true || flag == null) {
        for (let element of this.finalpayload.packetDetails) {
          let paramName = `${header}-${this.currentLabTab}`
          //  let temp = `${header}-+${this.currentTab}`
          if (element.paramName == paramName && flag == true) {
            const value = (element.paramValue.split('@')?.length > 1) ? element.paramValue.split('@')[0] : ((element.paramValue.split('+')?.length > 1) ? element.paramValue.split('+')[0] : element.paramValue);
            element.paramValue = `${value}+`
          } else if (element.paramName == paramName && flag == null) {
            const value = (element.paramValue.split('@')?.length > 1) ? element.paramValue.split('@')[0] : ((element.paramValue.split('+')?.length > 1) ? element.paramValue.split('+')[0] : element.paramValue);
            element.paramValue = `${value}`

          }
        }
      }

    }
  }

  onDownArrow(val: object, header: string) {
    if (this.actionStatus == 'Pending') {

      if (this.headerSelectedValues[this.currentTab][header].length === 1) {
        if (
          this.headerSelectedValues[this.currentTab][header]['isArrow'] === false
        ) {
          this.headerSelectedValues[this.currentTab][header]['isArrow'] = null;
          if (this.headerSelectedValues?.[this.currentTab]?.[header]?.[0]?.['isArrow'] || this.headerSelectedValues?.[this.currentTab]?.[header]?.[0]?.['isArrow'] == false)
            this.headerSelectedValues[this.currentTab][header][0]['isArrow'] = null;
        } else {
          this.headerSelectedValues[this.currentTab][header]['isArrow'] = false;
          if (this.headerSelectedValues?.[this.currentTab]?.[header]?.[0]?.['isArrow'] || this.headerSelectedValues?.[this.currentTab]?.[header]?.[0]?.['isArrow'] == false)
            this.headerSelectedValues[this.currentTab][header][0]['isArrow'] = false;
        }
      }
      this.redclass =
        this.headerSelectedValues[this.currentTab][header]['isArrow'];
      if (this.greenclass == true) {
        this.greenclass = null;
      }
      let flag = this.headerSelectedValues[this.currentTab][header]['isArrow']
      if (flag == false || flag == null) {
        for (let element of this.finalpayload.packetDetails) {
          let paramName = `${header}-${this.currentTab}`
          //  let temp = `${header}-+${tab}`
          if (element.paramName == paramName && flag == false) {
            const value = (element.paramValue.split('@')?.length > 1) ? element.paramValue.split('@')[0] : ((element.paramValue.split('+')?.length > 1) ? element.paramValue.split('+')[0] : element.paramValue);
            element.paramValue = `${value}@`;
          } else if (element.paramName == paramName && flag == null) {
            const value = (element.paramValue.split('@')?.length > 1) ? element.paramValue.split('@')[0] : ((element.paramValue.split('+')?.length > 1) ? element.paramValue.split('+')[0] : element.paramValue);
            // element.paramValue = `${element.paramValue.split('@')[0]}`
            element.paramValue = `${value}`

          }
        }
      }

    }
  }

  onDownArrowForLab(val: object, header: string) {
    if (this.actionStatus == 'Pending') {

      if (this.headerSelectedValues[this.currentLabTab][header].length === 1) {
        if (
          this.headerSelectedValues[this.currentLabTab][header]['isArrow'] === false
        ) {
          this.headerSelectedValues[this.currentLabTab][header]['isArrow'] = null;
          if (this.headerSelectedValues?.[this.currentTab]?.[header]?.[0]?.['isArrow'] || this.headerSelectedValues?.[this.currentTab]?.[header]?.[0]?.['isArrow'] == false)
            this.headerSelectedValues[this.currentLabTab][header][0]['isArrow'] = null;
        } else {
          this.headerSelectedValues[this.currentLabTab][header]['isArrow'] = false;
          if (this.headerSelectedValues?.[this.currentTab]?.[header]?.[0]?.['isArrow'] || this.headerSelectedValues?.[this.currentTab]?.[header]?.[0]?.['isArrow'] == false)
            this.headerSelectedValues[this.currentLabTab][header][0]['isArrow'] = false;
        }
      }
      this.redclass =
        this.headerSelectedValues[this.currentLabTab][header]['isArrow'];
      if (this.greenclass == true) {
        this.greenclass = null;
      }
      let flag = this.headerSelectedValues[this.currentLabTab][header]['isArrow']
      if (flag == false || flag == null) {
        for (let element of this.finalpayload.packetDetails) {
          let paramName = `${header}-${this.currentLabTab}`
          //  let temp = `${header}-+${tab}`
          if (element.paramName == paramName && flag == false) {
            const value = (element.paramValue.split('@')?.length > 1) ? element.paramValue.split('@')[0] : ((element.paramValue.split('+')?.length > 1) ? element.paramValue.split('+')[0] : element.paramValue);
            element.paramValue = `${value}@`
          } else if (element.paramName == paramName && flag == null) {
            const value = (element.paramValue.split('@')?.length > 1) ? element.paramValue.split('@')[0] : ((element.paramValue.split('+')?.length > 1) ? element.paramValue.split('+')[0] : element.paramValue);
            element.paramValue = `${value}`

          }
        }
      }

    }
  }
  onCompletedPage(val) {
    for (let element of val) {
      this.onClickOfValues(
        element.paramName,
        element.paramSValue,
        element.currentTab
      );
    }
  }

  deleteDataOnRemoval(id: number) {
    let deleteAPIEndpoint: string = `${'packetDetails/removePacketDetailsForLab'}/${id}`;
    
    this.httpService
      .deleteData(true, deleteAPIEndpoint)
      .subscribe((result: any) => {
        this.httpService.getErrorAndDisplayIt(result);
        if (result.statusCode == 200) {
          
          this.configService.showToast('success', result.message);
        } else {
          
          this.configService.showToast('error', result.error);
        }
      });
  }

  async onPDOldbtnClick() {
      console.log('-------PD......');
      // console.log
      const pdmodal = await this.modalCtl.create({
        component: PDComponent,
        // component: PdsComponent,
        cssClass: 'pdpopup',
        componentProps: {
          // "message": 'message',
          'data': { packetPlanStoneId: null, packetId: this.currRowData.packetId, from: 'planGrid' }
        },
      });
  
      pdmodal.onDidDismiss().then((d: any) => {
        if (d.data) {
          // this.PDValueSubmission(id)
        }
      });
      return await pdmodal.present();
    }

    // PDValueSubmission(m: any) {
    //   console.log(m.data)
    //   this.packetPlans = this.packetPlans.map(x => {
    //     if (x.packetPlanStones.length > 0) {
    //       let newPd = x.packetPlanStones.map(b => {
    //         if (b.id == m.data) {
    //           return {
    //             ...b,
    //             pdSaved: true
    //           }
    //         } else {
    //           return b
    //         }
    //       })
    //       return {
    //         ...x,
    //         packetPlanStones: newPd
    //       }
    //     }
    //   })
    //   console.log('PDValueSubmission');
    //   console.log(m);
    //   console.log(this.packetPlans)
    //   // this.store.dispatch(SignerAction.savePDParameterPacketPlanStone({pdParam:}));
    // }
  
  async onPDNewbtnClick() {
    const newpdModal = await this.modalCtl.create({
      component: NewPdComponent,
      cssClass: 'popup',
      componentProps: {
        'data': {}
      },
    });

    newpdModal.onDidDismiss().then((d: any) => {
      if (d.data) {
        // this.PDValueSubmission(id)
      }
    });
    return await newpdModal.present();
  }


  onResetbtnClick() {

  }

  onDeletebtnClick() {

  }
  onViewAllBtnClick() {

  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
