import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { Storage } from '@ionic/storage-angular';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/reducers';
import { getCurrentAction } from '../tempDataStore/tempData.selector';
import { filter, first, takeUntil } from 'rxjs/operators';
import { IonSlides, ModalController, NavParams } from '@ionic/angular';
import { HttpService } from '../services/http/http.service';

import {
  getAllStoneActionsMap,
  getCompletedPacketsData,
  getFinalPlanBYPacketID,
  getPacketDataByActionId,
  getPendingPacketsData
} from 'src/app/home/state/home.selector';
import { getParamsValuesListByName } from '../masterDataStore/masterData.selector';
import { actions } from '../authStore/auth.selector';
import { ConfigService } from '../services/config/config.service';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { HomeActions } from '../home/state/home.action-type';
import { ParameterTypes } from '../services/endpoint.enum';
@Component({
  selector: 'app-manual-color',
  templateUrl: './manual-color.component.html',
  styleUrls: ['./manual-color.component.scss'],
})
export class ManualColorComponent implements OnInit {
  private dataLoaded$ = new BehaviorSubject<boolean>(false);
  private isPlanLoaded$ = new BehaviorSubject<boolean>(false);
  private unsubscribe$: Subject<any> = new Subject<any>();
  private actionSub$: Subject<any> = new Subject<any>();
  private subscriptions: Subscription[] = [];
  loggedinUser$: Observable<any>;
  status: string;
  actionId: number;
  actionName: string;
  actionInfo: any;
  packetsList = [];
  packetDetail;
  showSubmit: boolean = true;
  formJson: any;
  userId: number;
  machinesByUser: any;
  mainFormJson: any;
  refreshField: boolean = false;
  resetForm: boolean = false;
  reloadForm: boolean = false;
  showDrawer: boolean = false;
  showRemoveButton: boolean = false;
  listOfActions: [];
  defaultSelected = 0;
  slideOptsThree = {
    initialSlide: 0,
    slidesPerView: 3,
  };
  currentSelectedIndex = 0;
  disablePrv = false
  disableNext = false;
  project: string = environment.project;

  @ViewChild('slider', { static: false }) slider: IonSlides;
  formData = {
    Color: "",
    Luster: "",
    Tinch: "",
    Stress: "",
    Fluorescence: "",
    //[this.project=='kps' ? 'Stress Type' : 'Stress']: ""
  }
  dropDownColorData: any = {
    name: 'Color',
    placeText: 'Color',
    options: [],
    selectedData: [],
    key: 'color',
    modeFlag: 'single',//single/multiple
  };
  dropDownLusterData: any = {
    name: 'Luster',
    placeText: 'Luster',
    options: [],
    selectedData: [],
    key: 'Luster',
    modeFlag: 'single',//single/multiple
  };
  dropDownTinchData: any = {
    name: 'Tinch',
    placeText: 'Tinch',
    options: [],
    selectedData: [],
    key: 'Tinch',
    modeFlag: 'single',//single/multiple
  };
  // dropDownStressData: any = {
  //   name: 'Purity',
  //   placeText: 'Purity',
  //   options: [],
  //   selectedData: [],
  //   key: 'Purity',
  //   modeFlag: 'single',//single/multiple
  // };
  dropDownStressTypeData: any = {
    name: 'Stress',
    placeText: 'Stress',
    options: [],
    selectedData: [],
    key: 'Stress',
    modeFlag: 'single',//single/multiple
  };
  dropDownFLData: any = {
    name: 'Fluorescence',
    placeText: 'Fluorescence',
    options: [],
    selectedData: [],
    key: 'Fluorescence',
    modeFlag: 'single',//single/multiple
  };
  yehudaData: any = {};
  tensionPicturePaths = [];
  symmentryOptions = [];
  message: string = '';
  forPage: any = '';
  visionFile: any = '';
  currRowData: any;
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    switch (event.key) {
      case 'ArrowLeft':
        this.slidePrev();
        break;
      case 'ArrowRight':
        this.slideNext();
        break;
    }
  }
  userActions$: Observable<any>;
  constructor(
    private store: Store<AppState>,
    private navParams: NavParams,
    private httpService: HttpService,
    private modalController: ModalController,
    private configService: ConfigService,
    private sanitizer: DomSanitizer,
  ) {
    this.status = this.navParams.get('status');
    this.actionId = this.navParams.get('actionId');
    // this.formJson = this.navParams.get('formJson')
    this.userActions$ = this.store.pipe(select(actions));
    this.userActions$.subscribe((data) => {
      if (data) {
        this.listOfActions = data;

        let sAction: any = this.listOfActions.filter(
          (x: any) => x.id == this.actionId
        )[0];

        if (!!sAction && sAction.listOfForms && sAction.listOfForms.length > 0) {
          this.formJson = JSON.parse(sAction.listOfForms[0]['formData']);
          console.log(this.formJson)
        }
      }
    });
  }

  ngOnInit() {

    this.store.pipe(select(getParamsValuesListByName(ParameterTypes.Color))).pipe(takeUntil(this.unsubscribe$)).subscribe(data => {
      if (!!data) {
        this.dropDownColorData.options = data;
      }
    });
    this.store.pipe(select(getParamsValuesListByName(ParameterTypes.LUSTER))).pipe(takeUntil(this.unsubscribe$)).subscribe(data => {
      if (!!data) {
        this.dropDownLusterData.options = data;
      }
    });
    this.store.pipe(select(getParamsValuesListByName(ParameterTypes.Tinch))).pipe(takeUntil(this.unsubscribe$)).subscribe(data => {
      if (!!data) {
        this.dropDownTinchData.options = data;
      }
    });
    // this.store.pipe(select(getParamsValuesListByName('Stress'))).pipe(takeUntil(this.unsubscribe$)).subscribe(data => {
    //   if (!!data) {
    //     this.dropDownStressData.options = data;
    //   }
    // });
    this.store.pipe(select(getParamsValuesListByName(ParameterTypes.Stress))).pipe(takeUntil(this.unsubscribe$)).subscribe(data => {
      if (!!data) {
        this.dropDownStressTypeData.options = data;
      }
    });
    this.store.pipe(select(getParamsValuesListByName(ParameterTypes.Fluorescence))).pipe(takeUntil(this.unsubscribe$)).subscribe(data => {
      if (!!data) {
        this.dropDownFLData.options = data;
      }
    });
    if (this.status == 'Pending') {
      this.store
        .pipe(select(getPendingPacketsData(this.actionId))).pipe(takeUntil(this.actionSub$))
        .subscribe((data) => {
          console.log(data, this.actionSub$);
          if (data?.length > 0) {
            this.actionSub$.complete();
            this.packetsList = [];
            this.packetsList = data;
            this.loadData()
          }
        })
    } else if (this.status == 'Completed') {
      this.store
        .pipe(select(getCompletedPacketsData(this.actionId)))
        .pipe(takeUntil(this.actionSub$))
        .subscribe((data) => {
          if (data.length > 0) {
            this.actionSub$.complete();
            this.packetsList = [];
            this.packetsList = data;
            this.loadData()
          }

        })
    } else {

      this.store
        .pipe(select(getPacketDataByActionId(this.actionId)))
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((data) => {
          if (data.length > 0) {
            this.packetsList = [];
            this.packetsList = data;

            this.loadData()

          }
        })

    }
    // this.subscriptions.push(
    //   this.store
    //     .pipe(select(getCurrentAction))
    //     .pipe(
    //       filter((data) => !!data),
    //       first()
    //     )
    //     .subscribe(async (data: any) => {
    //       if (data) {
    //         console.log(data);

    //         this.status = data.status;
    //         this.actionId = data.id;
    //         this.actionName = data.name;
    //         this.actionInfo = data;
    //         if (data.actionForm) {
    //           let formDataObject = JSON.parse(data.actionForm);
    //           let parsedFormData: any = formDataObject.form;
    //           console.log(parsedFormData);
    //           console.log(formDataObject);
    //           console.log(this.actionName);


    //         }
    //       }
    //     })
    // );
  }

  saveDetails() {
    console.log(this.formData)
  }

  getYehudaDetails(id) {
    console.log(id);

    if (id) {
      let endpoint: string = `packet-yehuda-data/getYehudaDataPacketWise?packetId=${id}`;
      this.httpService.fetchData(true, endpoint).subscribe((res: any) => {
        if (res) {
          console.log('change Status');
          console.log(res);
          let resultCode: number = res.statusCode;
          console.log(resultCode);
          let d: any = res.data;
          console.log(d);
          if (resultCode == 200) {
            this.yehudaData = [];
            for (const key in res.data) {
              let ydata = {
                version: key,
              };
              res.data[key].map((dt: any) => {
                let pName = dt.paramName.replace(/\s/g, "");
                ydata[pName] = dt.paramValue;
              });
              this.yehudaData = ydata
            }
          } else {
          }
        }
      });
    }
  }

  slideChanged(index) {
    this.defaultSelected = index;
    // this.slider.getActiveIndex().then(index => {
    //   this.defaultSelected = index; 
    //        
    // });
  }

  async getTensionPhoto(packetID) {
    //const packetID = this.currRowData?.id;
    // let response: any = await this.httpService.fetchDataOfTensionFile(true, `getfileForVision?folder=uploads/${this.packetData?.[this.currentIndex]?.barcodeNo}/V360&extension=html`);
    if (packetID) {
      let response: any = await this.httpService.fetchDataOfTensionFile(
        true,
        `processActionInput/getTensionPhotoFromCentral?packetId=${packetID}`
      );
      console.log(response);
      if (response.statusCode === 200) {
        const filePath = response.data.tensionPhoto?.filePath;
        // this.visionFile = this.httpService?.centralFileUrl +response.data.vision.filePath[0];
        this.tensionPicturePaths = filePath.map(path =>
          this.sanitizer.bypassSecurityTrustResourceUrl(this.httpService.centralFileUrl + path)
        );
      } else {
        console.error(response);
      }
    }
  }

  slidePrev() {
    if (this.message == 'showTensionPhoto') {
      this.slider?.slidePrev();
    }
  }

  slideNext() {
    if (this.message == 'showTensionPhoto') {
      this.slider?.slideNext();
    }
  }

  async dropDownSelection(ev) {
    console.log(ev);
    let property = ev.property;
    let idList = ev.idList;
    let selected = ev.arrayList;
    if (property == this.dropDownColorData['name']) {
      this.formData["Color"] = idList[0]
    } else if (property == this.dropDownLusterData['name']) {
      this.formData["Luster"] = idList[0]
    } else if (property == this.dropDownTinchData['name']) {
      this.formData["Tinch"] = idList[0]
      // } else if (property == this.dropDownStressData['name']) {
      //   this.formData["Stress"] = idList[0]
    } else if (property == this.dropDownStressTypeData['name']) {
      this.formData["Stress"] = idList[0]
    } else if (property == this.dropDownFLData['name']) {
      this.formData["Fluorescence"] = idList[0]
    }
    console.log(this.formData);
  }

  async saveInputParameter() {
    console.log(this.formData);

    const dynamicVal = Object.entries(this.formData).map(([parameterName, parameterValue]) => ({
      'parameterName': parameterName,
      'parameterValue': parameterValue
    }));

    console.log(dynamicVal);
    let payload = {
      listOfAllPackets: [
        {
          packetId: parseInt(this.packetDetail.id.toString()),
          listOfParameter: dynamicVal
        },
      ]
    };
    // debugger;
    // return;
    let response = await this.httpService.postDataUsingPromise(
      true,
      `processActionInput/inputReceiverByActionId/${this.actionId}`,
      payload
    );
    console.log(response);
    await this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {

      if (response.statusCode === 200) {
        console.log(response.data);
        let data = response.message;
        console.log(data);
        this.configService.showToast("success", data);
        this.modalController.dismiss();

        this.store.dispatch(
          HomeActions.getActionWisePendingAndCompletedPacketsCountList()
        );
      } else {
        console.error(response);
        this.configService.showToast("error", response.error.message);
      }
    }

    // } else {
    //   console.error(response);
    // }
  }


  isDisable() {
    if (!!this.formData.Color &&
      !!this.formData.Luster &&
      !!this.formData.Tinch &&
      !!this.formData.Stress &&
      !!this.formData.Fluorescence) {
      return false
    } else {
      return true
    }
  }

  formSubmitted(formData: any) {
    console.log(this.actionName);
  }

  packetSearched(event: any) {
    if (!event?.target?.value)
      return;
    this.packetsList.map((packet: any, index: number) => {
      if (packet.packetId.includes(event.target.value))
        this.currentSelectedIndex = index;
    });
    this.loadData();
  }

  async prv() {
    if (this.currentSelectedIndex !== 0) {
      this.currentSelectedIndex = this.currentSelectedIndex - 1;
      await this.loadData();
    } else {
      this.disablePrv = true
    }
    console.log(this.currentSelectedIndex)
  }

  async next() {
    if (this.currentSelectedIndex !== this.packetsList.length - 1) {
      this.currentSelectedIndex = this.currentSelectedIndex + 1;
      await this.loadData();

    } else {
      this.disableNext = true
    }
    console.log(this.currentSelectedIndex)
  }

  loadData() {
    console.log(this.currentSelectedIndex)
    console.log(this.packetsList)
    this.packetDetail = {}
    this.packetDetail = this.packetsList[this.currentSelectedIndex];
    if (this.packetDetail?.inputDetails) {
      for (const inputDetail of this.packetDetail.inputDetails) {
        if (inputDetail.paramName == 'Color') {
          this.dropDownColorData.selectedData = []
          let selectedData = this.dropDownColorData.options.filter(x => x.id == inputDetail.paramIValue)
          this.dropDownColorData.selectedData = selectedData;
        } else if (inputDetail.paramName == 'Luster') {
          this.dropDownLusterData.selectedData = []
          let selectedData = this.dropDownLusterData.options.filter(x => x.id == inputDetail.paramIValue)
          this.dropDownLusterData.selectedData = selectedData;
        } else if (inputDetail.paramName == 'Tinch') {
          this.dropDownTinchData.selectedData = []
          let selectedData = this.dropDownTinchData.options.filter(x => x.id == inputDetail.paramIValue)
          this.dropDownTinchData.selectedData = selectedData;
          // } else if (inputDetail.paramName == 'Stress') {
          //   this.dropDownStressData.selectedData = []
          //   let selectedData = this.dropDownStressData.options.filter(x => x.id == inputDetail.paramIValue)
          //   this.dropDownStressData.selectedData = selectedData;
        } else if (inputDetail.paramName == 'Stress') {
          this.dropDownStressTypeData.selectedData = []
          let selectedData = this.dropDownStressTypeData.options.filter(x => x.id == inputDetail.paramIValue)
          this.dropDownStressTypeData.selectedData = selectedData;
        } else if (inputDetail.paramName == 'Fluorescence') {
          this.dropDownFLData.selectedData = []
          let selectedData = this.dropDownFLData.options.filter(x => x.id == inputDetail.paramIValue)
          this.dropDownFLData.selectedData = selectedData;
        }
      }
    }
    this.getYehudaDetails(this.packetDetail.id);
    this.getTensionPhoto(this.packetDetail.id);
  }

}
