import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MenuController, ModalController } from "@ionic/angular";
import { select, Store } from "@ngrx/store";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { Observable, Subject, Subscription } from "rxjs";
import { masterDataActions } from "src/app/masterDataStore/masterData.action-type";
import { getPacketParameterHistoryData, getParamsValuesListByName } from "src/app/masterDataStore/masterData.selector";
import { AppState } from "src/app/reducers";
import { HttpService } from "src/app/services/http/http.service";
import { AutoReceiveAction } from "../actions/auto-receive/state/auto-receive.action.type";
import { ConfigService } from "src/app/services/config/config.service";
import { confirm } from "devextreme/ui/dialog";
import { Router } from "@angular/router";
import { ACTIONNAMES } from "src/app/services/endpoint.enum";
import { TrybemodalComponent } from "../trybemodal/trybemodal.component";
import { CopyplanmodalComponent } from "../copyplanmodal/copyplanmodal.component";
@Component({
  selector: "ngx-form",
  templateUrl: "./ngx-form.component.html",
  styleUrls: ["./ngx-form.component.scss"],
})
export class NgxFormComponent implements OnInit {
  @Input() formJson: any;
  @Input() showSubmit: any;
  @Input() showRemoveButton: any;
  @Input() disabled: boolean;
  @Input() packetDetails: any;
  @Input() actionName: any;
  @Input() actionStatus: any;
  @Input() currActionName: any;
  @Input() isScanned: boolean;
  @Input() formsByActionId: any;
  @Input() selectedPlan: any;
  @Input() wonPlan: any;
  @Input() packetStoneArrayCopy: any;
  @Input() originalPktWt: any;
  @Input() originalPcktLabel: any;
  @Input() packetPlans: any;
  @Input() EditPacketsArray: any;
  @Input() showAccordion: boolean = false;
  form = new FormGroup({});
  fields: FormlyFieldConfig[] = [];
  ACTIONNAMES = ACTIONNAMES;
  @Input() formJsonFormate: any;
  model = {};
  @Input() refreshField: boolean = false;
  @Input() resetForm: boolean = false;
  @Input() reloadForm: boolean = false;
  @Input() showDrawer: boolean = false;
  @Input() hideDrawer: boolean = false;
  @Input() showEdit: boolean;
  @Input() isFormRequired: boolean;
  @Input() showHistory: boolean;
  @Input() showRequest: boolean;
  @Input() curUserFulName: string;
  @Input() yehudaData;
  @Input() intermediatePlansForRecut;
  @Output() formSubmitted: EventEmitter<any> = new EventEmitter();
  @Output() removeFlurBtn: EventEmitter<any> = new EventEmitter();
  @Output() closeDrawerEvt: EventEmitter<any> = new EventEmitter();
  @Output() editHistory: EventEmitter<any> = new EventEmitter();
  @Output() clickofAPlan: EventEmitter<any> = new EventEmitter();
  @Output() checkerFinalPlan: EventEmitter<any> = new EventEmitter();
  @Output() requestPacket: EventEmitter<any> = new EventEmitter();
  @Output() openDrawerEvent: EventEmitter<any> = new EventEmitter();
  @Output() showFinalInspection: EventEmitter<any> = new EventEmitter();

  @ViewChild("drawerDiv") drawerDiv: ElementRef;
  @Input() currentSegment: string;
  isEditHist: any = false;
  historyStatus: string;
  segmentName: any = "";
  // ss: any = [];
  public BIFURCATION = ["3P Cutting", "Rough Cutting", "4P Process", ACTIONNAMES.recut];
  isFormShown: boolean;
  attachments: any = [];
  expandedIndex = 0;

  selectedRowData: any;
  packetParamHistory$: Observable<any>;
  private suscriptionHist: Subscription;
  private suscriptionParameters: Subscription;
  paramValuesSN: Subscription;
  // packetParamHistories: any = [];
  selPacketParamHistory: any = [];
  actionData: any = [];
  paramSelectionList: any = {};
  dropdownSelValues: any = {};
  currentSelHistory: any = {};
  packetId: any;
  selectedOption: any = [];
  selectedOptionObject: any = [];
  isUpdateDisable: boolean = true;
  isRowSelected: boolean = false;
  isUnProcessedCheck: boolean = false;
  isGalaxyFileForQc: boolean = false;
  isFinalOptionMap: boolean = false;
  isSave: boolean = false;
  // isSave:boolean=false;
  alertCtrl: any;
  savedPlans: any = [];
  isVirtualTask: boolean = false;
  unProcessCheckBox: boolean = false;
  QcRemarks: string = "";
  actionId: any;

  @HostListener("document:click", ["$event"])
  clickout(event) {
    if (this.elementRef.nativeElement.contains(event.target)) {
      // console.log('Clicked inside');
    } else {
      console.log("Outside");
    }
  }

  @HostListener("document:keydown.escape", ["$event"]) onKeydownHandler(evt: KeyboardEvent) {
    this.closeDrawer();
  }

  constructor(
    private store: Store<AppState>,
    private elementRef: ElementRef,
    private modalController: ModalController,
    private httpService: HttpService,
    public configService: ConfigService,
    public modalCtrl: ModalController,
    public router: Router
  ) {}

  segmentChanged(event) {
    console.log("segment Changed....");
    this.segmentName = event.detail.value;
    console.log(this.segmentName);
    // if (this.segmentName === this.currentSegment) {
    //   console.log(this.segmentName);
    // } else {
    //   console.log(this.segmentName);
    // }
  }

  // ngxFormlySelectionChanged(fields, event){
  //   console.log(fields)
  //   console.log(event)
  //   debugger
  // }

  async ngOnChanges(changes: any) {
    const actions = [ACTIONNAMES.generateReport, ACTIONNAMES.return, ACTIONNAMES.transfer, ACTIONNAMES.managerTrasnfer, ACTIONNAMES.thirdPartyTrf, ACTIONNAMES.mistakeEntry];
    if (actions.includes(this.currActionName)) {
      return;
    }
    this.isUnProcessedCheck = false;
    console.log("ngOnChanges");
    if (changes?.yehudaData?.currentValue) {
      console.log(this.yehudaData);
    }
    console.log(this.selectedPlan);
    console.log(this.isFormShown);
    console.log(this.showDrawer);
    if (this.formJson) {
      if (!this.formJsonFormate) this.formJsonFormate = this.fields?.[0];
      if ( (this.actionName == "4P Process" ) || (this.actionName === "Cutting" && this.currActionName == "Auto Receive") || this.BIFURCATION.includes(this.currActionName)) {
        this.fields = this.formJson.map((fjdt: any, index: number) => {
          return {
            ...fjdt,
            fieldGroup: [
              ...(fjdt?.fieldGroup || []),
              {
                type: "delete-button",
                templateOptions: {
                  text: "With Function",
                  click: $event => this.deletePacket(fjdt.key),
                },
              },
            ],
          };
        });
        this.fields.push({
          fieldGroup: [
            {
              type: "add-button",
              templateOptions: {
                text: "With Function",
                click: $event => this.addPacket(),
              },
            },
          ],
        });
      }
      // else if(this.currActionName == 'Edit Bifurcated Packets'){
      //   this.fields.push({
      //     fieldGroup: [{
      //       type: 'add-button',
      //       templateOptions: {
      //         text: 'With Function',
      //         click: ($event) => '',
      //       },
      //     }]
      //   });
      // }
      else {
        this.fields = this.formJson;
      }
    }
    if (!!changes.packetDetails && !!changes.packetDetails.currentValue) {
      this.isEditHist = false;
      console.log(changes.packetDetails.currentValue);
      let rowData = changes.packetDetails.currentValue;
      this.historyStatus = null;
      this.historyStatus = rowData.historyStatus;
      console.log(this.historyStatus);
      this.selectedRowData = !!changes.packetDetails && !!changes.packetDetails.currentValue ? rowData : null;
      this.isVirtualTask = !!rowData.inputDetails_virtual && rowData.inputDetails_virtual.length > 0 ? true : false;
      console.log(this.isVirtualTask);
      let values =
        !!rowData.inputDetails && this.historyStatus == "Completed"
          ? rowData.inputDetails
          : !!rowData.inputDetails_virtual && rowData.inputDetails_virtual[0] && !!rowData.inputDetails_virtual[0].details
          ? rowData.inputDetails_virtual[0].details
          : [];
      this.getAttachments(values);

      if (rowData.currActionName == "Planning") {
        this.savedPlans = values;
        this.isSave = true;
      }
      // parametershistory
      let packetId = this.selectedRowData.id;
      console.log(packetId);
      this.packetId = this.selectedRowData.packetId;
      if (!!packetId) {
        this.store.dispatch(masterDataActions.getParameterHistory({ packetId: packetId, currUserFullName: this.curUserFulName }));
      }
      this.packetParamHistory$ = this.store.pipe(select(getPacketParameterHistoryData));
      // this.suscriptionHist = this.store.pipe(select(getPacketParameterHistoryData)).subscribe(history => {
      //   if (history) {
      //     console.log('history');
      //     console.log(history);
      //     this.packetParamHistories = [];
      //     for (let index = 0; index < history.length; index++) {
      //       const d = history[index];
      //       const m = { ...d };
      //       if (m.historyStatus == 'Completed') {
      //         this.packetParamHistories.push(d);
      //       }
      //     }
      //     console.log(this.packetParamHistories);

      //   }
      // });
    } else {
      this.currentSelHistory = {};
      if (!!this.suscriptionHist) {
        this.suscriptionHist.unsubscribe();
      }
    }
    if (!!changes.refreshField) {
      if (changes.refreshField.currentValue) {
        if (!this.formJsonFormate) this.formJsonFormate = this.fields?.[0];
        // debugger
        // this.form = new FormGroup({});
        // this.fields.push(this.formJson)
        // this.form.get('total').setValue(total);

        for (let key in this.fields) {
          let i: any = this.fields[key];
          console.log(this.fields[key]);
          // if (i['key'].indexOf('Packet') > -1) {
          //   return;
          // }
          if (i.defaultValue && i.key !== "file") {
            let val = i.defaultValue;
            console.log(this.form);
            console.log(i["key"]);
            console.log(this.form.get(i["key"]));
            setTimeout(() => {
              this.form.get(i["key"]).setValue(val);
              if (i.formControl.value) {
                // i.templateOptions.disabled = true;
              } else {
                i.templateOptions.disabled = false;
              }
            }, 300);
            // if (!!this.form.get(i['key'])) {
            //   this.form.get(i['key']).setValue(val);
            // }
          }
        }
      }
    }

    if (!!changes.showDrawer) {
      if (changes.showDrawer.currentValue) {
        this.openDrawer();
      }
    }
    if (!!changes.hideDrawer) {
      if (changes.hideDrawer.currentValue) {
        this.closeDrawer();
      }
    }
    if (!!changes.resetForm) {
      if (changes.resetForm.currentValue) {
        this.form.reset();
      }
    }

    if (!!changes.reloadForm) {
      if (!!changes.reloadForm.currentValue) {
        if (!this.formJsonFormate) this.formJsonFormate = this.fields?.[0];
        this.form = new FormGroup({});
        if ((this.actionName === "Cutting" && this.currActionName == "Auto Receive") || this.BIFURCATION.includes(this.currActionName)) {
          this.fields = this.formJson.map((fjdt: any, index: number) => {
            return {
              ...fjdt,
              fieldGroup: [
                ...fjdt.fieldGroup,
                {
                  type: "delete-button",
                  templateOptions: {
                    text: "With Function",
                    click: $event => this.deletePacket(fjdt.key),
                  },
                },
              ],
            };
          });
          this.fields.push({
            fieldGroup: [
              {
                type: "add-button",
                templateOptions: {
                  text: "With Function",
                  click: $event => this.addPacket(),
                },
              },
            ],
          });
        } else if (this.currActionName == "Edit Bifurcated Packets") {
          this.fields.push({
            fieldGroup: [
              {
                type: "add-button",
                templateOptions: {
                  text: "With Function",
                  click: $event => this.addPacket(),
                },
              },
            ],
          });
        } else {
          this.fields = this.formJson;
        }

        if (this.fields.length > 0) {
          console.log(this.fields);
          for (let key in this.fields) {
            //
            // console.log(planTypeFiled)
            let field = this.fields[key];
            let i;
            if (field?.key) i = await this.assignOptions(field);
            //
            // let final = this.fields[0].fieldGroup.find(x => x.key == "Packet-Plan-Type-1")
            // console.log(final)
            // debugger
            // final.templateOptions.change  = ($event) => this.ngxFormlySelectionChanged($event);
          }
        }
      }
    }
    if (this.selectedRowData && !!this.selectedRowData.inputDetails_virtual && this.selectedRowData.inputDetails_virtual.length > 0) {
      this.historyStatus = "Completed";
    }
    console.log("this.selectedRowData");
    console.log(this.selectedRowData);
  }

  deletePacket(key: any) {
    if (!this.formJsonFormate) this.formJsonFormate = this.fields?.[0];
    // this.fields = this.fields.map((dt:any, i:number)=>{
    //   if(i>key){
    //     return dt
    //   } return dt;
    // })

    const selectedOptionKey = this.formJson?.find((dt: any) => dt?.key == key)?.fieldGroup?.find((fg: any) => fg?.key?.includes("Packet-Plan-StoneId"))?.key;
    if (selectedOptionKey) this.selectedOption = this.selectedOption.filter((dt: any) => dt.key != selectedOptionKey);

    this.fields = this.fields
      .filter((dt: any, i: number) => dt.key !== key)
      .map((dt: any, i: number) => ({ ...dt, templateOptions: { ...dt.templateOptions, label: `${this.formJsonFormate.templateOptions.label} ${i + 1}` } }));
    this.formJson = this.formJson
      .filter((dt: any, i: number) => dt.key !== key)
      .map((dt: any, i: number) => ({ ...dt, templateOptions: { ...dt.templateOptions, label: `${this.formJsonFormate.templateOptions.label} ${i + 1}` } }));
    this.form.removeControl(key);

    this.fields = this.fields.map((field: any) => {
      if (field?.templateOptions) {
        field.fieldGroup = field.fieldGroup.map((fgdt: any) => {
          if (fgdt?.key?.includes("Packet-Plan-StoneId")) {
            let opArray = this.packetStoneArrayCopy.filter((psac: any) => {
              const SELECTED_OPTION = this.selectedOption.find((so: any) => so.value == psac.value);
              if (SELECTED_OPTION) {
                if (SELECTED_OPTION.key == fgdt.key) return true;
                return false;
              } else {
                return true;
              }
            });
            fgdt.templateOptions = { ...fgdt.templateOptions, options: [...opArray] };

            // let opArray = this.packetStoneArrayCopy.filter((psac:any)=>(!(this.selectedOption.filter((so:any)=>{debugger; return((so.value==psac.value)&&(so.key!=fgdt.key))})?.length)));
            // fgdt.templateOptions.options = fgdt.templateOptions = { ...fgdt.templateOptions.templateOptions, options: [...opArray] };
          }
          return fgdt;
        });
      }
      return field;
    });
    this.ngxFormlyModalChanged(this.form.value);
  }

  alpha: any = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  getHexValue(a) {
    a += 1;
    var c = 0;
    var x = 1;
    while (a >= x) {
      c++;
      a -= x;
      x *= 26;
    }
    var s = "";
    for (var i = 0; i < c; i++) {
      s = this.alpha.charAt(a % 26) + s;
      a = Math.floor(a / 26);
    }
    return s;
  }

  fieldsLength: number = 0;
  async addPacket() {
    this.fieldsLength++;

    if (this.fields?.length > this.fieldsLength) this.fieldsLength = this.fields.length;
    if (!this.formJsonFormate) this.formJsonFormate = this.fields?.[0];
    // this.fields.push(this.formJsonFormate);
    const mainObject = { ...this.formJsonFormate };
    let newObj = mainObject;
    const key = `${mainObject.key}`;
    const label = `${mainObject.templateOptions.label}`;
    let objMainKey = `${key} ${this.fieldsLength}`;
    newObj["key"] = objMainKey;
    let newLable = `${label} ${this.fieldsLength}`;
    let planType = await this.httpService.getPlanTypes();
    const packetPlansStones = this.packetPlans?.[0]?.["packetPlanStones"]?.map((e, index) => {
      let obj = { ...e };
      console.log(e);
      let planTag = this.getHexValue(index);
      let exppol = e.expPol;
      let rhtwt = e.sawRghWgt;
      let shape = e.shape;
      let newobj = {
        label: planTag + "    " + rhtwt + "    " + shape + "    " + exppol,
        value: obj.id,
      };
      return newobj;
    });
    // newObj['templateOptions']['label'] = newLable
    let newLableObj = {
      label: newLable,
    };
    let newFieldGroudp = [...mainObject.fieldGroup].map(x => {
      let l = { ...x };
      l["key"] = `${l.key}-${this.fields.length}`;
      if (x.type == "select" && x.key == "Packet-Plan-Type") {
        l["templateOptions"] = { ...l["templateOptions"], options: this.currActionName == "Edit Bifurcated Packets" ? planType.filter(pt => pt.label == "By Product") : planType };
      } else if (x.type == "select" && x.key == "Packet-Plan-StoneId") {
        l["templateOptions"]["options"] = packetPlansStones;
      }
      l["templateOptions"] = { ...l["templateOptions"], ["disabled"]: false };
      return l;
    });

    // this.formJson.push({
    //   ...newObj,
    //   templateOptions: newLableObj,
    //   fieldGroup: newFieldGroudp,
    // });
    this.formJson = [...this.formJson, { ...newObj, templateOptions: newLableObj, fieldGroup: newFieldGroudp }];

    newFieldGroudp = newFieldGroudp.filter((dt: any) => dt.type !== "delete-button"); // removing extra delete buttons

    this.fields.splice(this.fields.length - 1, 0, {
      ...newObj,
      templateOptions: newLableObj,
      fieldGroup: [
        ...newFieldGroudp,
        {
          type: "delete-button",
          templateOptions: {
            text: "With Function",
            click: $event => this.deletePacket(newObj.key),
          },
        },
      ],
    });
    this.fields = this.fields
      .map((dt: any) => dt)
      .map((dt: any, i: number) => ({ ...dt, templateOptions: { ...dt.templateOptions, label: `${this.formJsonFormate.templateOptions.label} ${i + 1}` } }));
    this.formJson = this.formJson
      .map((dt: any) => dt)
      .map((dt: any, i: number) => ({ ...dt, templateOptions: { ...dt.templateOptions, label: `${this.formJsonFormate.templateOptions.label} ${i + 1}` } }));

    this.fields = this.fields.map((field: any) => {
      if (field?.templateOptions) {
        field.fieldGroup = field.fieldGroup.map((fgdt: any) => {
          if (fgdt?.key?.includes("Packet-Plan-StoneId")) {
            let opArray = this.packetStoneArrayCopy.filter((psac: any) => {
              const SELECTED_OPTION = this.selectedOption.find((so: any) => so.value == psac.value);
              if (SELECTED_OPTION) {
                if (SELECTED_OPTION.key == fgdt.key) return true;
                return false;
              } else {
                return true;
              }
            });
            fgdt.templateOptions = { ...fgdt.templateOptions, options: [...opArray] };

            // let opArray = this.packetStoneArrayCopy.filter((psac:any)=>(!(this.selectedOption.filter((so:any)=>{debugger; return((so.value==psac.value)&&(so.key!=fgdt.key))})?.length)));
            // fgdt.templateOptions.options = fgdt.templateOptions = { ...fgdt.templateOptions.templateOptions, options: [...opArray] };
          }
          return fgdt;
        });
      }
      return field;
    });
  }

  onUnProcessesClick(event) {
    this.isUnProcessedCheck = event?.detail?.checked;
    if (this.isUnProcessedCheck) {
      this.isSave = true;
      this.form.disable();
    } else {
      this.isSave = true;
      this.form.enable();
    }
  }

  onGalaxyFileForQc(event) {
    this.isGalaxyFileForQc = event?.detail?.checked;
    if (this.isGalaxyFileForQc) {
      this.isSave = true;
      this.form.disable();
      this.configService.showToast("error", "You Cannot Upload File Now Please Add Remark");
    } else {
      this.isSave = true;
      this.form.enable();
    }
  }

  async assignOptions(field) {
    return new Promise((resolve, reject) => {
      console.log(field);
      if (field && !!field.fieldGroup) {
        let planTypeFiled = field.fieldGroup.find((x: any) => x.key?.includes("Packet-Plan-Type"));
        console.log(planTypeFiled);
        let packetStonePlan = field.fieldGroup.find((x: any) => x.key?.includes("Packet-Plan-StoneId"));
        console.log(planTypeFiled && packetStonePlan);
        if (!!planTypeFiled) {
          planTypeFiled.templateOptions.change = $event => this.ngxFormlySelectionChanged($event);
          packetStonePlan.templateOptions.change = $event => this.ngxFormlyPacketStoneSelectionChanged($event);
          resolve(true);
        } else {
          reject(false);
        }
      }
    });
  }

  async ngxFormlySelectionChanged(event) {
    console.log(event.formControl.value);
    if (!!event.formControl.value || event.formControl.value == 0) {
      let fieldGroupsArray = this.fields.find(x => x.fieldGroup.find(y => y.key == event.key));
      console.log(fieldGroupsArray);

      let packetPlanFiled = await fieldGroupsArray.fieldGroup.find((x: any) => x.key.includes("Packet-Plan-StoneId"));
      console.log(packetPlanFiled);
      let ids = (await this.httpService.getPlanTypes()).filter(x => x.label == "Final Planning" || x.label == "Tops" || x.label == "Rough Return").map(obj => obj.value);
      console.log(ids);
      if (ids?.includes(event.formControl.value)) {
        packetPlanFiled.formControl.enable();
      } else {
        packetPlanFiled.formControl.setValue("");
        packetPlanFiled.formControl.disable();
      }
    }
  }

  ngxFormlyPacketStoneSelectionChanged(event) {
    this.selectedOption = this.selectedOption?.filter((so: any) => so.key !== event.key);
    this.selectedOption?.push({
      key: event.key,
      value: event.formControl.value,
    });
    this.selectedOptionObject[event.key] = event?.formControl?.value;
    // console.log("field---packet stone---->",field);
    // this.fields.map((field, index) => {
    //   this.selectedOption.map(op => {
    //     let planStoneIndex = field.fieldGroup.findIndex(fg => fg.key != op.value)
    //     if (planStoneIndex) {
    //       if(op.key == field['fieldGroup'][planStoneIndex].key){}
    //       field['fieldGroup'][planStoneIndex].templateOptions = { ...field?.['fieldGroup']?.[planStoneIndex].templateOptions, options: [...this.packetStoneArrayCopy] };
    //       let opArray: any = field.fieldGroup[planStoneIndex].templateOptions.options;
    //       let index = opArray.findIndex(op => op.value == op.value)
    //       if (index > -1) {
    //         opArray.splice(index, 1);
    //         field['fieldGroup'][planStoneIndex].templateOptions = { ...field?.['fieldGroup']?.[planStoneIndex].templateOptions, options: [...opArray] };
    //       }
    //     }
    //   })
    // })

    this.fields = this.fields.map((field: any) => {
      if (field?.templateOptions) {
        console.log(field.fieldGroup[0].key);
        let packetPlanFiled = field?.fieldGroup?.find((x: any) => x?.key?.includes("Packet-Plan-StoneId"));
        field.fieldGroup = field?.fieldGroup?.map((fgdt: any) => {
          if (fgdt?.key?.includes("Packet-Plan-StoneId")) {
            let opArray = this.packetStoneArrayCopy?.filter((psac: any) => {
              const SELECTED_OPTION = this.selectedOption?.find((so: any) => so.value == psac.value);
              if (SELECTED_OPTION) {
                if (SELECTED_OPTION.key == fgdt.key) return true;
                return false;
              } else {
                return true;
              }
            });
            if (opArray.length == 1) {
              this.isFinalOptionMap = true;
            }
            fgdt.templateOptions = { ...fgdt.templateOptions, options: [...opArray] };

            // let opArray = this.packetStoneArrayCopy.filter((psac:any)=>(!(this.selectedOption.filter((so:any)=>{debugger; return((so.value==psac.value)&&(so.key!=fgdt.key))})?.length)));
            // fgdt.templateOptions.options = fgdt.templateOptions = { ...fgdt.templateOptions.templateOptions, options: [...opArray] };
          } else if (fgdt?.key?.includes("Packet-Plan-Type")) {
            if (fgdt.key == "Intermediate planning") packetPlanFiled.formControl.disable();
          }
          return fgdt;
        });
      }
      return field;
    });

    // for (let key in this.form.value) {
    //   this.form.value[key]
    // for (let valKey in this.form.value[key]) {
    // this.selectedOption.forEach(selected =>{
    // if (valKey.includes('Packet-Plan-StoneId') && valKey !== selected.key) {
    // this.fields.map(field => {
    //   if (field?.templateOptions) {
    //     let keyIndex;
    //     if (valKey.includes('Packet-Plan-StoneId')) {
    //       keyIndex = field.fieldGroup.findIndex(fg => fg.key == valKey)
    //       if (keyIndex > -1) {
    //         if(valKey !== selected.key){
    //         field['fieldGroup'][keyIndex].templateOptions = { ...field?.['fieldGroup']?.[keyIndex].templateOptions, options: [...this.packetStoneArrayCopy] };
    //         }
    //         // field.fieldGroup[keyIndex].templateOptions.options = [...this.packetStoneArrayCopy]
    //           let opArray: any = field.fieldGroup[keyIndex].templateOptions.options
    //           let index = opArray.findIndex(op => op.value == selected.value)
    //           if (index > -1) {
    //             opArray.splice(index, 1);
    //             field['fieldGroup'][keyIndex].templateOptions = { ...field?.['fieldGroup']?.[keyIndex].templateOptions, options: [...opArray] };
    //           }
    //         }
    //       }
    //     }
    //   })
    // }
    // })
    // }
    // }

    // let selectedIndex = this.packetStoneArrayCopy.findIndex(val => val.value == event.formControl.value)
    // if(selectedIndex > -1){
    //   this.packetStoneArrayCopy.splice(selectedIndex,1);
    // }
  }

  closeDrawer() {
    const style = this.drawerDiv?.nativeElement?.style;
    style.width = 0;
    console.log("Bye..");
    if (this.isFormShown) {
      this.isFormShown = false;
      this.closeDrawerEvt.emit();
    }
  }

  openDrawer() {
    console.log(this.drawerDiv);
    let screenWith = innerWidth;
    console.log(screenWith);
    let formWidth = 350;
    if (screenWith >= 1281) {
      formWidth = 700;
    } else if (screenWith >= 1025) {
      formWidth = 590;
    } else if (screenWith >= 768) {
      formWidth = 392;
    } else {
      formWidth = 350;
    }
    if (this.drawerDiv) {
      const style = this.drawerDiv.nativeElement.style;
      style.width = formWidth + "px";
      // if(this.actionName === 'Cutting')
      // style.width = '100%';
      // this.drawerDiv.nativeElement.classList.add("actionForm");//now adding width by screen media quries
      this.isFormShown = true;
      this.openDrawerEvent.emit(formWidth);
    }
  }

  ngOnInit() {
    if (this.formJson) {
      this.fields = this.formJson;

      if (this.fields.length > 0) {
        console.log(this.fields);
        this.fields[0].templateOptions.clickEvent = $event => console.log("called");
      }
    }
    console.log(this.fields);
    console.log("OnInit");
    if (!!this.selectedPlan && this.selectedPlan.length > 0) this.isSave = true;
  }

  async onSubmit(model) {
    // this.disabled = true;
    if (this.isUnProcessedCheck) {
      this.sendStoneTounProcessedPacket(this.packetDetails?.id);
    }
    console.log(this.isScanned);
    if (this.actionName == "Chapka" && model.Chapka >= this.packetDetails.cts) {
      this.configService.showToast(false, "Loss Weight Is More Then Packet Cts");
    } else {
      if (!!this.selectedPlan && this.selectedPlan.length > 0) {
        this.formSubmitted.emit(this.form.value);
      } else {
        if (this.form.valid) {
          if (this.actionName == "Cutting" || this.BIFURCATION.includes(this.currActionName)) {
            let totalCts = 0;
            for (let key in this.form.value) {
              this.form.value[key];
              for (let valKey in this.form?.value?.[key]) {
                if (valKey.includes("Packet-Weight-")) {
                  totalCts += Number(this.form.value[key][valKey]);
                }
              }
            }
            if (this.currActionName == "Edit Bifurcated Packets") {
              let orgwt = 0;
              this.EditPacketsArray.map(epa => {
                orgwt += epa.cts;
              });
              if (totalCts > orgwt && this.actionName != "Manual Weight Change") {
                this.configService.showToast("error", "Total weight should be less than total CTS");
                return;
              } else {
                this.formSubmitted.emit(this.form.value);
                this.closeDrawer();
              }
            } else {
              let isReject = false;
              if (this.isFinalOptionMap) {
                let orgTenper = this.packetDetails.cts * (10 / 100);
                let curTotal = totalCts + this.intermediatePlansForRecut?.totalWt;
                let calLoss = this.packetDetails.cts - curTotal;
                if (calLoss >= orgTenper) {
                  isReject = true;
                }
              }
              if (isReject) {
                // await this.httpService.fetchDataUsingPromise(
                //   true,
                //   ENDPOINTS.generateTaskForReject + this.packetDetails?.id
                // );
                const modal = await this.modalController.create({
                  component: TrybemodalComponent,
                  componentProps: {
                    message: "Loss is very High!",
                    // data: data,
                  },
                  cssClass: "trybe_modal_print",
                });
                modal.onDidDismiss().then((d: any) => {});
                return await modal.present();
              } else if (totalCts > this.packetDetails?.cts && this.actionName != "Manual Weight Change") {
                this.configService.showToast("error", "Total weight should be less than total CTS");
                return;
              } else {
                this.formSubmitted.emit(this.form.value);
                this.closeDrawer();
              }
            }
            // let packetweightSum = 0;
            // Object.keys(model).map((pkt,index) => {
            //   if(model[pkt]['Packet-Weight-'+index]){
            //     packetweightSum += Number(model[pkt]['Packet-Weight-'+index])
            //   }
            // })
            // // let packetweightSum = Number(model['Packet 1']['Packet-Weight-1']) + Number(model['Packet 2']['Packet-Weight-2'])
            // if (this.packetDetails.cts >= packetweightSum) {
            //   this.formSubmitted.emit(this.form.value)
            //   console.log("show the modal.........................");

            //   this.closeDrawer()
            // } else {
            //   this.configService.showToast('error', `weight shouldn't be greater than saw rgh wt`);
            // }
          } else {
            let totalCts = 0;
            for (let key in this.form?.value) {
              // this.form.value[key]
              if (this.form.value[key] == 0 || this.form.value[key] == " " || this.form.value[key] == null) {
                this.configService.showToast("error", "Weight can not be 0 or empty");
                return;
              }
              if (key.toLowerCase() == "weight") {
                totalCts += Number(this.form.value[key]);
              }
            }
            if (totalCts > this.packetDetails?.cts && this.actionName != "Manual Weight Change") {
              this.configService.showToast("error", "Total weight should be less than total CTS");
              return;
            } else {
              this.formSubmitted.emit(this.form.value);
              this.closeDrawer();
            }
          }
        }
        if (this.currActionName === "Qc Check And Verify" && this.isGalaxyFileForQc && !!this.QcRemarks) {
          this.formSubmitted.emit(this.QcRemarks);
          this.closeDrawer();
        } else if (this.currActionName === "Qc Check And Verify" && this.isGalaxyFileForQc && this.QcRemarks === "") {
          this.configService.showToast("error", "Add Remarks To Save");
        }
      }
    }
  }

  async sendStoneTounProcessedPacket(id: Number) {
    let payload = {
      packetIds: [id],
    };
    let response = await this.httpService.postDataUsingPromise(true, "packet/markPacketAsUnproccessedForAutoReceive", payload);
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      this.closeDrawer();
      this.store.dispatch(AutoReceiveAction.getAutoReceivePacket());
      let data = response.message;
      this.configService.showToast("success", data);
    } else {
      console.error(response);
      this.configService.showToast("error", response.error.message);
    }
  }

  async showVision360(type) {
    console.log("showVision360");
    console.log(this.packetDetails);
    const modal = await this.modalCtrl.create({
      component: CopyplanmodalComponent,
      cssClass: "tension-photo",
      componentProps: {
        message: type,
        data: this?.packetDetails,
      },
    });
    return await modal.present();
  }

  removeFlur() {
    this.removeFlurBtn.emit(this.form.value);
    this.closeDrawer();
  }
  getAttachments(values) {
    if (!!values) {
      this.attachments = [];
      values.forEach(d => {
        if (!!d["url"]) {
          let url = d.url;
          let type = "link";
          let docName = url.substring(url.lastIndexOf("/") + 1);
          // d['docName'] = d['docName'] === undefined ? docName : "";
          docName = !!docName ? decodeURIComponent(docName) : "-";
          console.log(docName);
          this.attachments.push({ id: d.id, name: docName, url: url });
          this.isSave = true;
        }
      });
    }
    console.log(this.attachments);
  }
  async onEditHistory(rowData: any) {
    console.log(rowData);
    if (rowData.actionId == 149) {
      this.clickofAPlan.emit(this.savedPlans);
    } else {
      await this.editHistoryParameters(rowData);
    }
  }
  async editHistoryParameters(currHistory) {
    this.dropdownSelValues = {};
    this.currentSelHistory = {};
    let fieldsToEdit = [];
    this.selPacketParamHistory = [];
    this.isEditHist = true;
    this.selPacketParamHistory.push(currHistory);
    // this.isUpdateDisable = true;
    console.log("Edit History");
    console.log(this.formsByActionId);
    // let formJSON = this.formsByActionId[currHistory.actionId].listOfForms[0];
    // let obj = JSON.parse(formJSON.formData);
    // let currForm = obj.form;
    // let currjson = obj.inputJson;
    // let updatedForm = obj.inputJson;
    let ch = { ...currHistory };
    this.currentSelHistory = ch;
    let inputValues = [];
    if (this.isEditHist) {
      let parameters = !!ch && !!ch.inputDetails ? ch.inputDetails : [];
      console.log(parameters);
      parameters.forEach((p, k) => {
        if (!p.url && !p.paramBValue) {
          this.suscriptionParameters = this.store.pipe(select(getParamsValuesListByName(p.paramName.toUpperCase()))).subscribe(data => {
            if (!!p.paramName && !!data) {
              this.paramSelectionList[p.paramName] = [];
              let newData = [];
              data.forEach(d => {
                if (!!d) {
                  newData.push({ label: d.name, value: d.id });
                  // if (this.paramSelectionList[p.paramName].length === 0) {
                  //   this.paramSelectionList[p.paramName].push({label:d.name,value:d.id})
                  // } else if{
                  //   if (condition) {

                  //   }
                  // }
                }
              });
              this.paramSelectionList[p.paramName] = newData;
              if (ch.dropdowns == undefined) {
                ch.dropdowns = this.paramSelectionList;
              }
            }
          });
        } else {
        }
        console.log(this.paramSelectionList);

        let objField = {};
        if (!!p.url) {
          let docName = p.url.substring(p.url.lastIndexOf("/") + 1);
          if (this.dropdownSelValues["file"] == undefined) {
            this.dropdownSelValues["file"] = {};
            this.dropdownSelValues["file"] = {
              valId: p.paramIValue || p.paramBValue || p.paramDValue || p.paramSValue,
              url: p.url,
              name: docName,
              paramName: "file",
              parentParamName: p.paramName,
              id: p.id,
              actionId: ch.actionId,
            };
            objField = {
              key: "file",
              id: "formly_1_Edit_file_" + k,
              type: "file",
              templateOptions: {
                label: "",
                placeholder: "Attach File",
                disabled: false,
                focus: false,
              },
            };
            fieldsToEdit.push(objField);
          }
          inputValues.push(p);
        } else {
          if (p.paramIValue || p.paramDValue || p.paramSValue) {
            if (this.dropdownSelValues[p.paramName] == undefined) {
              this.dropdownSelValues[p.paramName] = {};
              this.dropdownSelValues[p.paramName] = {
                valId: p.paramIValue || p.paramDValue || p.paramSValue,
                paramName: p.paramName,
                parentParamName: p.paramName,
                id: p.id,
                actionId: ch.actionId,
              };
            }
            if (!!this.paramSelectionList[p.paramName] && this.paramSelectionList[p.paramName].length !== 0 && !!p.paramName) {
              console.log(this.paramSelectionList[p.paramName]);
              objField = {
                key: p.paramName,
                defaultValue: p.paramIValue || p.paramDValue || p.paramSValue,
                id: "formly_1_Edit_select_" + k,
                type: "searchSelect",
                templateOptions: {
                  label: p.paramName,
                  placeholder: "Select " + p.paramName,
                  disabled: false,
                  // focus: false
                  options: this.paramSelectionList[p.paramName],
                },
              };
              fieldsToEdit.push(objField);
            } else {
              let value = p.paramIValue || p.paramDValue || p.paramSValue;
              if (this.fields.length > 0) {
                let obj = this.fields.find(x => x.key == p.paramName);
                if (!!obj && !!obj.templateOptions) {
                  obj.templateOptions.disabled = false;
                  fieldsToEdit.push(obj);
                }
              }
            }
          } else {
            this.dropdownSelValues[p.paramName] = {
              valId: p.paramBValue,
              paramName: p.paramName,
              parentParamName: p.paramName,
              id: p.id,
              actionId: ch.actionId,
            };
            objField = {
              key: p.paramName,
              defaultValue: p.paramBValue,
              id: "formly_5_checkbox_" + p.paramName + "_" + k,
              type: "checkbox",
              templateOptions: {
                label: p.paramName,
                placeholder: "",
                disabled: false,
                required: true,
                focus: false,
                hideFieldUnderline: true,
                indeterminate: true,
                floatLabel: "always",
                hideLabel: true,
                align: "start",
                color: "accent",
              },
              _keyPath: {
                key: p.paramName,
                path: [p.paramName],
              },
            };
            fieldsToEdit.push(objField);
          }
        }
      });
      console.log(this.isEditHist);
      console.log(fieldsToEdit);
      console.log(this.selectedRowData);
      console.log(this.attachments);
      if (fieldsToEdit.length !== 0) {
        this.fields = fieldsToEdit;
        this.currentSegment = "Forms";
        this.getAttachments(inputValues);
      }
    }
  }

  onInputChange(event: any) {
    this.QcRemarks = event.target.value;
  }
  updateHistory() {
    // return
    let data = {};
    let currentActionId = 0;
    currentActionId = this.currentSelHistory.actionId;
    let formType = "";
    if (this.isEditHist) {
      this.isEditHist = false;
      // this.closeDrawer();
      let listOfAllPackets = [];
      console.log(this.isEditHist);
      console.log(this.selectedRowData);
      console.log(this.dropdownSelValues);
      if (this.dropdownSelValues.hasOwnProperty("file")) {
        data = {};
        listOfAllPackets.push({ packetId: this.selectedRowData.id, listOfParameter: [] });
      } else {
        data = {};
        data = {
          listOfAllPackets: [],
        };
        if (!!this.selectedRowData && !!this.selectedRowData.id && !!this.currentSelHistory.inputDetails && this.currentSelHistory.inputDetails.length > 0) {
          data["listOfAllPackets"].push({ packetId: this.selectedRowData.id, listOfParameter: [] });
        }
      }
      for (let key in this.fields) {
        let j: any = this.fields[key];
        console.log(this.fields[key]);
        let selectedVal = "";
        let currentVal = 0;
        if (!!this.dropdownSelValues[j.key] && !!this.dropdownSelValues[j.key].valId && j.key != "file") {
          selectedVal = this.dropdownSelValues[j.key].valId;
          if (this.form.value[j.key] !== selectedVal) {
            currentVal = this.form.value[j.key];
            if (j.formControl.value) {
              j.templateOptions.disabled = true;
            } else {
              j.templateOptions.disabled = false;
            }
            this.isUpdateDisable = false;
            data["listOfAllPackets"].forEach(obj => {
              let ob = {
                parameterName: this.dropdownSelValues[j.key].paramName,
                inputDetailsId: this.dropdownSelValues[j.key].id,
                parameterValue: currentVal,
              };
              obj["listOfParameter"].push(ob);
            });
            console.log("data");
            console.log(data);
          } else {
            this.isUpdateDisable = true;
          }
        } else {
          console.log("Y or V File");
          console.log(j.key);
          let selectedVal = this.form.value[j.key];
          formType = "Multipart";
          if (!!this.dropdownSelValues[j.key] && !!selectedVal && !!this.dropdownSelValues[j.key].id) {
            currentVal = this.form.value[j.key];
            let selectedFile = selectedVal[0]; //single file
            const formatData = new FormData();
            // const file: File = selectedFile;
            formatData.append("file", selectedFile);
            console.log(formatData);
            listOfAllPackets.forEach(obj => {
              let ob = {
                parameterName: "",
                inputDetailsId: this.dropdownSelValues[j.key].id,
                parameterValue: "",
              };
              obj["listOfParameter"].push(ob);
            });
            formatData.append("listOfAllPackets", JSON.stringify(listOfAllPackets));
            data = formatData;
          }
        }
      }
      console.log(this.selectedRowData);
      console.log(this.currentSelHistory);
      console.log(this.selectedRowData.id);
      console.log(data);
      console.log(currentActionId);
      console.log(formType);
      if (!!this.selectedRowData.id && !!currentActionId) {
        // setTimeout(() => {
        this.store.dispatch(
          masterDataActions.updateStoneParamater({ packetId: this.selectedRowData.id, parameterDetails: data, actionId: currentActionId, formType: formType, currUserFullName: this.curUserFulName })
        );
        // }, 1000);
        setTimeout(() => {
          let packetIdObj = {
            packetId: this.selectedRowData.packetId,
          };
          console.log(packetIdObj);
          this.store.dispatch(AutoReceiveAction.getScannedPacketDetails({ packetIdObj: packetIdObj }));
        }, 500);
      }
      this.closeDrawer();
    }
  }
  cancelUpdateHistory() {
    console.log("cancel Button triggered");
    // for (let key in this.fields) {
    //   let i: any = this.fields[key];
    //   if (i.defaultValue && i.key !== 'file') {
    //     if (i.formControl.value && this.isEditHist) {
    //       i.templateOptions.disabled = true;
    //     } else {
    //       i.templateOptions.disabled = false;
    //     }
    //   }
    // }
    // this.currentSegment = "paramsHistory";
    this.isEditHist = false;
    this.closeDrawer();
  }

  async deleteAttachment(id) {
    await this.confirmDeleteItem(id);
  }
  formFieldKeys(params: any) {
    for (let key in this.fields) {
      let i: any = this.fields[key];
      console.log(this.fields[key]);
      if (i.defaultValue && i.key !== "file") {
        let val = i.defaultValue;
        console.log("%cTrybe..............", "color:green");
        console.log(val);

        // console.log(val);
        // console.log(this.form.get(i['key']));
        // this.form.get(i['key']).setValue(val);
        if (i.formControl.value && this.isEditHist) {
          i.templateOptions.disabled = true;
        } else {
          i.templateOptions.disabled = false;
        }
      }
    }
  }
  async confirmDeleteItem(item) {
    confirm("Are you sure you want to delete this attachement?", "Warnning").then(result => {
      if (result) {
        console.log(item);
      }
    });
  }
  onPlanClick(d: any) {
    this.clickofAPlan.emit(d);
    console.log(d);
  }
  showCheckerPlan(evt) {
    this.checkerFinalPlan.emit(evt);
  }

  showFinalInspectionPlan(event: any) {
    this.showFinalInspection.emit(event);
  }

  showFinalPlan(p) {
    this.checkerFinalPlan.emit(p);
  }
  requestStones(p) {
    this.requestPacket.emit(this.form);
  }

  async ngxFormlyModalChanged(modal) {
    this.isSave = false;
    let activePacketsArray = this.packetStoneArrayCopy?.filter(psac => !psac.disabled);
    let finalPlanningId = (await this.httpService.getPlanTypes()).find(x => x.label == "Final Planning").value;
    let roughRetunId = (await this.httpService.getPlanTypes()).find(x => x.label == "Rough Return").value;
    let topsId = (await this.httpService.getPlanTypes()).find(x => x.label == "Tops").value;

    // let pckLength = Object.keys(modal).length;
    if (activePacketsArray?.length) {
      let pckArray = Object.keys(modal);
      let finalPlans = pckArray?.filter(
        (pa, i) => modal[pa]["Packet-Plan-Type-" + (i + 1)] == topsId || modal[pa]["Packet-Plan-Type-" + (i + 1)] == roughRetunId || modal[pa]["Packet-Plan-Type-" + (i + 1)] == finalPlanningId
      );

      let intermediateId = (await this.httpService.getPlanTypes()).find(x => x.label == "Intermediate Planning").value;
      let isIntermedite = pckArray.some((pa, i) => {
        if (modal[pa]["Packet-Plan-Type-" + (i + 1)] == intermediateId) {
          return true;
        }
      });
      if (finalPlans?.length == activePacketsArray?.length && !isIntermedite) this.isSave = true;
      else {
        if (isIntermedite) this.isSave = true;
      }
    }
    if (!this.isSave && this.intermediatePlansForRecut?.intermediatePackets && this.currActionName === this.ACTIONNAMES.recut) {
      this.isSave = true;
    }

    // if (!!pckLength && (pckLength == activePacketsArray?.length)) {
    //   this.isSave = true;
    // } else if (!!pckLength && (pckLength > activePacketsArray?.length)) {
    //   let finalPlans = pckArray?.filter((pa, i) => modal[pa]['Packet-Plan-Type-' + (i + 1)] == finalPlanningId);
    //   if (finalPlans?.length > activePacketsArray?.length) this.isSave = false
    //   else this.isSave = true
    //   // if(Object.keys(modal).find((m,i)=> m['Packet-Plan-Type-' + i] == finalPlanningId)){
    //   //   (m['Packet-Plan-StoneId-'])
    //   // }
    // } else if (!!pckLength && (activePacketsArray?.length > pckLength)) {
    //   if (activePacketsArray?.length - pckLength == 1) {
    //     let intermediateId = (await this.httpService.getPlanTypes()).find(x => x.label == 'Intermediate Planning').value
    //     pckArray.find((pa, i) => {
    //       if(modal[pa]['Packet-Plan-Type-' + (i + 1)] == intermediateId)
    //         this.isSave = true
    //     }) //Intermediate planning
    //   }
    // }

    // this.isSave = false;
    //   let activePacketsArray = this.packetStoneArrayCopy?.filter(psac => !psac.disabled)
    //   if(Math.abs(Object.keys(modal).length - activePacketsArray?.length) == 1){
    //     Object.keys(modal).filter(async(m, i) => {
    //       i = i + 1;
    //       let intermediateId = (await this.httpService.getPlanTypes()).find(x => x.label == 'Intermediate Planning').value
    //       if (modal[m]['Packet-Plan-Type-' + i] == intermediateId) //Intermediate planning
    //         this.isSave = true;
    //     })
    //   }else{
    //     this.isSave = true;
    //   }
    // for(let i=0;i<Object.keys(event).length;i++)
    // {
    //   let index=i+1;
    //   let packetName="Packet "+index;
    //   let packetPlanStoneId='Packet-Plan-StoneId-'+index;
    //   if(event[packetName]?.[packetPlanStoneId])
    //   {
    //     for(let j=0;j<Object.keys(event).length;j++)
    //     {
    //       if(i!=j)
    //       {
    //         this.fields[j]['fieldGroup'][3].templateOptions = { ...this.fields[j]?.['fieldGroup']?.[3].templateOptions, options: [...this.packetStoneArrayCopy] };
    //         let array:any=this.fields[j]['fieldGroup']?.[3]?.templateOptions?.options;
    //         let test=[...array];
    //         test.map((item,index)=>{
    //           if(item.value==event[packetName]?.[packetPlanStoneId])
    //           {
    //             test.splice(index,1);
    //             return test
    //           }
    //         })
    //         this.fields[j]['fieldGroup'][3].templateOptions = { ...this.fields[j]?.['fieldGroup']?.[3].templateOptions, options: [...test] };
    //       }
    //     }
    //   }
    // }
  }

  deletePacketBifurcation() {
    confirm("Do you really want to delete this packet?", "Warnning").then(res => {
      let packetId = this.packetDetails?.id;
      console.log("on delete packet---->", res);
      if (res) {
        if (!!packetId) {
          this.httpService.removePacketBifurcation(packetId).subscribe((d: any) => {
            console.log(d);
            if (d.statusCode == 200) {
              this.router.navigate(["./home"]);
              this.configService.showToast("success", d.message);
            } else {
              // this.l.hideSpinner();
              this.configService.showToast("error", d.message);
            }
          });
        }
      }
    });
  }

  ngOnDestroy() {
    console.log("%cngrx form Destroyed", "color:orange;font-size:20px");
    if (!!this.suscriptionHist) {
      this.suscriptionHist.unsubscribe();
    }
    if (!!this.suscriptionParameters) {
      this.suscriptionParameters.unsubscribe();
    }
  }
}
