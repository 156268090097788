import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
//import { ConfigService } from '../service/config.service';
import { ModalController, NavController } from "@ionic/angular";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store, select } from "@ngrx/store";
import { from, throwError } from "rxjs";
import { catchError, concatMap, map, mergeMap, tap, withLatestFrom } from "rxjs/operators";
import { AutoReceiveAction } from "../components/actions/auto-receive/state/auto-receive.action.type";
import { TrybemodalComponent } from "../components/trybemodal/trybemodal.component";
import { SignerAction } from "../components/trybesigner/state/signer.action.type";
import { HomeActions } from "../home/state/home.action-type";
import { routeMasterAPIs } from "../packet-route-master/state/packet-route-master.data";
import { ConfigService } from "../services/config/config.service";
import { HttpService } from "../services/http/http.service";
import { LoaderService } from "../services/loader/loader.service";
import { masterDataActions } from "./masterData.action-type";
import { MasterDataState } from "./masterData.state";
import { findPacketPlanStoneByPacketIdForSmartRecut, getPacketPlans } from "../components/trybesigner/state/signer.actions";
import { chekerAction } from "../components/trybe-checker/state/checker.action.type";
import { actionlist } from "../authStore/auth.selector";
import { myVirtualTaskActions } from "../components/my-virtual-task/state/my-virtual-task.actions.type";
import { virtualAPIs } from "../components/my-virtual-task/state/my-virtual-task.data";
import { ACTIONNAMES } from "../services/endpoint.enum";
@Injectable()
export class MasterDataEffects {
  constructor(
    private navCtrl: NavController,
    private actions: Actions,
    public configService: ConfigService,
    public httpService: HttpService,
    private store: Store<MasterDataState>,
    private modalController: ModalController,
    private loaderService: LoaderService,
    public router: Router
  ) {}

  getParametersList$ = createEffect(() =>
    this.actions.pipe(
      ofType(masterDataActions.getParametersList),
      concatMap(action =>
        from(this.httpService.findByStoneParameterName(action.paramList)).pipe(
          map(res => {
            this.httpService.getErrorAndDisplayIt(res);
            return res.data;
          }),
          catchError(error => {
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {
        if (response && response.error == "Unauthorized") {
          this.httpService.logout();
        }
      }),
      map((data: any) => {
        return masterDataActions.saveParametersList({ paramList: data });
      })
    )
  );

  removeStoneParamater$ = createEffect(() =>
    this.actions.pipe(
      ofType(masterDataActions.removeStoneParamater),
      tap((x: any) => {
        // this.httpService.showLoader();
      }),
      concatMap(action =>
        from(this.httpService.removeParam(action.parameterDetails)).pipe(
          map(res => {
            if (res.statusCode === 200) {
              // this.showModal(true, res.message, {});
              this.configService.showToast("success", res.message);
            } else {
              // this.configService.showToast("error", res.message);
            }
            this.httpService.getErrorAndDisplayIt(res);
            return action;
          }),
          catchError(error => {
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {}),
      // map((data: any) => {
      //     console.log(data)
      //     return HomeActions.parameterRemoved({ details: data });

      // })
      mergeMap((data: any) => {
        console.log(data);
        return [
          HomeActions.getActionWisePendingAndCompletedPacketsCountList(),
          // HomeActions.getActionWisePacketList({ actionId: data.actionId }),
          HomeActions.getActionNStatusWisePacketList({ actionId: data.actionId, status: "Completed" }),
          //HomeActions.parameterRemoved({ parameterDetails: data.parameterDetails }),
        ];
      })
    )
  );

  saveStoneParamater$ = createEffect(() =>
    this.actions.pipe(
      ofType(masterDataActions.saveStoneParamater),
      tap((w: any) => {
        // this.httpService.showLoader();
      }),
      concatMap(action =>
        from(this.httpService.saveStoneParamater(action.parameterDetails, action.actionId, action.formType)).pipe(
          map(res => {
            console.log("saveStoneParamater");
            console.log(action);
            console.log(res);
            console.log(action.actionInfo);
            // this.httpService.dismissLoader();

            if (res.statusCode === 200) {
              let isVirtual = Number(action?.parameterDetails["isTensionVirtual"]);
              if (isVirtual) {
                this.httpService.updateData(true, `virtual-task/update/${action.actionId}`, {}).subscribe((res: any) => {
                  this.httpService.getErrorAndDisplayIt(res);
                  this.store.dispatch(myVirtualTaskActions.fetchMyVirtualTasks({ api: virtualAPIs.virtualTaskEndPoint, Payload: null }));
                });
              }
              let data = {
                barcode: action.barcodeObj,
                from: "saveStoneParameter",
              };
              if (action?.actionInfo?.name == "Auto Receive") {
                this.router.navigate(["./home"]);
              } else if (action?.actionInfo?.name === "Planning") {
                console.log("______popup........................................");
                // this.showModal(true, res.message, action);
                this.configService.showToast("success", res.message);
              } else if (action.actionInfo?.name == "Final Planning") {
                console.log("no modal need to show");
              } else if (action.actionInfo?.name == "QC Check & Annotation") {
                this.router.navigate(["./home"]);
                // this.showModal(true, res.message, {});
                this.configService.showToast("success", res.message);
              } else {
                // this.showModal(true, res.message, {});
                this.configService.showToast("success", res.message);
              }
            } else {
              console.log(action.barcodeObj);
              // this.showModal(false, res.message, {});
              this.configService.showToast("error", res.message);
            }
            return action;
          }),
          catchError(error => {
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((x: any) => {}),
      mergeMap((data: any) => {
        if (data?.actionInfo?.name == "Planning") {
          return [
            // HomeActions.getActionWisePendingAndCompletedPacketsCountList(),
            // HomeActions.getActionWisePacketList({ actionId: data.actionId }),
            HomeActions.parameterSaved({
              parameterDetails: data.parameterDetails,
            }),
            // SignerAction.removeSelectedPlans()
          ];
        } else {
          return [
            // HomeActions.getActionWisePendingAndCompletedPacketsCountList(),
            // HomeActions.getActionWisePacketList({ actionId: data.actionId }),
            // HomeActions.getReceivedPackets({ limit: 50 }),
            // HomeActions.getActionNStatusWisePacketList({ actionId: data.actionId, status: 'Pending' }),
            HomeActions.parameterSaved({
              parameterDetails: data.parameterDetails,
            }),
            // AutoReceiveAction.getScannedPacketDetails({
            //   packetIdObj: data.barcodeObj,
            // }),
          ];
        }
      })
    )
  );

  getParameterHistory$ = createEffect(() =>
    this.actions.pipe(
      ofType(masterDataActions.getParameterHistory),
      tap((response: any) => {
        // this.httpService.showLoader();
      }),
      concatMap(action =>
        from(this.httpService.fetchHistoryDataByPacketId(action.packetId)).pipe(
          map(res => {
            this.httpService.getErrorAndDisplayIt(res);
            let modifiedData = !!res.data ? res.data : [];
            modifiedData = modifiedData.map(x => {
              let showEdit = false;
              if (x.userName == action.currUserFullName) {
                console.log(x.userName + "=" + action.currUserFullName);
                x["showEdit"] = true;
              } else {
                x["showEdit"] = showEdit;
              }
              return x;
            });
            modifiedData = modifiedData.filter(fd => fd.historyStatus == "Completed");
            return modifiedData;
          }),
          catchError(error => {
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {}),
      map((data: any) => {
        return masterDataActions.saveParameterHistory({
          parameterHistory: data,
        });
      })
    )
  );

  updateStoneParamater$ = createEffect(() =>
    this.actions.pipe(
      ofType(masterDataActions.updateStoneParamater),
      tap((x: any) => {
        console.log("Save Stones and show loader");
        console.log(x);
        // this.httpService.showLoader();
      }),
      concatMap(action =>
        from(this.httpService.updateStoneParamater(action.parameterDetails, action.actionId, action.formType)).pipe(
          map(res => {
            if (res.statusCode === 200) {
              this.configService.showToast("success", res.message);
            } else {
              this.configService.showToast("error", res.message);
            }
            return action;
          }),
          catchError(error => {
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {}),
      mergeMap((data: any) => {
        console.log("Parameter Updated");
        console.log(data);
        return [
          HomeActions.getActionWisePendingAndCompletedPacketsCountList(),
          HomeActions.getActionWisePacketList({ actionId: data.actionId }),
          HomeActions.parameterSaved({
            parameterDetails: data.parameterDetails,
          }),
          masterDataActions.getParameterHistory({ packetId: data.packetId, currUserFullName: data.currUserFullName }),
        ];
      })
    )
  );

  // Transfer Stones Effects
  getRoles$ = createEffect(() =>
    this.actions.pipe(
      ofType(masterDataActions.getRolesForUser),
      concatMap(action =>
        from(this.httpService.fetchRoles()).pipe(
          map(res => {
            console.log("...........Role............");
            console.log(res);
            this.httpService.getErrorAndDisplayIt(res);
            return this.httpService.sortStrings(res.data, "roleName", "asc");
          }),
          catchError(error => {
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {
        // this.httpService.dismissLoader();
      }),
      map((data: any) => {
        return masterDataActions.saveRolesForUser({ trybeRoles: data });
      })
    )
  );
  getTrybeDepartments$ = createEffect(() =>
    this.actions.pipe(
      ofType(masterDataActions.getTrybeDepartments),
      concatMap(action =>
        from(this.httpService.fetchTrybeDepartments(action.packetIds)).pipe(
          map(res => {
            console.log("...........Dept............");
            console.log(res);
            console.log(typeof res.data);
            if (typeof res.data == "string") {
              this.configService.showToast("error", res.data);
              this.httpService.getErrorAndDisplayIt(res);
              // return this.httpService.sortStrings(null, 'name', 'asc');
            } else {
              this.httpService.getErrorAndDisplayIt(res);
              if (res?.data?.msg)
                // this shows there is no data
                return [];
              return this.httpService.sortStrings(res.data, "name", "asc");
            }
          }),
          catchError(error => {
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {
        // this.httpService.dismissLoader();
        //
      }),
      map((data: any) => {
        return masterDataActions.saveTrybeDepartments({
          trybeDepartments: data,
        });
      })
    )
  );
  transferStone$ = createEffect(() =>
    this.actions.pipe(
      ofType(masterDataActions.transferStone),
      tap(async (w: any) => {
        //await this.httpService.showLoader();
      }),
      concatMap(action =>
        from(this.httpService.transferPacket(action.postData)).pipe(
          map(async res => {
            console.log("map", res);
            let resData = {
              data: res.data,
            };

            if (res.statusCode === 200) {
              //await this.httpService.dismissLoader();
              await this.showModal(true, res.message, resData);
            } else {
              //await this.httpService.dismissLoader();
              await this.showModal(false, res.message, resData);
            }
            this.httpService.getErrorAndDisplayIt(res);
            return action;
          }),
          catchError(error => {
            console.log("catchError", error);

            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap(async (x: any) => {
        console.log("tap", x);

        //await this.httpService.dismissLoader();
      }),
      mergeMap((data: any) => {
        let param = {
          limit: 500,
          page: 0,
        };
        return [HomeActions.getReceivedPackets(param), HomeActions.getPacketCount(), HomeActions.getOutgoingPackets(param)];
      })
    )
  );

  rejectPacket = createEffect(() =>
    this.actions.pipe(
      ofType(masterDataActions.rejectPacket),
      tap((w: any) => {}),
      concatMap(action =>
        from(this.httpService.reject(action.payload)).pipe(
          map(res => {
            console.log(action);
            console.log(res);

            if (res.statusCode === 200) {
              // this.showModal(true, res.message, action.modalData);
              this.configService.showToast("true", res.message);
            } else {
              // this.showModal(false, res.message, action.modalData);
              this.configService.showToast("false", res.message);
            }
            this.httpService.getErrorAndDisplayIt(res);
            return action;
          }),
          catchError(error => {
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((x: any) => {}),
      mergeMap((data: any) => {
        let limit = { limit: 500, page: 0 };
        return [HomeActions.getIncomingPackets(limit), HomeActions.getReceivedPackets(limit), HomeActions.getActionWisePendingAndCompletedPacketsCountList(), HomeActions.getPacketCount()];
      })
    )
  );

  receivePacket$ = createEffect(() =>
    this.actions.pipe(
      ofType(masterDataActions.receivePacket),
      tap(async (w: any) => {
        //await this.httpService.showLoader();
      }),
      concatMap(action =>
        from(this.httpService.receive(action.payload)).pipe(
          map(async res => {
            if (res.statusCode === 200) {
              //await this.httpService.dismissLoader();
              await this.showModal(true, res.message, action.modalData);
            } else {
              //await this.httpService.dismissLoader();
              await this.showModal(false, res.message, action.modalData);
            }
            this.httpService.getErrorAndDisplayIt(res);
            return action;
          }),
          catchError(error => {
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap(async (x: any) => {
        //await this.httpService.dismissLoader();
      }),
      mergeMap((data: any) => {
        let limit = { limit: 500, page: 0 };
        return [HomeActions.getIncomingPackets(limit), HomeActions.getReceivedPackets(limit), HomeActions.getActionWisePendingAndCompletedPacketsCountList(), HomeActions.getPacketCount()];
      })
    )
  );

  receivePacketVoucherwise$ = createEffect(() =>
    this.actions.pipe(
      ofType(masterDataActions.receivePacketVoucherwise),
      tap((w: any) => {
        //await this.httpService.showLoader();
      }),
      concatMap(action =>
        from(this.httpService.receiveVoucherwise(action.param.payload)).pipe(
          map(res => {
            if (res.statusCode === 200) {
              // this.httpService.dismissLoader();
              // this.showModal(true, res.message, {});
              this.configService.showToast("success", res.message);
            } else {
              // this.httpService.dismissLoader();
              // this.showModal(false, res.message, {});
              this.configService.showToast("false", res.message);
            }
            console.log(action);
            this.httpService.getErrorAndDisplayIt(res);
            return action;
          }),
          catchError(error => {
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((x: any) => {
        // this.httpService.dismissLoader();
      }),
      mergeMap((data: any) => {
        let withMeGridPageInfo = { limit: 50, page: 0 };
        console.log(data);
        return [
          HomeActions.getIncomingPackets(data.param.incomingGridPageInfo),
          HomeActions.getReceivedPackets(withMeGridPageInfo),
          HomeActions.getActionWisePendingAndCompletedPacketsCountList(),
          HomeActions.getPacketCount(),
        ];
      })
    )
  );

  /*removeIssuedPacket$ = createEffect(() =>
    this.actions.pipe(
      ofType(masterDataActions.removeIssuedPacket),
      tap(async (w: any) => {
        await this.httpService.showLoader();
      }),
      concatMap((action) =>
        from(
          this.httpService.removeIssuedPacket(action.packetId, action.voucherId)
        ).pipe(
          map(async (res) => {
            console.log(action);
            console.log(res);

            if (res.statusCode === 200) {
              await this.httpService.dismissLoader();
              await this.showModal(true, res.message, '');
            } else {
              await this.httpService.dismissLoader();
              await this.showModal(false, res.message, '');
            }
            return action;
          }),
          catchError(error => {
            this.httpService.getErrorAndDisplayIt(error)
            return error
          })
        )
      ),
      tap(async (x: any) => {
        await this.httpService.dismissLoader();
      }),
      mergeMap((data: any) => {
        let limit = { limit: 500, page: 0 };
        return [
          HomeActions.getOutgoingPackets(limit),
          HomeActions.getReceivedPackets(limit)
        ];
      })
    )
  );*/

  removeIssuedPacketVoucherwise$ = createEffect(() =>
    this.actions.pipe(
      ofType(masterDataActions.removeIssuedPacketVoucherwise),
      tap(async (w: any) => {}),
      concatMap(action =>
        from(this.httpService.removeIssuedPacketvoucherwise(action.voucherId)).pipe(
          map(async res => {
            if (res.statusCode === 200) {
              // this.httpService.updateOutgoingGridData(res.data);
              // await this.httpService.dismissLoader();
              await this.showModal(true, res.message, "");
            } else {
              await this.showModal(false, res.message, "");
            }
            this.httpService.getErrorAndDisplayIt(res);
            return action;
          }),
          catchError(error => {
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap(async (x: any) => {
        // await this.httpService.dismissLoader();
      }),
      mergeMap((data: any) => {
        let limit = { limit: 500, page: 0 };
        return [HomeActions.getOutgoingPackets(limit), HomeActions.getReceivedPackets(limit)];
      })
    )
  );

  ecsvUpload$ = createEffect(() =>
    this.actions.pipe(
      ofType(masterDataActions.ecsvUpload),
      tap(async (w: any) => {
        console.log(w);
        console.log("Please wait shown");

        // await this.httpService.showLoader();
      }),
      withLatestFrom(this.store.pipe(select(actionlist))),
      concatMap(([action, actionList]) => {
        let actionClone = { ...action };
        if (actionList && actionList.length > 0) {
          let isExist = actionList.find((el: any) => el.actionName == "Final Planning");
          if (isExist) {
            // actionClone.isAppend = false;
          }
        }
        return this.httpService.uploadECSVFile(actionClone.parameterDetails, actionClone.packetID, actionClone.formType, actionClone.isAppend, actionClone.actionInfo.name).pipe(
          map(async res => {
            console.log(action);
            let resultData = res.data;
            console.log(res);
            console.log(resultData);
            let act = { ...action };

            if (res.statusCode === 200) {
              act["status"] = "OK";
              if (action.actionInfo?.name == "Smart Recut Planning" || action.actionInfo?.name == ACTIONNAMES.anyCut) {
                if (action?.barcodeObj) {
                  this.store.dispatch(findPacketPlanStoneByPacketIdForSmartRecut({ packetIdOb: action.barcodeObj, reviewForSmartRecut: null }));
                }
              } else if(action.isGetPacketPlansCalled) {
                if (action?.packetString) this.store.dispatch(getPacketPlans({ packetIdOb: { packetId: action.packetString } }));
              }
              console.log(res);
              if (res?.data?.error === "error") {
                this.httpService.showToast("Error", "Please upload file with correct data format");
                let newFile = res.data.url;
                newFile = newFile.split("/");
                const uploadsIndex = newFile.indexOf("uploads");
                const joinedUrl = newFile.slice(0, uploadsIndex + 1).join("/");
                console.log(joinedUrl);
                let upload = newFile.slice(4).join("/");
                window.open(joinedUrl + "/" + encodeURIComponent(upload), "_blank");
              } else {
                await this.showModal(true, res.message, act);
              }
              await this.httpService.dismissLoader();
            } else {
              act["status"] = "FAILED";
              // await this.httpService.dismissLoader();
              await this.showModal(false, res.message, act);
            }
            this.httpService.getErrorAndDisplayIt(res);
            return !!res.data ? res.data : [];
          }),
          catchError(error => {
            this.httpService.showToast("Error", error.message);
            // this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        );
      }),
      tap(async (x: any) => {
        console.log(x);

        //await this.httpService.dismissLoader();
      }),
      mergeMap((data: any) => {
        return [
          // masterDataActions.savePacketPlans({ packetPlans: data })
        ];
      })
    )
  );

  getAllQualityMaster$ = createEffect(() =>
    this.actions.pipe(
      ofType(masterDataActions.getAllQualtiyMaster),
      tap((x: any) => {
        // this.httpService.showLoader()
      }),
      concatMap(action =>
        from(this.httpService.fetchAllWorkQualities()).pipe(
          map(res => {
            return !!res.data ? res.data : [];
          }),
          catchError(error => {
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {
        // this.httpService.dismissLoader()
        //
      }),
      mergeMap((data: any) => {
        console.log(data);
        return [masterDataActions.saveAllQualtiyMaster({ qualityMasterData: data })];
      })
    )
  );

  getAllParametersByTransType = createEffect(() =>
    this.actions.pipe(
      ofType(masterDataActions.getAllParametersByTransType),
      tap((x: any) => {
        // this.httpService.showLoader()
      }),
      concatMap(action =>
        from(this.httpService.getAllParametersForSingleStone(action.transType)).pipe(
          map(res => {
            this.httpService.getErrorAndDisplayIt(res);
            return !!res.data ? res.data : [];
          }),
          catchError(error => {
            // this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {
        // this.httpService.dismissLoader()
      }),
      mergeMap((data: any) => {
        console.log(data);
        return [masterDataActions.saveParametersByTransType({ parametrsData: data })];
      })
    )
  );

  // deleteAssetViaUniqueId$ = createEffect(() =>
  //   this.actions.pipe(
  //     ofType(AssetsActions.deleteAssetViaUniqueId),
  //     concatMap((action) => {
  //       console.log('getAssetByUniqueId$', action);
  //       return from(
  //         this.assetsService.deleteAssetViaUniqueId(action.uniqueId)
  //       ).pipe(
  //         map((res) => {
  //           return res;
  //           // return {
  //           //   res: res,
  //           //   pageNum: action.pageNum,
  //           //   pageSize: action.pageSize,
  //           //   assetType: action.assetType,
  //           //   voucherBookId: action.voucherBookId,
  //           // };
  //         }),
  //         catchError(this.storeService.handleError)
  //       );
  //     }),
  //     tap((response: any) => {
  //       // console.log('on asset effect addAssetDetails response: ', response);
  //       if (response.error) {
  //         this.storeService.toastMesssage('error', response.error);
  //       }
  //       if (response.status == 200) {
  //         this.storeService.toastMesssage('success', response.message);
  //       }
  //       if (response.error == 'Unauthorized') {
  //         this.storeService.logout();
  //       }
  //       debugger;
  //     })

  getAllDamageMaster$ = createEffect(() =>
    this.actions.pipe(
      ofType(masterDataActions.getAllQualtiyMaster),
      tap((x: any) => {
        // this.httpService.showLoader()
      }),
      concatMap(action =>
        from(this.httpService.fetchAllDamageMaster()).pipe(
          map(res => {
            this.httpService.getErrorAndDisplayIt(res);
            return !!res.data ? res.data : [];
          }),
          catchError(error => {
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {
        // this.httpService.dismissLoader()
      }),
      mergeMap((data: any) => {
        console.log(data);
        return [masterDataActions.saveAllDamageMaster({ damageMasterData: data })];
      })
    )
  );
  createNewPackets$ = createEffect(() =>
    this.actions.pipe(
      ofType(masterDataActions.createNewPackets),
      tap(async (w: any) => {
        // await this.httpService.showLoader();
      }),
      concatMap(action =>
        from(this.httpService.createNewPackets(action.payload)).pipe(
          map(async res => {
            // let resData = {
            //     data: res.data
            // }
            console.log(action);
            console.log(res);

            if (res.statusCode === 200) {
              this.showModal(true, res.message, {});
            } else {
              this.httpService.getErrorAndDisplayIt(res);
              // await this.showModal(false, res.message, {});
            }
            return action;
          }),
          catchError(error => {
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap(async (x: any) => {}),
      mergeMap((data: any) => {
        let limit = { limit: 500, page: 0 };
        return [HomeActions.getReceivedPackets(limit), HomeActions.getPacketCount()];
      })
    )
  );
  getUsersByPacket$ = createEffect(() =>
    this.actions.pipe(
      ofType(masterDataActions.getUsersByPacketId),
      concatMap(action =>
        from(this.httpService.getUsersByPacketID(action.packetID)).pipe(
          map(res => {
            this.httpService.getErrorAndDisplayIt(res);
            return res.data;
          }),
          catchError(error => {
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {
        if (!!response && response.error == "Unauthorized") {
          this.httpService.logout();
        }
      }),
      map((data: any) => {
        return masterDataActions.saveUsersByPacketId({ usersbyPacketId: data });
      })
    )
  );

  getDetailsOfAnnotation$ = createEffect(() =>
    this.actions.pipe(
      ofType(masterDataActions.getDetailsOfAnnotation),
      concatMap(action =>
        from(this.httpService.getAnnotatedImageFormDetailsAndInclusionDetails(action.packetID)).pipe(
          map(res => {
            console.log(res);
            this.httpService.getErrorAndDisplayIt(res);
            return res.data;
          }),
          catchError(error => {
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {
        console.log(response);
        if (!!response && response.error == "Unauthorized") {
          this.httpService.logout();
        }
      }),
      map((data: any) => {
        return masterDataActions.saveDetailsOfAnnotation({ detailByPacketId: data });
      })
    )
  );

  saveDetailsFormData$ = createEffect(() =>
    this.actions.pipe(
      ofType(masterDataActions.saveDetailsFormData),
      tap((response: any) => {
        // this.httpService.showLoader();
      }),
      concatMap(action =>
        from(this.httpService.saveDetailsFormData(action.postData)).pipe(
          map(res => {
            this.httpService.getErrorAndDisplayIt(res);
            if (res.statusCode === 200) {
              this.store.dispatch(masterDataActions.saveDetailsSuccess({ success: res }));
            } else {
              this.store.dispatch(masterDataActions.saveDetailsFailure({ error: res }));
            }
            return action;
          }),
          catchError(error => {
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {
        if (!!response && response.error == "Unauthorized") {
          this.httpService.logout();
        }
      }),
      mergeMap((data: any) => {
        return [];
      })
    )
  );

  async showModal(state: boolean, message: string, data: any) {
    if (state) {
      if (!!data.data && !!data.data.voucherLabel) {
        const modal = await this.modalController.create({
          component: TrybemodalComponent,
          componentProps: {
            message: message,
            data: data,
          },
          cssClass: "trybe_modal_print",
        });
        modal.onDidDismiss().then((d: any) => this.onModalDismiss(d));
        return await modal.present();
      } else {
        const modal = await this.modalController.create({
          component: TrybemodalComponent,
          componentProps: {
            message: message,
            data: data,
          },
          cssClass: "trybe_modal_1",
        });
        modal.onDidDismiss().then((d: any) => this.onModalDismiss(d));
        return await modal.present();
      }
    } else {
      const modal = await this.modalController.create({
        component: TrybemodalComponent,
        componentProps: {
          message: message,
        },
        cssClass: "trybe_modal_0",
      });
      modal.onDidDismiss().then((d: any) => this.onModalDismiss(d));
      return await modal.present();
    }
  }
  async onModalDismiss(d: any) {
    if (
      (!!d.data && !!d.data.actionInfo && d.data.actionInfo.name == "Planning") ||
      (!!d.data && !!d.data.actionInfo && d.data.actionInfo.name == "Planning review" && d.data.status == "OK") ||
      (!!d.data && !!d.data.actionInfo && d.data.actionInfo.name == "Manual Final Planning") ||
      (!!d.data && !!d.data.actionInfo && (d.data.actionInfo.name == "Smart Recut Planning" || d.data.actionInfo.name == ACTIONNAMES.anyCut)) ||
      (!!d.data && !!d.data.actionInfo && d.data.actionInfo.name == "Any Cut Planning") ||
      (!!d.data && !!d.data.actionInfo && d.data.actionInfo.name == "Final Planning")
    ) {
      console.log(d.data.barcodeObj);
      if (d.data.actionInfo?.name != "Smart Recut Planning" && d.data.actionInfo?.name != ACTIONNAMES.anyCut && d.data.actionInfo?.name != "Final Planning") {
        this.store.dispatch(SignerAction.getPacketPlans({ packetIdOb: d.data.barcodeObj }));
      }

      if (d.data.actionInfo?.name == "Final Planning") {
        this.store.dispatch(chekerAction.resetAllPlans());
        if(d?.data?.isFromPlanningScreen){
          this.store.dispatch(chekerAction.getPlansAccToUSers({ id: d.data.currentUserId, packetId: d.data.packetID}));
        }else{
          this.store.dispatch(chekerAction.getCheckerPlans({ packetIdOb: { packetId: d.data.packetID } }));
        }
      }

      //  await this.router.navigate(['/trybesigner']);
      // await this.router.navigate(['/planning']);

      if (d.data.actionInfo?.name === "Manual Final Planning") {
        await this.router.navigate(["/manualfinalplanningpage"]);
      } else if (d.data.actionInfo?.name === "Planning" || d.data.actionInfo?.name === "Smart Recut Planning" || d.data.actionInfo?.name === ACTIONNAMES.anyCut) {
        await this.router.navigate(["/planning"]);
      } else if (d.data.actionInfo?.name === "Final Planning") {
        await this.router.navigate(["/newchecker"]);
      }
    }
  }

  getPreplanningSummary$ = createEffect(() =>
    this.actions.pipe(
      ofType(masterDataActions.getPreplanningSummary),
      concatMap(action =>
        from(this.httpService.fetchPreplanningSummary()).pipe(
          map(res => {
            this.httpService.getErrorAndDisplayIt(res);
            return !!res.data ? res.data : res;
          }),
          catchError(error => {
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {
        if (response.error == "Unauthorized") {
          this.httpService.logout();
        }
      }),
      map((data: any) => {
        return masterDataActions.savePreplanningSummary({ prePlanningSummary: data });
      })
    )
  );
  getAllStoneMaster$ = createEffect(() =>
    this.actions.pipe(
      ofType(masterDataActions.getAllStoneMaster),
      concatMap(action =>
        from(this.httpService.getAllStoneMaster()).pipe(
          map(res => {
            this.httpService.getErrorAndDisplayIt(res);
            return !!res.data ? res.data : res;
          }),
          catchError(error => {
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {
        if (response.error == "Unauthorized") {
          this.httpService.logout();
        }
      }),
      map((data: any) => {
        return masterDataActions.saveAllStoneMaster({ allStoneMaster: data });
      })
    )
  );

  getAllStoneMasterDetailById$ = createEffect(() =>
    this.actions.pipe(
      ofType(masterDataActions.getStoneMasterDetailById),
      concatMap(action =>
        from(this.httpService.getData(true, routeMasterAPIs.parameterValuesEndpoint, action.payload)).pipe(
          map(res => {
            this.httpService.getErrorAndDisplayIt(res);
            return !!res.data ? res.data : res;
          }),
          catchError(error => {
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {
        if (response.error == "Unauthorized") {
          this.httpService.logout();
        }
      }),
      map((data: any) => {
        return masterDataActions.saveStoneMasterDetailById({ allStoneMasterDetailById: data });
      })
    )
  );
  getAllUsers$ = createEffect(() =>
    this.actions.pipe(
      ofType(masterDataActions.getAllUsers),
      concatMap(action =>
        from(this.httpService.getAllUsers()).pipe(
          map(res => {
            this.httpService.getErrorAndDisplayIt(res);
            return !!res.data ? res.data : res;
          }),
          catchError(error => {
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      // tap((response: any) => {
      //   if (response.error == 'Unauthorized') {
      //     this.httpService.logout();
      //   }
      // }),
      map((data: any) => {
        return masterDataActions.saveAllUsers({ usersOfTrybe: data });
      })
    )
  );

  getLabParametersData$ = createEffect(() =>
    this.actions.pipe(
      ofType(masterDataActions.getAllLabParametersData),
      concatMap(action =>
        from(this.httpService.fetchLabParameters()).pipe(
          map(res => {
            this.httpService.getErrorAndDisplayIt(res);
            console.log(res.data);
            return !!res.data ? res.data : res;
          }),
          catchError(error => {
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {
        if (response.error == "Unauthorized") {
          this.httpService.logout();
        }
      }),
      map((data: any) => {
        return masterDataActions.saveLabParameterData({ LabParameterData: data });
      })
    )
  );

  getColorData$ = createEffect(() =>
    this.actions.pipe(
      ofType(masterDataActions.getAllColorValues),
      concatMap(action =>
        from(this.httpService.fetchColorParameters()).pipe(
          map(res => {
            this.httpService.getErrorAndDisplayIt(res);
            console.log(res.data);
            return !!res.data ? res.data : res;
          }),
          catchError(error => {
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {
        if (response.error == "Unauthorized") {
          this.httpService.logout();
        }
      }),
      map((data: any) => {
        return masterDataActions.saveColorValues({ ColorData: data });
      })
    )
  );

  getFancyData$ = createEffect(() =>
    this.actions.pipe(
      ofType(masterDataActions.getAllFancyValues),
      concatMap(action =>
        from(this.httpService.fetchFancyParameters()).pipe(
          map(res => {
            this.httpService.getErrorAndDisplayIt(res);
            console.log(res.data);
            return !!res.data ? res.data : res;
          }),
          catchError(error => {
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {
        if (response.error == "Unauthorized") {
          this.httpService.logout();
        }
      }),
      map((data: any) => {
        return masterDataActions.saveFancyValues({ FancyData: data });
      })
    )
  );

  getColorShade$ = createEffect(() =>
    this.actions.pipe(
      ofType(masterDataActions.getAllColorShade),
      concatMap(action =>
        from(this.httpService.fetchColorShadeParameters()).pipe(
          map(res => {
            this.httpService.getErrorAndDisplayIt(res);
            console.log(res.data);
            return !!res.data ? res.data : res;
          }),
          catchError(error => {
            this.httpService.getErrorAndDisplayIt(error);
            return throwError(error);
          })
        )
      ),
      tap((response: any) => {
        if (response.error == "Unauthorized") {
          this.httpService.logout();
        }
      }),
      map((data: any) => {
        return masterDataActions.saveColorShade({ ColorShadeData: data });
      })
    )
  );
}
