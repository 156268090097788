import { EventEmitter, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { actions, authUser } from "src/app/authStore/auth.selector";
import { ActionOperationsComponent } from "src/app/components/actions/action-operations/action-operations.component";
import { AssignVirtualTasksComponent } from "src/app/components/actions/assign-virtual-tasks/assign-virtual-tasks.component";
import { PacketHistoryComponent } from "src/app/components/actions/packet-history/packet-history.component";
import { StoneTimeTrackingReportComponent } from "src/app/components/actions/stone-time-tracking-report/stone-time-tracking-report.component";
import { WorkQualityMasterComponent } from "src/app/components/actions/work-quality-master/work-quality-master.component";
import { DeptwiseStockdetailsComponent } from "src/app/components/deptwise-stockdetails/deptwise-stockdetails.component";
import { FinalPlanningReviewComponent } from "src/app/components/final-planning-review/final-planning-review.component";
import { MyVirtualTaskComponent } from "src/app/components/my-virtual-task/my-virtual-task.component";
import { PreplanningsummaryComponent } from "src/app/components/preplanningsummary/preplanningsummary.component";
import { ProcessActionItem } from "src/app/components/processActionItem";
import { SignerPlanningHistoryComponent } from "src/app/components/signer-planning-history/signer-planning-history.component";
import { TrybesignerComponent } from "src/app/components/trybesigner/trybesigner.component";
import { UserwisestockdetailsComponent } from "src/app/components/userwisestockdetails/userwisestockdetails.component";
import { HomeActions } from "src/app/home/state/home.action-type";
import { PacketRouteMasterComponent } from "src/app/packet-route-master/packet-route-master.component";
import { AppState } from "src/app/reducers";
import { tempDataActions } from "src/app/tempDataStore/tempData.action-type";
//import { FluorescenceComponent } from '../../components/actions/fluorescence/fluorescence.component';
import { IncomingComponent } from "../../components/actions/incoming/incoming.component";
import { IssueComponent } from "../../components/actions/issue/issue.component";
import { OutgoingComponent } from "../../components/actions/outgoing/outgoing.component";
import { OrderformComponent } from "src/app/orderform/orderform.component";
import { TensionmappingComponent } from "src/app/tensionmapping/tensionmapping.component";
import { RvverifyComponent } from "src/app/rvverify/rvverify.component";
import { AssignsubmanagerComponent } from "src/app/assignsubmanager/assignsubmanager.component";
import { DbcScanningComponent } from "src/app/dbc-scanning/dbc-scanning.component";
import { QcCheckAndAnnotationComponent } from "src/app/qc-check-and-annotation/qc-check-and-annotation.component";
import { PacketInwardComponent } from "src/app/kps/packet-inward/packet-inward.component";
import { ColorInspectionComponent } from "src/app/color-inspection/color-inspection.component";
import { LotAllocationComponent } from "src/app/kps/lot-allocation/lot-allocation.component";
import { TransferWithActionComponent } from "src/app/components/transfer-with-action/transfer-with-action.component";
import { PullComponent } from "src/app/pull/pull.component";
import { LockmasterComponent } from "src/app/lockmaster/lockmaster.component";
import { LockCriteriaMasterComponent } from "src/app/lock-criteria-master/lock-criteria-master.component";
import { DbcmakeexcelComponent } from "src/app/dbcmakeexcel/dbcmakeexcel.component";
import { WagesLedgerComponent } from "src/app/wages-ledger/wages-ledger.component";
import { DeptWiseParametersComponent } from "src/app/dept-wise-parameters/dept-wise-parameters.component";
import { PlanningReviewComponent } from "src/app/kps/planning-review/planning-review.component";
import { UserManagementComponent } from "src/app/user-management/user-management.component";
import { ReceiveForPartyComponent } from "src/app/receive-for-party/receive-for-party.component";
import { PacketHistoryViewComponent } from "src/app/packet-histroy-view/packet-history-view.component";
import { TallyStockComponent } from "src/app/tally-stock/tally-stock.component";
import { TallyManagerViewComponent } from "src/app/tally-manager-view/tally-manager-view.component";
import { ReturnToSafeComponent } from "src/app/return-to-safe/return-to-safe.component";
import { CompletedPacketHistoryComponent } from "src/app/completed-packet-history/completed-packet-history.component";
import { LivePacketReportComponent } from "src/app/live-packet-report/live-packet-report.component";
import { ThirdPartyTransferComponent } from "src/app/components/third-party-transfer/third-party-transfer.component";
import { MixpanelService } from "../mixpanel/mixpanel.service";
import { EditPacketsComponent } from "src/app/components/edit-packets/edit-packets.component";
import { WagesMasterComponent } from "src/app/components/wages-master/wages-master.component";
import { ManualFinalParametersComponent } from "src/app/components/manual-final-parameters/manual-final-parameters.component";
import { DispatchPacketComponent } from "src/app/dispatch-packet/dispatch-packet.component";
import { KgLotAllocationComponent } from "src/app/kg-lot-allocation/kg-lot-allocation.component";
import { KgPacketCreationComponent } from "src/app/kg-packet-creation/kg-packet-creation.component";
import { CriteriaMasterComponent } from "src/app/opinion-criteria-master/criteria-master.component";
import { ACTIONNAMES } from "../endpoint.enum";
import { ReprintDispatchVoucherComponent } from "src/app/components/reprint-dispatch-voucher/reprint-dispatch-voucher.component";
import { MyTaskForVirtualComponent } from "src/app/my-task-for-virtual/my-task-for-virtual.component";
import { ParameterMasterComponent } from "src/app/components/masters/parameter-master/parameter-master.component";
import { ProcessactionconfigComponent } from "src/app/processactionconfig/processactionconfig.component";
import { PolishGradeComponent } from "src/app/polish-grade/polish-grade.component";
import { OpinionApprovalMasterComponent } from "src/app/opinion-approval-master/opinion-approval-master.component";
import { ViewvirtualtaskComponent } from "src/app/components/viewvirtualtask/viewvirtualtask.component";
import { TransferPacketToDispatchComponent } from "src/app/transfer-packet-to-dispatch/transfer-packet-to-dispatch.component";
import { RoughpacketvalueComponent } from "src/app/components/roughpacketvalue/roughpacketvalue.component";
import { OpinionGeneratorViewComponent } from "src/app/opinion-generator-view/opinion-generator-view.component";
import { PacketRepairingComponent } from "src/app/packet-repairing/packet-repairing.component";
import { D3PyramidChartComponent } from "src/app/components/d3-pyramid-chart/d3-pyramid-chart.component";
import { PyramidviewcomponentComponent } from "src/app/components/pyramidviewcomponent/pyramidviewcomponent.component";
import { SalesReturnVoucherComponent } from "src/app/components/sales-return-voucher/sales-return-voucher.component";
import { PacketwiseapprovallistComponent } from "src/app/components/packetwiseapprovallist/packetwiseapprovallist.component";
import { PartyconfigComponent } from "src/app/components/partyconfig/partyconfig.component";
import { OpinionScreenComponent } from "src/app/opinion-screen/opinion-screen.component";

@Injectable({
  providedIn: "root",
})
export class ProcessService {
  private actionName: string;
  private actionId: number;
  private manageActionName: string;
  private voucherType: string;
  private listOfActionPermissions: any;
  listOfActions: [];
  userActions$: Observable<any>;
  private actionStatus: any;
  actionChanged$: Observable<any>;
  public actionSelected$: EventEmitter<boolean>;
  //public searchedActions: BehaviorSubject<any>;
  public actionObject: {};
  currentUser: any;
  constructor(
    public router: Router,
    // private mixpanelService: MixpanelService,
    private store: Store<AppState>
  ) {
    this.actionSelected$ = new EventEmitter(false);
    this.userActions$ = this.store.pipe(select(actions));
    //this.searchedActions = new BehaviorSubject(false);
    this.userActions$.subscribe(data => {
      if (data) {
        this.listOfActions = data;
      }
    });
    this.store.pipe(select(authUser)).subscribe((d: any) => {
      this.currentUser = d;
    });
  }
  // get setSearchedAction$(): Observable<any> {
  //   return this.searchedActions.asObservable();
  // }
  async setActionName(actionName, actionId, voucherType, listOfActionPermissions, actionStatus) {
    this.actionName = actionName;
    this.actionId = actionId;
    this.voucherType = voucherType;
    this.listOfActionPermissions = listOfActionPermissions;
    this.actionStatus = actionStatus;
    // this.mpTrack(actionName, actionId);
    console.log("----", actionName);
  }
  mpTrack(actionName: any, actionId: any) {
    // this.mixpanelService.track(actionName, { actionId, userName: this.currentUser?.username, fullName: this.currentUser?.fullName, userId: this.currentUser?.userId });
  }
  getProcessName(): ProcessActionItem[] {
    let result: ProcessActionItem[] = [];
    // this.searchedActions.subscribe
    if (this.actionName) {
      console.log(this.actionName, "color:green;font-size:1.25rem");
      let comp = this.resolveComponentsName(this.actionName, this.actionStatus);
      let newItem = new ProcessActionItem(comp, this.actionName, this.actionId, this.voucherType, this.listOfActionPermissions);
      result.push(newItem);
      console.log(result);
      console.log("%getProcessName................", "color:green;border:2px solid red;");
    }
    return result;
  }
  private resolveComponentsName(actionName, actionStatus) {
    console.warn(actionName);
    console.log(this.listOfActions);
    if (!!this.listOfActions && this.listOfActions.length > 0) {
      let sAction: any = this.listOfActions.filter((x: any) => x.actionName == actionName)[0];
      console.log(this.listOfActions);
      console.log(this.listOfActions.filter((x: any) => x.actionName == actionName));
      console.log(sAction);
      console.log(actionStatus);
      let actionForm = "";
      if (!!sAction) console.log(sAction);
      let searchData = {
        name: sAction?.actionName,
        id: sAction.id,
        status: actionStatus,
        actionForm: null,
        permissions: sAction.listOfActionPermission,
        description: sAction.description,
      };
      let actionData = {};
      if (!!sAction && sAction.listOfForms && sAction.listOfForms.length > 0) {
        actionForm = sAction.listOfForms[0]["formData"];
        actionData = {
          name: sAction.actionName,
          id: sAction.id,
          status: actionStatus,
          actionForm: actionForm,
        };
        searchData["actionForm"] = actionForm;
      } else {
        actionData = {
          name: sAction.actionName,
          id: sAction.id,
          status: actionStatus,
          actionForm: actionForm,
        };
      }
      this.store.dispatch(tempDataActions.setCurrentAction({ actionData: actionData }));
      this.store.dispatch(tempDataActions.setCurrentSearch({ searchData: searchData }));
      if (actionName == "With Me") {
        return IssueComponent;
      } else if (actionName == "In Coming") {
        return IncomingComponent;
      } else if (actionName == "Outgoing") {
        return OutgoingComponent;
      } else if (actionName == "Assign Sub Mnager") {
        return AssignsubmanagerComponent;
      } else if (actionName == "Packet Tracking") {
        return PacketHistoryComponent;
      } else if (actionName == "Work Quality Master") {
        return WorkQualityMasterComponent;
      } else if (actionName == "Packet Plans") {
        return TrybesignerComponent;
        // } else if (actionName == "Check Plan") {
        // return TrybeCheckerComponent;
        // } else if (actionName == "Final Planning") {
        //   return TrybeCheckerComponent;
      } else if (actionName == "Signer planning History") {
        return SignerPlanningHistoryComponent;
      } else if (actionName == "Report - Stone Time Tracking ") {
        return StoneTimeTrackingReportComponent;
      } else if (actionName == "Report - User Wise Stock") {
        return UserwisestockdetailsComponent;
      } else if (actionName == "Report -  Department Wise Stock") {
        return DeptwiseStockdetailsComponent;
      } else if (actionName == "Pre Planning Summary") {
        return PreplanningsummaryComponent;
      } else if (actionName == ACTIONNAMES.ORDER_FORM || actionName == ACTIONNAMES.ORDER_DASHBOARD) {
        return OrderformComponent;
      } else if (actionName == "Final Planning review") {
        return FinalPlanningReviewComponent;
      } else if (actionName == "Lot creation") {
        return KgLotAllocationComponent;
      } else if (actionName == "Packet inward") {
        return KgPacketCreationComponent;
      } else if (actionName == "Virtual Task") {
        return MyVirtualTaskComponent;
      } else if (actionName == "Import Stock") {
        return PacketInwardComponent;
      } else if (actionName == "Assign Lot") {
        return LotAllocationComponent;
      } else if (actionName == "Lock Criteria Master") {
        return LockCriteriaMasterComponent;
      } else if (actionName == "Assign Virtual Task") {
        return AssignVirtualTasksComponent;
      } else if (actionName == "Pull Back") {
        return PullComponent;
        // } else if (actionName == 'Lock Master') {
        //   return LockmasterComponent;
      } else if (actionName == "DBC Excel Download") {
        return DbcmakeexcelComponent;
      } else if (actionName?.toLowerCase() == "transfer with action") {
        return TransferWithActionComponent;
      } else if (actionName == "Third-Party Transfer") {
        return ThirdPartyTransferComponent;
      } else if (actionName == "Modify Recut Entry" || actionName == "All Bifurcated Packets Edit" || actionName == "Loss Ledger" || actionName == ACTIONNAMES.VIEW_WAGE_LEDGER) {
        return EditPacketsComponent;
      } else if (actionName == "Manual Final Parameters") {
        return ManualFinalParametersComponent;
      } else if (actionName && actionName.toLowerCase() == "route master") {
        return PacketRouteMasterComponent;
      } else if (actionName == "Parameter Master") {
        return DeptWiseParametersComponent;
      } else if (actionName == "Planning Review by Head Checker") {
        return PlanningReviewComponent;
      } else if (actionName == "User Admin") {
        return UserManagementComponent;
      } else if (actionName == "Receive from Party") {
        return ReceiveForPartyComponent;
      } else if (actionName == "Wages Ledger") {
        return WagesLedgerComponent;
      } else if (actionName == "Packet View History") {
        return PacketHistoryViewComponent;
      } else if (actionName == "Tally Stock" || actionName == ACTIONNAMES.tallyMismatchedStock) {
        return TallyStockComponent;
      } else if (actionName == "Return To Safe") {
        return ReturnToSafeComponent;
      } else if (actionForm == "Manager Transfer") {
        return ActionOperationsComponent;
      } else if (actionName == "Tally") {
        return TallyManagerViewComponent;
      } else if (actionName?.toLowerCase() == "admin tally") {
        return TallyManagerViewComponent;
      } else if (actionName == "Completed Packet History") {
        return CompletedPacketHistoryComponent;
      } else if (actionName == "Packet Live Status") {
        return LivePacketReportComponent;
      } else if (actionName == "Parameters") {
        return ParameterMasterComponent;
      } else if (actionName == "Route Master 2") {
        return PacketRouteMasterComponent;
      } else if (actionName == ACTIONNAMES.opinionMaster || actionName == ACTIONNAMES.Planning_Suggestion_Master) {
        return CriteriaMasterComponent;
      } else if (actionName == ACTIONNAMES.opinionGeneratorView) {
        return OpinionGeneratorViewComponent;
      } else if (actionName == ACTIONNAMES.mistakeEntry2 || actionName == ACTIONNAMES.lockMaster2) {
        return OpinionScreenComponent;
      } else if (actionName == ACTIONNAMES.universalAdminApprovalView) {
        return OpinionGeneratorViewComponent;
      } else if (actionName == ACTIONNAMES.reprint_dispatch_voucher) {
        return ReprintDispatchVoucherComponent;
      } else if (actionName == ACTIONNAMES.Opinion_Task_View) {
        return PacketwiseapprovallistComponent;
      } else if (actionName == ACTIONNAMES.Third_Party_config) {
        return PartyconfigComponent;
      } else if (actionName == "Wages Master") {
        return WagesMasterComponent;
      } else if (actionName == "Dispatch Packet") {
        return TransferPacketToDispatchComponent;
      } else if (actionName == "Assigned Virtual Task") {
        return MyTaskForVirtualComponent;
      } else if (actionName == "View Assigned virtual task") {
        return ViewvirtualtaskComponent;
      } else if (actionName == "Inward Packets Price Upload") {
        return RoughpacketvalueComponent;
      } else if (actionName == ACTIONNAMES.signerRequest) {
        return UserManagementComponent;
      } else if (actionName == ACTIONNAMES.PACKET_BIFURCATION_VIEW) {
        return PyramidviewcomponentComponent;
      } else if (actionName == ACTIONNAMES.deptRuleConfig) {
        return ProcessactionconfigComponent;
      } else if (actionName == ACTIONNAMES.polishGrade) {
        return PolishGradeComponent;
      } else if (actionName == ACTIONNAMES.opinionApprovalMaster) {
        return OpinionApprovalMasterComponent;
      } else if (actionName == ACTIONNAMES.trfPacketToDispatch) {
        return TransferPacketToDispatchComponent;
      } else if (actionName == ACTIONNAMES.packetReparing) {
        return PacketRepairingComponent;
      } else if (actionName == ACTIONNAMES.Sales_Return_Voucher || actionName == ACTIONNAMES.Self_Sales_Return_Voucher) {
        return SalesReturnVoucherComponent;
      } else if (actionName == ACTIONNAMES.PROCESS_RULE_CONFIG) {
        return PacketRouteMasterComponent;
      } else if (actionName && actionName == "Tension Mapping") {
        if (actionStatus) {
          this.store.dispatch(
            HomeActions.getActionNStatusWisePacketList({
              actionId: sAction.id,
              status: actionStatus,
            })
          );
        } else {
          this.store.dispatch(
            HomeActions.getActionNStatusWisePacketList({
              actionId: sAction.id,
              status: "Pending",
            })
          );
        }
        return TensionmappingComponent;
      } else if (actionName == "Rv Verify And Setup File") {
        if (actionStatus) {
          this.store.dispatch(
            HomeActions.getActionNStatusWisePacketList({
              actionId: sAction.id,
              status: actionStatus,
            })
          );
        } else {
          this.store.dispatch(
            HomeActions.getActionNStatusWisePacketList({
              actionId: sAction.id,
              status: "Pending",
            })
          );
        }
        return RvverifyComponent;
      } else if (actionName == "Qc Check And Verify") {
        if (actionStatus) {
          this.store.dispatch(
            HomeActions.getActionNStatusWisePacketList({
              actionId: sAction.id,
              status: actionStatus,
            })
          );
        } else {
          this.store.dispatch(
            HomeActions.getActionNStatusWisePacketList({
              actionId: sAction.id,
              status: "Pending",
            })
          );
        }
        return QcCheckAndAnnotationComponent;
      } else if (actionName == "DBC Scanning") {
        if (actionStatus) {
          this.store.dispatch(
            HomeActions.getActionNStatusWisePacketList({
              actionId: sAction.id,
              status: actionStatus,
            })
          );
        } else {
          this.store.dispatch(
            HomeActions.getActionNStatusWisePacketList({
              actionId: sAction.id,
              status: "Pending",
            })
          );
        }
        return DbcScanningComponent;
      }
      //   else if(actionName == "Qc Check And Verify"){
      //     if(actionStatus){
      //       this.store.dispatch(HomeActions.getActionNStatusWisePacketList({ actionId: sAction.id, status: actionStatus }));
      //     }else {
      //       this.store.dispatch(HomeActions.getActionNStatusWisePacketList({ actionId: sAction.id, status: 'Pending' }));
      //     }
      //     return QcCheckAndAnnotationComponent
      // }
      else if (actionName !== ACTIONNAMES.generateReport && actionName !== ACTIONNAMES.return && actionName !== ACTIONNAMES.transfer && actionName !== ACTIONNAMES.OPINION_REMARK) {
        if (actionStatus) {
          this.store.dispatch(
            HomeActions.getActionNStatusWisePacketList({
              actionId: sAction.id,
              status: actionStatus,
            })
          );
        } else {
          this.store.dispatch(
            HomeActions.getActionNStatusWisePacketList({
              actionId: sAction.id,
              status: "Pending",
            })
          );
        }
      }

      return ActionOperationsComponent;
    }
  }
  ngOnDestroy() {
    console.log("process destroyed");
    this.userActions$.subscribe();
  }
}
