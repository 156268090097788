import { Component, OnInit } from "@angular/core";
import { HttpService } from "../services/http/http.service";
import { routeMasterAPIs } from "../packet-route-master/state/packet-route-master.data";
import { ACTIONNAMES, ENDPOINTS } from "../services/endpoint.enum";
import { ConfigService } from "../services/config/config.service";
import { Store, select } from "@ngrx/store";
import { AppState } from "../reducers";
import { getAllParameters } from "../masterDataStore/masterData.selector";
import { ItemReorderEventDetail, PopoverController } from "@ionic/angular";
import { OpinionCriteriaMasterPopupComponent } from "./opinion-criteria-master-popup/opinion-criteria-master-popup.component";
import { authUser } from "../authStore/auth.selector";
import { Subscription } from "rxjs";
import { getCurrentAction } from "../tempDataStore/tempData.selector";
import { filter, first } from "rxjs/operators";
import { confirm } from "devextreme/ui/dialog";

@Component({
  selector: "app-criteria-master",
  templateUrl: "./criteria-master.component.html",
  styleUrls: ["./criteria-master.component.scss"],
})
export class CriteriaMasterComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  criteriaParameterLists = [];
  allCheckedParams: any = {};
  criteriaParameterHead = [];
  selectedDepartment;
  actionNames = ACTIONNAMES;
  refreshGrid: boolean;
  isView: string = "auto";
  parameterMaster: any = {
    lab: "Lab",
    "Opinion Remark": "OpinionRemark",
    clarityId: "Clarity",
    colorId: "Color",
    fluorescenceId: "Fluorescence",
    cutId: "CUT",
    symmetryId: "Symmetry",
    polishId: "Polish",
    LUSTER: "Luster",
    tinch: "Tinch",
    DEPTH: "DEPTH",
    "TOP OPEN": "TOP OPEN",
    "BOTTOM OPEN": "BOTTOM OPEN",
  };
  approvalParameters: any = [
    // {
    //   name: 'LAB',
    //   id: 'LAB',
    //   masterName: 'Lab',
    // },
    // {
    //   name: 'POINTER',
    //   id: 'POINTER',
    // },
    // {
    //   name: 'Opinion Remark',
    //   id: 'OpinionRemark',
    //   masterName : 'OpinionRemark'
    // },
    // {
    //   name: 'POLSHED WEIGHT',
    //   id: 'POLSHED WEIGHT',
    // },
    // {
    //   name: 'NET VALUE DIFFERENCE',
    //   id: 'NET VALUE DIFFERENCE',
    // },
    // {
    //   name: 'CLARITY',
    //   id: 'CLARITY',
    //   masterName: 'Clarity',
    // },
    // {
    //   name: 'COL',
    //   id: 'COL',
    //   masterName: 'Color',
    // },
    // {
    //   name: 'FLR',
    //   id: 'FLR',
    //   masterName: 'Fluorescence',
    // },
    // {
    //   name: 'CUT',
    //   id: 'CUT',
    //   masterName: 'CUT',
    // },
    // {
    //   name: 'SYM',
    //   id: 'SYM',
    //   masterName: 'Symmetry',
    // },
    // {
    //   name: 'POLISHING',
    //   id: 'POLISHING',
    //   masterName: 'Polish',
    // },
    // {
    //   name: 'LUSTER',
    //   id: 'LUSTER',
    //   masterName: 'Luster',
    // },
    // {
    //   name: 'TINCH',
    //   id: 'TINCH',
    //   masterName: 'Tinch',
    // },
    // {
    //   name: 'DEPTH',
    //   id: 'DEPTH',
    //   masterName: 'DEPTH',
    // },
    // {
    //   name: 'RATIO',
    //   id: 'RATIO',
    // },
    // {
    //   name: 'TOP OPEN',
    //   id: 'TOP OPEN',
    //   masterName: 'TOP OPEN',
    // },
    // {
    //   name: 'BOTTOM OPEN',
    //   id: 'BOTTOM OPEN',
    //   masterName: 'BOTTOM OPEN',
    // },
  ];

  dropDownApprovalParam: any = {
    name: "Approval Parameter",
    placeText: "Select Parameter",
    options: this.approvalParameters,
    selectedData: [],
    key: "name",
    modeFlag: "single", //single/multiple
  };
  selectedApprovalParam: any;
  tableApprovalParameters: any = [];
  dropDownChangeData: any = {
    name: "change",
    placeText: "Select",
    options: [
      {
        name: "Upgrade",
        id: "Upgrade",
      },
      {
        name: "Downgrade",
        id: "Downgrade",
      },
      {
        name: "Change",
        id: "Change",
      },
      {
        name: "Equal to",
        id: "Equal to",
      },
      ,
      {
        name: "Range",
        id: "Range",
      },
      // {
      //   name: 'Down Drade',
      //   id: 'downgrade',
      // },
      // {
      //   name: 'Group Change',
      //   id: 'groupChange',
      // },
      // {
      //   name: 'Heavy',
      //   id: 'heavy',
      // },
    ],
    selectedData: [],
    key: "name",
    modeFlag: "single", //single/multiple
  };
  priority: any;
  formData: any = {
    weightDiff: {
      lowerLimit: "",
      upperLimit: "",
    },
    tableVal: {
      lowerLimit: "",
      upperLimit: "",
    },
    girdle: {
      lowerLimit: "",
      upperLimit: "",
    },
    widthMM: {
      lowerLimit: "",
      upperLimit: "",
    },
    lengthMM: {
      lowerLimit: "",
      upperLimit: "",
    },
    culet: {
      lowerLimit: "",
      upperLimit: "",
    },
    diameter: {
      lowerLimit: "",
      upperLimit: "",
    },
    ratio: {
      lowerLimit: "",
      upperLimit: "",
    },
    height: {
      lowerLimit: "",
      upperLimit: "",
    },
    crHeight: {
      lowerLimit: "",
      upperLimit: "",
    },
    crAngle: {
      lowerLimit: "",
      upperLimit: "",
    },
    pavHeight: {
      lowerLimit: "",
      upperLimit: "",
    },
    pavAngle: {
      lowerLimit: "",
      upperLimit: "",
    },
    expPol: {
      lowerLimit: "",
      upperLimit: "",
    },
  };
  equalsFormData: any = {
    packetType: "",
    opinionReamrk: "",
  };
  inFormData: any = {
    departmentId: [],
    polishGrade: [],
  };
  dropDownTBOChangeData: any = {
    name: "change",
    placeText: "Select",
    options: [
      // {
      //   name: 'Change',
      //   id: 'change',
      // },
      // {
      //   name: 'Down Drade',
      //   id: 'downgrade',
      // },
      // {
      //   name: 'Group Change',
      //   id: 'groupChange',
      // },
      {
        name: "Heavy",
        id: "heavy",
      },
    ],
    selectedData: [],
    key: "name",
    modeFlag: "single", //single/multiple
  };

  dropDownUserData: any = {
    name: "user",
    placeText: "Approval By",
    options: [
      // {
      //   id: 'Checker',
      //   name: 'Checker',
      // },
      // {
      //   id: 'Admin',
      //   name: 'Admin',
      // },
    ],
    selectedData: [],
    key: "name",
    modeFlag: "single", //single/multiple
  };

  formDataObject: any = {};
  allMasterData: any = {};

  allOpinionMasterData: any = [];
  fromWeight: number | null = null;
  toWeight: number | null = null;
  gridData: any = [];
  selectedData: any;
  addPage: boolean = false;

  columns = [
    { name: "Name", prop: "name" },
    { name: "Created By", prop: "createdBy" },
    { name: "CreatedDate", prop: "createdDate" },
    { name: "Shape", prop: "shapeCriteriaList" },
    { name: "Clarity", prop: "clarityCriteriaList" },
    { name: "Status", prop: "isActive", columnTemplate: "isActive" },
  ];
  dropDownDepartment: any = {
    name: "Department",
    placeText: "Select Department",
    options: [],
    selectedData: [],
    key: "name",
    modeFlag: "single", //single/multiple
  };
  dropDownCheckerPolishGrade: any = {
    name: "Polish Grade",
    placeText: "Select Polish Grade",
    options: [],
    selectedData: [],
    key: "grade",
    modeFlag: "multiple", //single/multiple
  };
  dropDownPacketType: any = {
    name: "Packet Type",
    placeText: "Select Packet Type",
    options: ["Rough", "Makable"],
    selectedData: [],
    key: "name",
    modeFlag: "single", //single/multiple
  };
  dropDownOpinionRemark: any = {
    name: "Opinion Remark",
    placeText: "Select Remark",
    options: ["ZIRAM INCREASE"],
    selectedData: [],
    key: "name",
    modeFlag: "single", //single/multiple
  };
  loggedInUserRoles;
  currentAction: string;
  constructor(private httpService: HttpService, private configService: ConfigService, private store: Store<AppState>, private popoverController: PopoverController) {}

  ngOnInit() {
    this.subscriptions.push(
      this.store.pipe(select(authUser)).subscribe(user => {
        if (!!user) {
          this.loggedInUserRoles = user.roles;
        }
      })
    );
    this.subscriptions.push(
      this.store
        .pipe(select(getCurrentAction))
        .pipe(
          filter(data => !!data),
          first()
        )
        .subscribe(async (data: any) => {
          if (data) {
            this.currentAction = data?.name;
          }
        })
    );
    this.getGridData();

    this.fetchRequiredParameterValues();
    // this.opnionMasterfindAll();

    this.store.pipe(select(getAllParameters)).subscribe(allMasterData => {
      this.allMasterData = allMasterData;
    });

    this.fetchAllDepartment();
    this.fetchCheckerPolishGrade();
    this.fetchApprovalUsers();
    this.fetchApprovalParameters();
  }

  async getGridData() {
    let endpoint = "";
    if (this.currentAction == this.actionNames.opinionMaster) {
      endpoint = ENDPOINTS.opnionMasterfindAll;
    } else if (this.currentAction == this.actionNames.Planning_Suggestion_Master) {
      endpoint = "planning-suggestion-master/findAll";
    }
    this.gridData = (await this.httpService.fetchDataUsingPromise(true, endpoint))?.data || [];
  }

  async fetchAllDepartment() {
    let response: any = await this.httpService.fetchDataUsingPromise(true, "tbl-department/findAll");
    if (response.statusCode === 200) {
      this.dropDownDepartment["options"] = [];
      this.dropDownDepartment["options"] = response.data;
    } else {
      console.error(response);
    }
  }

  async fetchCheckerPolishGrade() {
    let response: any = await this.httpService.fetchDataUsingPromise(true, "checker-polish-grade/findAll");
    if (response.statusCode === 200) {
      this.dropDownCheckerPolishGrade["options"] = (response?.data || [])?.map((dt: any) => ({ ...dt, id: dt.grade }));
    }
  }

  async fetchApprovalUsers() {
    let response: any = await this.httpService.fetchDataUsingPromise(true, "tbl-role/getUserWhichAreAdmin");
    if (response.statusCode === 200) {
      this.dropDownUserData["options"] = (response?.data || []).map((dt: any) => ({ id: dt, name: dt }));
    }
  }

  async fetchApprovalParameters() {
    let response: any = await this.httpService.fetchDataUsingPromise(true, "opnion-master/getParameterList");
    if (response.statusCode === 200) {
      this.approvalParameters = (response?.data || []).map((dt: any) => {
        dt = { ...dt, id: dt.name, dbId: dt.id, name: dt.displayName };
        if (this.parameterMaster[dt.name]) return { ...dt, masterName: this.parameterMaster[dt.name] };
        return dt;
      });
      this.dropDownApprovalParam.options = this.approvalParameters;
    }
  }

  keyUp(event: any) {
    const pattern = /[0-9\+\ ]/;
    let inputChar = String.fromCharCode(event.key);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  fetchRequiredParameterValues() {
    let payloadObj: object = {
      names: [
        "Color",
        "Shape",
        "Fluorescence",
        "Clarity",
        "Polish",
        "Symmetry",
        "Tinch",
        "Cut",
        "Milkyness",
        "Proportion",
        "Lab",
        "Depth",
        "Luster",
        "Natts",
        "Tension Type",
        "TOP OPEN",
        "BOTTOM OPEN",
      ],
      // [
      //   'Color',
      //   'Shape',
      //   'Fluorescence',
      //   'Clarity',
      //   'Polish',
      //   'Symmetry',
      //   'Tinch',
      //   'Cut',
      //   'Milkyness',
      //   'Proportion',
      //   'Natts',
      //   'TopOpens',
      //   'BotOpens',
      //   'Tension Type',
      //   'Lab',
      //   'OpinionRemark'
      // ],
    };
    this.httpService.getData(true, routeMasterAPIs.parameterValuesEndpoint, payloadObj).subscribe(result => {
      if (result.statusCode === 200) {        
        this.criteriaParameterLists = result?.data.filter(dt => {
          if (dt.parameterName.toLowerCase() == "depth") return;
          return {
            ...dt,
            startIndex: null,
            endIndex: null,
            paramValues: dt?.paramValues?.map(pv => {
              return { ...pv, isSelected: false };
            }),
          };
        });
        this.criteriaParameterHead = this.criteriaParameterLists.map(dt => dt.parameterName);
      }
    });
  }

  opnionMasterfindAll() {
    this.httpService.fetchData(true, ENDPOINTS.opnionMasterfindAll).subscribe((res: any) => {
      this.allOpinionMasterData = res?.data || [];
    });
  }

  criteriaSelected(parameter: any, pi: number) {
    parameter.paramValues = parameter.paramValues.map((dt: any, i: number) => (pi == i ? { ...dt, isSelected: dt?.isSelected ? false : true } : { ...dt }));
    this.allCheckedParamsCheck(this.criteriaParameterLists);
  }

  dropdownChanged(event: any, approvalParameter: any, key: string) {
    if (!this.formDataObject[approvalParameter.name]) this.formDataObject[approvalParameter.name] = {};

    this.formDataObject[approvalParameter.name][key] = event?.arrayList?.[0];
  }

  priorityChange(event: any, approvalParameter: any) {
    if (!this.formDataObject[approvalParameter.name]) this.formDataObject[approvalParameter.name] = {};

    this.formDataObject[approvalParameter.name]["priority"] = event.target.value;
  }

  rowClicked($event: any) {
    this.selectedDepartment = null;
    this.isView = "auto";
    this.selectedData = $event?.row?.data;
    this.addPage = true;
    if (this.selectedData) {
      if (!this.loggedInUserRoles.includes("Super Admin")) {
        this.isView = "none";
      }
      const criteria = JSON.parse(this.selectedData.criteria)?.in;
      this.criteriaParameterLists.map((cp: any) => {
        cp.paramValues.map((pv: any) => {
          if (criteria[cp.parameterName.toLowerCase()]?.includes(cp.parameterName?.toLowerCase() == "lab" ? pv.name : pv.id)) {
            pv.isSelected = true;
          }
        });
      });
      for (const key in this.inFormData) {
        if (key == "departmentId") {
          this.selectedDepartment = criteria[key][0] || null;
          this.inFormData[key] = criteria[key] || [];
          this.dropDownDepartment.selectedData = this.dropDownDepartment.options.filter((dt: any) => this.inFormData[key]?.includes(dt.id) || this.inFormData[key]?.includes(dt));
        } else if (key == "polishGrade") {
          this.inFormData[key] = criteria[key] || [];
          this.dropDownCheckerPolishGrade.selectedData = this.dropDownCheckerPolishGrade.options.filter((dt: any) => this.inFormData[key]?.includes(dt.id) || this.inFormData[key]?.includes(dt));
        }
      }
      const range = JSON.parse(this.selectedData.criteria)?.range;
      const equals = JSON.parse(this.selectedData.criteria)?.equals;
      if (range) {
        this.formData = { ...this.formData, ...range };
      }
      if (equals) {
        this.equalsFormData = { ...this.equalsFormData, ...equals };
        for (const key in this.equalsFormData) {
          if (key == "packetType") {
            this.dropDownPacketType.selectedData = this.dropDownPacketType.options.filter((dt: any) => dt.id == this.equalsFormData[key] || dt == this.equalsFormData[key]);
          }
          if (key == "opinionReamrk") {
            this.dropDownOpinionRemark.selectedData = this.dropDownOpinionRemark.options.filter((dt: any) => dt.id == this.equalsFormData[key] || dt == this.equalsFormData[key]);
          }
        }
      }
      if (this.selectedData.priority) {
        this.priority = this.selectedData.priority;
      }
      if (this.currentAction == this.actionNames.opinionMaster) {
        const parameters = JSON.parse(this.selectedData.parameters);
        if (parameters?.length) {
          const keyToChange = {
            clarityId: "CLARITY",
            colorId: "COL",
            cutId: "CUT",
            fluorescenceId: "FLR",
            lab: "LAB",
            polishId: "POLISHING",
            symmetryId: "SYM",
            tinch: "TINCH",
            OpinionRemark: "OpinionRemark",
          };
          parameters.map((parameter: any) => {
            for (const key in parameter) {
              const dataToPush: any = {
                key: keyToChange[key] ? keyToChange[key] : key,
              };
              // this.formDataObject[keyToChange[key]?keyToChange[key]:key] = {};
              if (parameter[key].type) {
                // this.formDataObject[keyToChange[key]?keyToChange[key]:key]['change'] = {
                //   name: parameter[key].type,
                //   id: parameter[key].type,
                // };
                dataToPush.change = {
                  name: parameter[key].type,
                  id: parameter[key].type,
                };
              }
              dataToPush.priority = parameter[key].priority;
              dataToPush["mistake of"] = {
                id: parameter[key]?.["mistake of"],
                name: parameter[key]?.["mistake of"],
              };
              if (parameter[key].approvals) {
                const approval = JSON.parse(parameter[key].approvals);
                if (approval.approval1) {
                  dataToPush.approval1 = {
                    id: approval.approval1,
                    name: approval.approval1,
                  };
                }
                if (approval.approval2) {
                  dataToPush.approval2 = {
                    id: approval.approval2,
                    name: approval.approval2,
                  };
                }
                if (approval.approval3) {
                  dataToPush.approval3 = {
                    id: approval.approval3,
                    name: approval.approval3,
                  };
                }
              }
              // if(parameter[key].polishGrade){
              //   dataToPush.polishGrade = this.dropDownCheckerPolishGrade.options.find((dt:any) => dt.id==parameter[key].polishGrade);
              // }
              if (parameter[key].value) {
                // this.formDataObject[keyToChange[key]?keyToChange[key]:key]['value'] = this.allMasterData[keyToChange[key]?keyToChange[key]:key]?.find((value:any)=>value.id==parameter[key].value);
                let masterName = this.approvalParameters.find((dt: any) => dt.name?.toLowerCase() == (keyToChange[key] ? keyToChange[key] : key)?.toLowerCase())?.masterName;
                dataToPush["value"] = this.allMasterData[masterName ? masterName : keyToChange[key] ? keyToChange[key] : key]?.find((value: any) => value.id == parameter[key].value);
              }
              if (parameter[key].range) {
                dataToPush["range"] = parameter[key].range;
              }
              this.tableApprovalParameters.push(dataToPush);
            }
          });
        }
      }
      this.allCheckedParamsCheck(this.criteriaParameterLists);
    }
  }

  cancel() {
    this.selectedData = null;
    this.addPage = false;
    this.tableApprovalParameters = [];
    this.priority = null;
    this.dropDownPacketType.selectedData = [];
    this.dropDownCheckerPolishGrade.selectedData = [];
    this.dropDownDepartment.selectedData = [];
    this.dropDownOpinionRemark.selectedData = [];
    this.allCheckedParams = {};
    this.formData = {
      weightDiff: {
        lowerLimit: "",
        upperLimit: "",
      },
      tableVal: {
        lowerLimit: "",
        upperLimit: "",
      },
      girdle: {
        lowerLimit: "",
        upperLimit: "",
      },
      widthMM: {
        lowerLimit: "",
        upperLimit: "",
      },
      lengthMM: {
        lowerLimit: "",
        upperLimit: "",
      },
      culet: {
        lowerLimit: "",
        upperLimit: "",
      },
      diameter: {
        lowerLimit: "",
        upperLimit: "",
      },
      ratio: {
        lowerLimit: "",
        upperLimit: "",
      },
      height: {
        lowerLimit: "",
        upperLimit: "",
      },
      crHeight: {
        lowerLimit: "",
        upperLimit: "",
      },
      crAngle: {
        lowerLimit: "",
        upperLimit: "",
      },
      pavHeight: {
        lowerLimit: "",
        upperLimit: "",
      },
      pavAngle: {
        lowerLimit: "",
        upperLimit: "",
      },
      expPol: {
        lowerLimit: "",
        upperLimit: "",
      },
    };
    this.criteriaParameterLists.map((cp: any) => {
      cp.paramValues.map((pv: any) => {
        pv.isSelected = false;
      });
    });
  }

  equalsFormDataChange(event: any, param: string) {
    this.equalsFormData[param] = event?.idList?.[0] || event?.arrayList?.[0];
  }

  inFormDataChange(event: any, param: string) {
    // if(param == 'departmentId') this.priority = 100;
    if (param == "departmentId") this.selectedDepartment = event?.idList[0];
    this.inFormData[param] = event?.idList?.length ? event?.idList : event?.arrayList;
  }

  handleReorder(evt: CustomEvent<ItemReorderEventDetail>) {
    var element = this.tableApprovalParameters[evt.detail.from];
    this.tableApprovalParameters.splice(evt.detail.from, 1);
    this.tableApprovalParameters.splice(evt.detail.to, 0, element);
    this.tableApprovalParameters = this.tableApprovalParameters.map((dt: any, index: number) => ({ ...dt, priority: index + 1 }));
    evt.detail.complete();
  }

  deleteApprovalParamRow(approvalParameter: any) {
    this.tableApprovalParameters = this.tableApprovalParameters.filter((dt: any) => dt != approvalParameter).map((dt: any, index: number) => ({ ...dt, priority: index + 1 }));
  }

  async approvalParamRowClicked(approvalParameter: any, index: number) {
    const selectedApprovalParam = this.dropDownApprovalParam.options.find((dt: any) => dt.id == approvalParameter.key || dt.name == approvalParameter.key);
    await this.fetchOperatorByParam(selectedApprovalParam?.dbId);
    const popover = await this.popoverController.create({
      component: OpinionCriteriaMasterPopupComponent,
      cssClass: "approvalParameterAdd",
      componentProps: {
        forPage: "approvalParameterAdd",
        approvalParameter,
        allMasterData: this.allMasterData,
        dropDownUserData: this.dropDownUserData,
        selectedApprovalParam: selectedApprovalParam,
        operatorByParam: this.operatorByParam[this.approvalParameters.find((dt: any) => dt.id == selectedApprovalParam.id)?.dbId],
        dropDownApprovalParam: this.dropDownApprovalParam,
        // dropDownCheckerPolishGrade: this.dropDownCheckerPolishGrade,
      },
    });
    popover.present();
    popover.onDidDismiss().then(res => {
      if (res.data?.length > 0) {
        res.data.map((data: any, i: number) => {
          for (const key in data) {
            if (i == 0) {
              this.tableApprovalParameters[index] = {
                ...data[key],
                key,
              };
            } else {
              this.tableApprovalParameters.push({
                ...data[key],
                key,
                priority: this.tableApprovalParameters?.length + 1,
              });
            }
          }
        });
      }
    });
  }

  operatorByParam: any = {};
  async fetchOperatorByParam(id: number) {
    if (this.operatorByParam[id]) return;
    let response: any = await this.httpService.fetchDataUsingPromise(true, `opnion-master/getOperatorByParam?paramId=${id}`);
    this.operatorByParam[id] = (response.data || []).map((dt: any) => {
      dt = { ...dt, id: dt.name, dbId: dt.id };
      return dt;
    });
  }

  async approvalPramAddClicked() {
    await this.fetchOperatorByParam(this.approvalParameters.find((dt: any) => dt.id == this.selectedApprovalParam.id)?.dbId);
    const popover = await this.popoverController.create({
      component: OpinionCriteriaMasterPopupComponent,
      cssClass: "approvalParameterAdd",
      componentProps: {
        forPage: "approvalParameterAdd",
        selectedApprovalParam: this.approvalParameters.find((dt: any) => dt.id == this.selectedApprovalParam.id),
        allMasterData: this.allMasterData,
        dropDownUserData: this.dropDownUserData,
        operatorByParam: this.operatorByParam[this.approvalParameters.find((dt: any) => dt.id == this.selectedApprovalParam.id)?.dbId],
        // dropDownCheckerPolishGrade: this.dropDownCheckerPolishGrade,
      },
    });
    popover.present();
  popover.onDidDismiss().then(res => {
  if (res.data?.length > 0) {
    res.data.map((data: any, i: number) => {
      for (const key in data) {
        if (data[key] && Object.keys(data[key]).length > 0) {
          const hasNonEmptyKeys = Object.keys(data[key]).some(
            (innerKey) => data[key][innerKey] && typeof data[key][innerKey] === 'object' 
                          ? Object.keys(data[key][innerKey]).length > 0 
                          : data[key][innerKey] !== null && data[key][innerKey] !== ''
          );
          if (hasNonEmptyKeys) {
            this.tableApprovalParameters.push({
              ...data[key],
              key,
              priority: this.tableApprovalParameters?.length + 1,
            });
          }
        }
      }
    });
  }
});

  }

  save() {
    const criterias = [];
    let criteriasPayload = {};
    const keyToChange = {
      CLARITY: "clarityId",
      COL: "colorId",
      CUT: "cutId",
      FLR: "fluorescenceId",
      LAB: "lab",
      // natts : "natts",
      POLISHING: "polishId",
      // shape : "shapeId",
      SYM: "symmetryId",
      TINCH: "tinch",
      OpinionRemark: "OpinionRemark",
    };

    this.criteriaParameterLists.map((cp: any) => {
      cp.paramValues.map((pv: any) => {
        if (pv.isSelected) {
          criterias.push(pv);
          if (!criteriasPayload?.[cp.parameterName.toLowerCase()]) {
            criteriasPayload[cp.parameterName.toLowerCase()] = [];
          }
          criteriasPayload[cp.parameterName.toLowerCase()].push(cp.parameterName?.toLowerCase() == "lab" ? pv.name : pv.id);
        }
      });
    });

    const parameters = [];
    // for(const key in this.formDataObject){
    for (const index in this.tableApprovalParameters) {
      const key = this.tableApprovalParameters[index].key;
      // if(this.tableApprovalParameters[index]['change']){
      const dataPush: any = {
        [keyToChange?.[key] ? keyToChange[key] : key]: {
          type: this.tableApprovalParameters[index]?.["change"]?.id,
          priority: this.tableApprovalParameters[index]?.priority,
          "mistake of": this.tableApprovalParameters[index]?.["mistake of"]?.id,
          approvals: JSON.stringify({
            approval1: this.tableApprovalParameters[index]?.approval1?.id,
            approval2: this.tableApprovalParameters[index]?.approval2?.id,
            approval3: this.tableApprovalParameters[index]?.approval3?.id,
          }),
          range: this.tableApprovalParameters[index]?.["range"],
          // "polishGrade": this.tableApprovalParameters[index]?.polishGrade?.id,
        },
      };
      if (this.tableApprovalParameters[index]["value"]) dataPush[keyToChange?.[key] ? keyToChange[key] : key].value = this.tableApprovalParameters[index]["value"].id;
      parameters.push(dataPush);
      // }
    }

    criteriasPayload = { ...criteriasPayload, ...this.inFormData };
    let payload: any;
    let saveEndPoint = "";

    if (this.currentAction == this.actionNames.opinionMaster) {
      saveEndPoint = ENDPOINTS.opnionMasterSave;
      payload = [
        {
          criteria: JSON.stringify({
            in: criteriasPayload,
            range: this.formData,
            equals: this.equalsFormData,
          }),
          parameters: JSON.stringify(parameters),
         shapeCriteriaList: this.criteriaParameterLists
        ?.find((dt: any) => dt.parameterName == "SHAPE")
        ?.paramValues?.filter((dt: any) => criteriasPayload?.["shape"]?.includes(dt.id))
        ?.map((dt: any) => dt.name)
            ?.toString(),
         clarityCriteriaList:this.criteriaParameterLists
        ?.find((dt: any) => dt.parameterName == "Clarity")
        ?.paramValues?.filter((dt: any) => criteriasPayload?.["clarity"]?.includes(dt.id))
        ?.map((dt: any) => dt.name)
            ?.toString(),
          // priority: this.priority,
          // approvals: JSON.stringify({
          //   approval1: null, // name
          //   approval2: null,
          //   approval3: null
          // })
        },
      ];

      if (this.selectedData?.id) {
        payload[0].id = this.selectedData?.id;
      }

      if (this.selectedDepartment) {
        payload[0].departmentId = this.selectedDepartment;
      }
    } else {
      saveEndPoint = "planning-suggestion-master/save";
      payload = {
        criteria: JSON.stringify({
          in: criteriasPayload,
          range: this.formData,
          equals: this.equalsFormData,
        }),
    };
      

      if (this.selectedData?.id) {
        payload.id = this.selectedData?.id;
      }

      if (this.selectedDepartment) {
        payload.departmentId = this.selectedDepartment;
      }
    }
    // if(!criterias?.length)
    // return;

    // let range={};
    // if(this.fromWeight>=0&&this.toWeight>=0){
    //   range={
    //     weight: {
    //       from: this.fromWeight,
    //       to: this.toWeight,
    //     }
    //   }
    // }

    // for(const key in this.formDataObject){
    //   if(this.formDataObject[key]['change']){
    //     payload.push(
    //       {
    //         "criteria" : criteriasPayload,
    //         // "approval1" : this.formDataObject[key]['approval1'].id,
    //         "approval2" : this.formDataObject[key]?.['approval2']?.id || '',
    //         "priority" : Number(this.formDataObject[key]['priority']),
    //         "parameters" : {
    //           [keyToChange?.[key]?keyToChange[key]:key]: this.formDataObject[key]['change'].id,
    //         },
    //         range,
    //      }
    //     )
    //   } else {
    //     if(this.formDataObject[key]['change'] && !this.formDataObject[key]['approval1']){
    //       this.configService.showToast('error', 'Please select approval by!');
    //       return;
    //     } else if(!this.formDataObject[key]['change'] && this.formDataObject[key]['approval1']){
    //       this.configService.showToast('error', 'Please select change!');
    //       return;
    //     }
    //   }
    // }

    // if(!payload?.length){
    //   this.configService.showToast('error', 'Please select change criteria!');
    //   return;
    // }
    this.httpService.saveData(true, saveEndPoint, payload).subscribe(
      (res: any) => {
        if (res?.statusCode == 200) {
          this.configService.showToast("success", res?.message || "Saved successfully!");
          this.gridData = [];
          this.getGridData();
          this.cancel();
        } else {
          this.configService.showToast("Error", res?.message || "Something went wrong!");
        }
      },
      (err: any) => {
        this.configService.showToast("Error", err?.error?.message || "Something went wrong!");
      }
    );
  }

  onCheckBoxChange(isSelected: boolean, variable: string) {
    const parameterList = this.criteriaParameterLists.find(el => el.parameterName === variable);
    parameterList.paramValues.map(el => {
      el.isSelected = isSelected;
      return el;
    });
  }

  onSearchChange(event, parameterName) {
    const { value } = event.target;
    const criteria = this.criteriaParameterLists.find(el => el.parameterName === parameterName);
    criteria.searchText = value;
  }

  allCheckedParamsCheck(criteriaParameterLists: any) {
    criteriaParameterLists.map((cp: any) => {
      if (cp.paramValues.every((pv: any) => pv.isSelected)) this.allCheckedParams[cp.parameterName] = true;
      else this.allCheckedParams[cp.parameterName] = false;
    });
  }

  async ActiveChange(event: any) {
    const { data } = event.row;
    confirm(`Do you really want to ${!data?.isActive ? "Enable Opinion" : "Disable Opinion"} `, "Warning").then(res => {
      if (res) {
        data.isActive = data.isActive === 0 ? 1 : 0;
        const res = this.httpService.getDataWithoutPayloadForGet(true, `opnion-master/changeStatus?opinionId=${data.id}&isActive=${data.isActive}`).toPromise();
        let index = this.gridData.findIndex((el: any) => el.id === data.id);
        this.gridData[index].isActive = data.isActive;
      } else {
        let index = this.gridData.findIndex((el: any) => el.id === data.id);
        this.gridData[index] = { ...data };
      }
    });
  }

  saveScrollPosition(event) {  
    let colElement: HTMLElement = event.target.closest('.scrollable-col')
    const scrollTop = colElement.scrollTop;
    setTimeout(() => {
        colElement.scrollTop = scrollTop;
    });
}

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      if (!!subscription) {
        subscription.unsubscribe();
      }
    });
  }
}
