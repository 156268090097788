import { Component, ElementRef, HostListener, Input, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { HttpService } from "../services/http/http.service";
import { AlertController, IonSlides, ModalController, NavParams, PopoverController } from "@ionic/angular";
import { PopoverUserComponent } from "../components/popover-user/popover-user.component";
import { select, Store } from "@ngrx/store";
import { AppState } from "../reducers";
import { getCurrentAction } from "../tempDataStore/tempData.selector";
import { take } from "rxjs/operators";
import { HEADER_COLUMNS, SUGGESTED_HEADER_COLUMNS } from "./opinion-screen.data";
import { environment } from "src/environments/environment";
import { ConfigService } from "../services/config/config.service";
import { getMyTasks } from "../components/my-virtual-task/state/my-virtual.task.selectors";
import { authUser } from "../authStore/auth.selector";
import { User } from "../authStore/user";
import { ACTIONNAMES, ENDPOINTS } from "../services/endpoint.enum";
import { Router } from "@angular/router";
import { OpinionViewHistoryComponent } from "./opinion-view-history/opinion-view-history.component";
import { virtualAPIs } from "../components/my-virtual-task/state/my-virtual-task.data";
import { ResetordermodalComponent } from "../resetordermodal/resetordermodal.component";
import { CopyplanmodalComponent } from "../components/copyplanmodal/copyplanmodal.component";
import { DynamicModalComponent } from "../transfer-packet-to-dispatch/dynamic-modal/dynamic-modal.component";
import { getAllTrybeDepartments } from "../masterDataStore/masterData.selector";
import { masterDataActions } from "../masterDataStore/masterData.action-type";

@Component({
  selector: "app-opinion-screen",
  templateUrl: "./opinion-screen.component.html",
  styleUrls: ["./opinion-screen.component.scss"],
})
export class OpinionScreenComponent implements OnInit {
  project: string = environment.project;
  currentUser: User;
  @Input() LockMasterData: any;
  basicSize: number = 0.00646551724; // 12 / 1856
  headerColumns: {
    name: string;
    size: number;
    valueKey?: string;
    class?: string;
    showCheckbox?: boolean;
    showOrders?: boolean;
  }[] = HEADER_COLUMNS;
  tableData = [
    {
      noOfPackets: 46,
      totalMistakes: 3,
      types: [1, 0, 0, 2, 0],
    },
  ];

  suggestedBasicSize: number = 0.00631578947; // 12 / 1900
  suggestedHeaderColumns: {
    name: string;
    size: number;
    valueKey?: string;
    class?: string;
    showCheckbox?: boolean;
    showOrders?: boolean;
    showDelete?: boolean;
  }[] = SUGGESTED_HEADER_COLUMNS;
  ACTIONNAMES = ACTIONNAMES;
  allPackets: {
    id: number;
    lotNo: string;
    packetId: string;
    cts: number;
    predLimit: number;
    lastActionName: string;
    lastActionStatus: string;
    createdDate: string;
    virtualId: number;
  }[] = [];
  selectedPacketId: number;
  selectedPacketData: {
    actualPlans: any[];
    suggestedPlans: any[];
  } = {
    actualPlans: [],
    suggestedPlans: [],
  };
  selectedValue: number = 2;

  checkedActualPlan: number = -1;
  checkedSuggestedPlan: number = -1;
  previousSuggestedPlan: number = -1;
  // imageList: any = ["https://via.placeholder.com/150","https://via.placeholder.com/150/0000FF/808080?text=Image+2",'https://via.placeholder.com/150/0000FF/808080?text=Image+2','https://via.placeholder.com/150/0000FF/808080?text=Image+2','https://via.placeholder.com/150/0000FF/808080?text=Image+2'];
  imageList: any = [];
  currentAction: {
    name: string;
    id: number;
    status: string;
    isVirtual: boolean;
  };
  @ViewChild("DispatchForm") dispatchModal: TemplateRef<any>;
  receivedData: any;
  showHeader: boolean = true;
  mistakeOfOptions: string[] = ["Worker", "Checker", "Smart Recut", "No worker Mistake", "Prev Process"];
  listOfUsersOptions = {
    Worker: [],
    Checker: [],
    "Smart Recut": [],
    "No worker Mistake": [],
    Process: [],
    "Prev Process": [],
    "Stock Controller": [],
  };
  remarkOptions = [];
  mistakeOffData = {
    mistakeOff: null,
    userId: null,
    otherRemark: null,
    remark: null,
    process: null,
  };
  mistakeOffDataBackup = {
    mistakeOff: null,
    userId: null,
    otherRemark: null,
    remark: null,
    process: null,
  };
  showRejectTask: boolean = false;
  @ViewChild("Modal") modal: ElementRef;
  updatedSuggestedPlans: number[] = [];
  @ViewChild("slider", { static: false }) slider: IonSlides;
  defaultimgindex: any;
  downloadindex: any = 0;
  cardData: any = [];
  wageActionName: string;
  wageAmount: number;
  selectedIndex: any;
  showWageModal: boolean = false;
  defaultIndexSuggested: number;
  selectedDipatchDepartment: null;
  TransferdataSource: any = [];
  detailColumns = [];
  selectRoles: string;
  deptDropdownDataOptions: any;
  allDeptValues: any;
  selectedWage: any;
  isRepairing:number=0;
  isDispatchManager: any;
   currentDate = new Date();
  @HostListener("document:keydown", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    switch (event.key) {
      case "ArrowLeft":
        this.slidePrev();
        break;
      case "ArrowRight":
        this.slideNext();
        break;
    }
  }

  constructor(
    private httpService: HttpService,
    private store: Store<AppState>,
    private alertCtrl: AlertController,
    private configService: ConfigService,
    private modalController: ModalController,
    private router: Router,
    private navParams: NavParams,
    public popoverController: PopoverController
  ) {
    this.detailColumns = [
      { name: "Process", prop: "actionName" },
      { name: "name", prop: "wageName" },
      { name: "Wage as per rule", prop: "amount" },
      { name: "Amount", prop: "isActive", columnTemplate: "isInput" },
    ];
  }

  async ngOnInit() {
    this.receivedData = (await this.LockMasterData) || this.navParams.get("data");
    this.showHeader = (await this.receivedData?.isheader) ?? true;
    this.store.dispatch(masterDataActions.getTrybeDepartments({ packetIds: null }));

    this.store
      .pipe(select(getCurrentAction))
      .pipe(take(1))
      .subscribe((data: any) => {
        this.currentAction = data;
        if (this.currentAction == null) {
          let obj = {
            name: this.receivedData?.actionName,
            id: this.receivedData?.data?.virtualId,
            status: "",
            isVirtual: true,
          };
          this.currentAction = obj;
        }
        this.setDataOnBasicOfCondition();
      });

    this.store.pipe(select(getAllTrybeDepartments)).subscribe(data => {
      if (data) {
        this.allDeptValues = data;
        this.deptDropdownDataOptions = this.allDeptValues;
      }
    });

    await this.store
      .pipe(select(authUser))
      .pipe(take(1))
      .subscribe((data: any) => {
        this.currentUser = data;
        this.isDispatchManager = this?.currentUser?.roles?.includes("DISPATCH Manager");
      });
  }

  setDataOnBasicOfCondition() {
    if (this.currentAction.name == ACTIONNAMES.lockMaster || this.currentAction.name == ACTIONNAMES.lockMaster2) {
      this.showRejectTask = true;
      this.getLockMasterData(); // dynamically getting all options
    } else if (!!this.receivedData && this.currentAction.name == ACTIONNAMES.verifyRepairingAsking) {
      this.selectedPacketId = this.receivedData?.data?.id;
      this.allPackets = [this.receivedData?.data]
      // this.getLockMasterData();
      this.packetSelection(this.receivedData?.data?.id);
      if (this.currentAction.isVirtual) {
        this.showRejectTask = true;
      }
    } else if (!!this.receivedData && this.currentAction.name == ACTIONNAMES.UNIVERSAL_OPINION_VIEW) {
      this.selectedPacketId = this.receivedData?.data?.id;
      this.packetSelectionUniversalView(this.selectedPacketId);
    } else {
      this.getMistakeEntryData();
    }
  }

  async getMistakeEntryData() {
    try {
      const data = await this.httpService.getDataWithoutPayloadForGet(true, "packet/getPacketOfCurrDept").toPromise();
      this.allPackets = (data?.data || []).filter((dt: any) => !(dt?.isHold == 1));
      if (this.allPackets?.length) {
        if (this.currentAction.name != ACTIONNAMES.UNIVERSAL_OPINION_VIEW) {
          this.selectedPacketId = this.allPackets[0].id;
          if (this.currentAction.name === ACTIONNAMES.mistakeEntry2) {
            this.getMistakeOfOptions(this.selectedPacketId);
          }
        }
        this.packetSelection(this.selectedPacketId);
      }
    } catch (error) {
      console.error("Error fetching getPacketOfCurrDept data:", error);
    }
  }
  async getMistakeOfOptions(id) {
    console.log(this.selectedPacketId);
    try {
      const data = await this.httpService.getDataWithoutPayloadForGet(true, `packet/getPacketIsAvaiableForLastDeptOpinion?packetId=${id}`).toPromise();
      if (data?.data?.length) {
        this.mistakeOfOptions = data.data;
      }
    } catch (error) {
      console.error("Error fetching getPacketOfCurrDept data:", error);
    }
  }

  async getLockMasterData() {
    // dynamically getting all options
    this.store
      .pipe(select(getMyTasks))
      .pipe(take(1))
      .subscribe(res => {
        this.allPackets = res.data[this.currentAction.name];
        if (this.allPackets?.length) {
          this.selectedPacketId = this.allPackets[0].id;
          this.packetSelection(this.selectedPacketId);
        }
      });
  }

  async getUsersDropdownData() {
    this.httpService
      .fetchData(true, `packet/getCheckerOrSmartRecutForPacket?packetId=${this.selectedPacketId}&isChecker=1`)
      .pipe(take(1))
      .subscribe((res: any) => {
        this.listOfUsersOptions.Checker = res.data || [];
      });

    this.httpService
      .fetchData(true, `packet/getCheckerOrSmartRecutForPacket?packetId=${this.selectedPacketId}&isChecker=2`)
      .pipe(take(1))
      .subscribe((res: any) => {
        this.listOfUsersOptions["Smart Recut"] = res.data || [];
      });

    this.httpService
      .fetchData(true, `packet/getUserOnBasisOfMistakeOffSelection?packetId=${this.selectedPacketId}&process=2`)
      .pipe(take(1))
      .subscribe((res: any) => {
        this.listOfUsersOptions["Stock Controller"] = res.data || [];
      });

    this.httpService
      .fetchData(true, `packet/getUserOnBasisOfMistakeOffSelection?packetId=${this.selectedPacketId}&process=1`)
      .pipe(take(1))
      .subscribe((res: any) => {
        this.listOfUsersOptions.Process = [res.data];
        this.listOfUsersOptions["Prev Process"] = [res.data];
      });

    this.httpService
      .fetchData(true, `packet/getUserOnBasisOfMistakeOffSelection?packetId=${this.selectedPacketId}&process=0`)
      .pipe(take(1))
      .subscribe((res: any) => {
        this.listOfUsersOptions.Worker = res.data || [];
      });

    this.httpService
      .fetchData(true, `packet/getUserOnBasisOfMistakeOffSelection?packetId=${this.selectedPacketId}&process=2`)
      .pipe(take(1))
      .subscribe((res: any) => {
        this.listOfUsersOptions["No worker Mistake"] = res.data || [];
      });
  }

  changesTaskStatus(id: number) {
    let endpoint: string = `${virtualAPIs.statusVirtualTaskEndPoint}${id}`;
    this.httpService.fetchData(true, endpoint).subscribe();
  }

  mistakeOffChange(event: string) {
    if (event == "Prev Process" || event == "Process") {
      this.mistakeOffData.process = this.listOfUsersOptions?.Process?.[0]?.actionName;
      this.mistakeOffData.userId = this.listOfUsersOptions?.Process?.[0]?.id;
    } else if (event == "Stock Controller") {
      this.mistakeOffData.process = this.listOfUsersOptions["Stock Controller"]?.[0]?.actionName;
      this.mistakeOffData.userId = this.listOfUsersOptions["Stock Controller"]?.[0]?.id;
    } else {
      this.mistakeOffData.process = null;
      this.mistakeOffData.userId = this.listOfUsersOptions[event]?.[0]?.id || null;
      this.mistakeOffData.remark = null;
      this.mistakeOffData.otherRemark = null;
    }

    if (event != '"No worker Mistake') {
      this.fetchCardData(this.mistakeOffData?.userId);
    }
  }

  getOpinionRemark() {
    this.httpService
      .fetchData(true, "packet/getOpnionRemark")
      .pipe(take(1))
      .subscribe((res: any) => {
        this.remarkOptions = res.data || [];
      });
  }

  async packetSelection(event: number) {
    if (!event) return;
    this.resetData();
    this.cardData = [];
    if (this.currentAction.name == ACTIONNAMES.lockMaster || this.currentAction.name == ACTIONNAMES.lockMaster2) {
      const selectedPacket = this.allPackets.find((dt: any) => dt.id === this.selectedPacketId);
      this.changesTaskStatus(selectedPacket.virtualId);
    }
    this.getTensionPhoto(this.selectedPacketId);
    try {
      const res = await this.httpService.getDataWithoutPayloadForGet(true, `opinion-plans/getOpinionPlans?packetId=${this.selectedPacketId}`).toPromise();
      if (res.data) {
        this.wageActionName = res.data.actionName || "";
        this.wageAmount = res.data.amount ? parseFloat(Number(res.data.amount).toFixed(2)) : null;
        this.isRepairing = res.data.isRepairing,
        res.data.actualPlans = res.data.actualPlans.map((dt: any) => {
          if (this.project == "kg") {
            if (dt.pricingResponse) {
              dt.pricingResponse = JSON.parse(dt.pricingResponse);
              let finalPrice = 0;
              let mpmBasePd = dt.pricingResponse["MPM BASE_PD"] !== undefined ? dt.pricingResponse["MPM BASE_PD"] : 0;
              let mixPrice = dt.pricingResponse["MIX PRICE"] !== undefined ? dt.pricingResponse["MIX PRICE"] : 0;
              finalPrice = Math.max(mpmBasePd, mixPrice);
              dt.kgRate = Math.round(finalPrice * Number(dt.expPol) * 100) / 100;
              dt.net_stone_value = dt.kgRate;
            }
          } else if (this.project == "kps") {
            if (dt.pricingResponse) {
              dt.pricingResponse = JSON.parse(dt.pricingResponse);
              dt.kgRate = dt.pricingResponse.stone_value;
              dt.net_stone_value = dt.pricingResponse.net_stone_value;
              dt.assort = dt.pricingResponse.A_ASTNO;
              dt.trend = dt.pricingResponse.trend;
            }
          }
          dt.pricingResponse = JSON.stringify(dt.pricingResponse);
          return dt;
        });

        res.data.suggestedPlans = res.data.suggestedPlans.map((dt: any, i: number) => {
          dt.plans = dt.plans.map((pdt: any) => {
            if (this.project == "kg") {
              if (pdt.pricingResponse) {
                pdt.pricingResponse = JSON.parse(pdt.pricingResponse);
                let finalPrice = 0;
                let mpmBasePd = pdt.pricingResponse["MPM BASE_PD"] !== undefined ? pdt.pricingResponse["MPM BASE_PD"] : 0;
                let mixPrice = pdt.pricingResponse["MIX PRICE"] !== undefined ? pdt.pricingResponse["MIX PRICE"] : 0;
                finalPrice = Math.max(mpmBasePd, mixPrice);
                pdt.kgRate = Math.round(finalPrice * Number(pdt.expPol) * 100) / 100;
                pdt.net_stone_value = pdt.pricingResponse.net_stone_value;
              }
            } else if (this.project == "kps") {
              if (pdt.pricingResponse) {
                pdt.pricingResponse = JSON.parse(pdt.pricingResponse);
                pdt.kgRate = pdt.pricingResponse.stone_value;
                pdt.net_stone_value = pdt.pricingResponse.net_stone_value;
                pdt.assort = pdt.pricingResponse.A_ASTNO;
                pdt.trend = pdt.pricingResponse.trend;
                pdt.finalValue = pdt.net_stone_value
              }
            }
            pdt.pricingResponse = JSON.stringify(pdt.pricingResponse);
            // pdt.planRowStatus = JSON.parse(pdt.planRowStatus)
            return pdt;
          });

          let newStonePRice = dt.plans.map(e => e["net_stone_value"]);
          let newPrice = newStonePRice.reduce((sum, current) => sum + current, 0);
          dt.netStoneValues = newPrice;

          if (dt.mistakeOffList) {
            dt = {
              ...dt,
              plans: dt.plans.map((pdt: any) => {
                return {
                  ...pdt,
                  ...JSON.parse(dt.mistakeOffList)[0],
                };
              }),
            };
          }

          if (dt.isSelected) {
            this.checkedSuggestedPlan = i;
            this.selectedIndex = i;
            this.defaultIndexSuggested = i;
            this.previousSuggestedPlan = i;
          }

          return dt;
        });

        this.selectedPacketData = res.data;

        if (res.data.mistakeEntry) {
          this.mistakeOffData = {
            mistakeOff: res.data.mistakeEntry.mistakeOff,
            userId: res.data.mistakeEntry.userId,
            otherRemark: res.data.mistakeEntry.otherRemark,
            remark: res.data.mistakeEntry.remark,
            process: res.data.mistakeEntry.process,
          };
          this.mistakeOffDataBackup = {
            mistakeOff: res.data.mistakeEntry.mistakeOff,
            userId: res.data.mistakeEntry.userId,
            otherRemark: res.data.mistakeEntry.otherRemark,
            remark: res.data.mistakeEntry.remark,
            process: res.data.mistakeEntry.process,
          };
        }

        this.countRankAndDiff();
        this.getUsersDropdownData();
        this.getOpinionRemark();
      }
    } catch (error) {
      console.error("Error fetching getOpinionPlans data:", error);
    }
  }

  async packetSelectionUniversalView(event: number) {
    if (!event) return;
    this.resetData();

    this.getTensionPhoto(this.selectedPacketId);
    try {
      const res = await this.httpService.getDataWithoutPayloadForGet(true, `opinion-plans/getOpinionPlansOnHistory?packetId=${this.selectedPacketId}`).toPromise();
      if (res.data) {
        res.data.actualPlans = res.data.actualPlans.map((dt: any) => {
          if (this.project == "kg") {
            if (dt.pricingResponse) {
              dt.pricingResponse = JSON.parse(dt.pricingResponse);
              let finalPrice = 0;
              let mpmBasePd = dt.pricingResponse["MPM BASE_PD"] !== undefined ? dt.pricingResponse["MPM BASE_PD"] : 0;
              let mixPrice = dt.pricingResponse["MIX PRICE"] !== undefined ? dt.pricingResponse["MIX PRICE"] : 0;
              finalPrice = Math.max(mpmBasePd, mixPrice);
              dt.kgRate = Math.round(finalPrice * Number(dt.expPol) * 100) / 100;
              dt.net_stone_value = dt.kgRate;
            }
          } else if (this.project == "kps") {
            if (dt.pricingResponse) {
              dt.pricingResponse = JSON.parse(dt.pricingResponse);
              dt.kgRate = dt.pricingResponse.stone_value;
              dt.net_stone_value = dt.pricingResponse.net_stone_value;
              dt.assort = dt.pricingResponse.A_ASTNO;
              dt.trend = dt.pricingResponse.trend;
            }
          }
          dt.pricingResponse = JSON.stringify(dt.pricingResponse);
          return dt;
        });

        res.data.suggestedPlans = res.data.suggestedPlans.map((dt: any, i: number) => {
          dt.plans = dt.plans.map((pdt: any) => {
            if (this.project == "kg") {
              if (pdt.pricingResponse) {
                pdt.pricingResponse = JSON.parse(pdt.pricingResponse);
                let finalPrice = 0;
                let mpmBasePd = pdt.pricingResponse["MPM BASE_PD"] !== undefined ? pdt.pricingResponse["MPM BASE_PD"] : 0;
                let mixPrice = pdt.pricingResponse["MIX PRICE"] !== undefined ? pdt.pricingResponse["MIX PRICE"] : 0;
                finalPrice = Math.max(mpmBasePd, mixPrice);
                pdt.kgRate = Math.round(finalPrice * Number(pdt.expPol) * 100) / 100;
                pdt.net_stone_value = pdt.pricingResponse.net_stone_value;
              }
            } else if (this.project == "kps") {
              if (pdt.pricingResponse) {
                pdt.pricingResponse = JSON.parse(pdt.pricingResponse);
                pdt.kgRate = pdt.pricingResponse.stone_value;
                pdt.net_stone_value = pdt.pricingResponse.net_stone_value;
                pdt.assort = pdt.pricingResponse.A_ASTNO;
                pdt.trend = pdt.pricingResponse.trend;
              }
            }
            pdt.pricingResponse = JSON.stringify(pdt.pricingResponse);
            // pdt.planRowStatus = JSON.parse(pdt.planRowStatus)
            return pdt;
          });

          let newStonePRice = dt.plans.map(e => e["net_stone_value"]);
          let newPrice = newStonePRice.reduce((sum, current) => sum + current, 0);
          dt.netStoneValues = newPrice;

          if (dt.mistakeOffList) {
            dt = {
              ...dt,
              plans: dt.plans.map((pdt: any) => {
                return {
                  ...pdt,
                  ...JSON.parse(dt.mistakeOffList)[0],
                };
              }),
            };
          }

          if (dt.isSelected) {
            this.checkedSuggestedPlan = i;
            this.previousSuggestedPlan = i;
          }

          return dt;
        });

        this.selectedPacketData = res.data;

        if (res.data.mistakeEntry) {
          this.mistakeOffData = {
            mistakeOff: res.data.mistakeEntry.mistakeOff,
            userId: res.data.mistakeEntry.userId,
            otherRemark: res.data.mistakeEntry.otherRemark,
            remark: res.data.mistakeEntry.remark,
            process: res.data.mistakeEntry.process,
          };
          this.mistakeOffDataBackup = {
            mistakeOff: res.data.mistakeEntry.mistakeOff,
            userId: res.data.mistakeEntry.userId,
            otherRemark: res.data.mistakeEntry.otherRemark,
            remark: res.data.mistakeEntry.remark,
            process: res.data.mistakeEntry.process,
          };
        }

        this.countRankAndDiff();
        this.getUsersDropdownData();
        this.getOpinionRemark();
      }
    } catch (error) {
      console.error("Error fetching getOpinionPlans data:", error);
    }
  }

  countRankAndDiff() {
    this.selectedPacketData.suggestedPlans.map((dt: any) => {
      let newStonePRice = dt.plans.map(e => e["net_stone_value"]);
      let newPrice = newStonePRice.reduce((sum, current) => sum + current, 0);
      dt.netStoneValues = newPrice;
    });

    const uniqueValues = [...new Set(this.selectedPacketData.suggestedPlans.map(item => item.netStoneValues))];
    uniqueValues.sort((a: any, b: any) => b - a);
    const rankMap = new Map();
    uniqueValues.forEach((value, index) => {
      rankMap.set(value, index + 1);
    });
    const ACTUAL_NET_STONE_VALUE = this.selectedPacketData.actualPlans[this.selectedPacketData.actualPlans.length - 1]?.net_stone_value;
    this.selectedPacketData.suggestedPlans.forEach(item => {
      item.plans.map((pdt: any) => {
        if (item.netStoneValues) {
          pdt.rank = rankMap.get(item.netStoneValues);
          if (ACTUAL_NET_STONE_VALUE) {
            pdt.diff = (((item.netStoneValues - ACTUAL_NET_STONE_VALUE) / ACTUAL_NET_STONE_VALUE) * 100).toFixed(2) + " %";
            if (((item.netStoneValues - ACTUAL_NET_STONE_VALUE) / ACTUAL_NET_STONE_VALUE) * 100 > 0) {
              pdt.className = "f-green";
            } else if (((item.netStoneValues - ACTUAL_NET_STONE_VALUE) / ACTUAL_NET_STONE_VALUE) * 100 < 0) {
              pdt.className = "f-red";
            }
          }
        }
      });
    });
  }

  resetData() {
    this.checkedSuggestedPlan = -1;
    this.selectedIndex = -1;
    this.selectedDipatchDepartment = null;
    this.TransferdataSource = [];
    this.showWageModal = false;
    this.mistakeOffData = {
      mistakeOff: null,
      userId: null,
      otherRemark: null,
      remark: null,
      process: null,
    };
    this.mistakeOffDataBackup = {
      mistakeOff: null,
      userId: null,
      otherRemark: null,
      remark: null,
      process: null,
    };
    this.selectedPacketData = {
      actualPlans: [],
      suggestedPlans: [],
    };
    this.updatedSuggestedPlans = [];
    this.previousSuggestedPlan = -1;
  }

  planSelection(event: CustomEvent, index: number) {
    if (this.receivedData?.viewOnly) {
      event.stopPropagation(); return;
    }
    if (event.detail.checked) {
      if (this.checkedActualPlan > -1) this.selectedPacketData.actualPlans[this.checkedActualPlan].isSelected = false;
      this.checkedActualPlan = index;
    } else {
      if (!this.selectedPacketData.actualPlans.find((dt: any) => dt.isSelected)) this.checkedActualPlan = -1;
    }
  }

  onCheckboxClick(event: Event,index : number) {
    if (this.selectedIndex !== null && this.selectedIndex !== index) {
      this.showWageModal = true;
      // this.selectedIndex = index;
    } else if (this.selectedIndex === null || this.defaultIndexSuggested === index) {
      this.showWageModal = false;
      // this.selectedIndex = index;
    }
    event.stopPropagation();
  }

  suggestedPlanSelection(event: CustomEvent, index: number) {
      if (this.receivedData?.viewOnly) {
      event.stopPropagation(); return;
    }
    if (this.currentAction.name === ACTIONNAMES.lockMaster2 && this.isDispatchManager) {
      // console.log(this.selectedIndex)
      // debugger
      // if (this.selectedIndex !== null && this.selectedIndex !== index) {
      //   this.showWageModal = true;
      //   // this.selectedIndex = index;
      // } else if (this.selectedIndex === null || this.defaultIndexSuggested === index) {
      //   this.showWageModal = false;
      //   // this.selectedIndex = index;
      // }
    }

    if (event.detail.checked) {
      if (this.checkedSuggestedPlan > -1 && this.checkedSuggestedPlan !== index) this.selectedPacketData.suggestedPlans[this.checkedSuggestedPlan].isSelected = false;
      this.checkedSuggestedPlan = index;
    } else {
      if (!this.selectedPacketData.suggestedPlans.find((dt: any) => dt.isSelected)) {
        this.checkedSuggestedPlan = -1;
      }
    }
  }

  trackByName(i: number, column: any): string {
    return column.name;
  }

  async viewHistory() {
    const historyData = await this.httpService.fetchDataUsingPromise(true, `opinion-plans/getOpinionPlanHistory?packetId=${this.selectedPacketId}`);
    if (historyData.data.length) {
      historyData.data = historyData.data.map((dt: any, i: number) => {
        dt.plans = dt.plans.map((pdt: any) => {
          if (this.project == "kg") {
            if (pdt.pricingResponse) {
              pdt.pricingResponse = JSON.parse(pdt.pricingResponse);
              let finalPrice = 0;
              let mpmBasePd = pdt.pricingResponse["MPM BASE_PD"] !== undefined ? pdt.pricingResponse["MPM BASE_PD"] : 0;
              let mixPrice = pdt.pricingResponse["MIX PRICE"] !== undefined ? pdt.pricingResponse["MIX PRICE"] : 0;
              finalPrice = Math.max(mpmBasePd, mixPrice);
              pdt.kgRate = Math.round(finalPrice * Number(pdt.expPol) * 100) / 100;
              pdt.net_stone_value = pdt.pricingResponse.net_stone_value;
            }
          } else if (this.project == "kps") {
            if (pdt.pricingResponse) {
              pdt.pricingResponse = JSON.parse(pdt.pricingResponse);
              pdt.kgRate = pdt.pricingResponse.stone_value;
              pdt.net_stone_value = pdt.pricingResponse.net_stone_value;
              pdt.assort = pdt.pricingResponse.A_ASTNO;
              pdt.trend = pdt.pricingResponse.trend;
            }
          }
          pdt.pricingResponse = JSON.stringify(pdt.pricingResponse);
          return pdt;
        });
        let newStonePRice = dt.plans.map(e => e["net_stone_value"]);
        let newPrice = newStonePRice.reduce((sum, current) => sum + current, 0);
        dt.netStoneValues = newPrice;
        if (dt.mistakeOffList) {
          dt = {
            ...dt,
            plans: dt.plans.map((pdt: any) => {
              return {
                ...pdt,
                ...JSON.parse(dt.mistakeOffList)[0],
              };
            }),
          };
        }
        return dt;
      });

      const popover = await this.popoverController.create({
        component: OpinionViewHistoryComponent,
        cssClass: "planningRowOrderspopoup",
        componentProps: { historyData: historyData.data },
      });
      await popover.present();
    } else {
      this.configService.showToast("error", "No history data found!");
    }
  }

  keysToChange = {
    clarity: "clarityId",
    nattsName: "natts",
    color: "colorId",
    fluorescence: "fluorescenceId",
    tinchName: "tinch",
    lusterName: "luster",
    shape: "shapeId",
    // labName: 'labName',
    cut: "cutId",
    pol: "polishId",
    symmetry: "symmetryId",
    depthPercentage: "height",
    ratio: "diameter",
    // topOpenName: 'topOpenName',
    // bottomOpenName: 'bottomOpenName',
  };

  async actualPlanCopy() {
     this.currentDate.setMinutes(this.currentDate.getMinutes() + this.currentDate.getTimezoneOffset() + 330);
        const indianTimeFormatter = new Intl.DateTimeFormat('en-IN', {
             year: 'numeric',
             month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
           hour12: false,
          timeZone: 'Asia/Kolkata'
        });
    if (!(this.checkedActualPlan > -1)) return;

    let planCopy = { ...this.selectedPacketData.actualPlans[this.checkedActualPlan] };
    for (const key in this.keysToChange) {
      planCopy[key] = planCopy[this.keysToChange[key]];
    }
    planCopy.depthPercentage = planCopy.height;
    planCopy.labName = planCopy.mLabName ? planCopy.mLabName : planCopy.mLab;
    const popover = await this.popoverController.create({
      component: PopoverUserComponent,
      cssClass: "planningRowParameterSelection",
      componentProps: { forPage: "planningRowParameterSelection", gridRow: planCopy, enableSawRghWgt: true },
    });
    await popover.present();
    popover.onDidDismiss().then(async (res: any) => {
      if (res?.data?.gridRow) {
        res.data.gridRow.mLabName = res.data.gridRow.labName;
        res.data.gridRow.mLab = res.data.gridRow.labName;
        const pricingRes = await this.pricingCall(res.data.gridRow);
        if (!pricingRes) {
          this.configService.showToast("error", "Pricing call failed!");
          return;
        }
        planCopy = { ...res.data.gridRow };
        if (this.project == "kg") {
          let finalPrice = 0;
          let mpmBasePd = pricingRes["MPM BASE_PD"] !== undefined ? pricingRes["MPM BASE_PD"] : 0;
          let mixPrice = pricingRes["MIX PRICE"] !== undefined ? pricingRes["MIX PRICE"] : 0;
          finalPrice = Math.max(mpmBasePd, mixPrice);
          planCopy.kgRate = Math.round(finalPrice * Number(planCopy.expPol) * 100) / 100;
          planCopy.net_stone_value = pricingRes.net_stone_value;
          planCopy.pointer_range = pricingRes.pointer_range;
        } else if (this.project == "kps") {
          planCopy.kgRate = pricingRes.stone_value;
          planCopy.net_stone_value = pricingRes.net_stone_value;
          planCopy.mLabName = pricingRes.mLab;
          planCopy.labName = pricingRes.mLab;
          planCopy.finalValue = pricingRes.net_stone_value;
        }
        if (this.currentAction.name === ACTIONNAMES.verifyRepairingAsking) {
          planCopy.iscopy = true;
        }
        planCopy.assort = pricingRes.A_ASTNO;
        planCopy.trend = pricingRes.trend;
        planCopy.mLabName = pricingRes.mLab;
        planCopy.mLab = pricingRes.mLab;
        let obj = {
          id: planCopy.id,
          packetPlanStones: [planCopy],
        };
        let payloadForOrder = [];
        payloadForOrder.push(obj);
        let data = await this.updateOrderFormAfterUpdatingParam(payloadForOrder);
        planCopy.pricingResponse = JSON.stringify(pricingRes);
        // delete planCopy.Id;
        // delete planCopy.id;
        // delete planCopy.isSelected;
        // delete planCopy.deptId;
        // delete planCopy.deptName;
        // delete planCopy.process;
        // delete planCopy.userId;
        // delete planCopy.mistakeOff;
        // delete planCopy.remark;
        // delete planCopy.approvals;
        // planCopy = data[0].packetPlanStones[0];
        if (data?.length > 0) {
          planCopy = data[0].packetPlanStones.map(({ approvals, ...stone }) => stone)[0];  
        }
        console.log(this.selectedPacketId);
        planCopy.height = planCopy.depthPercentage;
        planCopy.subSeqTag = "A";
        const indianFormattedDate = indianTimeFormatter.format(this.currentDate).replace(/,/, '').replace(/\//g, '-');
        planCopy.uniqueId = `${this.selectedPacketId}-${indianFormattedDate}-${this.currentUser.id}`;
        delete planCopy.Id;
        delete planCopy.id;
        delete planCopy.isSelected;
        delete planCopy.deptId;
        delete planCopy.deptName;
        delete planCopy.process;
        delete planCopy.userId;
        delete planCopy.mistakeOff;
        delete planCopy.remark;
        delete planCopy.approvals;
        this.selectedPacketData.suggestedPlans.push({ plans: [planCopy], isCopied: true, createdby: this.currentUser.fullName,uniqueId : `sugges-${this.selectedPacketId}-${indianFormattedDate}-${this.currentUser.id}` });
        this.countRankAndDiff();

        this.selectedPacketData.actualPlans[this.checkedActualPlan].isSelected = false;
      }
    });
  }

  suggestedPlanCopy() {
    if (!(this.checkedSuggestedPlan > -1)) return;
    const copyPlan = JSON.parse(JSON.stringify({ ...this.selectedPacketData.suggestedPlans[this.checkedSuggestedPlan], createdby: this.currentUser.fullName }));
    if (this.currentAction.name === ACTIONNAMES.verifyRepairingAsking) {
      copyPlan.iscopy = true;
    }
    delete copyPlan.isSelected;
    delete copyPlan.id;
    copyPlan.plans = copyPlan.plans.map((pdt: any) => {
      delete pdt.approvals;
      delete pdt.remark;
      delete pdt.mistakeOff;
      delete pdt.process;
      delete pdt.deptId;
      delete pdt.userId;
      delete pdt.planGiveBy;
      console.log(pdt);

      return pdt;
    });

    copyPlan.labName = copyPlan.mLabName ? copyPlan.mLabName : copyPlan.mLab;
    this.selectedPacketData.suggestedPlans[this.checkedSuggestedPlan].isSelected = false;
    this.selectedPacketData.suggestedPlans.push(copyPlan);
  }

  async suggestedPlanAdd() {
    let planCopy: any;
    if (this.selectedPacketData?.suggestedPlans?.[this.checkedSuggestedPlan]?.plans?.length) {
      planCopy = { ...this.selectedPacketData.suggestedPlans[this.checkedSuggestedPlan].plans[0] };
      planCopy.depthPercentage = planCopy.height;
      planCopy.labName = planCopy.mLabName ? planCopy.mLabName : planCopy.mLab;
    }
    const popover = await this.popoverController.create({
      component: PopoverUserComponent,
      cssClass: "planningRowParameterSelection",
      componentProps: { forPage: "planningRowParameterSelection", gridRow: planCopy, enableSawRghWgt: true },
    });
    await popover.present();
    popover.onDidDismiss().then(async (res: any) => {
      if (res?.data?.gridRow) {
        res.data.gridRow.mLabName = res.data.gridRow.labName;
        res.data.gridRow.mLab = res.data.gridRow.labName;

        const pricingRes = await this.pricingCall(res.data.gridRow);
        if (!pricingRes) {
          this.configService.showToast("error", "Pricing call failed!");
          return;
        }
        let newPlanCopy = { ...res.data.gridRow };
        if (this.project == "kg") {
          let finalPrice = 0;
          let mpmBasePd = pricingRes["MPM BASE_PD"] !== undefined ? pricingRes["MPM BASE_PD"] : 0;
          let mixPrice = pricingRes["MIX PRICE"] !== undefined ? pricingRes["MIX PRICE"] : 0;
          finalPrice = Math.max(mpmBasePd, mixPrice);
          newPlanCopy.kgRate = Math.round(finalPrice * Number(newPlanCopy.expPol) * 100) / 100;
          newPlanCopy.net_stone_value = pricingRes.net_stone_value;
          newPlanCopy.pointer_range = pricingRes.pointer_range;
        } else if (this.project == "kps") {
          newPlanCopy.kgRate = pricingRes.stone_value;
          newPlanCopy.net_stone_value = pricingRes.net_stone_value;
          newPlanCopy.mLabName = pricingRes.mLab;
          newPlanCopy.labName = pricingRes.mLab;
          newPlanCopy.finalValue =  pricingRes.net_stone_value;
        }

        newPlanCopy.assort = pricingRes.A_ASTNO;
        newPlanCopy.trend = pricingRes.trend;
        newPlanCopy.mLabName = pricingRes.mLab;
        newPlanCopy.mLab = pricingRes.mLab;

        newPlanCopy.pricingResponse = JSON.stringify(pricingRes);
        delete newPlanCopy.Id;
        delete newPlanCopy.id;
        delete newPlanCopy.isSelected;
        newPlanCopy.height = newPlanCopy.depthPercentage;
        if (planCopy) {
          let letter = String.fromCharCode(65 + this.selectedPacketData.suggestedPlans[this.checkedSuggestedPlan].plans.length);
          newPlanCopy.subSeqTag = letter;
          this.selectedPacketData.suggestedPlans[this.checkedSuggestedPlan].plans.push(newPlanCopy);
        } else {
          this.selectedPacketData.suggestedPlans.push({ plans: [newPlanCopy], isCopied: true });
        }
        console.log(this.selectedPacketData.suggestedPlans);
        this.countRankAndDiff();
      }
    });
  }

  async suggestedPlanRowClicked(i: number, j: number) {
    if (this.selectedPacketData.suggestedPlans[i].createdby) {
      if (!(this.currentUser.fullName == this.selectedPacketData.suggestedPlans[i].createdby)) {
        this.configService.showToast("error", "You cannot update this plan!");
        return;
      }
    }

    let planCopy: any = { ...this.selectedPacketData.suggestedPlans[i].plans[j] };
    planCopy.depthPercentage = planCopy.height;
    planCopy.labName = planCopy.mLabName ? planCopy.mLabName : planCopy.mLab;
    const popover = await this.popoverController.create({
      component: PopoverUserComponent,
      cssClass: "planningRowParameterSelection",
      componentProps: { forPage: "planningRowParameterSelection", gridRow: planCopy, enableSawRghWgt: true },
    });
    await popover.present();
    popover.onDidDismiss().then(async (res: any) => {
      if (res?.data?.gridRow) {
        res.data.gridRow.mLabName = res.data.gridRow.labName;
        res.data.gridRow.mLab = res.data.gridRow.labName;
        const pricingRes = await this.pricingCall(res.data.gridRow);
        if (!pricingRes) {
          this.configService.showToast("error", "Pricing call failed!");
          return;
        }
        planCopy.height = planCopy.depthPercentage;
        let newPlanCopy = { ...res.data.gridRow };
        if (this.project == "kg") {
          let finalPrice = 0;
          let mpmBasePd = pricingRes["MPM BASE_PD"] !== undefined ? pricingRes["MPM BASE_PD"] : 0;
          let mixPrice = pricingRes["MIX PRICE"] !== undefined ? pricingRes["MIX PRICE"] : 0;
          finalPrice = Math.max(mpmBasePd, mixPrice);
          newPlanCopy.kgRate = Math.round(finalPrice * Number(newPlanCopy.expPol) * 100) / 100;
          newPlanCopy.net_stone_value = pricingRes.net_stone_value;
          newPlanCopy.pointer_range = pricingRes.pointer_range;
        } else if (this.project == "kps") {
          newPlanCopy.kgRate = pricingRes.stone_value;
          newPlanCopy.net_stone_value = pricingRes.net_stone_value;
          newPlanCopy.mLabName = pricingRes.mLab;
          newPlanCopy.labName = pricingRes.mLab;
          newPlanCopy.finalvalue = pricingRes.net_stone_value
        }

        newPlanCopy.assort = pricingRes.A_ASTNO;
        newPlanCopy.trend = pricingRes.trend;
        newPlanCopy.mLabName = pricingRes.mLab;
        newPlanCopy.mLab = pricingRes.mLab;
        newPlanCopy.pricingResponse = JSON.stringify(pricingRes);
        let obj = {
          id: planCopy.id,
          packetPlanStones: [newPlanCopy],
        };
        let data = await this.updateOrderFormAfterUpdatingParam([obj],i,j);
        newPlanCopy = data[0].packetPlanStones[0];
        this.selectedPacketData.suggestedPlans[i].plans[j] = newPlanCopy;
        if (!this.updatedSuggestedPlans.includes(i)) this.updatedSuggestedPlans.push(i);
        this.countRankAndDiff();
      }
    });
  }

  async pricingCall(plan: any) {
    let ratio = null;
    if (plan.shape == "ROUND" || plan.expPol < 0.18) {
      ratio = plan.diameter;
      plan.lOrW = ratio;
    } else {
      ratio = plan.lOrW;
      // ratio = Math.round(plan.lengthMM / plan.widthMM * 100) / 100
    }

    let lab = "";
    if (plan?.labName) {
      lab = plan.labName;
    } else if (plan?.mLab) {
      lab = plan?.mLab;
    } else if (plan?.mLabName) {
      lab = plan?.mLabName;
    } else if (plan?.labName == "-") {
      lab = "";
    } else {
      lab = plan.labName;
    }

    let res;

    if (this.project == "kps") {
      const kpPricingPayload = {
        id: plan?.id || 1, //
        mShape: plan?.shape,
        mCrts: plan?.expPol,
        mLab: lab || "",
        mColor: plan?.color,
        mPurity: plan?.clarity,
        mFlrc: plan?.fluorescence || "NONE",
        mCut: plan?.cut,
        mPol: plan?.pol,
        mSym: plan?.symmetry,
        mDepth: plan?.height || plan?.depthPercentage,
        mRatio: ratio,
        mNatts: plan?.nattsName || "NO BLK",
        mTinch: plan?.tinchName || "N/A",
        mMilky: plan?.lusterName || "NORMAL",
        mTO: plan.topOpenName ? plan.topOpenName : "N/A",
        mBO: plan.bottomOpenName ? plan.bottomOpenName : "N/A",
      };

      res = await new Promise((resolve, reject) => {
        this.httpService.getPrincing(environment.PricingUrl, [kpPricingPayload]).subscribe(
          (res: any) => {
            if (res.length > 0) {
              resolve(res[0]);
            } else {
              resolve("");
            }
          },
          (err: any) => {
            resolve("");
          }
        );
      });
    } else if (this.project == "kg") {
      let shape = plan?.shape == "R-EMD" ? "ASHOKA" : plan?.shape;
      ratio = Math.round((plan.lengthMM / plan.widthMM) * 100) / 100;
      const kgPricingPayload = {
        cts: plan?.expPol,
        Shape: shape,
        Clarity: plan?.clarity,
        Color: plan?.color,
        Cut: plan?.cut,
        Polish: plan?.pol,
        Symmetry: plan?.symmetry,
        Fluorescence: plan?.fluorescence || "NONE",
        ID: plan?.id || 1,
        "Pd Cat": plan?.pdCat,
        Length: plan.lengthMM,
        Width: plan.widthMM,
        "Table Per": Math.round(plan.tableVal * 100) / 100,
        "Tot Depth": plan?.height || plan?.depthPercentage,
        "New Cut": plan.newCut,
        L_W: ratio,
      };
      const kgPricingNewCutPayload = {
        expPol: plan?.expPol,
        shapeId: plan?.shapeId,
        clarityId: plan?.clarityId,
        colorId: plan?.colorId,
        cutId: plan?.cutId,
        polishId: plan?.polishId,
        symmetryId: plan?.symmetryId,
        fluorescenceId: plan?.fluorescenceId || 497,
        ID: plan?.id || 1,
        pdCatId: plan?.pdCatId,
        crAngle: plan?.crAngle,
        tableVal: Math.round(plan.tableVal * 100) / 100,
        height: plan?.height || plan?.depthPercentage,
        "New Cut": "",
        lOrW: ratio,
        girdle: plan?.girdle,
        tinch: plan?.tinch,
      };

      res = await new Promise((resolve, reject) => {
        this.httpService.getNewCutValue(kgPricingNewCutPayload).subscribe(
          (resCut: any) => {
            if (!!resCut.data) {
              kgPricingPayload["New Cut"] = resCut.data?.name || "";
            } else {
              kgPricingPayload["New Cut"] = "";
            }
            this.httpService.getPrincing(environment.PricingUrl, [kgPricingPayload]).subscribe(
              (res: any) => {
                if (res?.data?.[0]?.Errors) {
                  this.configService.showToast("error", res?.data?.[0]?.Errors);
                  return;
                }
                resolve(res.data[0]);
              },
              (err: any) => {
                resolve("");
              }
            );
          },
          (err: any) => {
            resolve("");
          }
        );
      });
    }
    return res;
  }

  async rejectTask() {
    const alert = await this.alertCtrl.create({
      header: "Are you sure you want to reject the task?",
      cssClass: "planAlertBox",
      inputs: [
        {
          type: "textarea",
          placeholder: "Remark",
          name: "remarkInput",
        },
      ],
      buttons: [
        {
          text: "NO",
          role: "cancel",
          handler: () => {},
        },
        {
          text: "YES",
          handler: (data: any) => {
            const remark = data.remarkInput;

            if (!remark || !remark.trim()) {
              this.configService.showToast("error", "Remark is compulsory.");
              return false;
            }

            this.callRejectTask(remark);
          },
        },
      ],
    });

    return await alert.present();
  }

  async callRejectTask(remark: string) {
    const rejectPayload = {
      packetId: this.selectedPacketId,
      remark: remark,
    };
    const res = await this.httpService.postDataUsingPromise(true, `opinion-plans/rejectAllTaskAndAddRemark`, rejectPayload);
    if (res.statusCode == 200) {
      this.configService.showToast("success", res.message);
      this.selectedPacketId = this.allPackets[this.allPackets.findIndex((dt: any) => dt.id == this.selectedPacketId) + 1]?.id;
      if (this.currentAction.isVirtual) {
        await this.modalController.dismiss();
        this.router.navigate(["/virtualTask"], {
          queryParams: { activetool: "myTask", actionName: this.currentAction.name },
        });
      } else if (this.selectedPacketId > -1) await this.packetSelection(this.selectedPacketId);
      else this.router.navigate(["/home"]);
    } else {
      this.httpService.getErrorAndDisplayIt(res);
    }
  }

  async deleteSuggestedPlan(i: number, j: number) {
    if (this.selectedPacketData.suggestedPlans[i].createdby) {
      if (!(this.currentUser.fullName == this.selectedPacketData.suggestedPlans[i].createdby)) {
        this.configService.showToast("error", "You cannot delete this plan!");
        return;
      }
    }

    const alert = await this.alertCtrl.create({
      header: "Are you sure you want to delete?",
      cssClass: "planAlertBox",
      buttons: [
        {
          text: "NO",
          handler: (d: any) => {
            // console.log('Closed', d);
          },
        },
        {
          text: "YES",
          handler: (d: any) => {
            if (this.selectedPacketData.suggestedPlans[i].plans?.length >= 1) {
              this.selectedPacketData.suggestedPlans[i].plans.splice(j, 1);
              this.selectedPacketData.suggestedPlans.forEach(plan => {
                delete plan.isCopied;
                delete plan.iscopy;
              });
            } else {
              this.selectedPacketData.suggestedPlans.splice(i, 1);
              if (this.updatedSuggestedPlans.includes(i)) {
                this.updatedSuggestedPlans.splice(i, 1);
                this.updatedSuggestedPlans = this.updatedSuggestedPlans.map((di: any) => {
                  if (di > i) {
                    return di - 1;
                  } else return di;
                });
              }
            }
            if (this.checkedSuggestedPlan == i) this.checkedSuggestedPlan = -1;
          },
        },
      ],
    });
    return await alert.present();
  }

  openFileDialog() {
    (document as any).getElementById("file-upload").click();
  }

  fileUpload(event: Event) {
    if (event?.target?.["files"]) {
      const formData = new FormData();
      formData.append("file", event.target["files"][0]);
      this.httpService.saveDataToDB(true, `opinion-plans/uploadPacketAdvisorCsv?packetId=${this.selectedPacketId}&actionId=${this.currentAction.id}`, formData, "Multipart").subscribe(res => {
        this.setDataOnBasicOfCondition();
        this.httpService.getErrorAndDisplayIt(res);
      });
    }
  }

  checkSaveConditions(): boolean {
    if (this.currentAction?.name === ACTIONNAMES.verifyRepairingAsking) {
      if (!(this.checkedSuggestedPlan > -1)) {
        this.configService.showToast("error", "Please select a plan to save!");
        return true;
      }
      return false;
    }

    if (!(this.checkedSuggestedPlan > -1)) {
      this.configService.showToast("error", "Please select a plan to save!");
      return true;
    }
    if (!this.mistakeOffData.remark) {
      this.configService.showToast("error", "Please select remark to save!");
      return true;
    }
    if (!this.mistakeOffData.mistakeOff) {
      this.configService.showToast("error", "Please select mistake of to save!");
      return true;
    }
    if (this.mistakeOffData.mistakeOff === "Worker" || this.mistakeOffData.mistakeOff === "Process" || this.mistakeOffData.mistakeOff === "Prev Process") {
      if (!this.mistakeOffData.userId) {
        this.configService.showToast("error", `For mistake of ${this.mistakeOffData.mistakeOff}, please select user!`);
        return true;
      }
    }
    return false;
  }

  async save() {
    if (this.showWageModal && this.isDispatchManager) {
      this.openTransferModal();
      return;
    }
    
    if ((this.isDispatchManager && (this.currentAction.name === ACTIONNAMES.lockMaster2)) || this.isRepairing) {
      
      let selectedPlanId: number;
      if (this.selectedPacketData.suggestedPlans.filter((dt: any) => !dt.id).length) selectedPlanId = await this.addNewPlans();

      if (!selectedPlanId) selectedPlanId = this.selectedPacketData.suggestedPlans[this.checkedSuggestedPlan].id;
      
      if (selectedPlanId) await this.updatePlans(selectedPlanId);
    }
    else {
      if (this.checkSaveConditions()) return;

      let selectedPlanId: number;
      if (this.selectedPacketData.suggestedPlans.filter((dt: any) => !dt.id).length) selectedPlanId = await this.addNewPlans();

      if (!selectedPlanId) selectedPlanId = this.selectedPacketData.suggestedPlans[this.checkedSuggestedPlan].id;

      if (selectedPlanId) await this.updatePlans(selectedPlanId);
    }
  }

  async addNewPlans() {
    const payload = {
      packetId: this.selectedPacketId,
      actionId: this.currentAction.id,
      listOfOpinion: this.selectedPacketData.suggestedPlans.filter((dt: any) => !dt.id),
    };
    const res = await this.httpService.postDataUsingPromise(true, "opinion-plans/addNewPlans", payload);

    if (res.statusCode == 200) {
      return res.data.planGrpId;
    } else {
      this.httpService.getErrorAndDisplayIt(res);
      return null;
    }
  }

  isOpinionActions = ["Opinion Generator", "Generate Opinion"];
  async updatePlans(selectedPlanId: number, isOpinion: boolean = null,isReject : boolean = null) {
    const updatePlans = [];
    if (this.updatedSuggestedPlans.length) {
      this.updatedSuggestedPlans.map((i: number) => {
        updatePlans.push(...this.selectedPacketData.suggestedPlans[i].plans);
      });
    }

    if (this.currentAction.name != ACTIONNAMES.verifyRepairingAsking) {
      isOpinion = this.isOpinionActions.includes(this.currentAction.name);
      if(this.isDispatchManager) {
        isOpinion = true
      }
    }

    let payload = {
      packetId: this.selectedPacketId,
      updatePlans: updatePlans,
      planGrpId: selectedPlanId,
      isOpinion: isOpinion,
      actionName: this.currentAction.name,
      isReject : isReject
    };
    let mistakeOffDataChanges = true;
    // for (const key in this.mistakeOffDataBackup) {
    //   if (this.mistakeOffDataBackup[key] != this.mistakeOffData[key])
    //     mistakeOffDataChanges = true;
    // }
    if (mistakeOffDataChanges) {
      payload = { ...payload, ...this.mistakeOffData };
    }
    const res = await this.httpService.postDataUsingPromise(true, "opinion-plans/markSelectPlanAddMistakeLogAndUpdatePlan", payload);

    if (res.statusCode == 200) {
      if (!this.isOpinionActions.includes(this.currentAction.name)) {
        if (this.currentAction.name == ACTIONNAMES.verifyRepairingAsking) {
          const actionId = this.receivedData?.data?.currAction;
          let isVirtual = this.receivedData?.data?.virtualId;
          if (isVirtual) {
            await this.inputReceiveByActionId(actionId, 1);
          } else {
            await this.inputReceiveByActionId(actionId);
          }
        } else {
          const actionId = this.allPackets.find((dt: any) => dt.id == this.selectedPacketId)?.["virtualAction"];
          await this.inputReceiveByActionId(actionId, 1);
        }
      }

      this.configService.showToast("success", res.message);
      if (this.currentAction.name !== ACTIONNAMES.verifyRepairingAsking) {
        const newSelectedPacketId = this.allPackets[this.allPackets.findIndex((dt: any) => dt.id == this.selectedPacketId) + 1]?.id;
        this.allPackets = this.allPackets.filter((dt: any) => dt.id != this.selectedPacketId);
        this.selectedPacketId = newSelectedPacketId;
      }
      if (this.selectedPacketId > -1 && this.currentAction.name !== ACTIONNAMES.verifyRepairingAsking) await this.packetSelection(this.selectedPacketId);
      else {
        await this.modalController.dismiss();
        this.router.navigate(["/home"]);
      }
    } else {
      this.httpService.getErrorAndDisplayIt(res);
    }
  }

  async inputReceiveByActionId(actionId, isVirtual = null) {
    const payload = {
      isTensionVirtual: isVirtual,
      listOfAllPackets: [
        {
          packetId: this.selectedPacketId,
          listOfParameter: [
            {
              parameterName: "Approval",
              parameterValue: "Done",
            },
          ],
        },
      ],
    };
    await this.httpService.postDataUsingPromise(true, `${ENDPOINTS.inputReceiverByActionId}/${actionId}`, payload);
  }

  async inputReceiveOnReject(isVirtual) {
    const payload = {
      isTensionVirtual: isVirtual,
      listOfAllPackets: [
        {
          packetId: this.selectedPacketId,
          listOfParameter: [
            {
              parameterName: "Approval",
              parameterValue: "Done",
            },
          ],
        },
      ],
    };
    const actionId = this.receivedData?.data?.currAction;
    await this.httpService.postDataUsingPromise(true, `${ENDPOINTS.inputReceiverByActionId}/${actionId}`, payload);
  }

 async onResetOrder(type:string, indexofChild: number, indexOfPlan?: number,) {
  if(type == 'actual'){
    let isActiveFound = false;
    // if (this.selectedPacketData.actualPlans.length) {
    //   this.selectedPacketData.actualPlans = [
    //       ...this.selectedPacketData.actualPlans?.["appliedOrders"]?.[0].map((dt: any) => ({ ...dt, isActive: dt.remark ? false : true, orderRemark: dt.remark })),
    //       ...(this.selectedPacketData.actualPlans || []),
    //     ];
    //     isActiveFound = true;
    //   }
      if (this.selectedPacketData.actualPlans.length)  {
        (this.selectedPacketData.actualPlans || []).map((dt: any) => {
          if (dt.isActive && !dt.orderRemark && !isActiveFound) {
            isActiveFound = true;
          }
        });
      }
      if (!isActiveFound) {
        let highestValue = 0,
          highestValueId;
        (this.selectedPacketData.actualPlans || []).map((dt: any) => {
          if (!dt?.orderRemark && highestValue < dt.finalValue) {
            highestValue = dt.finalValue;
            highestValueId = dt.id;
          }
        });
        (this.selectedPacketData.actualPlans || []).map((dt: any, i: number) => {
          if (!dt?.orderRemark && highestValueId == dt.id) {
            this.selectedPacketData.actualPlans[i]["isActive"] = true;
    } else {
      this.selectedPacketData.actualPlans[i]["isActive"] = false;
          }
        });
    }
  }
else{
    let isActiveFound = false;
  if (this.selectedPacketData.suggestedPlans?.[indexOfPlan]?.[indexofChild]?.["plans"][0]?.length) {
      this.selectedPacketData.suggestedPlans[indexOfPlan]["plans"] = [
        ...this.selectedPacketData.suggestedPlans?.[indexOfPlan]?.["appliedOrders"][0].map((dt: any) => ({ ...dt, isActive: dt.remark ? false : true, orderRemark: dt.remark })),
        ...(this.selectedPacketData.suggestedPlans?.[indexOfPlan]?.["plans"] || []),
      ];
      isActiveFound = true;
    } else {
      (this.selectedPacketData.suggestedPlans?.[indexOfPlan]?.["plans"] || []).map((dt: any) => {
        if (dt.isActive && !dt.orderRemark && !isActiveFound) {
          isActiveFound = true;
        }
      });
    }
    if (!isActiveFound) {
      let highestValue = 0,
        highestValueId;
      (this.selectedPacketData.suggestedPlans?.[indexOfPlan]?.["plans"] || []).map((dt: any) => {
        if (!dt?.orderRemark && highestValue < dt.finalValue) {
          highestValue = dt.finalValue;
          highestValueId = dt.id;
        }
      });
      (this.selectedPacketData.suggestedPlans?.[indexOfPlan]?.["plans"] || []).map((dt: any, i: number) => {
        if (!dt?.orderRemark && highestValueId == dt.id) {
          this.selectedPacketData.suggestedPlans[indexOfPlan]["plans"][i]["isActive"] = true;
  } else {
    this.selectedPacketData.suggestedPlans[indexOfPlan]["plans"][i]["isActive"] = false;
        }
      });
  }
}

  const resetOrder = await this.modalController.create({
    component: ResetordermodalComponent,
    cssClass: "restorePage",
    componentProps: {
      data: { orderDetails: type == 'actual'? this.selectedPacketData.actualPlans[indexofChild] : this.selectedPacketData.suggestedPlans[indexOfPlan].plans[indexofChild], packetId: this.selectedPacketId, showIsActive: true, isPage: "opinion" },
    },
  });

  resetOrder.onDidDismiss().then((d: any) => {
    if (d.data) {
      if (d.data?.dataSource) {
                  this.selectedPacketData.suggestedPlans[indexOfPlan]["plans"] = d.data.dataSource.map((dt: any) => {
            let odt = (this.selectedPacketData.suggestedPlans?.[indexOfPlan]?.["plans"] || []).find((oodt: any) => oodt.id == dt.id);
            return {
              ...(odt || {}),
              ...dt,
              orderRemark: dt.orderRemark?.name || "",
            };
          });
        //add updated orders
        }
        let highestValue = 0,
          isActiveFound = false;
        this.selectedPacketData.suggestedPlans?.[indexOfPlan]?.["plans"].map((dt: any) => {
          if (dt.isActive && !dt.orderRemark && !isActiveFound) {
            highestValue = dt.finalValue;
            isActiveFound = true;
          }
        });
        if (!isActiveFound)
          (this.selectedPacketData.suggestedPlans?.[indexOfPlan]?.["plans"] || []).map((dt: any) => {
            if (!dt?.orderRemark && highestValue < dt.finalValue) {
              highestValue = dt.finalValue;
            }
          });
                  this.selectedPacketData.suggestedPlans[indexOfPlan]["plans"]["maxOrderValue"] = highestValue;
        
        this.packetCalculationsInit();
      // this.PDValueSubmission(id)
    }
  });
  return await resetOrder.present();
}
  async updateOrderFormAfterUpdatingParam(payload, i?: number, j?: number) {
    try {
      let response = await this.httpService.postDataUsingPromise(true, "packetPlanStone/getOrderList", payload);
      this.httpService.getErrorAndDisplayIt(response);

      if (response.statusCode === 200 || response.statusCode ===201) {
        let data = response.data;
        if (i !== undefined && j !== undefined) {
          this.selectedPacketData.suggestedPlans[i].plans[j] = data[0].packetPlanStones[0];
        }
        if (data?.length) {
          if (i > -1 && j > -1) {
            this.packetCalculationsInit();
          } else if (i > -1) {
          } else {
            this.packetCalculationsInit();
          }
        }
        return data;
      } else {
        console.error(response);
        return null;
      }
    } catch (error) {
      console.error("An error occurred:", error);
      return null;
    }
  }

  async packetCalculationsInit(data = null) {
    if (data) {
      data[0].packetPlanStones
    } else {
       // this.arrayOfObjects =
    this.selectedPacketData.suggestedPlans.map(packetPlan => {
      // let packetPlanClone = { ...packetPlan };
      // packetPlanClone['totalMaxOrderValue'] = 0;
      // return {
      // ...packetPlan,
      packetPlan = packetPlan.plans.map(plans => {
        let plansClone = { ...plans };
        plansClone["maxOrderValue"] = null;
        return plansClone;
      });
      // }
    });
    for (let packetplan of this.selectedPacketData.suggestedPlans) {
      // if (!packetplan?.['totalMaxOrderValue'])
      // packetplan['totalMaxOrderValue'] = 0;
      for (let plans of packetplan.plans) {
        plans["maxOrderValue"] = null;
        let maxFinalValue: any = 0;
        let isFinal = false;
        if (!!plans.orders) {
          let isFinalOrderExist = plans.orders.find(el => !!el.isMarked);
          if (!!isFinalOrderExist) {
            maxFinalValue = isFinalOrderExist.finalValue;
          } else {
            for (let order of plans.orders) {
              if (!order?.orderRemark) {
                let rap = plans.RAPAPORT > 0 ? plans.RAPAPORT : plans.rapoRate;
                let calculatedValue = this.getRate(order.rateType, plans.kgRate, rap, order.value, plans.expPol, order);
                order.finalValue = calculatedValue;
                maxFinalValue = Math.max(maxFinalValue, order.finalValue);
              }
            }
          }
        }
        plans["maxOrderValue"] = Number(maxFinalValue.toFixed(2));
      }
    }
    }
   
  }

  getRate(rateType: string, kgRate: number, RAPAPORT: number, value: number, totalCarat: number, order: any) {
    let finalOrderValue = 1;
    switch (rateType) {
      case "Back":
        finalOrderValue = RAPAPORT - RAPAPORT * (value / 100);
        finalOrderValue = finalOrderValue * totalCarat;
        break;
      case "Piece":
        finalOrderValue = value;
        break;
      case "Percentage":
        finalOrderValue = kgRate + kgRate * ((value * -1) / 100);
        break;
      case "Rate Below":
        finalOrderValue = value;
        break;
      case "per cts rate":
        finalOrderValue = value * totalCarat;
        break;
    }
    return Number.isNaN(finalOrderValue) ? 0 : finalOrderValue;
  }

  async getTensionPhoto(id: string | number) {
    if (!id) return;

    try {
      const response = await this.httpService.fetchDataOfTensionFile(true, `processActionInput/getTensionPhotoFromCentral?packetId=${id}`);

      if (response.statusCode !== 200) {
        // console.error("Error fetching tension photo:", response);
        // this.configService.showToast("error", "Failed to fetch tension photo.");
        return;
      }

      const { tensionPhoto } = response?.data || {};
      const filePaths = tensionPhoto?.filePath || [];

      if (!filePaths.length) {
        // this.configService.showToast("error", "No File Available For Tension");
        return;
      }

      this.imageList = filePaths.map(path => `${this.httpService.centralFileUrl}${path}`);
    } catch (error) {
      console.error("An error occurred while fetching the tension photo:", error);
      this.configService.showToast("error", "An unexpected error occurred.");
    }
  }

  async onReject() {
    if (this.currentAction.name === ACTIONNAMES.verifyRepairingAsking) {
      let isOpinion = true;
      let selectedPlanId: number;
      if (this.selectedPacketData.suggestedPlans.filter((dt: any) => !dt.id).length) selectedPlanId = await this.addNewPlans();
      if (this.checkedSuggestedPlan > -1) if (!selectedPlanId) selectedPlanId = this.selectedPacketData.suggestedPlans[this.checkedSuggestedPlan].id;

      await this.createRejectTaskOnVerifyPacketRepairing();
      if (selectedPlanId) await this.updatePlans(selectedPlanId,null,true);
      // await this.addNewPlans()
      await this.inputReceiveOnReject(this.currentAction.isVirtual ? 1 : null);
      await this.modalController.dismiss();
      await this.router.navigate(["/home"]);
    }
  }

  async createRejectTaskOnVerifyPacketRepairing() {
    const res = await this.httpService.getDataUsingPromise(true, `packet/createRejectTaskOnVerifyReparingAsking?packetId=${this.selectedPacketId}`);

    if (res.statusCode == 200) {
      this.configService.showToast("success", "Task Created Successfully");
    } else {
      this.httpService.getErrorAndDisplayIt(res);
      return null;
    }
  }

  async openImageModal(index) {
    this.defaultimgindex = index;
    const modal = await this.modalController.create({
      component: DynamicModalComponent,
      componentProps: {
        template: this.modal,
        class: "border-radius",
      },
      cssClass: "DynamicModalAvgOnOpinion",
    });
    return await modal.present();
  }
  isSaveBtnDisabled(): boolean {
    const isVerifyPacketRepairingPage = this.currentAction?.name == ACTIONNAMES.verifyRepairingAsking;
    if (isVerifyPacketRepairingPage) {
      return this.selectedPacketData.suggestedPlans.some(item => item.isCopied || item.iscopy) || this.currentUser.roles.includes("DISPATCH Manager");
    }

    return false;
  }

  closemodal() {
    this.modalController.dismiss();
  }

  downloadimage() {
    window.open(this.imageList[this.downloadindex]);
  }

  async defaultIndex() {
    setTimeout(() => {
      if (this.slider) {
        this.slider
          .slideTo(this.defaultimgindex)
          .then(() => {
            this.downloadindex = this.defaultimgindex;
          })
          .catch(err => {
            console.log(err);
          });
      }
    }, 300);
  }

  toggleZoom(event: any) {
    const container = event.target.closest(".slide-content");
    if (container) {
      container.classList.toggle("zoom");
    }
  }

  slideChanged() {
    this.slider?.getActiveIndex().then(index => {
      this.downloadindex = index;
    });
  }
  slidePrev() {
    this.slider?.slidePrev();
  }

  slideNext() {
    this.slider?.slideNext();
  }

  async fetchCardData(id) {
    if (!!id) {
      try {
        const data = await this.httpService.getDataWithoutPayloadForGet(true, `packet/getUserMistakeLogsDetails?userId=${id}`).toPromise();
        if (!!data.data) {
          this.cardData = data.data;
          if (!!data.data[0].remark) {
            this.cardData[0].types = [this.cardData[0]?.totalMistake, ...this.cardData[0]?.remark.flatMap(Object.values)];
          }
        }
      } catch (error) {
        console.error("Error fetching worker data:", error);
      }
    }
  }

  getRemarkKeys(remark: any): string[] {
    if (!!remark) return Object.keys(remark);
  }

  onHoverKey(key: any): string {
    let grpRemarks = this.cardData[0]?.grpRemarks;
    let grpFind = grpRemarks?.find(e => e.groupName == key);

    return grpFind?.remarks;
  }

  calculatePercentages(data: any) {
    if (!data.totalPackets || !Array.isArray(data.types) || data.types.length === 0) {
      return [];
    }
    return data.types.map((type: number) => (type / data.totalPackets) * 100);
  }

  onDepartmentChange(event) {
    if (!event) {
      this.selectRoles = "";
      return;
    }
    this.selectRoles = event.receiveRole;
  }

  onModalSelectionChanged(event) {
    this.selectedWage = event.row;
  }
  isInputChange(event) {
    event.data["inputField"] = event.value;
    if (event.data.inputField > event.data.amount) {
      this.configService.showToast(true, "Input cannot be greater than the amount");
      event.value = "";
    }
  }

  async gridData() {
    let payload = this.selectedPacketData.suggestedPlans
      .find((dt: any) => dt.isSelected)
      ?.plans.map(plan => ({
        ...plan,
        packetId: this.selectedPacketId,
      }));

    try {
      const res: any = await this.httpService.postDataUsingPromise(true, "wages/getRepairingWagesListBasedOnCriteria", payload[0]);
      let data = res?.data || [];
      this.TransferdataSource = Object.keys(res.data).reduce((acc, deptID) => {
        const items = res.data[deptID].map(item => ({
          ...item,
          deptID,
        }));
        return [...acc, ...items];
      }, []);
      this.deptDropdownDataOptions = this.allDeptValues.filter(option => this.TransferdataSource.some(wage => wage.deptID == option.id));
      this.selectedDipatchDepartment = this.allDeptValues.filter(option => this.TransferdataSource.some(wage => wage.deptID == option.id))[0].id;
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
    }
  }

  async openTransferModal() {
    this.selectRoles = "";
    this.selectedDipatchDepartment = null;
    await this.gridData();
    const modal = await this.modalController.create({
      component: DynamicModalComponent,
      componentProps: {
        template: this.dispatchModal,
        class: "dispatch-modal",
      },
      cssClass: "DynamicModalLarge",
    });
    await modal.present();
  }

  async onModalSave() {
    console.log(this.selectedWage);
    let payload = {
      actionId: this.selectedWage.actionId,
      amount: this.selectedWage?.inputField != null && this.selectedWage.inputField !== "" ? this.selectedWage?.inputField : this.selectedWage?.amount,
      packetId: this.selectedPacketId,
      wageRuleId: this.selectedWage.id,
    };
    try {
      const res: any = await this.httpService.postDataUsingPromise(true, "repairing-packet-wages/save", payload);
      if (res.status === 200 || res.status === 201) {
        this.modalController.dismiss();
        let selectedPlanId: number;
        if (this.selectedPacketData.suggestedPlans.filter((dt: any) => !dt.id).length) selectedPlanId = await this.addNewPlans();

        if (!selectedPlanId) selectedPlanId = this.selectedPacketData.suggestedPlans[this.checkedSuggestedPlan].id;

        if (selectedPlanId) await this.updatePlans(selectedPlanId);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
    }
  }
}
