import { Component, OnInit, ViewChild } from "@angular/core";
import { ConfigService } from "src/app/services/config/config.service";
import { HttpService } from "src/app/services/http/http.service";
import { LoaderService } from "src/app/services/loader/loader.service";
import { ScannerinputComponent } from "../scannerinput/scannerinput.component";
import { PrintPageComponent } from "../print-page/print-page.component";
import * as moment from "moment";
import { printData } from "src/app/masterDataStore/master.data";
import { Subject, Subscription } from "rxjs";
import { AppState } from "src/app/reducers";
import { Store, select } from "@ngrx/store";
import { getCurrentAction } from "src/app/tempDataStore/tempData.selector";
import { filter, first, takeUntil } from "rxjs/operators";
import { getFinalPlanData } from "../trybe-checker/state/checker.selector";
import { HomeActions } from "src/app/home/state/home.action-type";
import { ACTIONNAMES } from "src/app/services/endpoint.enum";
import { fetchAllUsers } from "src/app/masterDataStore/masterData.selector";
import { masterDataActions } from "src/app/masterDataStore/masterData.action-type";

@Component({
  selector: "app-edit-packets",
  templateUrl: "./edit-packets.component.html",
  styleUrls: ["./edit-packets.component.scss"],
})
export class EditPacketsComponent implements OnInit {
  Columns: any;
  dataSource: any = [];
  selectedPacketPlanStoneId: any = {};
  page = "Edit Packets";
  pageSize = 10;
  orgWt: any;
  currWt: any = 0;
  totalCurWt = 0;
  totalCorrectWt = 0;
  totalCurLoss = 0;
  totalCorrectLoss = 0;
  totalPolishWt = 0;
  polishDiff = 0;
  apiData: any;
  dispatchData: any = [];
  packetPlans: any = [];
  voucherData: any = {};
  totalLedgerWeight: number = 0;
  lossLedgerTotal: number = 0;
  dispatchPrintData: any = printData;
  currActionName: any;
  @ViewChild(ScannerinputComponent)
  private scannerComponent!: ScannerinputComponent;
  private subscriptions: Subscription[] = [];
  public unsuscribes$: Subject<any> = new Subject<any>();
  plansDropdownData: any = {
    name: "plan",
    placeText: "Select plans",
    options: [],
    selectedData: [],
    key: "label",
    propertyName: "label",
    modeFlag: "single", //single/multiple
  };
  allPlansOptions: any = [];
  userId: any = null;
  userData: any = [];
  planTypeDropdownData: any = {
    name: "packetType",
    placeText: "Select plans type",
    options: [
      { id: 1, label: "Rough" },
      { id: 2, label: "Makeable" },
      { id: 5, label: "By Product" },
      { id: 4, label: "TOPS " },
      { id: 6, label: "Intermediate" },
    ],
    selectedData: [],
    key: "label",
    propertyName: "label",
    modeFlag: "single",
  };

  private unsubscribe$: Subject<any> = new Subject<any>();
  alpha: any = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  getHexValue(a) {
    a += 1;
    var c = 0;
    var x = 1;
    while (a >= x) {
      c++;
      a -= x;
      x *= 26;
    }
    var s = "";
    for (var i = 0; i < c; i++) {
      s = this.alpha.charAt(a % 26) + s;
      a = Math.floor(a / 26);
    }
    return s;
  }
  // @ViewChild('dispatchAll', { static: false }) dispatchAll: PrintPageComponent;

  constructor(public httpService: HttpService, private loaderService: LoaderService, private configService: ConfigService, private store: Store<AppState>) {}

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .pipe(select(getCurrentAction))
        .pipe(
          filter(data => !!data),
          first()
        )
        .subscribe(async (data: any) => {
          if (data) {
            this.currActionName = data?.name;
          }
        })
    );

    if (this.currActionName == "Modify Recut Entry") {
      this.Columns = [
        { name: "Packet ID", prop: "packetLabel" },
        { name: "Packet Type", prop: "packetType" }, //ADDED PACKET TYPE COLUMN
        { name: "Current RD", prop: "currCts" },
        { name: "Correction RD", prop: "ccts" },
        { name: "Polish Weight", prop: "rghCutWt" },
        { name: "Voucher no.", prop: "voucherno" },
      ];
    } else if (this.currActionName == "All Bifurcated Packets Edit") {
      this.Columns = [
        { name: "Packet ID", prop: "packetLabel" },
        { name: "Status", prop: "status" },
        { name: "Current RD", prop: "currCts" },
        { name: "New RD", prop: "ccts" },
        { name: "Tag", prop: "id" },
        { name: "Active", prop: "isActive" },
      ];
    } else if (this.currActionName == ACTIONNAMES.LOSS_LEDGER) {
      this.Columns = [
        { name: "Packet ID", prop: "packetId" },
        { name: "actionName", prop: "actionName" },
        // { name: 'issueCts', prop: 'issueCts' },
        // { name: 'returnCts', prop: 'returnCts' },
        { name: "weight", prop: "weight" },
        { name: "transactionType", prop: "transactionType" },
      ];
    } else if (this.currActionName == ACTIONNAMES.VIEW_WAGE_LEDGER) {
      this.Columns = [
        { name: "Packet Label", prop: "packetlabel" },
        { name: "ActionName", prop: "actionName" },
        { name: "UserName", prop: "userName" },
        { name: "Department Name", prop: "deptName" },
        { name: "AskShape", prop: "AskedShape" },
        { name: "AskColor", prop: "AskedColor" },
        { name: "AskClarity", prop: "AskedClarity" },
        { name: "AskCut", prop: "AskedCut" },
        { name: "AskPolish", prop: "AskedPolish" },
        { name: "AskSymm", prop: "AskedSymmetry" },
        { name: "AskCts", prop: "AskedExpPol" },
        { name: "Shape", prop: "shapeId" },
        { name: "Color", prop: "colorId" },
        { name: "Clarity", prop: "clarityId" },
        { name: "Cut", prop: "cutId" },
        { name: "Polish", prop: "polishId" },
        { name: "Symmetry", prop: "symmetryId" },
        { name: "Cts", prop: "expPol" },
        { name: "Mistake Of", prop: "mistakeOff" },
        { name: "Remark", prop: "remark" },
        { name: "WageNo", prop: "wageNo" },
        { name: "Rate", prop: "rate" },
        { name: "Amount", prop: "amount" },
      ];
      this.store.dispatch(masterDataActions.getAllUsers());
      this.store.pipe(select(fetchAllUsers), takeUntil(this.unsuscribes$)).subscribe((data: any) => {
        if (data) {
          this.userData = data;
        }
      });
    }
  }

  async packetScanned(evt) {
    let payload = this.currActionName === ACTIONNAMES.LOSS_LEDGER ? { packet: evt } : { packetLabel: evt };
    this.dataSource = [];
    if (this.currActionName !== ACTIONNAMES.VIEW_WAGE_LEDGER) {
      this.httpService.getAllPackets(payload, this.currActionName).subscribe(
        async (res: any) => {
          if (res.statusCode === 200) {
            if (!res?.data?.packetList?.length) this.configService.showToast("error", "No data found!");
            else if (this.currActionName != ACTIONNAMES.LOSS_LEDGER) {
              this.apiData = res.data;
              this.orgWt = res?.data?.orignalCts;
              this.dataSource = res?.data?.packetList || [];
              this.totalCurWt = 0;
              this.totalCurLoss = 0;
              this.totalPolishWt = 0;
              this.polishDiff = 0;
              this.dataSource.map(ds => {
                if (ds?.rghCutWt) this.totalCurWt += Number(ds?.rghCutWt);
                if (ds?.polishWt) this.totalPolishWt += Number(ds?.polishWt);
                ds["correctWt"] = 0;
                if (ds.packetType === "Polish" && ds.rghCutWt) {
                  ds["correctWt"] = ds.rghCutWt;
                }
                ds["packetPlanStoneId"] = ds?.packetPlanStoneId || "";
                ds["isActive"] = ds.isActive;
              });
              this.polishDiff = Math.round(this.totalPolishWt * 1000 - this.orgWt * 1000) / 1000;
              this.totalCurLoss = Math.round(this.orgWt * 1000 - this.totalCurWt * 1000) / 1000;
              if (this.currActionName == "All Bifurcated Packets Edit") {
                // get packet plan stones data.................
                this.store.dispatch(
                  HomeActions.getPacketPlanStoneIfIsFinal({
                    packetId: this.dataSource[0].id,
                  })
                );
                this.store
                  .pipe(select(getFinalPlanData))
                  .pipe(takeUntil(this.unsubscribe$))
                  .subscribe(async (data: any) => {
                    if (data) {
                      this.packetPlans = data || [];
                      setTimeout(() => {
                        this.packetPlans = data || [];
                      }, 0);
                      let packetPlansStones = [];
                      if (this.packetPlans) {
                        packetPlansStones = this.packetPlans?.[0]?.["packetPlanStones"];
                        if (packetPlansStones) {
                          packetPlansStones = await packetPlansStones.map((e, index) => {
                            let obj = { ...e };
                            console.log(e);
                            let planTag = this.getHexValue(index);
                            let exppol = e.expPol;
                            let rhtwt = e.sawRghWgt;
                            let shape = e.shape;
                            let newobj = {
                              label: planTag + "    " + rhtwt + "    " + shape + "    " + exppol,
                              id: obj.id,
                              disabled: obj.inActive ? true : false,
                            };
                            return newobj;
                          });
                        }
                        this.plansDropdownData["options"] = packetPlansStones || [];
                        this.allPlansOptions = packetPlansStones || [];
                        for (let i = 0; i < this.dataSource?.length; i++) {
                          if (this.dataSource[i].packetPlanStoneId) {
                            this.selectedPacketPlanStoneId[i] = this.plansDropdownData.options.filter((dt: any) => dt.id == this.dataSource[i].packetPlanStoneId);
                            this.plansDropdownData.options = this.plansDropdownData.options.filter((dt: any) => !(dt.id == this.dataSource[i].packetPlanStoneId));
                          } else {
                            this.selectedPacketPlanStoneId[i] = [];
                          }
                        }
                        if (packetPlansStones?.length > this.dataSource?.length) {
                          for (let i = this.dataSource?.length; i < packetPlansStones.length; i++) {
                            let bifurcationDs = { ...this.dataSource[0] };
                            bifurcationDs.rghCutWt = 0;
                            bifurcationDs.packetLabel = this.apiData?.orginalPacket + "-" + (i + 1);
                            bifurcationDs.packetType = null;
                            this.dataSource = [...this.dataSource, bifurcationDs];
                          }
                        }
                      }
                    }
                  });
              }
            } else if (this.currActionName === ACTIONNAMES.LOSS_LEDGER) {
              const { packetList = [], orignalCts, currCts } = res?.data || {};

              this.dataSource = packetList;
              this.orgWt = Number(orignalCts) || 0;
              this.currWt = Number(currCts) || 0;
              this.totalLedgerWeight = this.dataSource.reduce((total, dt) => {
                return total + (Number(dt?.weight) || 0);
              }, 0);
              this.lossLedgerTotal = this.currWt ? this.totalLedgerWeight - this.currWt : this.totalLedgerWeight - this.orgWt;
            }
          } else {
            this.configService.showToast("error", res.error.message || "Something went wrong!");
          }
        },
        (err: any) => {
          this.configService.showToast("error", err.message || "Something went wrong!");
        }
      );
    }
    if (this.currActionName === ACTIONNAMES.VIEW_WAGE_LEDGER) {
      if (!evt) return;
      let payload = {
        packetId: evt,
        userId: this.userId,
      };

      const res = await this.httpService.postDataUsingPromise(true, "wages-activity-log/getWagesActivityLogOnBasisOf", payload);
      if (res?.data) {
        this.dataSource = res.data;
      } else {
        this.configService.showToast("error", "No data found!");
      }
    }
  }

  onInputChange(evt, i) {
    console.log("in chnage event----->", evt?.detail?.value);
    this.dataSource[i].correctWt = Number(evt?.detail?.value);
    this.totalCorrectWt = 0;
    this.dataSource.map(ds => {
      if (Number(ds.correctWt)) {
        this.totalCorrectWt += Number(ds.correctWt);
      }
    });

    // this.totalCorrectWt += Number(evt?.detail?.value);
    this.totalCorrectLoss = 0;
    if (this.totalCorrectWt != 0) this.totalCorrectLoss = Math.round(this.orgWt * 1000 - this.totalCorrectWt * 1000) / 1000;
    else this.totalCorrectWt = 0;
  }

  async onUserDropdownChanges(evt) {
    this.userId = evt;
    if (!evt) return;
    let payload = {
      packetId: null,
      userId: this.userId,
    };

    const res = await this.httpService.postDataUsingPromise(true, "wages-activity-log/getWagesActivityLogOnBasisOf", payload);
    if (res?.data) {
      this.dataSource = res.data;
    } else {
      this.configService.showToast("error", "No data found!");
    }
  }

  onClearClick(evt, i) {
    this.dataSource[i].correctWt = 0;
    this.totalCorrectWt = 0;
    this.dataSource.map(ds => {
      if (Number(ds.correctWt)) {
        this.totalCorrectWt += Number(ds.correctWt);
      }
    });

    // this.totalCorrectWt += Number(evt?.detail?.value);
    this.totalCorrectLoss = 0;
    this.totalCorrectLoss = Math.round(this.orgWt * 1000 - this.totalCorrectWt * 1000) / 1000;
  }

  async save() {
    if (this.currActionName == "Modify Recut Entry") {
      if (this.totalCorrectWt > this.orgWt) {
        this.configService.showToast("error", "Total weight should be less than original weight");
        return;
      }
      if (this.totalCorrectLoss < 0) {
        this.configService.showToast("error", "Loss can not be negative");
        return;
      }

      let payload = {
        originalLabel: this.apiData?.orginalPacket,
        originalWt: this.apiData?.orignalCts,
        packetList: [],
      };

      this.dataSource.map(ds => {
        payload?.packetList.push({
          id: ds.id,
          rghCutWt: ds.correctWt != 0 ? ds.correctWt : ds.rghCutWt,
          currRghWt: ds.rghCutWt,
          packetMovementId: ds.packetMovementId,
          packetLabel: ds.packetLabel,
        });
      });

      this.httpService.updatePackets(payload).subscribe(
        (res: any) => {
          if (res.statusCode === 200) {
            this.dataSource = [];
            this.scannerComponent.onResetForm();
            this.totalCorrectWt = 0;
            this.totalCorrectLoss = 0;
            this.totalCurLoss = 0;
            this.totalCurWt = 0;
            this.totalPolishWt = 0;
            this.configService.showToast("success", "Packet Updated Successfully");
          } else {
            this.configService.showToast("error", res.message);
          }
        },
        (err: any) => {
          this.configService.showToast("error", err.message || "Something went wrong!");
        }
      );
    } else if (this.currActionName == "All Bifurcated Packets Edit") {
      let activePackets = this.dataSource.filter(ds => ds.isActive);
      if (activePackets.some(ds => ds.correctWt == 0 && ds.packetType != "Polish")) {
        this.configService.showToast("error", "Please add all new Rd value");
        return;
      }
      if (activePackets.some(ds => ds.packetPlanStoneId == "" && ds.packetTypeId < 5)) {
        this.configService.showToast("error", "Please select tag in all packets");
        return;
      }
      if (activePackets.some(ds => ds.packetType == null && !ds.packetTypeId)) {
        this.configService.showToast("error", "Please select status for new packets");
        return;
      }

      let totalWt = 0;
      activePackets.map(ds => {
        if (Number(ds.correctWt)) {
          totalWt += Number(ds.correctWt);
        }
      });
      if (totalWt > this.orgWt) {
        this.configService.showToast("error", "Total weight should be less than original weight");
        return;
      }

      const trashIds = this.dataSource.filter(ds => !ds.isActive).map(obj => obj.id);
      //.........rejectCheckerPropertyIfAvaiable....................................
      let endpoint: string = "packet/rejectCheckerPropertyIfAvaiable";
      let payload: any = null;
      payload = {
        packetLabel: this.apiData?.orginalPacket,
      };

      let response = await this.httpService.postDataUsingPromise(true, endpoint, payload);
      this.httpService.getErrorAndDisplayIt(response);
      if (response.statusCode === 200) {
      } else {
        console.error(response);

        this.configService.showToast("error", response.error.message);
        return;
      }
      //................................................................................
      //........................trash all inActive......................................
      if (trashIds.length > 0) {
        let trashEndpoint: string = "packet/sendPacketToTrash";
        let trashPayload: any = {
          Ids: trashIds,
        };

        let trashResponse = await this.httpService.postDataUsingPromise(true, trashEndpoint, trashPayload);
        this.httpService.getErrorAndDisplayIt(trashResponse);
        if (trashResponse.statusCode === 200) {
        } else {
          console.error(trashResponse);
          this.configService.showToast("error", trashResponse.error.message);
          return;
        }
      }
      //.......................save new bifurcation packet.............................

      let newBifurcationpkts = this.dataSource.filter(ds => ds.rghCutWt == 0 && ds.isActive);
      let newBiufurcationPayload: any = {};
      if (newBifurcationpkts.length > 0) {
        newBifurcationpkts.map(np => {
          if (np.packetType == null) {
            np.packetType = this.planTypeDropdownData?.options.find(op => op.id == np.packetTypeId).label;
          }
          if (!np.packetTypeId) {
            np.packetTypeId = this.planTypeDropdownData?.options.find(op => op.label == np.packetType).id;
          }
        });

        let origanalPkt = this.dataSource.filter(ds => ds.packetLabel.includes(this.apiData?.orginalPacket + "-1"))[0];
        (origanalPkt["packetTypeId"] = origanalPkt.packetTypeId
          ? origanalPkt?.packetTypeId
          : this.planTypeDropdownData?.options.find(op => op.label == origanalPkt.packetType)
          ? this.planTypeDropdownData?.options.find(op => op.label == origanalPkt.packetType).id
          : this.planTypeDropdownData?.options.find(op => op.label == "Makeable").id),
          (newBiufurcationPayload["packetId"] = origanalPkt?.id);

        newBiufurcationPayload["stones"] = [
          {
            packetType: origanalPkt?.packetTypeId,
            nfcCode: null,
            cts: origanalPkt?.correctWt,
            packetPlanStoneId: origanalPkt?.packetPlanStoneId,
          },
        ];
        newBifurcationpkts.forEach(nbfp => {
          newBiufurcationPayload.stones = [
            ...newBiufurcationPayload.stones,
            {
              packetType: nbfp.packetTypeId,
              nfcCode: null,
              cts: nbfp.correctWt,
              packetPlanStoneId: nbfp.packetPlanStoneId,
            },
          ];
        });
        let newBiufurcationEndpoint: string = "packet/createNewStoneAfterBifurcationForMultiplePacket";
        let newBiufurcationresponse = await this.httpService.postDataUsingPromise(true, newBiufurcationEndpoint, newBiufurcationPayload);
        this.httpService.getErrorAndDisplayIt(newBiufurcationresponse);
        if (newBiufurcationresponse.statusCode === 200) {
        } else {
          console.error(newBiufurcationresponse);
          this.configService.showToast("error", newBiufurcationresponse.error.message);
          return;
        }
      }
      //...............................................................................
      //................................................................................
      //.......................save new packets.........................................
      let newPktPayload = [];
      let newPackets: any;
      newPackets = this.dataSource.filter(ds => ds.isActive);
      newPackets = newPackets.filter(np => np.rghCutWt !== 0);
      if (newBiufurcationPayload?.packetId) {
        newPackets = newPackets.filter(np => np.id != newBiufurcationPayload?.packetId);
      }
      if (newPackets?.length > 0) {
        newPackets.map(np => {
          newPktPayload.push({
            packetId: np.id,
            packetPlanStoneId: np.packetPlanStoneId,
            expRghWt: np.correctWt,
            packetType: np.packetTypeId,
          });
        });
        let newPktEndpoint: string = "packet/storePacketDetailNewEntry";
        let newPktresponse = await this.httpService.postDataUsingPromise(true, newPktEndpoint, newPktPayload);
        this.httpService.getErrorAndDisplayIt(newPktresponse);
        if (newPktresponse.statusCode === 200) {
          this.dataSource = [];
          this.totalCorrectWt = 0;
          this.totalCurWt = 0;
          this.scannerComponent.onResetForm();
          this.configService.showToast("success", "Packet Updated sucessfully!");
        } else {
          console.error(newPktresponse);
          this.configService.showToast("error", newPktresponse.error.message);
          return;
        }
      } else {
        this.dataSource = [];
        this.totalCorrectWt = 0;
        this.totalCurWt = 0;
        this.totalCurLoss = 0;
        this.totalCorrectLoss = 0;
        this.scannerComponent.onResetForm();
        this.configService.showToast("success", "Packet Updated sucessfully!");
      }

      //................................................................................
    }
  }

  clearPackets() {
    this.dataSource = [];
  }

  async onVoucherClick(data) {
    const printRes = await this.httpService.getDataWithoutPayloadForGet(true, `packet/getPrintDataForDispatch?voucherId=${data.voucherId}`).toPromise();
    if (printRes?.data) {
      this.dispatchData = printRes?.data?.dispatchVoucher;
      this.dispatchPrintData["voucherNo"] = data?.voucherLabel;
      this.dispatchPrintData["jangadNo"] = data?.voucherLabel;
      this.dispatchPrintData["issueNo"] = data?.voucherLabel;
      this.dispatchPrintData["issueFor"] = `Transfer`;
      this.dispatchPrintData["fromCompany"] = this.dispatchData[0]?.fromCompany;
      this.dispatchPrintData["toCompany"] = this.dispatchData[0]?.toCompany;
      this.dispatchPrintData["voucherId"] = this.dispatchData[0]?.voucherId;
      this.dispatchPrintData["TocompanyName"] = this.dispatchData[0]?.partyName;
      this.voucherData["voucherLabel"] = data?.voucherLabel;
      this.dispatchPrintData["date"] = moment.utc(this.dispatchData[0].Date).local().format("DD-MMM-YYYY");
      this.dispatchPrintData["ttlPkt"] = this.dispatchData?.length;
      let rctsttl = 0;
      let ctsttl = 0;
      this.dispatchData.map(d => {
        if (d.originalWt) ctsttl += Math.round(d.originalWt * 1000) / 1000;
        if (d.wt) rctsttl += Math.round(d.wt * 1000) / 1000;
        // d['lotNo'] = d.currPacketId.split('#')[0];
      });
      this.dispatchPrintData["owtttl"] = ctsttl;
      this.dispatchPrintData["rctsttl"] = rctsttl;
      let baseURL = this.configService.getTrybeBaseUrl();
      if (printRes?.data?.lastPartReturnPath) window.open(baseURL + printRes?.data?.lastPartReturnPath, "_blank");

      if (printRes?.data?.partReturn) {
        setTimeout(() => {
          const window2 = window.open(baseURL + printRes?.data?.partReturn, "_blank");
          console.log("hello");
          console.log(baseURL);
          if (window2) {
            window2.focus();
          }
        }, 500);
      }

      if (printRes?.data?.combinePartPath) {
        setTimeout(() => {
          const window2 = window.open(baseURL + printRes?.data?.combinePartPath, "_blank");

          console.log(baseURL);
          if (window2) {
            window2.focus();
          }
        }, 500);
      }

      if (printRes?.data?.newDispatchPath) {
        setTimeout(() => {
          const window2 = window.open(baseURL + printRes?.data?.newDispatchPath, "_blank");

          console.log(baseURL);
          if (window2) {
            window2.focus();
          }
        }, 500);
      }
    }
  }

  printVoucher() {
    console.log("here in print voucher");
    const style = `.flex-center-between{
      display:flex;
      align-items:center;
      justify-content:space-between;
    }
    .header-text{
      font-weight:8px;
      color:rgb(168, 163, 163)
    }
    .float-right {
      float: right;
    }
    .nowrap{
      text-wrap:nowrap;
    }
    .logo_img{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .center{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    hr.initial {
      border-top: 1px dotted #000;
    }
    
    hr.signature {
      // width: 200px;
      border-top: 0.25px solid #000;
      // border-width: 0.5px;
    }
    
    .fs{
      font-weight: normal;
      font-size: small;
    }
      
    .space{
      min-height: 30px;
    }
    
    
    .column{
      border: 1px solid #000;
    }
    
    .br{
      border-right: 1px solid #000;
    }
    
    .bb{
      border-bottom: 1px solid #000;
    }
    
    .bt{
      border-top: 2px solid #000;
    }
    .fs-12{
      // font-weight: 10;
      font-size: 10px;
    }
    
    .fs-10{
      font-size: 8px;
    }
    
    span.tc {
      text-align: center;
    }
    
    .pr{
      padding-right: 70px;
    }
    
    .col-fs{
      font-size: 11px;
    }
    
    .print-page {
      page-break-before: always;
    }
    .fs-12 {
      font-size: 12px;
    }
    .tac {
      text-align: center;
    }
    .ion-grid{
      display: flex;
      justify-content: space-between;
      padding: 10px; 
    }
    .ion-row{
      display: flex;4
      width: 100%;
    }
    .ion-col{
      flex: 1;
    }
    table {
      width: 100%;
      border-collapse: collapse;
    }
    th, td {
      border: 1px solid black;
      border-collapse: collapse;
      text-align: center;
      padding:2px
    }
    body * {
      visibility: hidden;
  }
    #all,
    #all * {
      visibility: hidden;
    }
    #loss,
    #loss * {
      visibility: hidden;
    }
    @media print {
      body * {
        visibility: visible;
    }
    #all,
    #all * {
      visibility: visible;
    }
    #loss,
    #loss * {
      visibility: visible;
    }
  }
`;
    if (!!this.dispatchData && this.dispatchData.length > 0) {
      const div = document.getElementById("print");
      const voucher1Window = window.open("", "_blank");
      let pageBreak = "none";
      let lossDiv = '<div id="loss"></div>';
      // if (!!this.dispatchLossData && this.dispatchLossData.length > 0 && this.isLoss) {
      //   pageBreak = 'always'
      //   lossDiv = `<div id="loss">${this.lossDispatch.voucherContent.nativeElement.innerHTML}
      //   </div>`
      // }
      if (voucher1Window) {
        voucher1Window.document.write(`
        <html>
          <head>
          <title>Transfer</title>
            <style>${style}
            </style>
          </head>
          <body>
          <div id="all" style="page-break-before:${pageBreak};">
          
          ${div.innerHTML}
          </div>
          </body>
        </html>
      `);
        // ${lossDiv}
        voucher1Window.document.close();
        voucher1Window.print();
        voucher1Window.close();
      }
    } else {
      window.print();
    }
  }

  printDiv() {
    this.printVoucher();
  }

  onSwitchToggle(event) {
    console.log("event--toggle-->", event);
  }

  dropDownSelection(event, i) {
    this.dataSource[i].packetPlanStoneId = event?.idList[0];
    this.plansDropdownData.options = this.allPlansOptions;
    this.dataSource.map((dt: any) => {
      this.plansDropdownData.options = this.plansDropdownData.options.filter((odt: any) => !(odt.id == dt.packetPlanStoneId));
    });
  }

  planTypeSelection(event, i) {
    this.dataSource[i]["packetTypeId"] = event?.arrayList[0]?.id;
  }

  // twoDecimalWithoutRoundUp(number, decimalPlaces) {
  //   let temp = String(number);
  //   let s = temp.split(".");
  //   return `${s[0]}${s.length > 1 ? "." + s[1].substring(0, decimalPlaces) : ""}`;
  // }
  twoDecimalWithoutRoundUp(value: any): string {
    value = Number(value);
    if (isNaN(value)) {
      return "0.00";
    }

    value = String(value);
    let decimalIndex = value.indexOf(".");
    let newValue;

    if (decimalIndex === -1 || value.length - decimalIndex - 1 <= 2) {
      newValue = Number(value).toFixed(2);
    } else {
      let thirdDecimalPlace = value.charAt(decimalIndex + 3);
      if (Number(thirdDecimalPlace) >= 8) {
        newValue = (Math.ceil(Number(value) * 100) / 100).toFixed(2);
      } else {
        newValue = (Math.floor(Number(value) * 100) / 100).toFixed(2);
      }
    }

    return newValue;
  }

  ngOnDestroy() {
    this.unsuscribes$.next();
    this.unsuscribes$.complete();
  }
  generateExcel() {
    this.configService.createExcel(this.dataSource, "WageLedgerExcel");
  }
}
