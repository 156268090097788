import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { HttpService } from "../services/http/http.service";
import { Router } from "@angular/router";
import { AlertController, ModalController } from "@ionic/angular";
import { ConfigService } from "../services/config/config.service";
import { AppState } from "../reducers";
import { Store, select } from "@ngrx/store";
import { LoaderService } from "../services/loader/loader.service";
import { ScannerinputComponent } from "../components/scannerinput/scannerinput.component";
import { authUser } from "../authStore/auth.selector";
import { TrybemodalComponent } from "../components/trybemodal/trybemodal.component";
import { MultipleBarcodeScannerComponent } from "../components/multiple-barcode-scanner/multiple-barcode-scanner.component";
import { Subscription } from "rxjs";
import { filter, first } from "rxjs/operators";
import { getCurrentAction } from "../tempDataStore/tempData.selector";
import { ACTIONNAMES } from "src/app/services/endpoint.enum";
import { DynamicModalComponent } from "../transfer-packet-to-dispatch/dynamic-modal/dynamic-modal.component";

@Component({
  selector: "app-tally-stock",
  templateUrl: "./tally-stock.component.html",
  styleUrls: ["./tally-stock.component.scss"],
})
export class TallyStockComponent implements OnInit {
  dropdown: any = {};
  private scannerComponet!: ScannerinputComponent;
  isUpdateDisable: boolean = true;
  isDataAddedOnce: boolean = false;
  dropDownWorkerData: any = {
    name: "Worker",
    placeText: "Select Worker ",
    options: [],
    selectedData: [],
    key: "fullName",
    modeFlag: "single", //single/multiple
  };

  dropDownVoucherData: any = {
    name: "voucherLabel",
    placeText: "Select Vocuher ",
    options: [],
    selectedData: [],
    idField: "voucherId",
    key: "voucherLabel",
    modeFlag: "single", //single/multiple
  };
  actionEnums = ACTIONNAMES;
  isStartTally: boolean = true;
  barcodeInputs: any;
  dataSource: any = [];
  columns: any;
  isOutGoingNotEmpty: boolean = false;
  showStartTally: boolean = true;
  workerData: any;
  workerName: any;
  barcodeValue: any;
  workerId: any;
  currUserDeptId: number;
  tallyStatus: any;
  voucherStatus: any;
  voucherFailStatus: any;
  isVoucher: boolean = false;
  isNotOtherDeptTallyDone: boolean = false;
  barcodeInputList: any;
  private subscriptions: Subscription[] = [];
  misMachedPackets: any = [];
  curAction: any;
  tallyVoucherResponse: any;
  @ViewChild("modalContent") modalContent: ElementRef;
  selectedVoucherId: any;
  refVoucherId: any;
  voucherPackets: any = [];
  totalWeight: number = 0;
  department: any;
  currentTime: string = new Date().toLocaleTimeString("en-GB");
  printDate: string = new Date().toLocaleDateString("en-GB") + " " + this.currentTime;
  GridData: any;
  ModalData: any;
  constructor(
    private store: Store<AppState>,
    public httpService: HttpService,
    private router: Router,
    public modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private configService: ConfigService,
    private loaderService: LoaderService
  ) {}

  async ngOnInit() {
    this.subscriptions.push(
      this.store
        .pipe(select(getCurrentAction))
        .pipe(
          filter(data => !!data),
          first()
        )
        .subscribe(async (data: any) => {
          if (data) {
            this.curAction = data?.name;
            if (this.curAction == ACTIONNAMES.tallyMismatchedStock) {
              this.getVocucherList();
            }
          }
        })
    );
    // await this.getTallyStatus();
    console.log(this.tallyStatus);
    this.isStartTally = !this.tallyStatus;
    if (this.tallyStatus) {
      await this.getWorkers();
      console.log(this.workerData);
      this.dropDownWorkerData["options"] = this.workerData;
    }
    this.store.pipe(select(authUser)).subscribe(user => {
      if (!!user) {
        console.log(user);
        this.currUserDeptId = user?.departmentId;
        this.department = user?.departmentName;
      }
    });
    this.columns = [{ name: "packetID", prop: "packetID" }];
    this.IsOutgoingISEmpty();
    this.fetchGridsCount();
  }

  async fetchGridsCount() {
    try {
      const data = await this.httpService.getDataWithoutPayloadForGet(true, `packet/incomingAndOutGoingPacketsCount`).toPromise();
      if (!!data.data) {
        this.GridData = data.data;
      }
    } catch (error) {
      console.error("Error fetching worker data:", error);
    }
  }

  async startTally() {
    try {
      // await this.getWorkers();
      console.log(this.workerData);
      // this.dropDownWorkerData['options'] = this.workerData;
      this.showStartTally = !this.isStartTally;
      this.isStartTally = !this.isStartTally;
      console.log(this.isStartTally);
      let incoming = this.GridData?.totalIncoming ?? 0
      let outGoing = this.GridData?.totalOutgoing ?? 0
      if (incoming != 0 && outGoing != 0) {
        this.configService.showToast(false, "To Start Tallying The Stock First Clear OutGoing");
      } else {
        let statusParam = !this.isStartTally ? "1" : "0";
        this.showStartTally = !this.isStartTally;
        this.isStartTally = !this.isStartTally;
        this.tallyStatus = !this.tallyStatus;
        let payload = null;
        let response = await this.httpService.postDataUsingPromise(true, `tbl-department/changeStatusOfTallyStock?status=${statusParam}`, payload);
        console.log(response);

        this.httpService.getErrorAndDisplayIt(response);
        if (this) {
          this.dropDownWorkerData["options"] = this.workerData;
          let data = response.data;
          console.log(data);
          this.isVoucher = true;
        } else {
          this.isVoucher = false;
          console.error(response);
        }

        
      }
    } catch (error) {
      console.error("An error occurred:", error);
      this.configService.showToast(false, "Something went wrong!");
    } finally {
    }
  }

  dropDownSelection(evt: any, plan: any) {
    console.log("dropDownSelection");
    console.log(evt);
    if (evt.property == "Worker") {
      this.workerName = evt?.arrayList[0]?.fullName;
      this.workerId = evt?.arrayList[0]?.id;
      this.setValuesOfDropdown(evt);
    }
  }

  onVoucherSelection(evt: any) {
    this.selectedVoucherId = evt?.arrayList[0]?.id;
    this.getMisMatchedPackets(this.selectedVoucherId);
  }

  setValuesOfDropdown(evt) {
    if (this.dropdown[evt.property] == undefined) {
      if (evt.arrayList.length > 0) {
        this.dropdown[evt.property] = {};
        this.dropdown[evt.property] = evt.arrayList[0];
        console.log(evt.arrayList);
      } else {
        delete this.dropdown[evt.property];
      }
    } else {
      if (evt.arrayList.length > 0) {
        this.dropdown[evt.property] = {};
        this.dropdown[evt.property] = evt.arrayList[0];
      } else {
        delete this.dropdown[evt.property];
      }
    }
    console.log(this.dropdown);
    this.isUpdateDisable = Object.keys(this.dropdown).length === 0 ? true : false;
  }

  async barcodeDetection(barcode) {
    if (barcode == "" || barcode == null) {
      return;
    }
    // else if (this.isVoucher) {
    if (this.dataSource.filter(data => data.packetID === barcode).length === 0) {
      this.dataSource.push({
        id: this.dataSource.length + 1,
        packetID: barcode,
        // worker: this.workerName,
        // workerId: this.workerId,
      });
    }
    // }
    // this.dropDownWorkerData["selectedData"] = {};
    // this.workerName = '';
  }

  clearBarcode() {
    console.log("hii");
    this.barcodeInputs = "";
  }

  onSelectionChanged(event) {
    console.log("onSelectionChanged");
    console.log(event);
  }

  onCancel() {
    // if (this.tallyStatus) {
    //   this.startTally();
    // }
    this.changeStatusOfTally(0);
    this.isVoucher = false;
    this.dataSource = [];
  }

  async changeStatusOfTally(statusParam) {
    let response = await this.httpService.postDataUsingPromise(true, `tbl-department/changeStatusOfTallyStock?status=${statusParam}`, null);
    console.log(response);

    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      this.dropDownWorkerData["options"] = this.workerData;
      let data = response.data;
      console.log(data);
    } else {
      console.error(response);
    }
  }

  onDelete(event) {
    console.log(this.dataSource);
    const idToRemove = event.row.data.id;

    const indexToRemove = this.dataSource.findIndex(item => item.id === idToRemove);

    if (indexToRemove !== -1) {
      this.dataSource.splice(indexToRemove, 1);
    }
  }

  async IsOutgoingISEmpty() {
    // this.httpService.getDataWithoutPayloadForGet(true, "packet/checkTheStatusOfOutcomingPackets").subscribe(data => (this.isOutGoingNotEmpty = data.data));
  }

  async getWorkers() {
    try {
      const data = await this.httpService.getDataWithoutPayloadForGet(true, "users/getUsersListByDepartment").toPromise();
      this.workerData = data.data;
      console.log(this.workerData);
    } catch (error) {
      console.error("Error fetching worker data:", error);
    }
  }

  async getVocucherList() {
    try {
      const data = await this.httpService.getDataWithoutPayloadForGet(true, "tally-stock-logs/getCurDateTallyVouchers").toPromise();
      this.dropDownVoucherData["options"] = data.data;
    } catch (error) {
      console.error("Error fetching worker data:", error);
    } finally {
    }
  }

  async getTallyStatus() {
    try {
      const data = await this.httpService.getDataWithoutPayloadForGet(true, "tbl-department/getTallyStatus").toPromise();
      this.tallyStatus = data.data;
      console.log(this.tallyStatus);
    } catch (error) {
      console.error("Error fetching worker data:", error);
    }
  }

  async onAdd() {
    try {
      // this.voucherPackets = this.dataSource;
      if (this.curAction == ACTIONNAMES.tallyMismatchedStock) {
        if (!this.selectedVoucherId) {
          this.configService.showToast(false, "Please select voucher!");
          return;
        }
        const scannedPacketIds = [];
        this.dataSource.map((ds: any) => {
          scannedPacketIds.push(ds.packetID.toLowerCase());
        });

        const misMachedPacketIds = [];
        if (this.misMachedPackets.length > 0) {
          this.misMachedPackets.map((mp: any) => {
            misMachedPacketIds.push(mp.barcodeNo.toLowerCase());
          });
          for (let item in misMachedPacketIds) {
            if (scannedPacketIds.includes(misMachedPacketIds[item])) {
              continue;
            } else {
              this.configService.showToast(false, "Please scan all mismatched packets!");
              return;
            }
          }
        }

        if (this.isNotOtherDeptTallyDone) {
          this.configService.showToast(false, "Extra packet is not yet tally in other department!");
          return;
        }
      }
      const packetIds = [];
      this.dataSource.map((a: any) => {
        packetIds.push(a.packetID);
      });
      let payload = {
        packetIds,
      };
      let response = await this.httpService.postDataUsingPromise(true, "tally-stock-logs/saveTallyStock", payload);
      this.httpService.getErrorAndDisplayIt(response);
      if (response.statusCode === 200) {
        let data = response.message;
        this.dataSource = [];
        this.configService.showToast("success", data);
      } else {
        console.error(response);
        this.configService.showToast("error", response.error.message);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      this.configService.showToast(false, "Something went wrong!");
    } finally {
      // Hide the loader
    }
  }

  async openBarcodeScannerModal() {
    const modal = await this.modalCtrl.create({
      component: MultipleBarcodeScannerComponent,
      cssClass: "multiplBarcodeScanner",
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === "done") {
      this.barcodeInputList = data.map(barcode => barcode.barcode);
      console.log(this.barcodeInputList);
      this.barcodeInputList.forEach(barcodeItem => {
        if (this.dataSource.filter(data => data.packetID === barcodeItem).length === 0) {
          this.dataSource.push({
            id: this.dataSource.length + 1,
            packetID: barcodeItem,
          });
        }
      });
    }
  }

  async onVoucher() {
    // if (this.tallyStatus) {
    //   this.startTally();
    // }

    try {
      let payload = null;
      if (this.curAction == ACTIONNAMES.tallyMismatchedStock) {
        payload = {
          voucherId: this.selectedVoucherId,
        };
      }
      let response = await this.httpService.postDataUsingPromise(true, "tally-stock-logs/updateVoucherAndCheckPacketMatched", payload);
      console.log(response);
      this.httpService.getErrorAndDisplayIt(response);
      if (response.statusCode === 200) {
        this.tallyVoucherResponse = response.data;
        this.voucherStatus = response.data.packetsMatchedStatus;
        if (this.voucherStatus == true) {
          this.dataSource = [];
          this.showModalOnVoucherResponse("IsManagerTallyVoucherSuccessFull");
          // this.configService.showToast(true, "Voucher Created Successfully");
        } else {
          this.isVoucher = false;
          this.showModalOnVoucherResponse("IsManagerTallyVoucherFails");
        }
        // this.dropDownWorkerData['selectedData'] = {};
      } else if (response.err) {
        this.configService.showToast(false, response.err);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      this.configService.showToast(false, "Something went wrong!");
    } finally {
      // Hide the loader
    }
    this.isDataAddedOnce = false;
  }

  async showModalOnVoucherResponse(status: string) {
    this.isVoucher = false;
    console.log(status);
    const Modal = await this.modalCtrl.create({
      component: TrybemodalComponent,
      cssClass: "modalprint",
      componentProps: {
        data: { status: status },
      },
    });
    Modal.onDidDismiss().then(async (d: any) => {
      await this.getVoucherData();
      setTimeout(() => {
        this.onModalDismiss(d);
      }, 1000);
    });
    return await Modal.present();
  }

  async onModalDismiss(d: any) {
    console.log("onModalDismiss.....");
    console.log(d);
    this.isVoucher = false;
    if (!!d.data) {
      if (d.data == "printTallyVoucher") {
        let style = `
        table {
          width: 100%;
          border-collapse: collapse;
        }
        th, td {
          border: 1px solid black;
          border-collapse: collapse;
          text-align: center;
          padding:2px
        }
        body * {
          visibility: hidden;
      }
      .fs-12 {
        font-size: 12px;
      }
      .tac {
        text-align: center;
      }
      hr.initial {
        border-top: 1px dotted #000;
      }

      .flex-center-between{
        display:flex;
        align-items:center;
        justify-content:space-between;
      }
      .minspace{
        min-height: 15px;
      }
      .mr{
        margin-right:10px;
      }
      @media print {
        body * {
          visibility: visible;
      }
        `;
        const voucherHtml = document.getElementById("tallyVoucher");
        const voucherWindow = window.open("", "_blank");
        voucherWindow.document.write(`
        <html>
          <head>
          <title>Tally Voucher</title>
            <style>${style}
            </style>
          </head>
          <body>
            ${voucherHtml.innerHTML}
          </body>
        </html>
      `);
        voucherWindow.document.close();
        voucherWindow.print();
        voucherWindow.close();
      }
      console.log(d.data);
      this.voucherFailStatus = d.data;
      if (this.voucherFailStatus == "startTally") {
        this.dataSource = [];
      }
    }
  }

  async getVoucherData() {
    let payload = {
      voucherId: this.tallyVoucherResponse.voucherId,
      refVoucherId: this.selectedVoucherId ? this.selectedVoucherId : null,
    };
    let response = await this.httpService.postDataUsingPromise(true, "tally-stock-logs/getTallyVoucherData", payload);
    this.httpService.getErrorAndDisplayIt(response);
    if (response.statusCode === 200) {
      this.voucherPackets = response.data;
      this.totalWeight = parseFloat(this.voucherPackets.reduce((total, packet) => total + packet.cts, 0).toFixed(2));
    }
  }

  async getMisMatchedPackets(id) {
    if (id) {
      try {
        const data = await this.httpService.getMisMachedPacketbyVoucherId(id).toPromise();
        this.misMachedPackets = data?.data?.packets;
        this.isNotOtherDeptTallyDone = data?.data?.isTallyNotDone;
        console.log("this.misMachedPackets-------->", this.misMachedPackets);
      } catch (error) {
        console.error("Error fetching worker data:", error);
      }
    }
  }

  async openModal(type) {
    this.ModalData = type === "Incoming" ? Object.keys(this.GridData.incoming) : type === "Outgoing" ? Object.keys(this.GridData.outgoing) : [];

    const modal = await this.modalCtrl.create({
      component: DynamicModalComponent,
      componentProps: {
        template: this.modalContent,
        class: "scrool",
      },
      cssClass: "DynamicModalSmall",
    });

    await modal.present();
  }
}
