import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { HttpService } from "src/app/services/http/http.service";
import { selectChildParameters, selectMasterParameters } from "../state-management/masters.selector";
import { ADD_CHILD_PARAMETERS, ADD_MASTER_PARAMETERS, GET_CHILD_PARAMETERS, GET_MASTER_PARAMETERS, UPDATE_CHILD_PARAMETERS } from "../state-management/masters.action";
import { ConfigService } from "src/app/services/config/config.service";
import { confirm } from "devextreme/ui/dialog";
import { DynamicModalComponent } from "src/app/transfer-packet-to-dispatch/dynamic-modal/dynamic-modal.component";
import { ModalController } from "@ionic/angular";

@Component({
  selector: "app-parameter-master",
  templateUrl: "./parameter-master.component.html",
  styleUrls: ["./parameter-master.component.scss"],
})
export class ParameterMasterComponent implements OnInit, OnDestroy {
  parameterDatasource: any;
  // ModalCtrl: any;
  @ViewChild("modalContent") modalContent: ElementRef;
  @ViewChild("opinionmodalContent") opinionmodalContent: ElementRef;
  @ViewChild("remarkmodalContent") remarkmodalContent: ElementRef;
  frValue: any;
  toValue: any;
  roughname: any;
  islotparametersUpdate: boolean = false;
  selectedRowData: any = null;
  refreshGrid = false;
  opinionDatasource: any;
  OpinionDropDownOptionForAdd: any = [
    // {
    //   id: 2,
    //   remark: "BROKEN",
    //   mistakeFrom: "MANAGER MISTAKE",
    //   groupId: 2,
    // },
    // {
    //   id: 19,
    //   remark: "ZIRAM INCREASE",
    //   mistakeFrom: null,
    //   groupId: 2,
    // },
  ];
  allOpinionRemark: any;
  constructor(private httpService: HttpService, private store: Store, private configService: ConfigService, private ModalCtrl: ModalController) {}

  ngOnInit() {
    this.onInit();

    // document.body.addEventListener('click', (v) => {
    //     this.areOptionsVisible &&
    //     !this.menu.nativeElement.contains(v.target) &&
    //     !this.toogle.nativeElement.contains(v.target)
    //       ? (this.areOptionsVisible = false)
    //       : null;
    // });
  }

  subScriptions = new Subscription();
  searchText: string = "";
  copyParameters: any[] = [];
  parameters: any[] = [];
  roughcolumns: any;
  OpinionDropDownOption: any;
  selectedRemarkForOpinion: any[]=[];
  opinioncolumns: any;
  columns: any[] = [
    { name: "Active", prop: "isDisabled", columnTemplate: "isDisable" },
    { name: "Short Code", prop: "code", columnTemplate: "row-click" },
    { name: "Name", prop: "name", columnTemplate: "row-click" },
    { name: "Type", prop: "transTypeId", columnTemplate: "row-click" },
    { name: "Priority", prop: "priority", columnTemplate: "row-click" },
  ];

  roughtypecolumns: any[] = [{ name: "Name", prop: "name", columnTemplate: "row-click" }];
  roughCriteriacolumns: any[] = [
    { name: "Name", prop: "name", columnTemplate: "row-click" },
    { name: "frValue", prop: "frValue" },
    { name: "toValue", prop: "toValue" },
  ];
  roughCountrycolumns: any[] = [{ name: "Name", prop: "name", columnTemplate: "row-click" }];
  roughNcodecolumns: any[] = [{ name: "Name", prop: "name", columnTemplate: "row-click" }];
  groupypecolumns: any[] = [{ name: "Group", prop: "groupName", columnTemplate: "row-click" }];
  remarkTypecolumns: any[] = [
    { name: "Remark", prop: "remark", columnTemplate: "row-click" },
    { name: "Mistake From", prop: "mistakeFrom" },
  ];
  remark;
  typeList = [
    {
      id: 1,
      code: "SS",
      name: "SS",
    },
    {
      id: 2,
      code: "MIX",
      name: "MIX",
    },
    {
      id: 3,
      code: "PURCHASE",
      name: "PURCHASE",
    },
  ];
  dataSource: any[] = [];
  isMasterParameterModalOpen: boolean = false;
  parameterName: string = "";
  description: string = "";
  selectedMistakeFrom: any = [];
  selectedMasterParameter: any;
  selectedLotMasterParameter: any;
  selectedOpinionMasterParameter: any;
  isAddNewModal: boolean = false;
  currentBox: any = "HomePage";
  selectedType: string | null = null;
  isActive: boolean = false;
  shortCode: string = "";
  name: string = "";
  priority: number | null = null;
  searchParameterText: string = "";
  copyDataSource: any[] = [];
  mistakeFromOption: any[];
  selectedChildParameter: any;
  paramTypeCards = [
    {
      title: "Parameter Master",
      description: "Parameter Master",
    },
    {
      title: "Lot Creation Master",
      description: "Lot Creation Master",
    },
    {
      title: "Opinion Remark Master",
      description: "Opinion Remark Master",
    },
  ];

  lotCreationMasters = [
    { title: "Rough Type", description: "Manage rough types" },
    { title: "Rough Category", description: "Manage rough categories" },
    { title: "Rough Country", description: "Manage rough countries" },
    { title: "Rough N Code", description: "Manage rough NCodes" },
  ];
  OpinionRemarkMasters = [
    { title: "Group Master", description: "Manage Group" },
    { title: "Remark Master", description: "Manage Remark" },
  ];

  private onInit() {
    // this.getMasterParameters()
  }

  //#region masterParameter

  onInputChange() {
    this.filterParameters(this.searchText);
  }

  getMasterParameters() {
    const subs = this.store.select(selectMasterParameters).subscribe(res => {
      const { masterList, isLoaded } = JSON.parse(JSON.stringify(res));
      if (isLoaded) {
        this.parameters = masterList;
        this.copyParameters = JSON.parse(JSON.stringify(masterList));
      } else {
        this.store.dispatch(GET_MASTER_PARAMETERS({ isReload: true }));
      }
    });

    this.subScriptions.add(subs);
  }

  filterParameters(searchText) {
    if (!searchText) {
      this.parameters = JSON.parse(JSON.stringify(this.copyParameters));
      return;
    }
    const filteredParameters = this.parameters.filter(parameter => {
      return parameter.masterName.toLowerCase().includes(searchText.toLowerCase());
    });
    this.parameters = JSON.parse(JSON.stringify(filteredParameters));
  }

  onOpenMasterParameterModal() {
    this.isMasterParameterModalOpen = true;
  }

  onMasterParameterClose() {
    this.isMasterParameterModalOpen = false;
    this.refreshGrid = true;
  }

  addMasterParameter() {
    const payload = {
      masterName: this.parameterName,
      description: this.description,
    };

    const subs = this.httpService.postAddUpdateMasterParameters(payload).subscribe(res => {
      console.log(res);
      if (res?.statusCode === 200) {
        const { data } = res;
        this.store.dispatch(ADD_MASTER_PARAMETERS({ payload: data || payload }));
        // this.parameters.push(data);
        this.onMasterParameterClose();
      }
    });

    this.subScriptions.add(subs);
  }

  onMasterParameterSelected(item: any) {
    console.log(item);
    this.selectedMasterParameter = item;
    this.dataSource = [];
    const subs = this.store.select(selectChildParameters).subscribe(res => {
      const { parameters, isLoaded } = JSON.parse(JSON.stringify(res));
      if (parameters[item.id]) {
        this.dataSource = parameters[item.id];
        this.copyDataSource = JSON.parse(JSON.stringify(parameters[item.id]));
      } else {
        this.store.dispatch(GET_CHILD_PARAMETERS({ ID: item.id, isReload: true }));
      }
    });

    this.subScriptions.add(subs);
  }

  onLotCreationParameterSelected(item: any) {
    this.selectedLotMasterParameter = item.title;
    switch (this.selectedLotMasterParameter) {
      case "Rough Type":
        this.roughcolumns = this.roughtypecolumns;
        this.fetchGridData();
        break;

      case "Rough Category":
        this.roughcolumns = this.roughCriteriacolumns;
        this.fetchGridData();
        break;

      case "Rough Country":
        this.roughcolumns = this.roughCountrycolumns;
        this.fetchGridData();
        break;

      case "Rough N Code":
        this.roughcolumns = this.roughNcodecolumns;
        this.fetchGridData();
        break;
    }
  }

  onRemarkParameterSelected(item: any) {
    this.selectedOpinionMasterParameter = item.title;
    switch (this.selectedOpinionMasterParameter) {
      case "Group Master":
        this.opinioncolumns = this.groupypecolumns;
        this.fetchOpinionGridData();
        break;

      case "Remark Master":
        this.opinioncolumns = this.remarkTypecolumns;
        this.fetchOpinionGridData();
        this.mistakeFromOption = [{ mistakeof: "WORKER MISTAKE" }, { mistakeof: "MANAGER MISTAKE" }, { mistakeof: "PLANNER MISTAKE" }, { mistakeof: "NO WORKER MISTAKE" }];
        break;
    }
  }

  //#endregion

  //#region Parameters
  async getRemarkForOpinion() {
    const data = await this.httpService
      .getDataWithoutPayloadForGet(
        true,
        `opnion-remark/getRemarksWhoseGrpIsNotAssigned
`
      )
      .toPromise();
    this.OpinionDropDownOptionForAdd = data.data;
  }
  onSelectionChanged(event: any) {
    console.log(event);
    this.selectedChildParameter = event?.data;
    const { data } = event;
    this.selectedType = data?.transTypeId;
    this.name = data?.name;
    this.isActive = data?.isDisabled ? false : true;
    this.priority = data?.priority;
    this.shortCode = data?.code;
    if (data) this.isAddNewModal = true;
  }

  onAddParameterOpen() {
    this.onResetForm();
    this.isAddNewModal = true;
  }

  onSearchParameter() {
    if (!this.searchParameterText) {
      this.dataSource = this.copyDataSource;
      return;
    }
    this.dataSource = this.dataSource.filter((el: any) => el.name.toLowerCase().includes(this.searchParameterText.toLowerCase()));
  }

  onAddParameterClose() {
    this.isAddNewModal = false;
  }

  addParameter() {
    if (!this.validateParameter()) return;
    const payload = {
      code: this.shortCode,
      name: this.name,
      stoneParameterNameId: this.selectedMasterParameter.id,
      isDisabled: this.isActive ? 1 : 0,
      priority: this.priority,
      transTypeId: this.selectedType,
    };
    const subs1 = this.httpService.postChildParameterData(payload).subscribe(res => {
      const { data } = res;
      this.dataSource.push(data);
      this.copyDataSource.push(data);
      const tempModal = {
        parameters: this.dataSource,
        stoneParameterNameId: this.selectedMasterParameter.id,
      };
      this.store.dispatch(ADD_CHILD_PARAMETERS({ payload: { ...tempModal } }));
      this.store.select(selectChildParameters).subscribe(res => console.log(res));
      this.onAddParameterClose();
    });

    this.subScriptions.add(subs1);
  }

  private validateParameter() {
    let isValid = true;
    let msg = "";

    if (!this.shortCode) {
      msg += "code is Required \n";
    }

    if (!this.selectedType) {
      msg += "type is Required \n";
    }

    if (!this.priority) {
      msg += "priority is Required \n";
    }

    if (msg) {
      this.configService.showToast("error", msg);
      return false;
    }

    return isValid;
  }

  updateParameter() {
    if (!this.validateParameter()) return;
    const payload = {
      code: this.shortCode,
      name: this.name,
      stoneParameterNameId: this.selectedMasterParameter.id,
      isDisabled: this.isActive ? 0 : 1,
      priority: this.priority,
      transTypeId: this.selectedType,
      id: this.selectedChildParameter.id,
    };
    const subs1 = this.httpService.postChildParameterData(payload).subscribe(res => {
      const { data } = res;
      let index = this.dataSource.findIndex((el: any) => el.id === this.selectedChildParameter.id);
      if (index != -1) {
        this.dataSource[index] = { ...data };
      }
      this.copyDataSource = JSON.parse(JSON.stringify(this.dataSource));
      const tempModal = {
        parameters: this.dataSource,
        stoneParameterNameId: this.selectedMasterParameter.id,
      };
      this.store.dispatch(UPDATE_CHILD_PARAMETERS({ payload: { ...tempModal } }));
      this.isAddNewModal = false;
    });
    this.subScriptions.add(subs1);
  }

  onResetForm() {
    this.selectedChildParameter = null;
    this.selectedType = null;
    this.name = null;
    this.isActive = false;
    this.priority = null;
    this.shortCode = null;
  }
  onResetlatest() {
    this.name = null;
    this.toValue = null;
    this.frValue = null;
  }

  async onDisableParameter(event) {
    console.log(event);
    const { data } = event.row;
    confirm(`Do you want to ${data?.isDisabled ? "Enable parameter ?" : "Disable parameter ?"} `, "Warning").then(response => {
      if (response) {
        const payload = {
          isDisabled: data?.isDisabled === 1 ? 0 : 1,
          id: data.id,
        };
        const subs1 = this.httpService.postDisableChildParameter(payload).subscribe((res: any) => {
          console.log(res);
          console.log(this.dataSource);
          let index = this.dataSource.findIndex(el => el.id === data.id);
          this.dataSource[index].isDisabled = payload.isDisabled;
          console.log(this.dataSource);
        });
        this.subScriptions.add(subs1);
      } else {
        let index = this.dataSource.findIndex(el => el.id === data.id);
        this.dataSource[index] = { ...data };
      }
      this.refreshGrid = true;
    });
  }

  //#endregion

  onBackBtnClick() {
    this.currentBox = "HomePage";
  }

  onCardClick(title: string) {
    this.selectedRowData = null;
    this.currentBox = title;
    switch (this.currentBox) {
      case "Parameter Master":
        this.getMasterParameters();
        break;

      case "Lot Creation Master":
        this.roughcolumns = this.roughtypecolumns;
        this.fetchGridData();
        break;

      case "Opinion Remark Master":
        this.opinioncolumns = this.opinioncolumns;
        this.findAllRemark();

        break;

      default:
        console.log("Unknown box type");
        break;
    }
  }

  async fetchGridData() {
    try {
      let endpoint: string;
      switch (this.selectedLotMasterParameter) {
        case "Rough Type":
          endpoint = "rough-lot/getRoughType";
          break;
        case "Rough Category":
          endpoint = "rough-lot/getCategory";
          break;
        case "Rough Country":
          endpoint = "rough-lot/getCountries";
          break;
        case "Rough N Code":
          endpoint = "rough-lot/getNCode";
          break;

        default:
          return;
        // throw new Error("Unknown box type");
        // return;
      }

      const res: any = await this.httpService.fetchData(true, endpoint).toPromise();
      if (res) {
        console.log(res);
        let resultCode: number = res.statusCode;
        let data: any = res.data;
        console.log(data);
        if (resultCode == 200) {
          console.log(res.data);
          if (res.data.length == 0) {
            this.configService.showToast("Error", "No Data Available");
          }
          this.parameterDatasource = res.data;
        } else {
          this.configService.showToast("Error", res.error);
        }
      }
    } catch (error) {
      console.error("An error occurred:", error);
      this.configService.showToast("Error", "Something went wrong!");
      this.parameterDatasource = [];
    } finally {
    }
  }

  async fetchOpinionGridData() {
    try {
      let endpoint: string;
      switch (this.selectedOpinionMasterParameter) {
        case "Group Master":
          endpoint = "opnion-remark/fetchAllRemarkGrp";
          break;
        case "Remark Master":
          endpoint = "opnion-remark/findAll";
          break;
        default:
          return;
      }
      const res: any = await this.httpService.fetchData(true, endpoint).toPromise();
      if (res) {
        console.log(res);
        let resultCode: number = res.statusCode;
        let data: any = res.data;
        console.log(data);
        if (resultCode == 200) {
          console.log(res.data);
          if (res.data.length == 0) {
            this.configService.showToast("Error", "No Data Available");
          }
          this.opinionDatasource = res.data;
        } else {
          this.configService.showToast("Error", res.error);
        }
      }
    } catch (error) {
      console.error("An error occurred:", error);
      this.configService.showToast("Error", "Something went wrong!");
      this.opinionDatasource = [];
    } finally {
    }
  }

  async save() {
    let payload: any = {
      name: this.name,

      ...(this.islotparametersUpdate ? { id: this.selectedRowData?.id } : {}),
    };
    // console.log(payload,"payload")

    switch (this.selectedLotMasterParameter) {
      case "Rough Type":
        payload.name = this.name;
        break;
      case "Rough Category":
        payload.name = this.name;
        payload.frValue = this.frValue;
        payload.toValue = this.toValue;
        break;
      case "Rough Country":
        payload.name = this.name;
        break;
      case "Rough N Code":
        payload.name = this.name;
        break;
      default:
        console.error("Unknown box type");
        return;
    }

    let endpoint: string;
    switch (this.selectedLotMasterParameter) {
      case "Rough Type":
        endpoint = "rough-lot/roughType";
        break;
      case "Rough Category":
        endpoint = "rough-lot/roughCategory";
        break;
      case "Rough Country":
        endpoint = "rough-lot/roughCountry";
        break;
      case "Rough N Code":
        endpoint = "rough-lot/roughNCode";
        break;
      default:
        console.error("Unknown box type");
        return;
    }
    // console.log(payload,"payload")

    try {
      // return
      const res: any = await this.httpService.postDataUsingPromise(true, endpoint, payload);
      if (res?.statusCode === 200) {
        this.configService.showToast("success", "Data saved successfully");
        this.fetchGridData();
        this.Close();
        this.onResetlatest();
      } else {
        this.configService.showToast("error", res?.error || "Failed to save data");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      this.configService.showToast("error", "Something went wrong!");
    }
  }

  async addLotParameters(rowData?: any) {
    this.selectedRowData = [];
    this.islotparametersUpdate = false;

    if (rowData) {
      this.islotparametersUpdate = !!rowData;
      this.selectedRowData = rowData?.data || {};
      this.name = rowData.data.name;
      if (this.selectedLotMasterParameter === "Rough Category") {
        this.frValue = rowData.data.frValue;
        this.toValue = rowData.data.toValue;
      }
    } else {
      this.name = "";
      this.frValue = "";
      this.toValue = "";
    }
    const modal = await this.ModalCtrl.create({
      component: DynamicModalComponent,
      componentProps: {
        template: this.modalContent,
      },
      cssClass: "LotPrameterModal",
    });
    await modal.present();
  }

  async addOpinionGroupParameters(isRefresh: boolean = true) {
    if (isRefresh) {
      await this.getRemarkForOpinion();
      this.selectedRowData = [];
      this.selectedRemarkForOpinion = [];
      this.refreshGrid = true;
      setTimeout(() => {
        this.refreshGrid = false;
      });
    }
    if (!this.selectedRowData?.id) {
      this.OpinionDropDownOption = this.OpinionDropDownOptionForAdd;
    }
    const modal = await this.ModalCtrl.create({
      component: DynamicModalComponent,
      componentProps: {
        template: this.opinionmodalContent,
      },
      cssClass: "partyconfigmodal",
    });
    await modal.present();
  }

  async addRemarkParameters() {
    const modal = await this.ModalCtrl.create({
      component: DynamicModalComponent,
      componentProps: {
        template: this.remarkmodalContent,
      },
      cssClass: "partyconfigmodal",
    });
    await modal.present();
  }

  onLotPrameterclick(rowData: any) {
    this.selectedRowData = rowData.data;
    this.addLotParameters(rowData);
  }
  async onOpinionPrameterclick(rowdata: any) {
    if (this.selectedOpinionMasterParameter === "Remark Master") {
      return;
    }
    this.selectedRemarkForOpinion = [];
    this.selectedRowData = rowdata.row;
    var data;
    if (!!this.selectedRowData) {
      this.addOpinionGroupParameters(false);
      data = await this.httpService.getDataWithoutPayloadForGet(true, `opnion-remark/findRemarkGrpWise?groupId=${this.selectedRowData?.id}`).toPromise();
    }
    this.OpinionDropDownOption = [...(data?.data || []), ...(this.OpinionDropDownOptionForAdd || [])];
    this.selectedRemarkForOpinion = data?.data ? data.data.map(item => item.id) : [];

  }
  async findAllRemark() {
    const data = await this.httpService.getDataWithoutPayloadForGet(true, `opnion-remark/findAll`).toPromise();
    this.OpinionDropDownOption = data.data;
    this.allOpinionRemark = data.data;
    // this.addLotParameters(rowData);
  }
  Close() {
    this.ModalCtrl.dismiss();
    this.onResetlatest();
    this.selectedRowData = null;
  }

  ngOnDestroy(): void {
    this.subScriptions.unsubscribe();
  }
  async onSaveNewremark(type) {
    let payload: any = {
      remarksIds: this.selectedRemarkForOpinion,
    };

    if (type === "update") {
      payload.grpId = this.selectedRowData.id;
    }

    console.log(payload);
    try {
      const response = await this.httpService.updateData(true, `opnion-remark/updateRemarkGrp`, payload).toPromise();
      if (response && response.statusCode === 200) {
        await this.getRemarkForOpinion();
        this.fetchOpinionGridData();
         this.refreshGrid = true;
         setTimeout(() => {
        this.refreshGrid = false;
      });
        this.ModalCtrl.dismiss();
      } else {
        console.error("Unexpected status code:", response.status);
      }
    } catch (error) {
      console.error("Error while saving remarks:", error);
    }
  }
  async onSaveremarkMaster() {
    let payload: any = {
      remark: this.remark,
      mistakeFrom: this.selectedMistakeFrom,
    };
    try {
      const response = await this.httpService.saveData(true, `opnion-remark/save`, payload).toPromise();
      if (response && response.statusCode === 200) {
        this.fetchOpinionGridData();
        this.ModalCtrl.dismiss();
      } else {
        console.error("Unexpected status code:", response.status);
      }
    } catch (error) {
      console.error("Error while saving remarks:", error);
    }
  }
}
