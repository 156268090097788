import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { IonSlides, ModalController, NavParams } from '@ionic/angular';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { getParamsValuesListByName } from 'src/app/masterDataStore/masterData.selector';
import { AppState } from 'src/app/reducers';
import { ConfigService } from 'src/app/services/config/config.service';
import { ParameterTypes } from 'src/app/services/endpoint.enum';
import { HttpService } from 'src/app/services/http/http.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { getCurrentRowData } from 'src/app/tempDataStore/tempData.selector';

@Component({
  selector: 'app-copyplanmodal',
  templateUrl: './copyplanmodal.component.html',
  styleUrls: ['./copyplanmodal.component.scss'],
})
export class CopyplanmodalComponent implements OnInit {
  planData: any;
  shapeOptions = [];
  @ViewChild('slider', { static: false }) slider: IonSlides;
  shapeIds = [];
  clarityOptions = [];
  fluoresceneOptions = [];
  clarityIds = [];
  colorOptions = [];
  colorIds = [];
  cutOptions = [];
  polishOptions: any = [];
  symmetryOptions: any = [];
  nattsOptions: any = [];
  tinchOptions: any = [];
  lusterOptions: any = [];
  labOptions: any = [];
  cutIds = []
  symmentryOptions = [];
  message: string = '';
  forPage: any = '';
  visionFile: any;
  currRowData: any;
  private unsubscribe$: Subject<any> = new Subject<any>();
  tensionPicturePaths: any;
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    switch (event.key) {
      case 'ArrowLeft':
        this.slidePrev();
        break;
      case 'ArrowRight':
        this.slideNext();
        break;
    }
  }

  constructor(
    private modalCtl: ModalController,
    public navParam: NavParams,
    private httpService: HttpService,
    private loaderService: LoaderService,
    private store: Store<AppState>,
    private configService: ConfigService,
    private sanitizer: DomSanitizer
  ) {


  }

  alpha: any = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";


  closeModal(data: any) {
    if (!!data) {
      this.modalCtl.dismiss(data);
    } else {
      this.modalCtl.dismiss();
    }
  }

  getHexValue(a) {
    a += 1;
    var c = 0;
    var x = 1;
    while (a >= x) {
      c++;
      a -= x;
      x *= 26;
    }
    var s = "";
    for (var i = 0; i < c; i++) {
      s = this.alpha.charAt(a % 26) + s;
      a = Math.floor(a / 26);
    }
    return s;
  }


  async ngOnInit() {

    this.store
      .pipe(select(getCurrentRowData))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((rd: any) => {
        if (rd) {
          this.currRowData = rd[0];
        }
      });


    this.planData = await this.navParam.get('data');
    this.message = await this.navParam.get('message');
    // if (this.message == 'showVision360') {
    //   await this.getVision360File();
    // }

    if (this.message == 'showTensionPhoto' || this.message == 'showVision360' && this.planData) {
      this.getTensionPhoto();
    }

    this.twoDecimalNum("tableVal");
    this.twoDecimalNum("diameter");
    this.twoDecimalNum("height");
    this.twoDecimalNum("lOrW");
    this.twoDecimalNum("girdle");
    this.twoDecimalNum("crHeight");
    this.twoDecimalNum("pavAngle");
    this.twoDecimalNum("lengthMM");
    this.twoDecimalNum("widthMM");
    this.twoDecimalNum("crAngle");
    this.twoDecimalNum("pavHeight");

    if (this.message != 'showVision360') {
      this.store.pipe(select(getParamsValuesListByName(ParameterTypes.Color))).subscribe(data => {
        if (!!data) {
          this.colorOptions = data;
        }
      });
      this.store.pipe(select(getParamsValuesListByName(ParameterTypes.Shape))).subscribe(data => {
        if (!!data) {
          this.shapeOptions = data;
        }
      });

      this.store.pipe(select(getParamsValuesListByName(ParameterTypes.Clarity))).subscribe(data => {
        if (!!data) {
          this.clarityOptions = data;
        }
      });

      this.store.pipe(select(getParamsValuesListByName(ParameterTypes.Fluorescence))).subscribe(data => {
        if (!!data) {
          this.fluoresceneOptions = data;
        }
      });

      this.store.pipe(select(getParamsValuesListByName(ParameterTypes.CUT))).subscribe(data => {
        if (!!data) {
          this.cutOptions = data;
        }
      });

      this.store.pipe(select(getParamsValuesListByName(ParameterTypes.Polish))).subscribe(data => {
        if (!!data) {
          this.polishOptions = data;
        }
      });

      this.store.pipe(select(getParamsValuesListByName(ParameterTypes.Symmetry))).subscribe(data => {
        if (!!data) {
          this.symmetryOptions = data;
        }
      });

      this.store.pipe(select(getParamsValuesListByName(ParameterTypes.NATTS))).subscribe(data => {
        if (!!data) {
          this.nattsOptions = data;
        }
      });

      this.store.pipe(select(getParamsValuesListByName(ParameterTypes.Tinch))).subscribe(data => {
        if (!!data) {
          this.tinchOptions = data;
        }
      });

      this.store.pipe(select(getParamsValuesListByName(ParameterTypes.LUSTER))).subscribe(data => {
        if (!!data) {
          this.lusterOptions = data;
        }
      });

      this.store.pipe(select(getParamsValuesListByName(ParameterTypes.Lab))).subscribe(data => {
        if (!!data) {
          this.labOptions = data;
        }
      });

      let newObj = []
      if (this.planData.packetPlanStones) {

        newObj = [...this.planData?.packetPlanStones].map((d: any) => {
          let clarityIds = [];
          let colorIds = [];
          let shapeIds = [];
          let fluorescenceIds = [];
          let cutIds = [];
          let polIds = [];
          let symmetryIds = [];
          let nattsIds = [];
          let tinchIds = [];
          let lusterIds = [];
          let labIds = [];

          if (d.clarity) {
            clarityIds = this.clarityOptions.filter(x => x.name == d.clarity)
          }

          if (d.color) {
            colorIds = this.colorOptions.filter(x => x.name == d.color);
          }

          if (d.shape) {
            shapeIds = this.shapeOptions.filter(x => x.name == d.shape);
          }

          if (d.fluorescence) {
            fluorescenceIds = this.fluoresceneOptions.filter(x => x.name == d.fluorescence);
          }
          if (d.cut) {
            cutIds = this.cutOptions.filter(x => x.name == d.cut);
          }
          if (d.pol) {
            polIds = this.polishOptions.filter(x => x.name == d.pol);
          }
          if (d.symmetry) {
            symmetryIds = this.symmetryOptions.filter(x => x.name == d.symmetry);
          }
          if (d.nattsName) {
            nattsIds = this.nattsOptions.filter(x => x.name == d.nattsName);
          }
          if (d.tinchName) {
            tinchIds = this.tinchOptions.filter(x => x.name == d.tinchName);
          }
          if (d.lusterName) {
            lusterIds = this.lusterOptions.filter(x => x.name == d.lusterName);
          }
          if (d.Lab ? d.Lab : d.labName) {
            labIds = this.labOptions.filter(x => x.name == d.Lab ? d.Lab : d.labName);
          }
          return { ...d, colorIds: colorIds, clarityIds: clarityIds, shapeIds: shapeIds, fluorescenceIds, cutIds, polIds, symmetryIds, nattsIds, tinchIds, lusterIds, labIds }

        });
      }
      this.planData = { ...this.planData, packetPlanStones: newObj }
    }

  }

  async dropDownSelection(evt: any, planId) {
    let property = evt.property;
    let idList = evt.idList;
    let selected = evt.arrayList;
    if (property == "Shape") {
      let newObj = await [...this.planData.packetPlanStones].map((d: any) => {
        if (d.id == planId) {
          return { ...d, shape: selected[0]?.name, planShape: selected[0]?.name, shapeId: selected[0]?.id, shapeIds: selected }
        } else {
          return d
        }
      })
      this.planData = { ...this.planData, packetPlanStones: newObj }
    } else if (property == "Clarity") {
      let newObj1 = await [...this.planData.packetPlanStones].map((d: any) => {
        if (d.id == planId) {
          return { ...d, clarity: selected[0]?.name, planClarity: selected[0]?.name, clarityId: selected[0]?.id, clarityIds: selected }
        } else {
          return d
        }
      })
      this.planData = { ...this.planData, packetPlanStones: newObj1 }
    } else if (property == "Color") {
      let newObj2 = await [...this.planData.packetPlanStones].map((d: any) => {
        if (d.id == planId) {
          return { ...d, color: selected[0]?.name, planColor: selected[0]?.name, colorId: selected[0]?.id, colorIds: selected }
        } else {
          return d
        }
      })
      this.planData = { ...this.planData, packetPlanStones: newObj2 }
    } else if (property == "Fluorescence") {
      let newObj2 = await [...this.planData.packetPlanStones].map((d: any) => {
        if (d.id == planId) {
          return { ...d, fluorescence: selected[0]?.name, fluorescenceId: selected[0]?.id, fluorescenceIds: selected }
        } else {
          return d
        }
      })
      this.planData = { ...this.planData, packetPlanStones: newObj2 }
    } else if (property == "Cut") {
      let newObj2 = await [...this.planData.packetPlanStones].map((d: any) => {
        if (d.id == planId) {
          return { ...d, cut: selected[0]?.name, cutId: selected[0]?.id, cutIds: selected }
        } else {
          return d
        }
      })
      this.planData = { ...this.planData, packetPlanStones: newObj2 }
    } else if (property == "Polish") {
      let newObj2 = await [...this.planData.packetPlanStones].map((d: any) => {
        if (d.id == planId) {
          return { ...d, polish:selected[0]?.name ,pol: selected[0]?.name, polIds: selected,polishId:selected[0]?.id }
        } else {
          return d
        }
      })
      this.planData = { ...this.planData, packetPlanStones: newObj2 }
    } else if (property == "Symmetry") {
      let newObj2 = await [...this.planData.packetPlanStones].map((d: any) => {
        if (d.id == planId) {
          return { ...d, symmetry: selected[0]?.name, symmetryId: selected[0]?.id, symmetryIds: selected }
        } else {
          return d
        }
      })
      this.planData = { ...this.planData, packetPlanStones: newObj2 }
    } else if (property == "Natts") {
      let pdData = await this.getPDCatValueFromNatts(selected[0].id);

      let newObj2 = await [...this.planData.packetPlanStones].map((d: any) => {
        if (d.id == planId) {
          return { ...d, nattsName: selected[0]?.name, natts: selected[0]?.id, nattsIds: selected, pdCat: pdData?.data?.pdCat, pdCatId: pdData?.data?.pdCatId }
        } else {
          return d
        }
      })
      this.planData = { ...this.planData, packetPlanStones: newObj2 }
    } else if (property == "Tinch") {
      let newObj2 = await [...this.planData.packetPlanStones].map((d: any) => {
        if (d.id == planId) {
          return { ...d, tinchName: selected[0]?.name, tinch: selected[0]?.id, tinchIds: selected }
        } else {
          return d
        }
      })
      this.planData = { ...this.planData, packetPlanStones: newObj2 }
    } else if (property == "Luster") {
      let newObj2 = await [...this.planData.packetPlanStones].map((d: any) => {
        if (d.id == planId) {
          return { ...d, lusterName: selected[0]?.name, luster: selected[0]?.id, lusterIds: selected }
        } else {
          return d
        }
      })
      this.planData = { ...this.planData, packetPlanStones: newObj2 }
    } else if (property == "Lab") {
      let newObj2 = await [...this.planData.packetPlanStones].map((d: any) => {
        if (d.id == planId) {
          return { ...d, Lab: selected[0]?.name, labName: selected[0]?.name, labId: selected[0]?.id, labIds: selected }
        } else {
          return d
        }
      })
      this.planData = { ...this.planData, packetPlanStones: newObj2 }
    } else {

    }
  }

  async twoDecimalNum(X) {
    // for(let temp of this.planData.packetPlanStones) {
    // let test = parseFloat(temp[X]).toFixed(2);
    // temp[X] = parseFloat(test);

  }

  async getVision360File(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        let payload = {
          "barcode": this?.currRowData?.barcodeNo,
          "shortCode": "V360"
        };
        

        let res: any;
        res = await (new Promise((resolve, reject) => {
          (this.httpService.fetchVision360File(payload)).subscribe((res: any) => { resolve(''); }, (err: any) => { reject(); });
        }));

        

        if (!!res && res.statusCode == 200) {
          console.log(res);
          resolve();
        } else {
          reject("Error in API response");
        }
      } catch (error) {
        
        reject(error);
      }
    });
  }

  toggleZoom(event: any) { 
    const container = event.target.closest('.slide-content'); 
    if (container) { 
      container.classList.toggle('zoom'); 
    } 
  }

  async getTensionPhoto() {
    const packetID = !!this.currRowData?.id ? this.currRowData?.id : this.planData?.id
    // let response: any = await this.httpService.fetchDataOfTensionFile(true, `getfileForVision?folder=uploads/${this.packetData?.[this.currentIndex]?.barcodeNo}/V360&extension=html`);
    if (packetID) {
      let response: any = await this.httpService.fetchDataOfTensionFile(
        true,
        `processActionInput/getTensionPhotoFromCentral?packetId=${packetID}`
      );
      console.log(response);
      if (response.statusCode === 200) {

        const { tensionPhoto, vision } = response?.data

        const filePath = tensionPhoto?.filePath;
        if (this.message === 'showVision360' && (!vision || vision?.filePath.length === 0)) {
          this.configService.showToast("error", 'No File Available For Vision 360')
          return
        }

        if (this.message === 'showVision360') {

          let newFileName = vision?.filePath[0].split("/");
          let joinedPath = newFileName.slice(1).join('/');
          this.visionFile = this.sanitizer.bypassSecurityTrustResourceUrl(this.httpService?.centralFileUrl + newFileName[0] + "/" + encodeURIComponent(joinedPath));
          const sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            `${this.httpService?.centralFileUrl + newFileName[0] + "/"}${encodeURIComponent(joinedPath)}`
          );
          this.visionFile = sanitizedUrl;


          console.log(this.visionFile);
        }


        if (this.message === 'showTensionPhoto' && (!filePath || filePath.length === 0)) {
          this.configService.showToast("error", 'No File Available For Tension')
          return
        }
        if(this.message==='showVision360'){
          
          let newFileName = vision?.filePath[0].split("/")
          let joinedPath = newFileName.slice(1).join('/');
          this.visionFile = this.httpService?.centralFileUrl + newFileName[0] + "/" + encodeURIComponent(joinedPath);
          let self = this
          setTimeout(function () {
            self.visionFile =
              self.sanitizer.bypassSecurityTrustResourceUrl(
                `${self.httpService?.centralFileUrl + newFileName[0] + "/"}${encodeURIComponent(joinedPath)}`
              );
            // console.log(self.videoOfTension);
            console.log(
              '%cActions----------',
              'color:green;font-weight:bold;font-size:1rem;'
            );
          }, 1000);
          console.log(this.visionFile)
        }



        if (this.message === 'showTensionPhoto') {
          this.tensionPicturePaths = filePath.map(path => this.httpService?.centralFileUrl + path);
          console.log(this.tensionPicturePaths);
        }
      } else {
        console.error(response);
      }
    }
  }



  slideChanged() {
    if (this.message == 'showTensionPhoto') {
      this.slider.getActiveIndex().then(index => {
      });
    }
  }
  slidePrev() {
    if (this.message == 'showTensionPhoto') {
      this.slider?.slidePrev();
    }
  }

  slideNext() {
    if (this.message == 'showTensionPhoto') {
      this.slider?.slideNext();
    }
  }

  async getPDCatValueFromNatts(nattsId) {
    return await this.httpService.getPDCatValueFromNatts(nattsId)
  }


}
