import { Component, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ModalController, NavParams } from "@ionic/angular";
import { select, Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { filter, first, takeUntil } from "rxjs/operators";
import { actionlist, authUser } from "src/app/authStore/auth.selector";
import { HomeActions } from "src/app/home/state/home.action-type";
import { masterDataActions } from "src/app/masterDataStore/masterData.action-type";
import { getAllDamageMasterData, getAllWorkQualityMasterData, getDetailsAnnotation, getPacketParameterHistoryData } from "src/app/masterDataStore/masterData.selector";
import { AppState } from "src/app/reducers";
import { ConfigService } from "src/app/services/config/config.service";
import { HttpService } from "src/app/services/http/http.service";
import { LoaderService } from "src/app/services/loader/loader.service";
import { ProcessService } from "src/app/services/process/process.service";
import { getCurrentSearchData } from "src/app/tempDataStore/tempData.selector";
import { environment } from "src/environments/environment";
import * as JsBarcode from "jsbarcode";
import { DatePipe } from "@angular/common";
import { getSelectedEcsvOption } from "../trybesigner/state/signer.selector";
import { PrintPageComponent } from "../print-page/print-page.component";
import { ACTIONNAMES } from "src/app/services/endpoint.enum";

@Component({
  selector: "app-trybemodal",
  templateUrl: "./trybemodal.component.html",
  styleUrls: ["./trybemodal.component.scss"],
})
export class TrybemodalComponent implements OnInit {
  // @Input() packetIds: object;
  project: string = environment.project;
  displayMessage: any = "";
  receivedData: any = "";
  isFormDisabled: any = "";
  packetheaderData: any;
  printData: any = [];
  packetIds: any = "";
  voucherLabel: any = "";
  fromUserData: any = {};
  toUserData: any = {};
  showParams: boolean = false;
  saveBtnDisable: boolean = true;
  printDate: any = "";
  fromPage: any = "";
  isPerson: boolean = true;
  public selectedValue: string = "";
  public keyword = "fullName";
  options: any = [];
  dropDownOptions: any = [];
  dropDownIdList: any = [];
  formName: any = "";
  placeValue: any = "";
  routeNameOptions: any;
  actionName: any = "";
  historyId: any = "";
  dropDowns: any = {};
  remarkValue: string;
  weightlossValue: string;
  inputDetails: any = [];
  transactionType = "";
  // to:any='';voucherLabel
  // printDate:any='';
  searchedPageName: any = "";
  selectedObj: any;
  @Output() onFormSubmit: EventEmitter<any> = new EventEmitter();
  packetId: any;
  selectedRouteType: string = "SameRoute";
  printType: string = "Reprint";
  selectWhichDataForEcsv: any;
  paramterOptionsByName: [];
  signerDropdowns: any = [];
  planningFormData: any = {};
  datalength: number = 0;
  dataStatus = "";
  sequence: any = [];
  selectedRow: any;
  hideOptions: boolean = false;
  selFluorescence: any = {};
  selTension: any;
  currentUser: any;
  workQuality$: Observable<any>;
  damageMaster$: Observable<any>;
  routeName$: Observable<any>;
  dropdownValues: any = {};
  public selectedWorkQuality: string = "Ok"; //Ok/work Qualitt Remark
  public successData: any = {};
  public formDetails: any;
  public unsuscribes$: Subject<any> = new Subject<any>();
  showDropDown: boolean = false;
  routeChangeVal: any;
  routeVal: any = "SameRoute";
  typeOfFileForEcsv: any;
  showNewFileUpload: boolean = true;
  showLossDropdown: boolean = false;
  PrintDropDownData: any = {
    name: "printOption",
    placeText: "Select print option",
    options: ["separate loss voucher", "All"],
    selectedData: [],
    propertyName: "printOption",
    modeFlag: "single", //single/multiple
  };
  // dispatchPrintOptions = [
  //   { label: 'All', value: 'all' },
  //   { label: '', value: 'lossOnly' }
  // ]
  @ViewChild("dispatchAll", { static: false }) dispatchAll: PrintPageComponent;
  @ViewChild("lossDispatch", { static: false }) lossDispatch: PrintPageComponent;
  selectedPrintOption = "All";
  isLoss: boolean = false;
  dispatchData: any;
  dispatchDataCopy: any;
  voucherDto: any;
  dispatchLossData: any;
  manualFinalParaDropdownVal: any;
  newSignerDropDown = [];
  FA_SelectedRows;
  isFromPlanningPage : boolean = false
  actionNames: any = ACTIONNAMES;
  showDefaultFileUpload: boolean = false;
  // printData : any
  constructor(
    private store: Store<AppState>,
    private navParams: NavParams,
    public router: Router,
    public processService: ProcessService,
    public loaderService: LoaderService,
    private modalController: ModalController,
    public httpService: HttpService,
    private datePipe: DatePipe,
    private configService: ConfigService
  ) {
    this.displayMessage = this.navParams.get("message");
    this.receivedData = this.navParams.get("data");
    this.isFormDisabled = this.navParams.get("isFormDisabled");
    this.transactionType = this.navParams.get("transactionType");
    this.dispatchData = this.dispatchDataCopy = this.navParams.get("dispatchData");
    // this.dispatchDataCopy = [...this.dispatchData]
    this.voucherDto = this.navParams.get("voucherDto");
    this.printData = this.navParams.get("printData");
    this.dispatchLossData = this.navParams.get("dispatchLossData");
    this.showLossDropdown = this.dispatchLossData?.length > 0 ? true : false;
    this.manualFinalParaDropdownVal = this.navParams.get("data")?.dropdownValues;
    this.FA_SelectedRows = this.navParams.get("selectedRows");
    this.actionName = this.navParams.get("actionName");

    console.log(this.displayMessage);
    console.log(this.receivedData);

    this.voucherLabel =
      !!this.receivedData && !!this.receivedData.data && !!this.receivedData.data.voucherLabel
        ? this.receivedData.data.voucherLabel
        : !!this.receivedData && !!this.receivedData.data && !!this.receivedData.data.voucherDto && !!this.receivedData.data.voucherDto.voucherLabel
        ? this.receivedData.data.voucherDto.voucherLabel
        : "";
    this.packetIds = !!this.receivedData && !!this.receivedData.packetIds ? this.receivedData.packetIds.join() : "";
    this.fromUserData = !!this.receivedData && !!this.receivedData.fromUser ? this.receivedData.fromUser : {};
    this.toUserData = !!this.receivedData && !!this.receivedData.toUser ? this.receivedData.toUser : {};
    this.fromPage = !!this.receivedData && !!this.receivedData.from ? this.receivedData.from : "";
    this.options = !!this.receivedData && !!this.receivedData.options ? this.receivedData.options : [];
    this.formName = !!this.receivedData && !!this.receivedData.dropdown ? this.receivedData.dropdown : !!this.receivedData && !!this.receivedData.actionName ? this.receivedData.actionName : "";
    this.keyword = !!this.receivedData && !!this.receivedData.keyword ? this.receivedData.keyword : "";
    this.placeValue = !!this.receivedData && this.formName === "Process" ? "Search for " + this.formName + "es" : "Search for " + this.formName + "s";
    this.actionName = !!this.receivedData && !!this.receivedData.actionName ? this.receivedData.actionName : "";
    this.historyId = !!this.receivedData && !!this.receivedData.id ? this.receivedData.id : "";
    this.dropDowns = !!this.receivedData && !!this.receivedData.data && !!this.receivedData.data.dropdowns ? this.receivedData.data.dropdowns : {};
    this.inputDetails = !!this.receivedData && !!this.receivedData.data && !!this.receivedData.data.inputDetails ? this.receivedData.data.inputDetails : [];
    this.dataStatus = !!this.receivedData && !!this.receivedData.status ? this.receivedData.status : "";
    this.isFromPlanningPage = !!this.receivedData && !!this.receivedData.isPlanningPage ? this.receivedData.isPlanningPage : false;
    this.showDefaultFileUpload = !!this.receivedData && !!this.receivedData.showDefaultFileUpload ? this.receivedData.showDefaultFileUpload : false;
    this.sequence = !!this.receivedData && !!this.receivedData.sequence ? this.receivedData.sequence : "";
    this.selectedRow = !!this.receivedData && !!this.receivedData.status && this.receivedData.status == "Initial" ? this.receivedData.rowData : {};
    this.paramterOptionsByName = !!this.receivedData && !!this.receivedData.parameterList ? this.receivedData.parameterList : {};
    this.successData = !!this.receivedData && !!this.receivedData.data ? this.receivedData.data : {};
    if (this.transactionType == "Reject") {
      this.selectedWorkQuality = "Damage";
    } else {
      this.selectedWorkQuality = "Ok";
    }
    if (this.selectedWorkQuality == "Ok") {
      this.datalength = 1;
    } else {
      this.datalength = 0;
    }
    console.log(this.selectedRow);
    console.log(this.paramterOptionsByName);
    if (this.dataStatus == "Initial" && this.showDefaultFileUpload == true) {
      this.showParams = true;
    }
  }

  async ngOnInit() {
    this.getCurrentUser();
    let appLocation = environment.fromLocation;

    if (appLocation == "botswana-dev") {
      this.store.dispatch(masterDataActions.getDetailsOfAnnotation({ packetID: this.selectedRow["id"] }));
    }
    if (this.remarkValue) {
      this.saveBtnDisable = false;
    }
    if (this.weightlossValue) {
      this.saveBtnDisable = false;
    }
    this.planningFormData = {};
    this.dropdownValues = {};
    this.printDate = new Date().toLocaleDateString();
    this.store
      .pipe(select(getCurrentSearchData))
      .pipe(
        filter(d => !!d),
        first()
      )
      .pipe(takeUntil(this.unsuscribes$))
      .subscribe((d: any) => {
        if (d) {
          this.searchedPageName = d.name;
        }
      });
    if (this.dataStatus == "Initial") {
      this.signerDropdowns = [];

      if ( !!this.selectedRow  && this.selectedRow?.["id"] && appLocation != "botswana-dev") {
        // let endpoint: string = `packetDetails/findByPacketId/${this.selectedRow['id']}`
        // let response: any = await this.httpService.fetchDataUsingPromise(true, endpoint)
        // this.httpService.getErrorAndDisplayIt(response);
        // if (response.statusCode === 200) {
        //   this.packetheaderData = response.data;
        //   console.log(this.packetheaderData)
        // }

        console.log(this.selectedRow["id"]);
        let packetId = this.selectedRow["refParentPacketId"] ? this.selectedRow["refParentPacketId"] : this.selectedRow["id"];
        this.store.pipe(select(getPacketParameterHistoryData))
          .pipe(takeUntil(this.unsuscribes$))
          .subscribe(async history => {
            this.httpService.getErrorAndDisplayIt(history);
            if (history) {
              this.signerDropdowns = [];
              history={data:history}
              // await new Promise((resolve, reject) => {
              //   setTimeout(() => {
              //     resolve("");
              //   }, 100);
              // });
              
              if (!!history.data && !!history.data && history.data.length > 0) {
                let csspData = history.data.filter((h: any) => h.actionName == "CFSP"); //Fluorescence
                if (csspData.length > 0 && !!csspData[0].inputDetails && csspData[0].inputDetails.length > 0) {
                  let fluData = csspData[0].inputDetails.filter(x => x.paramName == "Fluorescence");
                  if (fluData.length > 0) {
                    this.selFluorescence = fluData[0];
                  }
                }

                console.log("this.formDetails");
                console.log(this.formDetails);
                console.log(this.formDetails);
                let tension = history.data.filter((h: any) => h.actionName == "Tension Mapping"); //Tension Mapping
                let find = tension.find(e => {
                  let inputDetails = e.inputDetails
                  if(inputDetails && inputDetails.length > 0) {
                    return e
                  }
                })
                this.selTension = find ? find : {}
                console.log(csspData);
                //this.selFluorescence = !!fluorescence && !!fluorescence[0] && !!fluorescence[0]['inputDetails'] && !!fluorescence[0]['inputDetails'][0] ? fluorescence[0]['inputDetails'][0] : {};
                // this.selTension = !!tension && !!tension[0] && !!tension[0]["inputDetails"] && !!tension[0]["inputDetails"][0] ? tension[0]["inputDetails"][0] : {};
                console.log(this.selFluorescence);
                console.log(this.selTension);
                if (!Object.keys(this.paramterOptionsByName)?.length) this.showParams = true;
                Object.keys(this.paramterOptionsByName).forEach(keyName => {
                  console.log("--------------");
                  console.log(this.paramterOptionsByName[keyName]);
                  let label = keyName;
                  if (keyName == "Tension Mapping") {
                    label = "Stress";
                  } 
                  if(keyName == "Stress Type") {
                    label = "Stress Value";
                  }
                  if (this.selFluorescence.paramName && keyName == this.selFluorescence.paramName) {
                    console.log(this.selFluorescence);

                    let obj = {
                      name: keyName,
                      placeText: "Select " + keyName,
                      options: this.paramterOptionsByName[keyName],
                      selectedData: [{ name: this.selFluorescence.paramValue, id: this.selFluorescence.paramIValue }],
                      propertName: "name",
                      modeFlag: "single", //single/multiple
                      masterId: keyName,
                      label: label,
                    };
                    this.signerDropdowns.push(obj);
                    this.showParams = true;
                    this.planningFormData[keyName] = this.selFluorescence.paramIValue;
                  } else if (keyName == this.selTension.paramName) {
                    console.log(this.selTension);
                    console.log(keyName);
                    let obj = {
                      name: keyName,
                      placeText: "Select " + keyName,
                      options: this.paramterOptionsByName[keyName],
                      selectedData: [{ name: this.selTension.paramValue, id: this.selTension.paramIValue }],
                      propertName: "name",
                      modeFlag: "single", //single/multiple
                      masterId: keyName,
                      label: label,
                    };
                    this.signerDropdowns.push(obj);
                    this.showParams = true;
                    this.planningFormData[keyName] = this.selTension.paramIValue;
                  } else {
                    let obj = {
                      name: keyName,
                      placeText: "Select " + keyName,
                      options: this.paramterOptionsByName[keyName],
                      selectedData: [],
                      propertName: "name",
                      modeFlag: "single", //single/multiple
                      masterId: keyName,
                      label: label,
                    };
                    this.signerDropdowns.push(obj);
                    this.showParams = true;
                  }
                });

                this.signerDropdowns = this.signerDropdowns.map((sddt: any) => {
                  if ("Tinch" == sddt.name && this.project == "kg") {
                    if (sddt?.options?.find((odt: any) => odt.name == "N/A")) {
                      this.planningFormData[sddt.label] = sddt?.options?.find((odt: any) => odt.name == "N/A")?.paramIValue;
                      return { ...sddt, selectedData: [sddt?.options?.find((odt: any) => odt.name == "N/A")] };
                    }
                  }
                  return sddt;
                });
                const manualColorData = history.data.find((h: any) => h.actionName == "manual color" && h.historyStatus == "Completed");
                if (manualColorData?.inputDetails) {
                  manualColorData?.inputDetails?.map((mcdt: any) => {
                    this.signerDropdowns = this.signerDropdowns.map((sddt: any) => {
                      if (mcdt.paramName == sddt.name) {
                        this.planningFormData[sddt.label] = mcdt.paramIValue;
                        return { ...sddt, selectedData: [{ name: mcdt.paramValue, id: mcdt.paramIValue }] };
                      } else if ((mcdt.paramName == "Stress" && sddt.label == "Stress Type") || (mcdt.paramName == "Stress Value" && sddt.label == "Stress")) {
                        this.planningFormData[sddt.label] = mcdt.paramIValue;
                        return { ...sddt, selectedData: [{ name: mcdt.paramValue, id: mcdt.paramIValue }] };
                      } else if ((sddt.name == "Milky" || sddt.name == "Luster") && (mcdt.paramName == "Milky" || mcdt.paramName == "Luster")) {
                        this.planningFormData[sddt.label] = mcdt.paramIValue;
                        return { ...sddt, selectedData: [{ name: mcdt.paramValue, id: mcdt.paramIValue }] };
                      }
                      return sddt;
                    });
                  });
                }
                const tensionCheckingData = history.data.find((h: any) => h.actionName == "Tension Checking" && h.historyStatus == "Completed");
                if (tensionCheckingData?.inputDetails) {
                  tensionCheckingData?.inputDetails?.map((mcdt: any) => {
                    this.signerDropdowns = this.signerDropdowns.map((sddt: any) => {
                      if ((mcdt.paramName == "Stress" && sddt.label == "Stress Type") || (mcdt.paramName == "Stress Value" && sddt.label == "Stress")) {
                        this.planningFormData[sddt.label] = mcdt.paramIValue;
                        return { ...sddt, selectedData: [{ name: mcdt.paramValue, id: mcdt.paramIValue }] };
                      }
                      return sddt;
                    });
                  });
                }
                csspData = history.data.find((h: any) => h.actionName == "CFSP");
                if (csspData?.inputDetails) {
                  csspData?.inputDetails?.map((mcdt: any) => {
                    this.signerDropdowns = this.signerDropdowns.map((sddt: any) => {
                      if (mcdt.paramName == sddt.name) {
                        this.planningFormData[sddt.label] = mcdt.paramIValue;
                        return { ...sddt, selectedData: [{ name: mcdt.paramValue, id: mcdt.paramIValue }] };
                      } else if ((mcdt.paramName == "Stress" && sddt.label == "Stress Type") || (mcdt.paramName == "Stress Value" && sddt.label == "Stress")) {
                        this.planningFormData[sddt.label] = mcdt.paramIValue;
                        return { ...sddt, selectedData: [{ name: mcdt.paramValue, id: mcdt.paramIValue }] };
                      } else if ((sddt.name == "Milky" || sddt.name == "Luster") && (mcdt.paramName == "Milky" || mcdt.paramName == "Luster")) {
                        this.planningFormData[sddt.label] = mcdt.paramIValue;
                        return { ...sddt, selectedData: [{ name: mcdt.paramValue, id: mcdt.paramIValue }] };
                      }
                      return sddt;
                    });
                  });
                }
                const tensionMapData = history.data.find((h: any) => h.actionName == "Tension Mapping");
                if (tensionMapData?.inputDetails && this.project == "kg") {
                  tensionMapData?.inputDetails?.map((mcdt: any) => {
                    this.signerDropdowns = this.signerDropdowns.map((sddt: any) => {
                      if(this.project == "kg"){
                        if ((mcdt.paramName == "Stress Value" && sddt.label == "Stress Value") || (mcdt.paramName == "Stress" && sddt.label == "Stress")) {
                          this.planningFormData[sddt.label] = mcdt.paramIValue;
                          return { ...sddt, selectedData: [{ name: mcdt.paramValue, id: mcdt.paramIValue }] };
                        }
                      } else {
                        if ((mcdt.paramName == "Stress" && sddt.label == "Stress Type") || (mcdt.paramName == "Stress Value" && sddt.label == "Stress")) {
                          this.planningFormData[sddt.label] = mcdt.paramIValue;
                          return { ...sddt, selectedData: [{ name: mcdt.paramValue, id: mcdt.paramIValue }] };
                        }
                      }
                      return sddt;
                    });
                  });
                }
              } else {
                Object.keys(this.paramterOptionsByName).forEach(keyName => {
                  let options = this.paramterOptionsByName[keyName];
                  let label = keyName;
                  if (keyName == "Tension Mapping") {
                    label = "Stress";
                  }
                  let obj = {
                    name: keyName,
                    placeText: "Select " + keyName,
                    options: options,
                    selectedData: [],
                    propertName: "name",
                    modeFlag: "single", //single/multiple
                    masterId: keyName,
                    label: label,
                  };
                  this.signerDropdowns.push(obj);
                  this.showParams = true;
                });
              }
              if (!!this.manualFinalParaDropdownVal) {
                this.signerDropdowns = [];
                this.manualFinalParaDropdownVal.map(mfpd => {
                  let obj = {
                    name: mfpd.paramname,
                    placeText: "Select " + mfpd.paramname,
                    options: this.paramterOptionsByName[mfpd.paramname],
                    selectedData: [{ name: mfpd.masterValue, id: mfpd.masterId }],
                    propertName: "name",
                    modeFlag: "single", //single/multiple
                    masterId: mfpd.paramname,
                    label: mfpd.paramname,
                  };
                  this.signerDropdowns.push(obj);
                  this.showParams = true;
                  this.datalength = this.signerDropdowns?.filter((dt: any) => dt?.selectedData?.length > 0)?.length;
                  this.newSignerDropDown.push(obj);
                  this.planningFormData[mfpd.paramname] = mfpd.masterId;
                });
              }
            }
            this.datalength = this.signerDropdowns?.filter((dt: any) => dt?.selectedData?.length > 0)?.length;
            console.log(this.datalength);
            console.log("=====");
          });
      } else {
        if (appLocation == "botswana-dev") {
          this.store
            .pipe(select(getDetailsAnnotation))
            .pipe(takeUntil(this.unsuscribes$))
            .subscribe(async data => {
              if (data) {
                let ad = await data;
                console.log(data);
                this.formDetails = !!ad && !!ad.formDetails ? ad.formDetails.filter(d => d.paramName.includes("Tension") || d.paramName.includes("Fluorescence")) : [];
                let map = {};
                for (let d of this.formDetails) {
                  if (d.paramValue) {
                    map[d.paramName] = d;
                  }
                }
                this.formDetails = Object.values(map);
                this.formDetails = this.formDetails.map(d => {
                  let m = { ...d };
                  if (d.paramName == "Tension" || d.paramName == "Stress" || d.paramName == "Tension Mapping") {
                    m.paramName = "Tension Mapping";
                  }
                  return m;
                });
                let selFl = this.formDetails.filter(d => d.paramName == "Fluorescence")[0];
                let selT = this.formDetails.filter(d => d.paramName == "Tension Mapping")[0];
                let fluorescences = !!selFl && !!selFl.paramName ? this.paramterOptionsByName[selFl.paramName] : [];
                let tensions = !!selT && !!selT.paramName ? this.paramterOptionsByName[selT.paramName] : [];
                let selFluorescence = fluorescences.length != 0 ? fluorescences.filter(d => d.name == selFl.paramValue)[0] : {};
                let selTension = tensions.length != 0 ? tensions.filter(d => d.name == selT.paramValue)[0] : {};
                this.setTensionFluorescence(this.formDetails, { Fluorescence: selFluorescence }, { "Tension Mapping": selTension });
              }
            });
        } else {
          this.selFluorescence = null;
          this.selTension = null;
          Object.keys(this.paramterOptionsByName).forEach(keyName => {
            let options = this.paramterOptionsByName[keyName];
            let label = keyName;
            if (keyName == "Tension Mapping") {
              label = "Stress";
            }
            let obj = {
              name: keyName,
              placeText: "Select " + keyName,
              options: options,
              selectedData: [],
              propertName: "name",
              modeFlag: "single", //single/multiple
              masterId: keyName,
              label: label,
            };
            this.signerDropdowns.push(obj);
            this.showParams = true;
          });
        }
      }
      console.log(this.newSignerDropDown);
      console.log(this.signerDropdowns);
      console.log(this.datalength);
    }
    if (this.dataStatus == "WorkQualityMaster") {
      this.workQuality$ = this.store.pipe(select(getAllWorkQualityMasterData));
      this.damageMaster$ = this.store.pipe(select(getAllDamageMasterData));
      this.routeName$ = this.store.pipe(select(getAllDamageMasterData));
    }
    if (this.dataStatus == "lockModal") {
      this.fecthRouteNameData();
    }
  }

  getCurrentUser() {
    this.store.pipe(select(authUser), takeUntil(this.unsuscribes$)).subscribe((d: any) => {
      this.currentUser = d;

      this.store.pipe(select(getSelectedEcsvOption), takeUntil(this.unsuscribes$)).subscribe((d: any) => {
        if (this.selectedRow?.currActionName == "Final Planning" && d == "appendData" && (this.currentUser?.roles.includes("Checker") || this.currentUser?.roles.includes("checker"))) {
          this.hideOptions = true;
        }
      });
    });
    this.store.pipe(select(actionlist), takeUntil(this.unsuscribes$)).subscribe((d: any) => {
      if (!d) {
        return;
      }
      d.filter(action => {
        if (action.actionName === "Final Planning") {
          this.showNewFileUpload = true;
        }
      });
    });
  }

  setTensionFluorescence(data, selFL, selT) {
    console.log("--------setTensionFluorescence------");
    this.signerDropdowns = [];
    if (data.length !== 0) {
      Object.keys(this.paramterOptionsByName).forEach(keyName => {
        let options = this.paramterOptionsByName[keyName];
        let selFluorescence = selFL[keyName];
        let selTension = selT[keyName];
        let label = keyName;
        if (keyName == "Tension Mapping") {
          label = "Stress";
        }
        if (!!selFluorescence && Object.keys(selFluorescence).length != 0 && !!options) {
          let obj = {
            name: keyName,
            placeText: "Select " + keyName,
            options: options,
            selectedData: [selFluorescence],
            propertName: "name",
            modeFlag: "single", //single/multiple
            masterId: keyName,
            label: label,
          };
          this.signerDropdowns.push(obj);
          this.showParams = true;
          this.planningFormData[keyName] = selFluorescence.id;
        } else if (!!selTension && Object.keys(selTension).length != 0 && !!options) {
          let obj = {
            name: keyName,
            placeText: "Select " + keyName,
            options: options,
            selectedData: [selTension],
            propertName: "name",
            modeFlag: "single", //single/multiple
            masterId: keyName,
            label: label,
          };
          this.signerDropdowns.push(obj);
          this.showParams = true;
          this.planningFormData[keyName] = selTension.id;
        } else {
          let obj = {
            name: keyName,
            placeText: "Select " + keyName,
            options: options,
            selectedData: [],
            propertName: "name",
            modeFlag: "single", //single/multiple
            masterId: keyName,
            label: label,
          };
          this.signerDropdowns.push(obj);
          this.showParams = true;
        }
      });
    } else {
      Object.keys(this.paramterOptionsByName).forEach(keyName => {
        let options = this.paramterOptionsByName[keyName];
        let label = keyName;
        if (keyName == "Tension Mapping") {
          label = "Stress";
        }
        let obj = {
          name: keyName,
          placeText: "Select " + keyName,
          options: options,
          selectedData: [],
          propertName: "name",
          modeFlag: "single", //single/multiple
          masterId: keyName,
          label: label,
        };
        this.signerDropdowns.push(obj);
        this.showParams = true;
      });
    }
  }

  BIF_print() {
    const allChild = [];
    const printWindow = window.open("", "_blank");
    printWindow.document.write("<html><head><title>Print</title>");
    printWindow.document.write('</head><body style="margin: 0;">');
    let checkerName = this.successData.data["checkerName"];
    for (const key in this.successData.data) {
      if (key.includes("packetList")) {
        let data = this.successData.data[key];
        if (data.length > 0) {
          data.forEach(data => {
            const textToShow = {};
            const keyToShow = [
              "packetLabel",
              "lotNo",
              "cts",
              "checkerName",
              "inDate",
              "SHAPE",
              "Clarity",
              "CUT",
              "expPol",
              "COLOR",
              "accountNo",
              // 'purity',
              // 'sieve',
              // 'polishGrade',
            ];
            let barcode = "";

            if (data.length > 0) {
              data = data[0];
            }
            if (data?.barcodeNo) barcode = data.barcodeNo;

            keyToShow.map((d: any) => {
              console.log(d, data[d]);
              if (data[d]) {
                textToShow[d] = data[d];
              }
            });

            const canvas = document.createElement("canvas");

            JsBarcode(canvas, barcode, {
              format: "CODE128",
              displayValue: false,
              text: ``,
            });

            canvas.style.margin = "0";
            canvas.style.padding = "0";
            canvas.style.border = "none";
            canvas.style.width = "35%";
            canvas.style.height = "50px";

            const containerDiv = document.createElement("div");
            containerDiv.style.display = "flex";
            containerDiv.style.flexDirection = "column";
            containerDiv.style.alignItems = "center";
            containerDiv.style.justifyContent = "center";
            containerDiv.style.fontWeight = "900";
            containerDiv.style.fontFamily = "monospace";
            containerDiv.style.padding = "3px";

            containerDiv.appendChild(canvas);

            const innerDiv = document.createElement("div");
            innerDiv.style.breakAfter = "page";
            innerDiv.style.fontSize = "18px";
            innerDiv.style.display = "flex";
            innerDiv.style.alignItems = "center";
            innerDiv.style.flexDirection = "column";

            const paragraph1 = document.createElement("p");
            paragraph1.style.margin = "2px 0";
            paragraph1.textContent = `${textToShow?.[keyToShow[0]]} \xa0\xa0\xa0\xa0\xa0 ${textToShow?.[keyToShow[1]]} \xa0\xa0 ${textToShow?.[keyToShow[2]]}`;

            const paragraph2 = document.createElement("p");
            paragraph2.style.margin = "2px 0";
            const value3 = checkerName ?? "";
            const value4 = `${this.datePipe.transform(textToShow?.[keyToShow[4]], "dd-MMM")}`;
            const value5 = textToShow?.[keyToShow[5]] ?? "";
            const value6 = textToShow?.[keyToShow[6]] ?? "";
            const value7 = textToShow?.[keyToShow[7]] ?? "";
            const value8 = textToShow?.[keyToShow[8]] ?? "";
            const value9 = textToShow?.[keyToShow[9]] ?? "";
            const value10 = textToShow?.[keyToShow[10]] ?? "";
            paragraph2.textContent = `${value3} \xa0 ${value4} `;

            const paragraph3 = document.createElement("p");
            paragraph3.style.margin = "1px 0";
            paragraph3.textContent = `${value8} \xa0 ${value9} ${value10} ${value6} \xa0  ${value5}`;

            innerDiv.appendChild(paragraph1);
            innerDiv.appendChild(paragraph3);
            innerDiv.appendChild(paragraph2);

            containerDiv.appendChild(innerDiv);

            printWindow.document.body.appendChild(containerDiv);

            allChild.push(containerDiv);
          });
        }
      }
    }
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
    allChild.map((containerDiv: any) => {
      printWindow.document.body.removeChild(containerDiv);
    });
    printWindow.close();
  }

  closeModal(o: any) {
    if (!!o) {
      this.modalController.dismiss(o);
    } else {
      this.modalController.dismiss(this.remarkValue);
    }
    this.datalength = 0;
  }

  async closeModalForWeightLoss(o: any) {
    console.log(this.weightlossValue);
    if (!!o) {
      this.modalController.dismiss(this.weightlossValue);
    } else {
      this.modalController.dismiss(this.weightlossValue);
    }
  }

  printAcknowledgement(evt: any) {
    console.log("here in print ackno");
    const style = `.flex-center-between{
      display:flex;
      align-items:center;
      justify-content:space-between;
    }
    .header-text{
      font-weight:8px;
      color:rgb(168, 163, 163)
    }
    .float-right {
      float: right;
    }
    .nowrap{
      text-wrap:nowrap;
    }
    .logo_img{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .center{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    hr.initial {
      border-top: 1px dotted #000;
    }
    
    hr.signature {
      // width: 200px;
      border-top: 0.25px solid #000;
      // border-width: 0.5px;
    }
    
    .fs{
      font-weight: normal;
      font-size: small;
    }
      
    .space{
      min-height: 30px;
    }

    .minspace{
      min-height: 10px;
    }
    
    
    .column{
      border: 1px solid #000;
    }
    
    .br{
      border-right: 1px solid #000;
    }
    
    .bb{
      border-bottom: 1px solid #000;
    }
    
    .bt{
      border-top: 2px solid #000;
    }
    .fs-12{
      // font-weight: 10;
      font-size: 10px;
    }
    
    .fs-10{
      font-size: 8px;
    }
    
    span.tc {
      text-align: center;
    }
    
    .pr{
      padding-right: 70px;
    }
    
    .col-fs{
      font-size: 11px;
    }
    
    .fs-12 {
      font-size: 12px;
    }
    .tac {
      text-align: center;
    }
    .ion-grid{
      display: flex;
      justify-content: space-between;
      padding: 10px; 
    }
    .ion-row{
      display: flex;
      width: 100%;
    }
    .ion-col{
      flex: 1;
    }
    table {
      width: 100%;
      border-collapse: collapse;
    }
    th, td {
      border: 1px solid black;
      border-collapse: collapse;
      text-align: center;
      padding:2px
    }
    body * {
      visibility: hidden;
  }
    #all,
    #all * {
      visibility: hidden;
    }
    #loss,
    #loss * {
      visibility: hidden;
    }
    @media print {
      body * {
        visibility: visible;
    }
    #all,
    #all * {
      visibility: visible;
    }
    #loss,
    #loss * {
      visibility: visible;
    }
  }
`;
    if ((!!this.dispatchData && this.dispatchData.length > 0) || (!!this.dispatchLossData && this.dispatchLossData.length > 0 && this.isLoss)) {
      const voucher1Window = window.open("", "_blank");
      let pageBreak = "none";
      let lossDiv = '<div id="loss"></div>';
      let dispatchDiv = `<div id="all" style="page-break-after:${pageBreak};"></div>`;
      if (!!this.dispatchLossData && this.dispatchLossData.length > 0 && this.isLoss) {
        pageBreak = !!this.dispatchData && this.dispatchData.length > 0 ? "always" : "none";
        lossDiv = `<div id="loss">${this.lossDispatch.voucherContent.nativeElement.innerHTML}
        </div>`;
      }
      if (!!this.dispatchData && this.dispatchData.length > 0) {
        dispatchDiv = `<div id="all" style="page-break-after:${pageBreak};">
        ${this.dispatchAll.voucherContent.nativeElement.innerHTML}
        </div>`;
      }
      if (voucher1Window) {
        voucher1Window.document.write(`
        <html>
          <head>
          <title>Transfer</title>
            <style>${style}
            </style>
          </head>
          <body>
          ${dispatchDiv}
          ${lossDiv}
          </body>
        </html>
      `);
        voucher1Window.document.close();
        voucher1Window.print();
        voucher1Window.close();
      }
    } else {
      const voucherHtml = document.getElementById("printVoucher");
      const voucherWindow = window.open("", "_blank");
      voucherWindow.document.write(`
      <html>
        <head>
        <title>Transfer</title>
          <style>${style}
          </style>
        </head>
        <body>
          ${voucherHtml.innerHTML}
        </body>
      </html>
    `);
      voucherWindow.document.close();
      voucherWindow.print();
      voucherWindow.close();
      // window.print();
    }

    console.warn(evt);
    // window.print();
    // this.configService.print();
    // var divContents = document.getElementById("printDoc").innerHTML;
    // var a = window.open('', '', 'status=1, height=700, width=700');
    // a.document.write('<html>');
    // a.document.write('<body onafterprint="self.close()"> <h1 class="tac">Trybe <br>');
    // a.document.write(divContents);
    // a.document.write('</body></html>');
    // a.document.close();
    // a.print();
  }

  onOptionSelection(evt: any) {
    console.log("Person");
    console.log(evt);
    this.selectedObj = evt;
  }
  onFocused(evt: any) {
    console.log("Focused");
    console.log(evt);
  }
  onChangeSearch(evt: any) {
    console.log("ChangedSearch");
    console.log(evt);
  }
  submitedData(evt: any) {
    let obj = {};
    console.log("submitedData");
    obj["actions"] = [];
    obj["userAndMachines"] = [];
    let actionId = 0,
      userId = 0;
    if (this.selectedObj.hasOwnProperty("actionName")) {
      actionId = this.selectedObj.id;
      obj["actionId"] = actionId;
      obj["callFrom"] = "Process";
      obj["actions"].push(this.selectedObj);
    } else if (this.selectedObj.hasOwnProperty("fullName")) {
      userId = this.selectedObj.id;
      obj["userId"] = userId;
      obj["callFrom"] = "Person";
      obj["userAndMachines"].push(this.selectedObj);
    } else {
      return;
    }
    console.log(obj);

    if (!!userId || !!actionId) {
      this.closeModal(obj);
      this.store.dispatch(HomeActions.submitParameter({ actionId: actionId, userId: userId }));
    }

    // this.configService.showToast("success", 'Under development');
  }

  dropDownSelection(evt: any) {
    console.log(evt);
    console.log(this.planningFormData);
    if (!evt?.arrayList?.length) this.datalength -= 1;
    let elmID = evt.masterId;
    let idList = evt.idList;
    console.log(evt.arrayList);
    if (!!elmID && elmID == "Fluorescence" && idList.length > 0) {
      this.planningFormData["Fluorescence"] = idList[0];
      this.datalength = Object.keys(this.planningFormData).length;
    } else if (!!elmID && elmID == "Tension Mapping" && idList.length > 0) {
      this.planningFormData["Tension Mapping"] = idList[0]; //Stress
      this.datalength = Object.keys(this.planningFormData).length;
    } else if (!!elmID && elmID == "Stress" && idList.length > 0) {
      this.planningFormData["Stress"] = idList[0];
      this.datalength = Object.keys(this.planningFormData).length;
    } else if (!!elmID && elmID == "Stress Value" && idList.length > 0) {
      this.planningFormData["Stress Value"] = idList[0];
      this.datalength = Object.keys(this.planningFormData).length;
    } else if (!!elmID && elmID == "Color" && idList.length > 0) {
      this.planningFormData["Color"] = idList[0];
      this.datalength = Object.keys(this.planningFormData).length;
    } else if (!!elmID && elmID == "Luster" && idList.length > 0) {
      this.planningFormData["Luster"] = idList[0];
      this.datalength = Object.keys(this.planningFormData).length;
    } else if (!!elmID && elmID == "Tinch" && idList.length > 0) {
      this.planningFormData["Tinch"] = idList[0];
      this.datalength = Object.keys(this.planningFormData).length;
    } else if (evt.property == "damageName" && idList.length > 0 && this.selectedWorkQuality == "Damage") {
      this.dropdownValues = {};
      this.dropdownValues["damageName"] = evt.arrayList[0];
      this.datalength = Object.keys(this.dropdownValues).length;
    } else if (evt.property == "remark" && idList.length > 0 && this.selectedWorkQuality == "Ok") {
      this.dropdownValues = {};
      this.dropdownValues["remark"] = evt.arrayList[0];
      this.datalength = Object.keys(this.dropdownValues).length;
    } else if (this.dataStatus == "lockModal" && evt.property == "routeSelection" && idList.length > 0 && this.showDropDown) {
      this.dropdownValues = {};
      this.dropdownValues["name"] = evt.arrayList[0];
      this.routeChangeVal = this.dropdownValues;
      this.datalength = Object.keys(this.dropdownValues).length;
    }

    if (evt.property == "remark" && idList.length == 0) {
      this.datalength = 0;
    }
    if (evt.property == "damageName" && idList.length == 0) {
      this.datalength = 0;
    }
    console.log(this.datalength);
    console.log(this.planningFormData);
    console.log(this.datalength);
    console.log(this.dropdownValues);
  }
  onRemarkSelChange(evt) {
    console.log("onRemarkSelChange");
    console.log(evt.detail.value);
    let val = evt.detail.value;
    this.datalength = 0;
    if (val == "Ok") {
      this.datalength = 1;
    }
    console.log(this.datalength);
  }

  onLockModalRouteSelection(evt) {
    this.routeVal = evt.detail.value;
    console.log(this.routeVal);
    if (this.routeVal == "changeRoute") {
      this.showDropDown = true;
    } else {
      this.showDropDown = false;
    }
  }

  onSelectionOfDataForEcsv(evt) {
    this.typeOfFileForEcsv = evt.detail.value;
    console.log(this.typeOfFileForEcsv);
    console.log(this.selectWhichDataForEcsv);
  }

  getInputData(e: any) {
    this.planningFormData["file"] = e.target.files;
    // this.datalength = Object.keys(this.planningFormData).length;
    // console.log(this.datalength);
  }

  async showModal(state: boolean, message: string, data: any) {
    if (state) {
      if (data.data != undefined) {
        const modal = await this.modalController.create({
          component: TrybemodalComponent,
          componentProps: {
            message: message,
            data: data,
          },
          cssClass: "trybe_modal_print",
        });
        modal.onDidDismiss().then(dataReturned => {
          if (dataReturned !== null) {
            console.log(dataReturned);
          }
        });
        return await modal.present();
      } else {
        const modal = await this.modalController.create({
          component: TrybemodalComponent,
          componentProps: {
            message: message,
          },
          cssClass: "trybe_modal_1",
        });
        modal.onDidDismiss().then(dataReturned => {
          if (dataReturned !== null) {
            console.log(dataReturned);
          }
        });
        return await modal.present();
      }
    } else {
      const modal = await this.modalController.create({
        component: TrybemodalComponent,
        componentProps: {
          message: message,
        },
        cssClass: "trybe_modal_0",
      });
      return await modal.present();
    }
  }
  async onECSVSubmit(e: any) {
    this.planningFormData["status"] = "ecsv file submitted";
    // let isFormValid = await validateForm('ecsv',this.planningFormData);
    let formData = await this.planningFormData;
    this.closeModal(formData);
  }
  workQualityAndDamageSubmit(e: any) {
    this.dropdownValues["status"] = "WorkQualitySubmitted";
    this.dropdownValues["transactionType"] = this.transactionType;
    console.log("workQualityAndDamageSubmit");
    this.closeModal(this.dropdownValues);
  }

  fecthRouteNameData() {
    let endpoint: string = `route-master/getAllRoutesInfo`;
    this.httpService.fetchData(true, endpoint).subscribe((res: any) => {
      if (res) {
        console.log("change Status");
        console.log(res);
        let resultCode: number = res.statusCode;
        console.log(resultCode);
        let d: any = res.data;
        console.log(d);
        if (resultCode == 200) {
          this.routeNameOptions = res.data;
        } else {
          this.configService.showToast("Error", res.error);
        }
      }
    });
  }
  // validateForm(formName:string, dta:any){
  //   if (formName=='ecsv') {
  //     if (!dta["Fluorescence"]) {

  //     }
  //     this.configService.showToast("error", "Please enter valid PAN")
  //   }
  // }

  async closeModalForRouteChange() {
    console.log(this.routeVal);
    console.log(this.routeChangeVal);

    if (!!this.routeChangeVal && this.routeVal == "changeRoute") {
      this.modalController.dismiss(this.routeChangeVal);
    } else if (this.routeVal != "changeRoute") {
      this.modalController.dismiss(this.routeVal);
    }
  }

  async closeModalForEcsv() {
    console.log(this.selectWhichDataForEcsv);
    console.log(this.typeOfFileForEcsv);
    this.modalController.dismiss(this.selectWhichDataForEcsv);
  }

  getifRouteModalDisable() {
    if (this.routeVal == "sameRoute") {
      return false;
    } else if (this.routeVal == "changeRoute" && !this.routeChangeVal) {
      return true;
    } else {
      return false;
    }
  }

  sequenceSelection(event: any) {
    this.selectWhichDataForEcsv = event.arrayList;
  }

  onprintOptionSelection(event) {
    this.selectedPrintOption = event.arrayList[0];
    if (event.arrayList[0] == "All") {
      this.isLoss = false;
      this.dispatchData = this.dispatchDataCopy;
      this.printData["ttlPkt"] = this.dispatchData?.length;
      let rctsttl = 0;
      let ctsttl = 0;
      this.dispatchData?.map(d => {
        if (d.originalWt) ctsttl += d.originalWt;
        if (d.wt) rctsttl += d.wt;
        // d['lotNo'] = d.currPacketId.split('#')[0];
      });
      this.printData["owtttl"] = ctsttl;
      this.printData["rctsttl"] = rctsttl;
    } else {
      // this.dispatchData = this.dispatchData.filter(d => d.id !== this.dispatchLossData[0].id)
      this.dispatchData = this.dispatchDataCopy?.filter(d => {
        return !this.dispatchLossData?.some(lossdt => lossdt.currPacketId == d.currPacketId);
      });
      //total count for dispatch vouchers.............
      this.printData["ttlPkt"] = this.dispatchData?.length;
      let rctsttl = 0;
      let ctsttl = 0;
      this.dispatchData?.map(d => {
        if (d.originalWt) ctsttl += d.originalWt;
        if (d.wt) rctsttl += d.wt;
        // d['lotNo'] = d.currPacketId.split('#')[0];
      });
      this.printData["owtttl"] = ctsttl;
      this.printData["rctsttl"] = rctsttl;

      //total count of dispatch voucher with loss.................
      this.printData["lossTtlPkt"] = this.dispatchLossData?.length;
      let lossRctsTtl = 0;
      let lossCtsTtl = 0;
      this.dispatchLossData.map(d => {
        if (d.originalWt) lossCtsTtl += d.originalWt;
        if (d.wt) lossRctsTtl += d.wt;
        // d['lotNo'] = d.currPacketId.split('#')[0];
      });
      this.printData["lossOwtttl"] = lossCtsTtl;
      this.printData["lossRctsTtl"] = lossRctsTtl;
      this.isLoss = true;
    }
  }

  FA_printBarcode(event?) {
    if (!this.FA_SelectedRows?.length) return;
    const allChild = [];
    const printWindow = window.open("", "_Blank");
    printWindow.document.write("<html><head><title>Print</title>");
    printWindow.document.write('</head><body style="margin: 0;">');
    for (const data of this.FA_SelectedRows) {
      const textToShow = {};
      const keyToShow = ["inDate", "packetLabel", "lotNo", "cts", "purity", "sieve", "polishGrade", "accountNo"];
      let barcode = "";
      if (data?.barcodeNo) barcode = data.barcodeNo;

      keyToShow.map((d: any) => {
        console.log(d, data[d]);
        if (data[d]) {
          textToShow[d] = data[d];
        }
      });

      const canvas = document.createElement("canvas");

      JsBarcode(canvas, barcode, {
        format: "CODE128",
        displayValue: false,
        text: ``,
      });

      canvas.style.margin = "0";
      canvas.style.padding = "0";
      canvas.style.border = "none";
      canvas.style.width = "35%";
      canvas.style.height = "50px";

      const containerDiv = document.createElement("div");
      containerDiv.style.display = "flex";
      containerDiv.style.flexDirection = "column";
      containerDiv.style.alignItems = "center";
      containerDiv.style.justifyContent = "center";
      containerDiv.style.fontWeight = "900";
      containerDiv.style.fontFamily = "monospace";
      containerDiv.style.padding = "3px";

      containerDiv.appendChild(canvas);

      const innerDiv = document.createElement("div");
      innerDiv.style.breakAfter = "page";
      innerDiv.style.fontSize = "18px";
      innerDiv.style.display = "flex";
      innerDiv.style.alignItems = "center";
      innerDiv.style.flexDirection = "column";

      const paragraph1 = document.createElement("p");
      paragraph1.style.margin = "2px 0";
      paragraph1.textContent = `${this.datePipe.transform(new Date(), "dd-MMM-yyyy")}  ${textToShow?.[keyToShow[1]]}  ${textToShow?.[keyToShow[2]]}`;

      const paragraph2 = document.createElement("p");
      paragraph2.style.margin = "2px 0";
      const value3 = textToShow?.[keyToShow[3]] ?? "";
      const value4 = textToShow?.[keyToShow[4]] ?? "";
      const value5 = textToShow?.[keyToShow[5]] ?? "";
      const value6 = textToShow?.[keyToShow[6]] ?? "";
      const value7 = textToShow?.[keyToShow[7]] ?? "";
      paragraph2.textContent = `${value3}  ${value4}  ${value5}  ${value7}  ${value6}`;
      // paragraph2.textContent = `${value3}  ${value4} ${value5} ${value7}    ` + "\xa0\xa0\xa0\xa0\xa0" + `${value6} `;

      innerDiv.appendChild(paragraph1);
      innerDiv.appendChild(paragraph2);

      containerDiv.appendChild(innerDiv);

      printWindow.document.body.appendChild(containerDiv);

      allChild.push(containerDiv);
    }
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
    allChild.map((containerDiv: any) => {
      printWindow.document.body.removeChild(containerDiv);
    });
    printWindow.close();
  }

  ngOnDestroy() {
    this.unsuscribes$.next();
    this.unsuscribes$.complete();
    console.log("Modal Destroyed");
  }
}
